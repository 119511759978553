import { css } from 'styled-components';

export const OptionsButton = css`
	height: min-content;
	width: fit-content;
	border-radius: 0.4rem;
	border: 0.2rem solid var(--primary-blue);
	padding: 1.2rem 2rem;
	font-weight: bold;
	background-color: var(--primary-blue);
	color: var(--white);
`;
