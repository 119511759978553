import { create } from 'zustand';
import { FilterParams } from '../components/Filter';

type Store = {
	filtersParamsRequested: FilterParams[];
	setFiltersParamsRequested: (f: FilterParams[]) => void;
	filtersParamsReviewed: FilterParams[];
	setFiltersParamsReviewed: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const filtersParams: FilterParams[] = [
	{
		name: 'Nome',
		filter: 'name',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Email',
		filter: 'email',
		value: '',
		type: 'email',
		selected: false,
	},
	{
		name: 'Empresa',
		filter: 'company_name',
		value: '',
		type: 'company',
		selected: false,
	},
	{
		name: 'Grupo corporativo',
		filter: 'corporate_name',
		value: '',
		type: 'group_company',
		selected: false,
	},
];

const useUnderageDocsListStore = create<Store>()((set) => ({
	filtersParamsRequested: filtersParams,
	setFiltersParamsRequested: (f) => set({ filtersParamsRequested: f }),
	filtersParamsReviewed: filtersParams,
	setFiltersParamsReviewed: (f) => set({ filtersParamsReviewed: f }),
	resetFilters: () =>
		set({
			filtersParamsRequested: filtersParams,
			filtersParamsReviewed: filtersParams,
		}),
}));

export { useUnderageDocsListStore };
