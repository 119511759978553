import styled from 'styled-components';
import { OptionsButton } from '../../../components/Button';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
`;

export const ReportButton = styled.button`
	${OptionsButton}
	width: 18rem;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	column-gap: 2rem;
`;

export const StatsCaptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	font-size: 1.2rem;
	row-gap: 0.5rem;
	margin-top: 2rem;
`;

export const FormattedDate = styled.span`
	display: flex;
	justify-content: center;
`;
