import { Chargeback, ProductOrigin } from '../../@types';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../hooks/useSortColumnHook';
import api from '../api';

export interface ChargebackBodyQuery {
	value: number;
	justification: string;
	company_id: string | undefined;
	origin: ProductOrigin;
}

export interface ChargebacksListReturn {
	chargebacks: Chargeback[];
	totalChargebacks: number;
}

export async function createChargeback(
	body: ChargebackBodyQuery
): Promise<void> {
	await api.post(`api/v1/master/chargeback`, body);
}

export async function fetchChargeback(
	chargebackId: string
): Promise<Chargeback> {
	const { data } = await api.get<Chargeback>(
		`/api/v1/master/chargeback/${chargebackId}`
	);

	return data;
}

export async function getChargebacks(
	chargebacksCurrentPage: number,
	sortColumn: SortColumn | null
): Promise<ChargebacksListReturn> {
	const { data } = await api.get(`api/v1/master/chargebacks/q`, {
		params: {
			page: chargebacksCurrentPage,
			...parseSortColumnToParams(sortColumn),
		},
	});

	const chargebacks = data.chargebacks;
	const totalChargebacks = data.totalChargebacks;

	return {
		chargebacks,
		totalChargebacks,
	};
}

export async function getFilteredChargebacks(
	chargebacksFiltersParams: FilterParams[],
	page: number,
	sortColumn: SortColumn | null
): Promise<ChargebacksListReturn> {
	const { data } = await api.get(`/api/v1/master/chargebacks/filter/q`, {
		params: {
			page,
			...parseFilterParamsToParams(chargebacksFiltersParams),
			...parseSortColumnToParams(sortColumn),
		},
	});

	return data;
}
