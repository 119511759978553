import { useState } from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';
import Modal from '../../../../../../../components/Modal';
import KYC_RESULTS from './KYC_RESULTS.json';
import * as S from './styles';

export interface KycModalProps {
	kycDetails: string | undefined;
}

export function ModalKycReprovedDetails({ kycDetails }: KycModalProps) {
	const [isOpen, setIsOpen] = useState(false);

	function getModalContent() {
		if (kycDetails === 'Divergent Basic Data') {
			return (
				<>
					<S.ModalTitle>Detalhamento: Dados Básicos Divergentes</S.ModalTitle>
					<S.Divider />

					<S.ModalText>
						Você deve checar os seguintes campos para realizar a correção:
					</S.ModalText>

					<S.ModalList>
						<S.ListItem>CNPJ</S.ListItem>
						<S.ListItem>Razão social</S.ListItem>
						<S.ListItem>Data de fundação</S.ListItem>
					</S.ModalList>
				</>
			);
		} else {
			const kyc_result =
				KYC_RESULTS[String(kycDetails) as keyof typeof KYC_RESULTS];

			if (!kyc_result) {
				return (
					<>
						<S.ModalTitle>
							Detalhamento: Rejeitado por políticas internas
						</S.ModalTitle>
						<S.Divider />
						<S.ModalText>
							Mais informações devem ser enviadas via ticket de suporte.
						</S.ModalText>
					</>
				);
			}

			return (
				<>
					<S.ModalTitle>
						Detalhamento: Campo de '{kyc_result.param}' inválido.
					</S.ModalTitle>
					<S.Divider />
					<S.ModalText>{kyc_result.msg}.</S.ModalText>
				</>
			);
		}
	}

	return (
		<>
			<FaRegQuestionCircle
				onClick={() => setIsOpen(true)}
				data-testid='ModalKycReprovedDetails-open-btn'
			/>

			<Modal
				isOpen={isOpen}
				onRequestClose={() => setIsOpen(false)}
				enableClose
			>
				<S.ModalContainer>{getModalContent()}</S.ModalContainer>
			</Modal>
		</>
	);
}
