import { useState } from 'react';
import { MdNotes } from 'react-icons/md';
import { EmptyContent } from '../../../components/EmptyContent';
import PageTitle from '../../../components/PageTitle';
import * as TableStyle from '../../../components/Table/TableStyles';
import { parseDate } from '../../../utils/parseDate';
import Loader from '../../../components/Loader';
import * as S from './styles';
import { Pagination } from '../../../components/Pagination';
import { Filter } from '../../../components/Filter';
import { Chargeback } from '../../../@types';
import CompanyCard from '../../../components/CompanyCard';
import {
	ChargebacksListReturn,
	getChargebacks,
	getFilteredChargebacks,
} from '../../../services/queries/Chargebacks';
import { useQuery, useQueryClient } from 'react-query';
import { useAuth } from '../../../hooks/useAuth';
import { convertCentsToReais } from '../../../utils/CurrencyConvert';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { useChargebacksListStore } from '../../../stores/useChargebacksListStore';
import { useSetHistoryNonMatchCallbackLocation } from '../../../hooks/useSetHistoryNonMatchCallbackLocation';
import { useSortColumnHook } from '../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../components/SortColumnButton';
import { ProductOriginLabel } from '../../../components/ProductOriginLabel';

export function Chargebacks() {
	const queryClient = useQueryClient();
	const [showingTable, setShowingTable] = useState<string>();
	const { updateMasterBalance } = useAuth();
	const [chargebacksCurrentPage, setChargebacksCurrentPage] = useState(1);
	const { currentSortColumn, toggleSort } = useSortColumnHook();

	const [filtersParams, setFiltersParams, resetFilters] =
		useChargebacksListStore((state) => [
			state.filtersParams,
			state.setFiltersParams,
			state.resetFilters,
		]);

	useSetHistoryNonMatchCallbackLocation('chargebacks', resetFilters);

	const getChargebacksQuery = useQuery<ChargebacksListReturn, Error>(
		['chargebacksList', chargebacksCurrentPage, currentSortColumn],
		() => {
			return getChargebacks(chargebacksCurrentPage, currentSortColumn);
		},
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar os estornos. ');
			},
			keepPreviousData: true,
		}
	);

	const filteredChargebacksQuery = useQuery<ChargebacksListReturn, Error>(
		[
			'filteredChargebacks',
			filtersParams,
			chargebacksCurrentPage,
			currentSortColumn,
		],
		() => {
			return getFilteredChargebacks(
				filtersParams,
				chargebacksCurrentPage,
				currentSortColumn
			);
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar os estornos. '
				);
			},
			enabled:
				!!filtersParams.find((f) => !!f.value) && !!chargebacksCurrentPage,
		}
	);

	function getTableTotalRecords() {
		if (filtersParams.find((f) => !!f.value)) {
			return filteredChargebacksQuery.data?.totalChargebacks;
		}
		return getChargebacksQuery.data?.totalChargebacks;
	}

	function refreshPage() {
		setChargebacksCurrentPage(1);
		queryClient.resetQueries('chargebacksList');
		queryClient.resetQueries('filteredChargebacks');
		updateMasterBalance.refetch();
	}

	function generateRow(chargeback: Chargeback) {
		return (
			<TableStyle.TableRow key={chargeback.id}>
				<TableStyle.TableData>
					<CompanyCard
						company_name={chargeback.company.name}
						cnpj={chargeback.company.cnpj}
						avatar_url={chargeback.company.avatar_url}
					/>
				</TableStyle.TableData>
				<TableStyle.TableData>
					<ProductOriginLabel origin={chargeback.origin} />
				</TableStyle.TableData>
				<TableStyle.TableData>
					{convertCentsToReais(
						Number(
							chargeback.reversed_value
								? chargeback.reversed_value
								: chargeback.value
						)
					)}
				</TableStyle.TableData>
				<TableStyle.TableData>
					{parseDate(chargeback.created_at, 1)}
				</TableStyle.TableData>
				<TableStyle.TableData>
					<TableStyle.OptionLink to={`/chargebacks/${chargeback.id}`}>
						<MdNotes />
					</TableStyle.OptionLink>
				</TableStyle.TableData>
			</TableStyle.TableRow>
		);
	}

	function getTable(tableName: string, chargebacks?: Chargeback[]) {
		if (!chargebacks?.length) {
			return (
				<>
					<TableStyle.TitleWrapper>
						<PageTitle title={tableName} />
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								setChargebacksCurrentPage(1);
								setFiltersParams(updatedFilters);
							}}
						/>
						<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
					</TableStyle.TitleWrapper>
					<EmptyContent text='Não há ítens a serem exibidos.' />
				</>
			);
		}

		return (
			<>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle
							title='Solicitações de estorno'
							totalRecords={getTableTotalRecords() ?? 0}
						/>
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								setChargebacksCurrentPage(1);
								setFiltersParams(updatedFilters);
							}}
						/>
						{showingTable === tableName ? (
							<TableStyle.SeeLess
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver menos
							</TableStyle.SeeLess>
						) : (
							<TableStyle.SeeMore
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver mais
							</TableStyle.SeeMore>
						)}
					</TableStyle.TitleWrapper>
					{showingTable === tableName ? (
						tableName === 'Solicitações de estorno' ? (
							filtersParams.find((f) => !!f.value) ? (
								<Pagination
									onPageChange={(page) => setChargebacksCurrentPage(page)}
									currentPage={chargebacksCurrentPage}
									totalCount={
										filteredChargebacksQuery.data
											? filteredChargebacksQuery.data.totalChargebacks
											: 1
									}
								/>
							) : (
								<Pagination
									onPageChange={(page) => setChargebacksCurrentPage(page)}
									currentPage={chargebacksCurrentPage}
									totalCount={
										getChargebacksQuery.data
											? getChargebacksQuery.data.totalChargebacks
											: 1
									}
								/>
							)
						) : (
							<></>
						)
					) : (
						<></>
					)}
					<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
				</TableStyle.TableHeaderContainer>

				<TableStyle.Table>
					<TableStyle.TableHeader>
						<TableStyle.TableRow>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'company'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('company')}
								/>
								EMPRESA
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>PRODUTO</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'value'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('value')}
								/>
								VALOR
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'created_at'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('created_at')}
								/>
								DATA
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>OPÇÕES</TableStyle.TableHeaderCell>
						</TableStyle.TableRow>
					</TableStyle.TableHeader>

					<TableStyle.TableBody>
						{showingTable === tableName
							? chargebacks.map((chargebacks) => generateRow(chargebacks))
							: generateRow(chargebacks[0])}
					</TableStyle.TableBody>
				</TableStyle.Table>
				{chargebacks.length < 1 && (
					<EmptyContent text='Não há ítens a serem exibidos.' />
				)}
			</>
		);
	}

	if (
		getChargebacksQuery.isLoading ||
		filteredChargebacksQuery.isLoading ||
		getChargebacksQuery.isPreviousData
	) {
		return (
			<S.Container>
				<PageTitle title='Solicitações de estorno' />
				<Loader />
				<S.CreateButton to='/chargebacks/create'>Criar estorno</S.CreateButton>
			</S.Container>
		);
	}

	return (
		<S.Container>
			{getTable(
				'Solicitações de estorno',
				filtersParams.find((f) => !!f.value)
					? filteredChargebacksQuery.data?.chargebacks
					: getChargebacksQuery.data?.chargebacks
			)}

			<S.CreateButton to='/chargebacks/create'>Criar estorno</S.CreateButton>
		</S.Container>
	);
}
