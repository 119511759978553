import { LeafletStock, LeafletStockHistory } from '../../@types';
import { UpdateLeafletStockData } from '../../pages/Main/Cards/CardStock';
import api from '../api';

interface LeafletStockReturn {
	quantity_total_envelopes: number;
	quantity_envelopes_lost: number;
	quantity_envelopes_personalized: number;
	quantity_envelopes_available: number;
	quantity_total_accompanying_letters: number;
	quantity_accompanying_letters_lost: number;
	quantity_accompanying_letters_personalized: number;
	quantity_accompanying_letters_available: number;
}
export async function getLeafletStock(): Promise<LeafletStock> {
	const { data } = await api.get<LeafletStockReturn>(
		`/api/v1/master/leaflet-stock`
	);

	return {
		quantityTotalEnvelopes: data.quantity_total_envelopes,
		quantityEnvelopesAvailable: data.quantity_envelopes_available,
		quantityEnvelopesLost: data.quantity_envelopes_lost,
		quantityEnvelopesPersonalized: data.quantity_envelopes_personalized,

		quantityTotalAccompanyingLetters: data.quantity_total_accompanying_letters,
		quantityAccompanyingLettersAvailable:
			data.quantity_accompanying_letters_available,
		quantityAccompanyingLettersLost: data.quantity_accompanying_letters_lost,
		quantityAccompanyingLettersPersonalized:
			data.quantity_accompanying_letters_personalized,
	};
}

interface GetAllLeafletStockReturn {
	leafletStockMasterHistory: LeafletStockHistory[];
	totalLeafletStockMasterHistory: number;
}
export async function getAllLeafletStock() {
	const { data } = await api.get<GetAllLeafletStockReturn>(
		'/api/v1/master/leaflet-stock-history'
	);
	return data;
}

export async function updateLeafletStock(body: UpdateLeafletStockData) {
	const { data } = await api.put(`/api/v1/master/leaflet-stock-edit/`, body);
	return data;
}
