import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const MainContent = styled.div`
	min-height: 100vh;
`;

export const Sidebar = styled.aside`
	display: flex;
	flex-direction: column;
	min-height: 100%;
	max-width: 30rem;
	min-width: 28rem;
	padding: 1rem;
	border-right: 1px solid var(--light-gray);
`;

export const PagesContainer = styled.div`
	flex: 1;
	width: 100%;
	max-width: 100%;
	overflow-x: auto;
`;
