import { CardStockMasterHistory } from '../../../../../services/queries/CardsBatchs';
import { toast } from 'react-toastify';
import { Document, Page, Text, View, pdf, Image } from '@react-pdf/renderer';
import * as TableStyle from '../../../../../components/Table/TableStyles';
import { FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { formatDateWithHours } from '../../../../../utils/parseDate';
import * as Excel from 'exceljs';
import { StyleSheet } from '@react-pdf/renderer';
import ArrowUp from '../../../../../assets/arrowUp.png';
import ArrowDown from '../../../../../assets/arrowDown.png';

export interface QuantityProps {
	valueQuantity: CardStockMasterHistory | undefined;
}

export function GenerateHistoryPDForExcel({ valueQuantity }: QuantityProps) {
	const PDFstyles = StyleSheet.create({
		page: {
			flexDirection: 'row',
			width: '297mm',
			height: '210mm',
			orientation: 'landscape',
		},
		section: {
			margin: 10,
			padding: 10,
			flexDirection: 'column',
		},
		resultsRow: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			columnGap: 10,
			marginTop: 10,
		},
		heading: {
			fontSize: 24,
			marginBottom: 10,
			marginLeft: 200,
			marginTop: 20,
			fontFamily: 'Ubuntu',
			color: '#575757',
		},
		table: {
			width: '100%',
			borderCollapse: 'collapse',
		},
		tableHeaderCell: {
			backgroundColor: '#393939',
			color: 'white',
			fontSize: 14,
			textAlign: 'center',
			padding: 8,
		},
		tableDataCell: {
			fontSize: 12,
			textAlign: 'center',
			padding: 8,
			margin: 1,
			borderBottomColor: '#000',
			borderBottomWidth: 1,
			color: '#575757',
		},
		footer: {
			marginTop: 'auto',
			fontSize: 10,
			width: '100%',
			display: 'flex',
			color: '#575757',
			alignItems: 'center',
		},
		logo: {
			width: 100,
			position: 'absolute',
			left: 10,
		},
	});

	const getColumnTitleStyle = (width: Number) => ({
		...PDFstyles.tableHeaderCell,
		width: width + '%',
	});

	const getColumnDataStyle = (width: Number) => ({
		...PDFstyles.tableDataCell,
		width: width + '%',
	});

	async function generatePDFContent() {
		const currentTime = new Date();

		return (
			<Document>
				<Page size='A4' style={PDFstyles.page} orientation='landscape'>
					<View style={PDFstyles.section}>
						<View>
							<Image
								src={`${window.location.origin}/LogoBounty.png`}
								style={PDFstyles.logo}
							/>
							<Text style={PDFstyles.heading}>
								Histórico de modificações de estoque
							</Text>
						</View>

						<View style={PDFstyles.table}>
							<View style={PDFstyles.resultsRow}>
								<Text style={{ ...getColumnTitleStyle(100) }}>
									DATA | HORA {'\n'}
									<Text>
										dd/mm/aaaa | <Text> hh/mm/ss</Text>
									</Text>
								</Text>
								<Text style={{ ...getColumnTitleStyle(80) }}>
									QNTD PLÁSTICO {'\n'}
									<Text>
										valor antigo |{' '}
										<Text style={{ fontWeight: 'bold' }}>valor novo</Text>
									</Text>
								</Text>
								<Text style={{ ...getColumnTitleStyle(80) }}>
									QNTD PERSONALIZADO {'\n'}
									<Text>
										valor antigo |{' '}
										<Text style={{ fontWeight: 'bold' }}>valor novo</Text>
									</Text>
								</Text>
								<Text style={{ ...getColumnTitleStyle(80) }}>
									QNTD PRONTO {'\n'}
									<Text>
										valor antigo |{' '}
										<Text style={{ fontWeight: 'bold' }}>valor novo</Text>
									</Text>
								</Text>
							</View>

							{valueQuantity?.cardStockMasterHistory.map((historyItem) => (
								<View style={PDFstyles.resultsRow} key={historyItem.id}>
									<Text style={{ ...getColumnDataStyle(100) }}>
										{`${formatDateWithHours(historyItem.created_at)}`}
									</Text>
									<Text style={{ ...getColumnDataStyle(80) }}>
										{`${historyItem.original_value_quantity_raw}`} /{' '}
										<Text
											style={{ fontSize: 13, color: 'black' }}
										>{`${historyItem.new_value_quantity_raw}`}</Text>
										{Number(historyItem.new_value_quantity_raw) !==
											Number(historyItem.original_value_quantity_raw) && (
											<Image
												source={
													Number(historyItem.new_value_quantity_raw) >
													Number(historyItem.original_value_quantity_raw)
														? ArrowUp
														: ArrowDown
												}
												style={{ width: 12, height: 12, marginLeft: 5 }}
											/>
										)}
									</Text>
									<Text style={{ ...getColumnDataStyle(80) }}>
										{`${historyItem.original_value_quantity_embossed}`} /{' '}
										<Text
											style={{ fontSize: 13, color: 'black' }}
										>{`${historyItem.new_value_quantity_embossed}`}</Text>
										{Number(historyItem.new_value_quantity_embossed) !==
											Number(historyItem.original_value_quantity_embossed) && (
											<Image
												source={
													Number(historyItem.new_value_quantity_embossed) >
													Number(historyItem.original_value_quantity_embossed)
														? ArrowUp
														: ArrowDown
												}
												style={{ width: 12, height: 12, marginLeft: 5 }}
											/>
										)}
									</Text>
									<Text style={{ ...getColumnDataStyle(80) }}>
										{`${historyItem.original_value_quantity_cards_available}`} /{' '}
										<Text
											style={{ fontSize: 13, color: 'black' }}
										>{`${historyItem.new_value_quantity_cards_available}`}</Text>
										{Number(historyItem.new_value_quantity_cards_available) !==
											Number(
												historyItem.original_value_quantity_cards_available
											) && (
											<Image
												source={
													Number(
														historyItem.new_value_quantity_cards_available
													) >
													Number(
														historyItem.original_value_quantity_cards_available
													)
														? ArrowUp
														: ArrowDown
												}
												style={{ width: 12, height: 12, marginLeft: 5 }}
											/>
										)}
									</Text>
								</View>
							))}

							<View style={PDFstyles.footer}>
								<Text>
									Gerado em {currentTime.toLocaleDateString()} às{' '}
									{currentTime.toLocaleTimeString([], {
										hour: '2-digit',
										minute: '2-digit',
									})}{' '}
									no Bounty Master.
								</Text>
							</View>
						</View>
					</View>
				</Page>
			</Document>
		);
	}

	async function generateSheetContent() {
		const workbook = new Excel.Workbook();

		const worksheet = workbook.addWorksheet(
			'Histórico de modificações de estoque'
		);

		let columns = [
			{ header: 'DATA-HORA', key: 'date', width: 40 },
			{ header: 'NOVA QNTD PLÁSTICO', key: 'nqntdp', width: 20 },
			{ header: 'NOVA QNTD PERSONALIZADO', key: 'nqntdperso', width: 20 },
			{ header: 'NOVA QNTD PRONTO', key: 'nqtdpronto', width: 20 },
		];

		// headers
		worksheet.columns = columns;

		const valueQuantityHistory = valueQuantity?.cardStockMasterHistory;

		if (valueQuantityHistory) {
			valueQuantityHistory.forEach((historyItem) => {
				const row = {
					date: formatDateWithHours(historyItem.created_at),
					nqntdp: historyItem.new_value_quantity_raw,
					nqntdperso: historyItem.new_value_quantity_embossed,
					nqtdpronto: historyItem.new_value_quantity_cards_available,
				};
				worksheet.addRow(row);
			});
		}

		const buffer = await workbook.xlsx.writeBuffer();
		const fileType =
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

		const blob = new Blob([buffer], { type: fileType });

		return blob;
	}

	async function handleGenerateStockHistory(pdfReport: boolean) {
		try {
			let blob: Blob;

			if (pdfReport) {
				const PDFGenerated = await generatePDFContent();
				blob = await pdf(PDFGenerated).toBlob();
			} else {
				blob = await generateSheetContent();
			}

			// Create a temporary URL for the blob
			const url = URL.createObjectURL(blob);

			// Create a link element
			const link = document.createElement('a');
			link.href = url;
			link.download = `historico_modificacoes_estoque.${
				pdfReport ? 'pdf' : 'xlsx'
			}`;

			// Simulate a click event to trigger the download
			link.dispatchEvent(new MouseEvent('click'));

			// Clean up the temporary URL
			URL.revokeObjectURL(url);
		} catch (error) {
			toast.error(
				'Ocorreu um erro ao tentar gerar o arquivo do histórico de modificações de estoque. Tente novamente.'
			);
		}
	}

	return (
		<>
			<TableStyle.OptionButton
				data-rh='Baixar histórico PDF'
				onClick={async () => await handleGenerateStockHistory(true)}
			>
				<FaFilePdf />
			</TableStyle.OptionButton>

			<TableStyle.OptionButton
				data-rh='Baixar histórico XLSX'
				onClick={async () => await handleGenerateStockHistory(false)}
			>
				<FaFileExcel />
			</TableStyle.OptionButton>
		</>
	);
}
