import { useForm } from 'react-hook-form';

import { useState } from 'react';

import * as S from './styles';
import { toast } from 'react-toastify';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useMutation, useQueryClient } from 'react-query';
import { useDialogModal } from '../../../../../hooks/useDialogModal';
import { createOperator } from '../../../../../services/queries/Users';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { GroupCompany, Operator } from '../../../../../@types';
import { trimObjectData } from '../../../../../utils/trimObjectData';
import { firebaseAuth } from '../../../../../services/firebase';
import Modal from '../../../../../components/Modal';
import * as FormStyle from '../../../../../components/Form/FormStyles';
import {
	ObrigatoryFieldsIndication,
	ObrigatoryIndicator,
} from '../../../../../components/ObrigatoryFieldsIndicator';
import { emailPattern } from '../../../../../utils/patterns';
import Loader from '../../../../../components/Loader';

export interface OperatorFormData {
	name: string;
	email: string;
	access_level: string;
	corporate_id?: string;
}

export function CreateOperatorModal({
	groupCompany,
	hasControlAdmin,
	hasFlexAdmin,
}: {
	groupCompany: GroupCompany;
	hasControlAdmin: boolean;
	hasFlexAdmin: boolean;
}) {
	const { openOptionsDialog } = useDialogModal();

	const [isOpen, setIsOpen] = useState(false);

	const { register, handleSubmit, formState, reset } =
		useForm<OperatorFormData>();

	const { isDirty } = formState;

	const queryClient = useQueryClient();

	const createOperatorMutation = useMutation(
		(operator: OperatorFormData) => {
			return createOperator(operator);
		},
		{
			onSuccess: async (data: Operator) => {
				await queryClient.invalidateQueries('listOperators');
				await queryClient.invalidateQueries('fetchGroupCompany');
				toast.info('Operador criado com sucesso');
				setIsOpen(false);
				reset();
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu algum problema ao tentar criar o operador. '
				);
			},
		}
	);

	async function handleSubmitForm(data: OperatorFormData) {
		data.corporate_id = groupCompany.id;

		const operator_created = await createOperatorMutation.mutateAsync(
			trimObjectData(data)
		);

		await createUserWithEmailAndPassword(
			firebaseAuth,
			operator_created.email,
			operator_created.email
		).catch((err) => {
			console.log(err.message);
		});
	}

	function handleClose() {
		if (!isDirty) {
			setIsOpen(false);
			return;
		}

		openOptionsDialog(
			'Alterações não salvas. Tem certeza que deseja sair?',
			'Confirmar',
			() => {
				setIsOpen(false);
				reset();
			},
			'Cancelar',
			() => {}
		);
	}

	return (
		<>
			<S.MainButton
				type='button'
				disabled={hasControlAdmin && hasFlexAdmin}
				onClick={() => {
					setIsOpen(true);
				}}
			>
				Criar admin
			</S.MainButton>

			<Modal isOpen={isOpen} enableClose={true} onRequestClose={handleClose}>
				<S.Container
					onSubmit={(e) => {
						e.stopPropagation();
						handleSubmit(handleSubmitForm)(e);
					}}
					data-testid='operator_form_test_id'
				>
					<FormStyle.FormContainer>
						{/* COMPANY DATA FIELDSET */}
						<FormStyle.FieldSet>
							<FormStyle.FieldGroup>
								<FormStyle.Field
									style={{ paddingRight: '0.5rem' }}
								>
									<FormStyle.Label htmlFor='name'>
										Nome completo <ObrigatoryIndicator />
									</FormStyle.Label>
									<FormStyle.Input
										type='text'
										{...register('name')}
										name='name'
										data-testid='nameInput_test_id'
										required
									/>
								</FormStyle.Field>

								<FormStyle.Field>
									<FormStyle.Label htmlFor='contract_type'>
										Nível de Acesso <ObrigatoryIndicator />
									</FormStyle.Label>
									<FormStyle.SelectInput
										{...register('access_level')}
										name='access_level'
										data-testid='accessInput_test_id'
										required
									>
										<option
											disabled={hasFlexAdmin}
											value="admin"
											data-testid='access_select_option'
										>
											Admin Benefícios Flexíveis
										</option>
										<option
											disabled={hasControlAdmin}
											value='general_admin'
											data-testid='access_select_option'
										>
											Admin Control
										</option>
									</FormStyle.SelectInput>
								</FormStyle.Field>
							</FormStyle.FieldGroup>
						</FormStyle.FieldSet>

						<FormStyle.Divider />

						{/* CONTACT FIELDSET */}
						<FormStyle.FieldSet>
							<FormStyle.FieldGroup>
								<FormStyle.Field
									style={{ maxWidth: '50%', paddingRight: '0.5rem' }}
								>
									<FormStyle.Label htmlFor='email'>
										Email <ObrigatoryIndicator />
									</FormStyle.Label>
									<FormStyle.Input
										type='email'
										{...register('email')}
										placeholder='Ex: exemplo@email.com'
										pattern={emailPattern}
										name='email'
										data-testid='emailInput_test_id'
										required
									/>
								</FormStyle.Field>
							</FormStyle.FieldGroup>
						</FormStyle.FieldSet>
					</FormStyle.FormContainer>

					<ObrigatoryFieldsIndication />

					<FormStyle.ButtonsContainer>
						<FormStyle.LeftButtonsContainer>
							<FormStyle.FormButton type='submit'>
								{createOperatorMutation.isLoading ? (
									<Loader size={14} />
								) : (
									'Salvar'
								)}
							</FormStyle.FormButton>
						</FormStyle.LeftButtonsContainer>
					</FormStyle.ButtonsContainer>
				</S.Container>
			</Modal>
		</>
	);
}
