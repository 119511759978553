import api from "../api";

export async function session(email: string, password: string) {
    const response = await api.post("api/v1/master/session", { email, password });
  
    return response.data;
}

export async function getMasterBalance() {
  const { data } = await api.get("/api/v1/master/balances");

  return data;
}

export async function deleteToken() {
  await api.delete("/api/v1/master/notification/token");
}

export async function refreshToken(refreshToken: string) {
  const {data} = await api.post("/api/v1/master/refresh-token", {
    refresh_token: refreshToken,
  });

  return data;
}

  