import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';
import { useState } from 'react';
import Modal from '../../../../components/Modal';
import { RegisterShareholder } from '../../../../@types';
import * as S from './styles';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import * as FormStyle from '../../../../components/Form/FormStyles';
import { cnpjMask, cpfMask } from '../../../../utils/masks';
import { parseDateToInput } from '../../../../utils/parseDate';
import { userTypeToText } from '../../../../utils/preRegister';


export default function ShareholdersModal({ shareholders }: { shareholders: RegisterShareholder[] }) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [fileUrl, setFileUrl] = useState<string | null>(null);


	const PjShareholder = ({ shareholder }: { shareholder: RegisterShareholder }) =>
		<S.Details key={shareholder.id}>
			<summary>
				<S.SectionHeader>
					<div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
						<TypePartnerBox shareholder={shareholder}>
							Pessoa jurídica
						</TypePartnerBox>
						<h1>{shareholder.legal_name}</h1>
					</div>
					<MdOutlineKeyboardArrowDown />
				</S.SectionHeader>
			</summary>
			<FormStyle.FormContainer>
				<FormStyle.FieldSet>
					<FormStyle.FieldGroup>
						<FormStyle.Field>
							<FormStyle.Label>
								Razão Social
							</FormStyle.Label>
							<FormStyle.Input
								type="text"
								readOnly
								value={shareholder.legal_name}
							/>
						</FormStyle.Field>
						<FormStyle.Field>
							<FormStyle.Label>
								CNPJ
							</FormStyle.Label>
							<FormStyle.Input
								type="text"
								readOnly
								value={cnpjMask(shareholder.cnpj!)}
							/>
						</FormStyle.Field>
					</FormStyle.FieldGroup>
					<FormStyle.FieldGroup>
						<FormStyle.Field>
							<FormStyle.Label>
								Perfil do sócio
							</FormStyle.Label>
							<FormStyle.Input
								type="text"
								readOnly
								value={userTypeToText(shareholder.type)}
							/>
						</FormStyle.Field>
						{
							shareholder.social_contract_file?.url && (
								<FormStyle.FormButton type={"button"} style={{ width: '30rem', marginTop: '1rem' }}
																			onClick={() => setFileUrl(shareholder.social_contract_file?.url!)}>
									Contrato social
								</FormStyle.FormButton>
							)
						}
					</FormStyle.FieldGroup>
				</FormStyle.FieldSet>
			</FormStyle.FormContainer>
		</S.Details>;


	const PfShareholder = ({ shareholder }: { shareholder: RegisterShareholder }) =>
		<S.Details key={shareholder.id}>
			<summary>
				<S.SectionHeader>
					<div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
						<TypePartnerBox shareholder={shareholder}>
							Pessoa física
						</TypePartnerBox>
						<h1>{shareholder.first_name + ' ' + shareholder.last_name}</h1>
					</div>

					<MdOutlineKeyboardArrowDown />
				</S.SectionHeader>
			</summary>
			<FormStyle.FormContainer>
				<FormStyle.FieldSet>
					<FormStyle.FieldGroup>
						<FormStyle.Field>
							<FormStyle.Label>
								Nome completo
							</FormStyle.Label>
							<FormStyle.Input
								type="text"
								readOnly
								value={shareholder.first_name + " " + shareholder.last_name}
							/>
						</FormStyle.Field>
						<FormStyle.Field>
							<FormStyle.Label>
								CPF
							</FormStyle.Label>
							<FormStyle.Input
								type="text"
								readOnly
								value={cpfMask(shareholder.cpf!)}
							/>
						</FormStyle.Field>
						<FormStyle.Field>
							<FormStyle.Label>
								Data de nascimento
							</FormStyle.Label>
							<FormStyle.Input
								type="date"
								readOnly
								value={parseDateToInput(shareholder.birth_date!)}
							/>
						</FormStyle.Field>
					</FormStyle.FieldGroup>
					<FormStyle.FieldGroup>
						<FormStyle.Field>
							<FormStyle.Label>
								Perfil do sócio
							</FormStyle.Label>
							<FormStyle.Input
								type="text"
								readOnly
								value={userTypeToText(shareholder.type)}
							/>
						</FormStyle.Field>
						{
							shareholder.social_contract_file?.url && (
								<FormStyle.FormButton type={"button"} style={{ width: '30rem', marginTop: '1rem' }}
																			onClick={() => setFileUrl(shareholder.social_contract_file?.url!)}>
									Contrato social
								</FormStyle.FormButton>
							)
						}
					</FormStyle.FieldGroup>
				</FormStyle.FieldSet>
			</FormStyle.FormContainer>
		</S.Details>
	;


	return (
		<>
			<OpenModalButton onClick={() => setIsModalOpen(true)}>
				Sócios cadastrados
			</OpenModalButton>
			<Modal isOpen={isModalOpen} enableClose onRequestClose={() => setIsModalOpen(false)}>
				<div style={{ width: '70rem' }}>
					<h1>Sócios cadastrados</h1>
					{shareholders.map((shareholder, index) => shareholder.type_of_social_contract === 'PJ' ?
						<PjShareholder key={index} shareholder={shareholder} /> :
						<PfShareholder key={index} shareholder={shareholder} />)}
				</div>
				<OpenModalButton style={{marginTop: "2rem"}} onClick={() => setIsModalOpen(false)}>
					Fechar
				</OpenModalButton>
				<Modal
					isOpen={fileUrl != null}
					enableClose
					onRequestClose={() => setFileUrl(null)}
				>
					<div style={{ width: '90rem' }}>
						<embed
							src={fileUrl!}
							type="application/pdf"
							width="100%"
							height="700px"
							data-testid="embed-pdf"
						/>
					</div>
				</Modal>
			</Modal>
		</>
	);
}

export const OpenModalButton = styled.button`
    ${OptionsButton}
    padding: 1.2rem 3rem;
    background-color: var(--white);
    color: var(--dark-gray);
    border: 0.2rem solid var(--dark-gray);
`;

const TypePartnerBox = styled.div<{ shareholder: RegisterShareholder }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14.5rem;
    height: 2.6rem;
    padding: 1.6rem 0.8rem;
    border-radius: 1.6rem;
    background-color: ${(props) =>
            props.shareholder.type_of_social_contract === 'PF'
                    ? 'var(--primary-blue)'
                    : 'var(--primary-red)'};
    color: white;
    font-size: 1.4rem;
`;