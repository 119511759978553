export function preRegisterStatusToText(status: string) {
	switch (status) {
 		case 'step-one':
			return { message:  'Primeira etapa do cadastro', color: "var(--primary-blue)" };
		case 'step-one-approved':
			return { message: 'Primeira etapa do cadastro aprovada', color: "green" };
		case 'step-one-reproved':
			return { message: 'Primeira etapa do cadastro reprovada', color: "var(--primary-red)" };
		case 'step-two':
			return { message: 'Segunda etapa do cadastro', color: "var(--primary-blue)" }
		case 'step-two-approved':
			return { message: 'Segunda etapa do cadastro aprovada', color: "green" };
		case 'step-two-reproved':
			return { message: 'Segunda etapa do cadastro reprovada', color: "var(--primary-red)" };
		case "registration-completed":
			return { message: 'Cadastro finalizado', color: "green" }
		case "send-swap":
			return { message: 'Dados enviados para análise de kyc', color: "var(--primary-blue)" }
		case "kyc-reproved":
			return { message: 'A empresa foi reprovada na análise de kyc', color: "var(--primary-red)" }
		case "step-two-resent":
			return { message: 'Reenvio de dados', color: "var(--primary-blue)" }
		default:
			return { message: status, color: "var(--primary-blue)" }
	}
}

export function userTypeToText(type: string) {
	switch (type) {
		case 'partner':
			return 'Sócio'
		case 'proxyholder':
			return 'Procurador'
		case 'legal_representative':
			return 'Representante legal'
		case 'other':
			return 'Outro'
		default:
			return type
	}
}

export const primaryFunctions = [
	{ label: 'Sócio/Fundador', value: 'socio_fundador' },
	{ label: 'Procurador responsável', value: 'procurador_responsavel' },
	{ label: 'Responsável financeiro', value: 'responsavel_financeiro' },
	{ label: 'Time financeiro', value: 'time_financeiro' },
	{ label: 'Gestor de área', value: 'gestor_area' },
	{ label: 'Colaborador', value: 'colaborador' },
	{ label: 'Outro', value: 'outro' },
];

export function primaryFunctionsToText(value: string) {
	switch (value){
		case 'socio_fundador':
			return 'Sócio/Fundador'
		case 'procurador_responsavel':
			return 'Procurador responsável'
		case 'responsavel_financeiro':
			return 'Responsável financeiro'
		case 'time_financeiro':
			return 'Time financeiro'
		case 'gestor_area':
			return 'Gestor de área'
		case 'colaborador':
			return 'Colaborador'
		case 'outro':
			return 'Outro'
		default:
			return value
	}
}