import styled from 'styled-components';
import { OptionsButton } from '../../Button';

export const MainButton = styled.button`
	${OptionsButton}
`;

export const Container = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	min-height: 20rem;
`;

export const Title = styled.h2`
	color: var(--primary-blue);
	margin-bottom: 1rem;
`;

export const ProductsContainer = styled.div`
	display: flex;
	column-gap: 10rem;
	margin-left: 2rem;
`;

export const ProductContainer = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1rem;
`;
