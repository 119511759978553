import styled from 'styled-components';
import { OptionsButton } from '../Button';

export const Container = styled.div`
	width: 30rem;
	/* height: 12rem; */
	padding-bottom: 1rem;
`;

export const Title = styled.h1`
	align-self: center;
`;

export const Message = styled.p`
	font-size: 1.4rem;
	margin: 2rem 0;
`;

export const ActionsContainer = styled.div`
	display: flex;
	width: 80%;
	margin: 0 auto;
	justify-content: space-around;
`;

export const MainAction = styled.button`
	${OptionsButton}
	padding: 0.6rem 0;
	width: 10rem;
`;

export const SecondaryAction = styled.button`
	${OptionsButton}
	padding: 0.6rem 0;
	width: 10rem;
	background-color: var(--white);
	color: var(--primary-blue);
`;
