import { useState } from 'react';
import { RiAddCircleLine, RiCloseCircleLine } from 'react-icons/ri';
import { useMutation, useQuery } from 'react-query';
import { useAuth } from '../../hooks/useAuth';
import { getCompanyBenefits } from '../../services/queries/Benefits';
import { getBenefitIcon } from '../../utils/benefits/getBenefitIcon';
import { getBenefitParsedTitle } from '../../utils/benefits/getBenefitParsedTitle';
import Loader from '../Loader';
import Modal from '../Modal';
import * as S from './styles';
import {
	UpdateBenefitParams,
	updateBenefits,
} from '../../services/queries/Companies';
import { showErrorMessage } from '../../utils/ErrorHandler';
import { toast } from 'react-toastify';
import * as TableStyle from '../../components/Table/TableStyles';
import { FaWallet } from 'react-icons/fa';
import { useDialogModal } from '../../hooks/useDialogModal';
import { MasterBenefit } from '../../@types';

interface Props {
	companyId: string;
	smallButton: boolean;
}

interface SelectedBenefits {
	id_baas: string;
	label: string;
}

export function ManageBenefitsModal({ companyId, smallButton }: Props) {
	const { masterBenefits } = useAuth();
	const { openOptionsDialog } = useDialogModal();
	const [isOpen, setIsOpen] = useState(false);
	const [hasMadeChanges, setHasMadeChanges] = useState(false);
	const [selectedBenefitsIds, setSelectedBenefitsIds] = useState<
		SelectedBenefits[]
	>([]);

	const fetchBenefitsQuery = useQuery(
		['fetchBenefits', companyId],
		() => getCompanyBenefits(companyId),
		{
			onSuccess: (data) => {
				setSelectedBenefitsIds(
					data.map((b) => {
						return {
							id_baas: b.id_baas!,
							label: b.title,
						};
					})
				);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar os benefícios'
				);
			},
			enabled: isOpen,
			refetchOnWindowFocus: false,
		}
	);

	const updateCompanyBenefitsQuery = useMutation(
		({ benefits, companyId }: UpdateBenefitParams) => {
			return updateBenefits({ benefits, companyId });
		},
		{
			onSuccess: () => {
				toast.info('Benefícios atualizados');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar atualizar os benefícios da empresa. '
				);
			},
		}
	);

	async function updateCompanyBenefits(selectedBenefits: SelectedBenefits[]) {
		const benefits = selectedBenefits.map((b) => {
			return { id_baas: b.id_baas, label: b.label, value_default: 0 };
		});

		await updateCompanyBenefitsQuery.mutateAsync({
			benefits,
			companyId: companyId,
		});

		setHasMadeChanges(false);
	}

	function handleSubmit() {
		if (!fetchBenefitsQuery.data) {
			return;
		}

		//Checking if there were any changes in selected benefits
		const arrayDifference = selectedBenefitsIds
			.filter(
				(benefit) =>
					!fetchBenefitsQuery.data.find((b) => b.id_baas === benefit.id_baas)
			)
			.concat(
				fetchBenefitsQuery.data
					.filter(
						(b) =>
							!selectedBenefitsIds.includes({
								id_baas: b.id_baas,
								label: b.label,
							})
					)
					.map((b) => {
						return { id_baas: b.id_baas, label: b.label };
					})
			);

		if (arrayDifference.length > 0) {
			updateCompanyBenefits(selectedBenefitsIds);
		}

		setIsOpen(false);
	}

	function handleClose() {
		if (!hasMadeChanges) {
			setIsOpen(false);
			return;
		}

		openOptionsDialog(
			'Alterações não salvas. Tem certeza que deseja sair?',
			'Confirmar',
			() => setIsOpen(false),
			'Cancelar',
			() => {}
		);
	}

	function checkBenefitSelection(id_baas: string) {
		return !!selectedBenefitsIds.find((b) => b.id_baas === id_baas);
	}

	function handleToggleBenefitSelection(benefit: MasterBenefit) {
		if (checkBenefitSelection(benefit.id)) {
			setSelectedBenefitsIds([
				...selectedBenefitsIds.filter((b) => b.id_baas !== benefit.id),
			]);
		} else {
			setSelectedBenefitsIds([
				...selectedBenefitsIds,
				{ id_baas: benefit.id, label: benefit.label },
			]);
		}
		setHasMadeChanges(true);
	}

	if (fetchBenefitsQuery.isLoading || fetchBenefitsQuery.isError) {
		return (
			<>
				{smallButton ? (
					<TableStyle.OptionButton
						data-rh='Gerenciar benefícios'
						onClick={(e) => {
							e.preventDefault();
							setIsOpen(true);
						}}
					>
						<FaWallet />
					</TableStyle.OptionButton>
				) : (
					<S.ModalButton
						onClick={(e) => {
							e.preventDefault();
							setIsOpen(true);
						}}
					>
						Gerenciar Benefícios
					</S.ModalButton>
				)}

				<Modal
					isOpen={isOpen}
					enableClose={true}
					onRequestClose={() => {
						setIsOpen(false);
					}}
				>
					<S.Container>
						{fetchBenefitsQuery.isLoading && <Loader />}
						{fetchBenefitsQuery.isError && (
							<span>Ocorreu um problema ao buscar os benefícios</span>
						)}
					</S.Container>
				</Modal>
			</>
		);
	}

	return (
		<>
			{smallButton ? (
				<TableStyle.OptionButton
					data-rh='Gerenciar benefícios'
					onClick={(e) => {
						e.preventDefault();
						setIsOpen(true);
					}}
				>
					<FaWallet />
				</TableStyle.OptionButton>
			) : (
				<S.ModalButton
					onClick={(e) => {
						e.preventDefault();
						setIsOpen(true);
					}}
				>
					Gerenciar Benefícios
				</S.ModalButton>
			)}

			<Modal isOpen={isOpen} enableClose={true} onRequestClose={handleClose}>
				<S.Container>
					<S.Title>Selecione os benefícios para empresa</S.Title>

					<S.BenefitsList>
						{masterBenefits.map((benefit) => (
							<S.BenefitContainer key={benefit.id}>
								<S.TitleContainer>
									{getBenefitIcon(benefit.label)}{' '}
									{getBenefitParsedTitle(benefit.label)}
								</S.TitleContainer>
								<S.SelectBenefit
									onClick={() => handleToggleBenefitSelection(benefit)}
									selected={checkBenefitSelection(benefit.id)}
									data-testid={
										checkBenefitSelection(benefit.id)
											? `uncheck-${benefit.id}`
											: `check-${benefit.id}`
									}
								>
									{checkBenefitSelection(benefit.id) ? (
										<RiCloseCircleLine />
									) : (
										<RiAddCircleLine />
									)}
								</S.SelectBenefit>
							</S.BenefitContainer>
						))}
					</S.BenefitsList>
					<S.MainButton onClick={handleSubmit} style={{ width: '16rem' }}>
						Salvar
					</S.MainButton>
				</S.Container>
			</Modal>
		</>
	);
}
