import { FormEvent, useEffect, useState } from 'react';
import PageTitle from '../../../../components/PageTitle';
import * as FormStyles from '../../../../components/Form/FormStyles';
import * as S from './styles';
import { toast } from 'react-toastify';
import Loader from '../../../../components/Loader';
import { useDialogModal } from '../../../../hooks/useDialogModal';
import { Company, GroupCompany, ProductOrigin } from '../../../../@types';
import { SelectGroupCompany } from '../../../../components/SelectGroupCompany';
import { SelectCompany } from '../../../../components/SelectCompany';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../hooks/useAuth';
import { useMutation } from 'react-query';
import {
	ChargebackBodyQuery,
	createChargeback,
} from '../../../../services/queries/Chargebacks';
import PreventTransitionPrompt from '../../../../components/PreventTransitionPrompt';
import { convertReaisToCents } from '../../../../utils/CurrencyConvert';
import { showErrorMessage } from '../../../../utils/ErrorHandler';

export function CreateChargeback() {
	const navigate = useNavigate();
	const { updateMasterBalance } = useAuth();
	const { openConfirmDialog } = useDialogModal();
	const [selectedGroupCompany, setSelectedGroupCompany] =
		useState<GroupCompany | null>(null);
	const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
	const [value, setValue] = useState(0);
	const [justification, setJustification] = useState('');
	const [selectedProduct, setSelectedProduct] =
		useState<ProductOrigin>('multiflex');

	const [allowNavigation, setAllowNavigation] = useState(true);

	useEffect(() => {
		setAllowNavigation(
			!(
				value ||
				justification ||
				selectedGroupCompany ||
				selectedProduct !== 'multiflex'
			)
		);
	}, [value, justification, selectedGroupCompany, selectedProduct]);

	useEffect(() => {
		setSelectedCompany(null);
	}, [selectedGroupCompany]);

	function showSuccessMessage() {
		updateMasterBalance.refetch();
		openConfirmDialog(
			'A solicitação de estorno foi criada',
			'Entendi',
			() => {}
		);
		navigate('/chargebacks');
	}

	const createChargebackQuery = useMutation(
		(body: ChargebackBodyQuery) => {
			return createChargeback(body);
		},
		{
			onSuccess: () => {
				setAllowNavigation(true);
				showSuccessMessage();
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar criar o estorno. '
				);
			},
		}
	);

	function handleSubmitForm(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();

		if (value <= 0) {
			toast.error('Valor abaixo do mínimo permitido');
			return;
		}
		if (!selectedCompany || !selectedGroupCompany) {
			toast.error('Selecione uma empresa');
			return;
		}

		const body = {
			value: convertReaisToCents(value),
			justification,
			company_id: selectedCompany!.id,
			origin: selectedProduct,
		};

		createChargebackQuery.mutate(body);
	}

	return (
		<S.Container>
			<PreventTransitionPrompt when={!allowNavigation} />

			<PageTitle title='Solicitar estorno' />
			<S.Form onSubmit={(e) => handleSubmitForm(e)}>
				<S.FormContainer>
					<FormStyles.Field>
						<FormStyles.Label>Grupo corporativo</FormStyles.Label>
						<SelectGroupCompany
							selectedGroupCompany={selectedGroupCompany}
							onGroupCompanySelection={(g) => setSelectedGroupCompany(g)}
						/>
					</FormStyles.Field>
					<FormStyles.Field>
						<FormStyles.Label>Empresa</FormStyles.Label>
						<SelectCompany
							groupCompanyId={
								selectedGroupCompany ? selectedGroupCompany.id : null
							}
							selectedCompany={selectedCompany}
							onCompanySelection={(c) => setSelectedCompany(c)}
						/>
					</FormStyles.Field>

					<FormStyles.Field style={{ width: 'fit-content' }}>
						<FormStyles.Label htmlFor='product'>Produto</FormStyles.Label>

						<FormStyles.SelectInput
							id='product'
							value={selectedProduct}
							onChange={(e) =>
								setSelectedProduct(e.target.value as ProductOrigin)
							}
						>
							<option value='multiflex'>Benefícios Flexíveis</option>
							<option value='corpway'>Bounty Control</option>
						</FormStyles.SelectInput>
					</FormStyles.Field>

					<FormStyles.Field>
						<FormStyles.Label>Valor</FormStyles.Label>
						<FormStyles.FormCurrencyInput
							style={{ width: 'fit-content' }}
							value={value}
							onValueChange={({ floatValue }) => setValue(floatValue)}
							required
						/>
					</FormStyles.Field>
				</S.FormContainer>

				<FormStyles.Field>
					<FormStyles.Label>Justificativa</FormStyles.Label>
					<FormStyles.InputTextArea
						value={justification}
						onChange={(e) => setJustification(e.target.value)}
						data-testid='justification-input'
						required
					/>
				</FormStyles.Field>

				<S.Submit type='submit' disabled={createChargebackQuery.isLoading}>
					{!createChargebackQuery.isLoading ? (
						'Enviar'
					) : (
						<Loader size={20} color='var(--white)' />
					)}
				</S.Submit>
			</S.Form>
		</S.Container>
	);
}
