import styled from 'styled-components';
import { OptionsButton } from '../../../../../components/Button';

export const Container = styled.div`
	margin-bottom: 4rem;
	width: 80rem;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const MainBtn = styled.button`
	${OptionsButton}
	width: 14rem;
`;
export const MainBtnSmall = styled.button`
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	background-color: var(--primary-blue);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	margin: 0 auto;
	& > svg {
		color: var(--white);
		width: 100%;
		height: 100%;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-height: 40rem;
	overflow-y: auto;
	margin: 2rem 0;
	row-gap: 2rem;
`;

export const ReferenceDate = styled.span`
	display: block;
	text-align: center;
	font-size: 1.6rem;
	font-weight: 500;
`;

export const Total = styled.span`
	display: block;
	margin-left: 1rem;
	font-weight: 500;
	font-size: 1.2rem;
	margin-top: 1rem;
`;

export const FooterOptionsContainer = styled.div`
	display: flex;
	column-gap: 2rem;
	margin-top: 1rem;
`;

export const Option = styled.button`
	${OptionsButton}
	width: 20rem;
	padding: 0.6rem 0;
`;
