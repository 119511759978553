import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
	margin-bottom: 2rem;
`;

export const FilterCaption = styled.span`
	color: rgba(198, 198, 198, 1);
	display: block;
	font-size: 1.4rem;
	margin-left: 1rem;
	margin-top: 2rem;
`;

export const TablesContainer = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 70rem;
	margin: 4rem 0;
`;

export const ReferenceDate = styled.span`
	display: block;
	font-size: 1.4rem;
	font-weight: 500;
`;

export const Total = styled.span`
	margin-left: 1rem;
	font-weight: 500;
	font-size: 1.2rem;
`;
