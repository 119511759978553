import { CompanyBenefit, MasterBenefit } from '../../@types';
import api from '../api';

export async function getMasterBenefits() {
	const { data } = await api.get<MasterBenefit[]>('/api/v1/master/benefits');

	return data;
}

export async function getCompanyBenefits(companyId: string) {
	const { data } = await api.get<CompanyBenefit[]>(
		`/api/v1/master/companies/${companyId}/benefits`
	);

	return data;
}
