import { Link } from "react-router-dom";
import styled from "styled-components";
import { OptionsButton } from "../../../../components/Button";

export const Container = styled.div`
  flex: 1;
  padding: 4rem 7rem 2rem 7rem;
`;

export const CreateCompanyButton = styled(Link)`
  ${OptionsButton}
  font-size: 1.4rem;
`;

export const CreateCompany = styled(Link)`
  ${OptionsButton}
  width: fit-content;
  font-size: 1.5rem;
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  > svg {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }
`;
