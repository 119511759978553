import { useState } from 'react';
import { toast } from 'react-toastify';
import Modal from '../../../../components/Modal';
import * as S from './styles';
import * as FormStyle from '../../../../components/Form/FormStyles';
import { useMutation } from 'react-query';
import {
	createGroupCompany,
	GroupCompanyCreationBodyQuery,
} from '../../../../services/queries/GroupCompany';
import { trimObjectData } from '../../../../utils/trimObjectData';
import { showErrorMessage } from '../../../../utils/ErrorHandler';

interface GroupCompanyCreationModalProps {
	onGroupCompanyCreate(): void;
}

export function GroupCompanyCreationModal({
	onGroupCompanyCreate,
}: GroupCompanyCreationModalProps) {
	const [modalOpen, setModalOpen] = useState(false);
	const [name, setName] = useState('');

	const createGroupCompanyQuery = useMutation(
		(bodyData: GroupCompanyCreationBodyQuery) => {
			return createGroupCompany(bodyData);
		},
		{
			onSuccess: () => {
				setName('');
				onGroupCompanyCreate();
				toast.info('Grupo corporativo criado com sucesso.');
				setModalOpen(false)
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível criar o grupo corporativo. '
				);
				setModalOpen(false)
			},
		}
	);

	async function handleSubmitGroupCompanyCreation() {
		if (!name.trim().length) {
			toast.error('Insira o nome para o grupo corporativo.');
			return;
		}
		createGroupCompanyQuery.mutate(
			trimObjectData({
				name,
			})
		);
	}

	return (
		<>
			<S.AddGroupCompany
				onClick={() => {
					setModalOpen(true);
				}}
			>
				Adicionar grupo corporativo
			</S.AddGroupCompany>

			<Modal
				isOpen={modalOpen}
				onRequestClose={() => {
					setModalOpen(false);
				}}
				enableClose
			>
				<FormStyle.FormContainer>
					<FormStyle.FieldGroup>
						<FormStyle.Field>
							<FormStyle.Label>
								Título do grupo corporativo
							</FormStyle.Label>
							<FormStyle.Input
								type='text'
								value={name}
								placeholder='Título'
								required
								onChange={(e) => {
									setName(e.target.value);
								}}
							/>
						</FormStyle.Field>
					</FormStyle.FieldGroup>
					<FormStyle.ButtonsContainer>
						<S.CancelOption
							onClick={() => {
								setModalOpen(false);
							}}
						>
							Cancelar
						</S.CancelOption>
						<FormStyle.FormButton onClick={handleSubmitGroupCompanyCreation}>
							Salvar
						</FormStyle.FormButton>
					</FormStyle.ButtonsContainer>
				</FormStyle.FormContainer>
			</Modal>
		</>
	);
}
