import { useQuery } from 'react-query';
import Loader from '../../../../components/Loader';
import PageTitle from '../../../../components/PageTitle';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import * as S from './styles';
import { getCorpwayCardsQuantitative } from '../../../../services/queries/CorpwayCards';
import * as TableStyles from '../../../../components/Table/TableStyles';
import { CardsListModal } from './CardsListModal';
import { useSortColumnHook } from '../../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../../components/SortColumnButton';

export function CorpwayCards() {
	const { currentSortColumn, toggleSort, sortList } = useSortColumnHook();

	const fetchCardStatsQuery = useQuery(
		'corpway-cards',
		getCorpwayCardsQuantitative,
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar o quantitativo de cartões. '
				);
			},
		}
	);

	if (!fetchCardStatsQuery.data || fetchCardStatsQuery.isLoading)
		return (
			<S.Container>
				<PageTitle title='Cartões Virtuais' />
				<Loader />
			</S.Container>
		);

	return (
		<S.Container>
			<PageTitle title='Cartões Virtuais' />

			<TableStyles.Table>
				<TableStyles.TableHeader>
					<TableStyles.TableRow>
						<TableStyles.TableHeaderCell>
							<SortColumnButton
								order={
									currentSortColumn?.name === 'company_name'
										? currentSortColumn.order
										: null
								}
								onToggleSort={() => toggleSort('company_name')}
							/>
							Empresa
						</TableStyles.TableHeaderCell>
						<TableStyles.TableHeaderCell>
							<SortColumnButton
								order={
									currentSortColumn?.name === 'cardCount'
										? currentSortColumn.order
										: null
								}
								onToggleSort={() => toggleSort('cardCount')}
							/>
							Quantidade
						</TableStyles.TableHeaderCell>
						<TableStyles.TableHeaderCell>Opções</TableStyles.TableHeaderCell>
					</TableStyles.TableRow>
				</TableStyles.TableHeader>

				<TableStyles.TableBody>
					{sortList(fetchCardStatsQuery.data.companyCardsQuantitatives).map(
						(stat) => (
							<TableStyles.TableRow key={stat.company_id}>
								<TableStyles.TableData>
									{stat.company_name}
								</TableStyles.TableData>
								<TableStyles.TableData>{stat.cardCount}</TableStyles.TableData>
								<TableStyles.TableData>
									<CardsListModal
										company={{ id: stat.company_id, name: stat.company_name }}
										disabled={Number(stat.cardCount) === 0}
									/>
								</TableStyles.TableData>
							</TableStyles.TableRow>
						)
					)}
				</TableStyles.TableBody>
			</TableStyles.Table>
		</S.Container>
	);
}
