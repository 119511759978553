import { useMemo, useState } from 'react';
import * as S from './styles';
import * as TableStyle from '../../../../components/Table/TableStyles';
import PageTitle from '../../../../components/PageTitle';
import {
	getAllCardsStockMasterHistory,
	getCardsStats,
	getCardsStockMasterHistory,
	updateCardStock,
} from '../../../../services/queries/CardsBatchs';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { GenerateHistoryPDForExcel } from './GenerateHistoryPDForExcel/GenerateHistoryPDForExcel';
import { formatDateWithHours } from '../../../../utils/parseDate';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import Loader from '../../../../components/Loader';
import { Pagination } from '../../../../components/Pagination';
import { ManageCardStock } from './ManageCardStock';
import { ManageLeafletStock } from './ManageLeafletStock';
import {
	getLeafletStock,
	updateLeafletStock,
} from '../../../../services/queries/Leaflet';
import { useManageLeafletStockStore } from '../../../../stores/useManageLeafletStock';
import {
	ManageCardStockState,
	useManageCardStockStore,
} from '../../../../stores/useManageCardStock';
import { toast } from 'react-toastify';

export type UpdateLeafletStockData = {
	quantity_total_envelopes: number;
	quantity_envelopes_lost: number;
	quantity_total_accompanying_letters: number;
	quantity_accompanying_letters_lost: number;
};

export function CardStock() {
	const queryClient = useQueryClient();
	const {
		state: leafletStockState,
		actions: { setState: setLeafletStockState },
	} = useManageLeafletStockStore();
	const {
		state: cardStockState,
		actions: { setState: setCardStockState },
	} = useManageCardStockStore();
	const [cardHistoryPage, setCardHistoryPage] = useState(1);

	const fetchLeafletStockQuery = useQuery('leaflet-stock', getLeafletStock, {
		refetchOnWindowFocus: false,
		onError: (err) => {
			showErrorMessage(
				err as Error,
				'Não foi possível buscar o quantitativo da folheteria. '
			);
		},
	});

	const fetchCardStatsQuery = useQuery('card-stats', getCardsStats, {
		refetchOnWindowFocus: false,
		onError: (err) => {
			showErrorMessage(
				err as Error,
				'Não foi possível buscar o quantitativo de cartões. '
			);
		},
	});

	const cardStockStats = useMemo(() => {
		return fetchCardStatsQuery.data
			? {
					quantityAvailable:
						fetchCardStatsQuery.data.bounty_card_stats.quantity_available,
					quantityEmbossed:
						fetchCardStatsQuery.data.bounty_card_stats.quantity_embossed,
					quantityRaw: fetchCardStatsQuery.data.bounty_card_stats.quantity_raw,
					quantityLost:
						fetchCardStatsQuery.data.bounty_card_stats.quantity_lost,
			  }
			: null;
	}, [fetchCardStatsQuery.data]);

	const fetchCardStockMasterHistory = useQuery(
		['card-history', cardHistoryPage],
		() => {
			return getCardsStockMasterHistory(cardHistoryPage);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar o histórico de mudanças de cartões. '
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	const fetchAllCardStockMasterHistory = useQuery(
		['all-card-history', cardHistoryPage],
		getAllCardsStockMasterHistory,
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar o histórico de mudanças de cartões. '
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	const updateCardStockMutation = useMutation(
		({
			quantityAvailable,
			quantityEmbossed,
			quantityRaw,
			quantityLost,
		}: Omit<ManageCardStockState, 'editedFlag'>) =>
			updateCardStock(
				quantityAvailable,
				quantityEmbossed,
				quantityRaw,
				quantityLost
			),
		{
			onSuccess: () => {
				toast.info('Estoque de cartões editado com sucesso');
				queryClient.invalidateQueries(['card-history']);
				queryClient.invalidateQueries(['leaflet-stock']);
				queryClient.invalidateQueries(['leaflet-stock-history']);

				queryClient.resetQueries(['card-stats']);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu algum problema ao tentar atualizar o estoque do cartão. '
				);
			},
		}
	);

	const updateLeafletStockMutation = useMutation(
		(data: UpdateLeafletStockData) => {
			return updateLeafletStock(data);
		},
		{
			onSuccess: () => {
				toast.info('Estoque de folheteria editado com sucesso');
				queryClient.resetQueries(['leaflet-stock']);
				queryClient.invalidateQueries(['leaflet-stock-history']);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu algum problema ao tentar atualizar o estoque de folheteria. '
				);
			},
		}
	);

	const isSubmitButtonDisabled =
		updateCardStockMutation.isLoading ||
		updateLeafletStockMutation.isLoading ||
		(!cardStockState.editedFlag && !leafletStockState.editedFlag);

	function generateTable(tableName: string) {
		return (
			<>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle
							title={tableName}
							totalRecords={
								fetchCardStockMasterHistory.data?.totalCardStockMasterHistory ??
								0
							}
						/>
					</TableStyle.TitleWrapper>
					<Pagination
						onPageChange={(page) => setCardHistoryPage(page)}
						currentPage={cardHistoryPage}
						totalCount={
							fetchCardStockMasterHistory.data
								? fetchCardStockMasterHistory.data.totalCardStockMasterHistory
								: 1
						}
						onFirstPageButton={true}
						onLastPageButton={true}
					/>
				</TableStyle.TableHeaderContainer>

				<TableStyle.Table>
					<TableStyle.TableHeader>
						<TableStyle.TableRow>
							<S.TableHeaderCell>DATA | HORA</S.TableHeaderCell>
							<S.TableHeaderCell>
								QNTD. PLÁSTICO
								<br />
								<S.LabelHeader>valor antigo | valor novo</S.LabelHeader>
							</S.TableHeaderCell>
							<S.TableHeaderCell>
								QNTD PERSONALIZADO
								<br />
								<S.LabelHeader>valor antigo | valor novo</S.LabelHeader>
							</S.TableHeaderCell>
							<S.TableHeaderCell>
								QNTD PRONTO
								<br />
								<S.LabelHeader>valor antigo | valor novo</S.LabelHeader>
							</S.TableHeaderCell>
							<S.TableHeaderCell>
								QNTD PERDIDO
								<br />
								<S.LabelHeader>valor antigo | valor novo</S.LabelHeader>
							</S.TableHeaderCell>
						</TableStyle.TableRow>
					</TableStyle.TableHeader>
					<TableStyle.TableBody>
						{fetchCardStockMasterHistory.data?.cardStockMasterHistory.map(
							(historyItem) => (
								<TableStyle.TableRow key={historyItem.id}>
									<S.TableData>
										{formatDateWithHours(historyItem.created_at)}
									</S.TableData>
									<S.TableData>
										{historyItem.original_value_quantity_raw} /{' '}
										<strong>{historyItem.new_value_quantity_raw}</strong>
										{Number(historyItem.new_value_quantity_raw) !==
											Number(historyItem.original_value_quantity_raw) &&
											(Number(historyItem.new_value_quantity_raw) >
											Number(historyItem.original_value_quantity_raw) ? (
												<S.ArrowContainer>
													<AiOutlineArrowUp
														data-rh={`+ ${
															Number(historyItem.new_value_quantity_raw) -
															Number(historyItem.original_value_quantity_raw)
														}`}
														color='green'
													/>
												</S.ArrowContainer>
											) : (
												<S.ArrowContainer>
													<AiOutlineArrowDown
														data-rh={`- ${
															Number(historyItem.original_value_quantity_raw) -
															Number(historyItem.new_value_quantity_raw)
														}`}
														color='red'
													/>
												</S.ArrowContainer>
											))}
									</S.TableData>
									<S.TableData>
										{historyItem.original_value_quantity_cards_available} /{' '}
										<strong>
											{historyItem.new_value_quantity_cards_available}
										</strong>
										{Number(historyItem.new_value_quantity_cards_available) !==
											Number(
												historyItem.original_value_quantity_cards_available
											) &&
											(Number(historyItem.new_value_quantity_cards_available) >
											Number(
												historyItem.original_value_quantity_cards_available
											) ? (
												<S.ArrowContainer>
													<AiOutlineArrowUp
														data-rh={`+ ${
															Number(
																historyItem.new_value_quantity_cards_available
															) -
															Number(
																historyItem.original_value_quantity_cards_available
															)
														}`}
														color='green'
													/>
												</S.ArrowContainer>
											) : (
												<S.ArrowContainer>
													<AiOutlineArrowDown
														data-rh={`- ${
															Number(
																historyItem.original_value_quantity_cards_available
															) -
															Number(
																historyItem.new_value_quantity_cards_available
															)
														}`}
														color='red'
													/>
												</S.ArrowContainer>
											))}
									</S.TableData>
									<S.TableData>
										{historyItem.original_value_quantity_embossed} /{' '}
										<strong>{historyItem.new_value_quantity_embossed}</strong>
										{Number(historyItem.new_value_quantity_embossed) !==
											Number(historyItem.original_value_quantity_embossed) &&
											(Number(historyItem.new_value_quantity_embossed) >
											Number(historyItem.original_value_quantity_embossed) ? (
												<S.ArrowContainer>
													<AiOutlineArrowUp
														data-rh={`+ ${
															Number(historyItem.new_value_quantity_embossed) -
															Number(
																historyItem.original_value_quantity_embossed
															)
														}`}
														color='green'
													/>
												</S.ArrowContainer>
											) : (
												<S.ArrowContainer>
													<AiOutlineArrowDown
														data-rh={`- ${
															Number(
																historyItem.original_value_quantity_embossed
															) -
															Number(historyItem.new_value_quantity_embossed)
														}`}
														color='red'
													/>
												</S.ArrowContainer>
											))}
									</S.TableData>
									<S.TableData>
										{historyItem.original_value_quantity_cards_lost} /{' '}
										<strong>{historyItem.new_value_quantity_cards_lost}</strong>
										{Number(historyItem.new_value_quantity_cards_lost) !==
											Number(historyItem.original_value_quantity_cards_lost) &&
											(Number(historyItem.new_value_quantity_cards_lost) >
											Number(historyItem.original_value_quantity_cards_lost) ? (
												<S.ArrowContainer>
													<AiOutlineArrowUp
														data-rh={`+ ${
															Number(
																historyItem.new_value_quantity_cards_lost
															) -
															Number(
																historyItem.original_value_quantity_cards_lost
															)
														}`}
														color='green'
													/>
												</S.ArrowContainer>
											) : (
												<S.ArrowContainer>
													<AiOutlineArrowDown
														data-rh={`- ${
															Number(
																historyItem.original_value_quantity_cards_lost
															) -
															Number(historyItem.new_value_quantity_cards_lost)
														}`}
														color='red'
													/>
												</S.ArrowContainer>
											))}
									</S.TableData>
								</TableStyle.TableRow>
							)
						)}
					</TableStyle.TableBody>
				</TableStyle.Table>
			</>
		);
	}

	function resetState() {
		setCardStockState({ ...cardStockStats!, editedFlag: false });
		setLeafletStockState({
			...fetchLeafletStockQuery.data!,
			editedFlag: false,
		});
	}

	async function handleSubmitUpdate() {
		const updatedCardStockData = {
			quantityAvailable: cardStockState.quantityAvailable,
			quantityEmbossed: cardStockState.quantityEmbossed,
			quantityRaw: cardStockState.quantityRaw,
			quantityLost: cardStockState.quantityLost,
		};
		const updatedLeafletStockData: UpdateLeafletStockData = {
			quantity_total_envelopes: leafletStockState.quantityTotalEnvelopes,
			quantity_envelopes_lost: leafletStockState.quantityEnvelopesLost,
			quantity_total_accompanying_letters:
				leafletStockState.quantityTotalAccompanyingLetters,
			quantity_accompanying_letters_lost:
				leafletStockState.quantityAccompanyingLettersLost,
		};

		if (cardStockState.editedFlag) {
			try {
				await updateCardStockMutation.mutateAsync(updatedCardStockData);
			} catch (_) {}
		}

		if (leafletStockState.editedFlag)
			updateLeafletStockMutation.mutate(updatedLeafletStockData);
	}

	if (
		fetchCardStatsQuery.isLoading ||
		fetchLeafletStockQuery.isLoading ||
		fetchCardStockMasterHistory.isLoading ||
		!fetchCardStatsQuery.data
	) {
		return (
			<S.ContainerBody>
				<PageTitle title='Estoque' />
				<Loader />
			</S.ContainerBody>
		);
	}
	return (
		<S.ContainerBody>
			<PageTitle title={'Estoque de Folheteria'} />
			{fetchLeafletStockQuery.data && (
				<ManageLeafletStock leafletStock={fetchLeafletStockQuery.data} />
			)}

			<PageTitle title={'Estoque de Cartões'} />
			{cardStockStats !== null && (
				<ManageCardStock cardStockStats={cardStockStats} />
			)}

			<S.SubmitContainer>
				<S.ResetBtn onClick={resetState}>Reverter</S.ResetBtn>
				<S.MainButton
					onClick={handleSubmitUpdate}
					disabled={isSubmitButtonDisabled}
				>
					{updateCardStockMutation.isLoading ||
					updateLeafletStockMutation.isLoading ? (
						<Loader size={14} />
					) : (
						'Salvar'
					)}
				</S.MainButton>
			</S.SubmitContainer>

			<S.Divider />

			{generateTable('Histórico de modificações do Estoque de Cartões')}

			<S.OptionsContainer>
				<GenerateHistoryPDForExcel
					valueQuantity={fetchAllCardStockMasterHistory?.data}
				/>
			</S.OptionsContainer>
		</S.ContainerBody>
	);
}
