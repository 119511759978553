export function preRegisterStatusToText(status: string) {
	switch (status) {
 		case 'step-one':
			return { message:  'Primeira etapa do cadastro', color: "var(--primary-blue)" };
		case 'step-one-approved':
			return { message: 'Primeira etapa do cadastro aprovada', color: "green" };
		case 'step-one-reproved':
			return { message: 'Primeira etapa do cadastro reprovada', color: "var(--primary-red)" };
		case 'step-two':
			return { message: 'Segunda etapa do cadastro', color: "var(--primary-blue)" }
		case 'step-two-approved':
			return { message: 'Segunda etapa do cadastro aprovada', color: "green" };
		case 'step-two-reproved':
			return { message: 'Segunda etapa do cadastro reprovada', color: "var(--primary-red)" };
		case "registration-completed":
			return { message: 'Cadastro finalizado', color: "green" }
		case "kyc-reproved":
			return { message: 'A empresa foi reprovada na análise de kyc', color: "var(--primary-red)" }
		default:
			return { message: status, color: "var(--primary-blue)" }
	}
}

export function userTypeToText(type: string) {
	switch (type) {
		case 'partner':
			return 'Sócio'
		case 'proxyholder':
			return 'Procurador'
		case 'legal_representative':
			return 'Representante legal'
		case 'other':
			return 'Outro'
		default:
			return type
	}
}