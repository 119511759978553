import { Route, Routes as RouterRoutes } from 'react-router-dom'
import { Main } from '../pages/Main'
import NotFound from '../pages/NotFound'
import SignIn from '../pages/SignIn'
import { RequireAuth } from './RequireAuth'

export default function Routes() {
  return (
    <RouterRoutes>
      <Route path="*" element={<RequireAuth><Main /></RequireAuth>} />
      
      <Route path="/session" element={<SignIn />} />

      <Route path='/404' element={<NotFound />} />
    </RouterRoutes>
  )
}
