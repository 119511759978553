import { create } from 'zustand';
import { LeafletStock } from '../@types';

export type ManageLeafletStockState = {
	editedFlag: boolean;
} & LeafletStock;

type Actions = {
	updateQuantityTotalEnvelopes: (payload: number) => void;
	updateQuantityEnvelopesLost: (payload: number) => void;
	updateQuantityTotalAccompanyingLetters: (payload: number) => void;
	updateQuantityAccompanyingLettersLost: (payload: number) => void;
	setState: (payload: ManageLeafletStockState) => void;
};

type Store = {
	state: ManageLeafletStockState;
	actions: Actions;
};

export const useManageLeafletStockStore = create<Store>((set) => ({
	state: {
		editedFlag: false,
		quantityTotalEnvelopes: 0,
		quantityEnvelopesLost: 0,
		quantityEnvelopesPersonalized: 0,
		quantityEnvelopesAvailable: 0,
		quantityTotalAccompanyingLetters: 0,
		quantityAccompanyingLettersLost: 0,
		quantityAccompanyingLettersPersonalized: 0,
		quantityAccompanyingLettersAvailable: 0,
	},

	actions: {
		updateQuantityTotalEnvelopes: (payload) =>
			set((state) => ({
				...state,
				state: {
					...state.state,
					quantityTotalEnvelopes:
						state.state.quantityTotalEnvelopes + Number(payload),
					quantityEnvelopesAvailable:
						state.state.quantityEnvelopesAvailable + Number(payload),
					editedFlag: true,
				},
			})),

		updateQuantityEnvelopesLost: (payload) =>
			set((state) => ({
				...state,
				state: {
					...state.state,
					quantityEnvelopesLost:
						state.state.quantityEnvelopesLost + Number(payload),
					quantityEnvelopesAvailable:
						state.state.quantityEnvelopesAvailable - Number(payload),
					editedFlag: true,
				},
			})),

		updateQuantityTotalAccompanyingLetters: (payload) =>
			set((state) => ({
				...state,
				state: {
					...state.state,
					quantityTotalAccompanyingLetters:
						state.state.quantityTotalAccompanyingLetters + Number(payload),
					quantityAccompanyingLettersAvailable:
						state.state.quantityAccompanyingLettersAvailable + Number(payload),
					editedFlag: true,
				},
			})),

		updateQuantityAccompanyingLettersLost: (payload) =>
			set((state) => ({
				...state,
				state: {
					...state.state,
					quantityAccompanyingLettersLost:
						state.state.quantityAccompanyingLettersLost + Number(payload),
					quantityAccompanyingLettersAvailable:
						state.state.quantityAccompanyingLettersAvailable - Number(payload),
					editedFlag: true,
				},
			})),
		setState: (payload) => set({ state: payload }),
	},
}));
