import styled from 'styled-components';

export const OpenCompaniesModalButton = styled.button`
	background-color: var(--primary-blue);
	height: 3rem;
	width: 24rem;
	color: white;
	border-radius: 10rem;
	font-weight: bold;
`;

// MODAL
export const CompanyContainer = styled.ul`
	display: flex;
	flex-direction: column;
	min-width: 50rem;
	& li {
		border-bottom: 0.1rem solid var(--primary-blue);
	}
`;

export const CompanyOption = styled.li`
	display: flex;
	list-style: none;
	align-items: center;
	padding: 1rem;
	font-size: 1.4rem;
	cursor: pointer;
	transition: background-color 0.4s;
	border-radius: 10px;
	width: 100%;
	& img {
		width: 5rem;
		height: 5rem;
		border-radius: 50%;
		margin-right: 2rem;
		border: 1px solid #7d7d7d;
		object-fit: cover;
	}

	&:hover {
		background-color: #c5c5c5;
	}
`;

export const CompanyInfo = styled.div`
	display: flex;
	flex-direction: column;
	& > span {
		margin-bottom: 0.6rem;
		color: var(--primary-blue);
		font-size: 1.6rem;
	}
`;

export const CompanyInfoName = styled.div`
	max-width: 21rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const CompanyBalanceContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;
