import { FaInfoCircle } from "react-icons/fa"
import styled from "styled-components"

const Text = styled.span`
  color: var(--dark-gray);
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
  & svg {
    color: var(--light-gray);
    width: 2rem;
    height: 2rem;
    margin: 0 1rem;
  }
`
interface EmptyContentProp {
  text: string
}

export function EmptyContent({text}: EmptyContentProp) {
  return (
    <Text> <FaInfoCircle />{text}</Text>
  )
}
