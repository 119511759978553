import api from '../api';

export async function updateTermOfUse(terms_document: FormData) {
	await api.post('api/v1/term-of-use', terms_document);
}

export async function updatePrivacyPolicy(privacy_document: FormData) {
	await api.post('api/v1/privacy-policy', privacy_document);
}

export async function getTerms() {
	const { data } = await api.get('/api/v1/term-of-use');
	return data;
}

export async function getPrivacyPolicy() {
	const { data } = await api.get('/api/v1/privacy-policy');
	return data;
}
