import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';

export const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 4rem 7rem 2rem 7rem;
`;
export const FormContainer = styled.div`
	display: flex;
	margin-top: 2rem;
`;
export const Form = styled.form`
	display: grid;
	grid-template-columns: 1fr 1fr;
	flex-direction: column;
	width: 100%;
	max-width: 60rem;
	height: 100%;
	font-size: 1.4rem;
	padding: 1rem;
	row-gap: 2rem;
`;

export const InputValueContainer = styled.div`
	display: flex;
	width: 12rem;
`;

export const SubmitButton = styled.button`
	${OptionsButton}
	align-self: flex-start;
	margin-top: 2rem;
	width: 16rem;
`;
