export function getCurrentMonthYYYYMMValue() {
	// Get the current date
	const currentDate = new Date();

	// Get the year and month from the current date
	const year = currentDate.getFullYear();
	// JavaScript months are zero-based, so we add 1 to get the correct month
	const month = String(currentDate.getMonth() + 1).padStart(2, '0');

	// Create the formatted string "yyyy-mm"
	const formattedDate = `${year}-${month}`;

	return formattedDate;
}
