import 'react-toastify/dist/ReactToastify.css';
import { createGlobalStyle } from 'styled-components';

export const GlobalTheme = createGlobalStyle`
  :root {
    --primary-blue: #27ADFF;
    --primary-red: #FF0037;
    --dark-gray: #575757;
    --mid-gray: #939393;
    --light-gray: #C6C6C6;

    --terciary: #2DB9C5;
    --accent-green: #8BC52D;
    --black: #3D3E40;
    --white: #FFF;
    --light-pink: #F7E5E5;

    font-size: 62.5%;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    height: 100%;
    color: var(--dark-gray);
  }

  html {
    scroll-behavior: smooth;
  }
      
  body {
    background: var(--white);
    color: var(--dark-gray);
    -webkit-font-smoothing: antialiased;
    font-family: 'Ubuntu', sans-serif;
  }

  a, button {
    cursor: pointer;
    text-decoration: 0;
    border: none;
    transition: filter 0.2s;
    -webkit-font-smoothing: antialiased;
    font-family: 'Ubuntu', sans-serif;
    &:hover {
      filter: brightness(0.9);
    }
    &:disabled {
      opacity: .6;
      cursor: not-allowed;
      &:hover{
        filter: none;
      }   
    }
  }

  input {
    -webkit-font-smoothing: antialiased;
    font-family: 'Ubuntu', sans-serif;
    
    &:disabled {
      opacity: .75;
      cursor: not-allowed;
  }
  }

  @media(max-width: 700px) {
    :root {
      font-size: 56%;
    }
  }
  
  //Removing steps from input number
  //
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;
