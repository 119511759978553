import { useState } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import Loader from '../../../../components/Loader';
import PageTitle from '../../../../components/PageTitle';
import {
	getPartnersStats,
	getPartnersStatsByMonth,
	getPartnersStatsByYear,
	PartnersStatsByMonthResponse,
} from '../../../../services/queries/Partners';
import { getMessageError } from '../../../../utils/DefaultErrors';
import * as TableStyles from '../../../../components/Table/TableStyles';
import * as S from './styles';
import CompanyCard from '../../../../components/CompanyCard';
import { getGroupsCompanyLookUp } from '../../../../services/queries/GroupCompany';
import * as FormStyles from '../../../../components/Form/FormStyles';
import { BsPeopleFill } from 'react-icons/bs';
import * as XLSX from 'xlsx';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import {
	MONTHS,
	parseDate,
	parseQuantitativeDateToMonthYear,
} from '../../../../utils/parseDate';
import { EmptyContent } from '../../../../components/EmptyContent';

export function PartnersStats() {
	const [groupCompanyId, setGroupCompanyId] = useState('');
	const [date, setDate] = useState(getCurrentMonth);

	const getPartnersStatsQuery = useQuery(
		['fetch-partners-stats', groupCompanyId],
		() => getPartnersStats(groupCompanyId),
		{
			onError: (err) => {
				if (axios.isAxiosError(err)) {
					let errorMessage =
						'Não foi possível buscar os quantitativos de parceiros. ' +
						getMessageError(err.response?.data);

					toast.error(errorMessage);
				} else {
					toast.error(
						'Ocorreu algum problema ao buscar os quantitativos de parceiros. Tente novamente'
					);
				}
			},
		}
	);

	const getPartnersStatsByMonthQuery = useQuery(
		['fetch-partners-stats-by-month', groupCompanyId, date],
		() => getPartnersStatsByMonth(groupCompanyId, date),
		{
			onError: (err) => {
				if (axios.isAxiosError(err)) {
					let errorMessage =
						'Não foi possível buscar os quantitativos de parceiros por mês. ' +
						getMessageError(err.response?.data);

					toast.error(errorMessage);
				} else {
					toast.error(
						'Ocorreu algum problema ao buscar os quantitativos de parceiros. Tente novamente'
					);
				}
			},
		}
	);

	const getPartnersStatsByYearQuery = useQuery(
		['fetch-partners-stats-by-year', groupCompanyId, date],
		() => getPartnersStatsByYear(groupCompanyId, date),
		{
			onError: (err) => {
				if (axios.isAxiosError(err)) {
					let errorMessage =
						'Não foi possível buscar os quantitativos de parceiros por ano. ' +
						getMessageError(err.response?.data);

					toast.error(errorMessage);
				} else {
					toast.error(
						'Ocorreu algum problema ao buscar os quantitativos de parceiros. Tente novamente'
					);
				}
			},

			enabled: false,
		}
	);

	const getGroupCompaniesQuery = useQuery(
		['fetch-group-companies-select'],
		getGroupsCompanyLookUp,
		{
			onError: (err) => {
				if (axios.isAxiosError(err)) {
					let errorMessage =
						'Não foi possível buscar os grupos corporativos. ' +
						getMessageError(err.response?.data);

					toast.error(errorMessage);
				} else {
					toast.error(
						'Ocorreu algum problema ao buscar os grupos corporativos. Tente novamente'
					);
				}
			},
		}
	);

	function getPartners(byMonth = false) {
		type Partner = {
			id: string;
			name: string;
		};

		const partners: Partner[] = [];

		if (byMonth) {
			if (getPartnersStatsByMonthQuery.data?.yearRegisterQuantity[0]) {
				getPartnersStatsByMonthQuery.data?.yearRegisterQuantity[0].data.forEach(
					(stat) => {
						stat.partners.forEach((partner) => {
							if (
								!partners.find(
									(partnerIncluded) => partnerIncluded.name === partner.name
								)
							) {
								partners.push({
									id: partner.id,
									name: partner.name,
								});
							}
						});
					}
				);
			}
		} else {
			getPartnersStatsQuery.data?.stats3.forEach((stat) => {
				stat.users_by_partner.forEach((partner) => {
					if (
						!partners.find(
							(partnerIncluded) =>
								partnerIncluded.name === partner.partner_data.name
						)
					) {
						partners.push({
							id: partner.partner_data.id,
							name: partner.partner_data.name,
						});
					}
				});
			});
		}

		return partners;
	}

	function getPartnersByYear(stats: PartnersStatsByMonthResponse) {
		type Partner = {
			id: string;
			name: string;
		};

		const partners: Partner[] = [];

		stats.yearRegisterQuantity.forEach((month) => {
			month.data.forEach((company) => {
				company.partners.forEach((partner) => {
					if (
						!partners.find(
							(partnerIncluded) => partnerIncluded.name === partner.name
						)
					) {
						partners.push({
							id: partner.id,
							name: partner.name,
						});
					}
				});
			});
		});

		return partners;
	}

	function getCurrentMonth() {
		const currentDate = new Date();
		const currentYear = currentDate.getFullYear();
		let currentMonth: string | number = currentDate.getMonth() + 1;
		if (currentMonth < 10) {
			currentMonth = String(currentMonth).padStart(2, '0');
		}
		return `${currentYear}-${currentMonth}`;
	}

	function generateSpreadsheet() {
		const stats =
			getPartnersStatsByMonthQuery.data!.yearRegisterQuantity[0].data;

		if (!stats) {
			toast.error('Não há conteúdo para gerar a planilha.');
		}

		const jsonSpreadsheet: any = [];

		stats.forEach((stat) => {
			let spreadSheetRow = {
				Empresa: stat.company.name,
				CNPJ: stat.company.cnpj,
			};
			getPartners(true).forEach((partner) => {
				spreadSheetRow = {
					...spreadSheetRow,
					[partner.name]:
						stat.partners.find((p) => p.name === partner.name)
							?.total_collaborator ?? 0,
				};
			});
			jsonSpreadsheet.push(spreadSheetRow);
		});

		const partnersStatsSheet = XLSX.utils.json_to_sheet(jsonSpreadsheet);
		const partnerStatsWorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(
			partnerStatsWorkBook,
			partnersStatsSheet,
			'Quantitativo'
		);

		XLSX.writeFile(partnerStatsWorkBook, `quantitativo_parceiros_${date}.xlsx`);
	}

	async function generateSpreadsheetByYear() {
		const stats = (await getPartnersStatsByYearQuery.refetch()).data;

		const jsonSpreadsheet: any = [];

		stats?.yearRegisterQuantity.forEach((stat) => {
			jsonSpreadsheet.push({
				Mês: MONTHS[Number(stat.month) - 1],
			});

			stat.data.forEach((partnerData) => {
				let spreadSheetRow = {
					Empresa: partnerData.company.name,
					CNPJ: partnerData.company.cnpj,
				};
				getPartnersByYear(stats).forEach((partner) => {
					spreadSheetRow = {
						...spreadSheetRow,
						[partner.name]:
							partnerData.partners.find((p) => p.name === partner.name)
								?.total_collaborator ?? 0,
					};
				});

				jsonSpreadsheet.push(spreadSheetRow);
			});
		});

		const partnersStatsSheet = XLSX.utils.json_to_sheet(jsonSpreadsheet);
		const partnerStatsWorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(
			partnerStatsWorkBook,
			partnersStatsSheet,
			'Quantitativo'
		);

		const [year] = date.split('-');
		XLSX.writeFile(partnerStatsWorkBook, `quantitativo_parceiros_${year}.xlsx`);
	}

	const GroupCompanySelect = () => {
		return (
			<S.GroupCompanyContainer>
				<FormStyles.Label>Filtrar por Grupo Corporativo</FormStyles.Label>
				<FormStyles.SelectInput
					value={groupCompanyId}
					onChange={(e) => {
						setGroupCompanyId(e.target.value);
					}}
				>
					<option value=''>TODOS</option>
					{getGroupCompaniesQuery.data?.map((groupCompany) => (
						<option key={groupCompany.id} value={groupCompany.id}>
							{groupCompany.name}
						</option>
					))}
				</FormStyles.SelectInput>
			</S.GroupCompanyContainer>
		);
	};

	if (getPartnersStatsQuery.isLoading || !getPartnersStatsQuery.data) {
		return (
			<S.Container>
				<PageTitle title='Quantitativo dos parceiros' />
				<GroupCompanySelect />

				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<PageTitle title='Quantitativo dos parceiros' />
			<GroupCompanySelect />

			<S.Content>
				<S.InfoRow>
					<S.InfoContainer>
						<S.InfoTitle>Empresas com parceiros</S.InfoTitle>
						<span data-testid='companies_with_partners'>
							{getPartnersStatsQuery.data.stats1.companies_with_partners}
						</span>
					</S.InfoContainer>

					<S.InfoContainer>
						<S.InfoTitle>Empresas sem parceiros</S.InfoTitle>
						<span data-testid='companies_without_partners'>
							{getPartnersStatsQuery.data.stats1.companies_without_partners}
						</span>
					</S.InfoContainer>
				</S.InfoRow>

				<S.InfoRow>
					<S.InfoContainer>
						<S.InfoTitle style={{ marginBottom: 0 }}>Empresas</S.InfoTitle>
						<S.TableContainer>
							<TableStyles.Table>
								<TableStyles.TableHeader>
									<TableStyles.TableRow>
										<TableStyles.TableHeaderCell>
											EMPRESA
										</TableStyles.TableHeaderCell>
										<TableStyles.TableHeaderCell>
											QNTD DE PARCEIROS
										</TableStyles.TableHeaderCell>
									</TableStyles.TableRow>
								</TableStyles.TableHeader>

								<TableStyles.TableBody>
									{getPartnersStatsQuery.data.stats2.map((companyStat) => (
										<TableStyles.TableRow key={companyStat.company.id}>
											<TableStyles.TableData>
												<CompanyCard
													company_name={companyStat.company.name}
													cnpj={companyStat.company.cnpj}
												/>
											</TableStyles.TableData>
											<TableStyles.TableData
												style={{ textAlign: 'center' }}
												data-testid={`${companyStat.company.id}-number_of_partners`}
											>
												{companyStat.number_of_partners}
											</TableStyles.TableData>
										</TableStyles.TableRow>
									))}
								</TableStyles.TableBody>
							</TableStyles.Table>
						</S.TableContainer>
					</S.InfoContainer>
				</S.InfoRow>

				<S.InfoRow>
					<S.InfoContainer>
						<S.TableTitleContainer>
							<S.TitleInfoContainer>
								<S.InfoTitle style={{ marginBottom: 0 }}>
									Colaboradores por parceria
								</S.InfoTitle>

								<S.IconContainer>
									<AiOutlineExclamationCircle
										data-rh={`Valores obtidos no dia ${parseDate(
											new Date().toString(),
											2
										)} às ${new Date().toLocaleTimeString()}`}
									/>
								</S.IconContainer>
							</S.TitleInfoContainer>
						</S.TableTitleContainer>

						<S.TableContainer>
							<TableStyles.Table>
								<TableStyles.TableHeader>
									<TableStyles.TableRow>
										<TableStyles.TableHeaderCell>
											EMPRESA
										</TableStyles.TableHeaderCell>
										{getPartners().map((partner) => (
											<TableStyles.TableHeaderCell key={partner.id}>
												{partner.name}
											</TableStyles.TableHeaderCell>
										))}
									</TableStyles.TableRow>
								</TableStyles.TableHeader>

								<TableStyles.TableBody>
									{getPartnersStatsQuery.data.stats3.map((companyStat) => (
										<TableStyles.TableRow key={companyStat.company.id}>
											<TableStyles.TableData>
												<CompanyCard
													company_name={companyStat.company.name}
													cnpj={companyStat.company.cnpj}
												/>
											</TableStyles.TableData>
											{getPartners().map((partner) => (
												<TableStyles.TableData
													key={companyStat.company.id + partner.id}
													style={{ textAlign: 'center' }}
													data-testid={`${companyStat.company.id}-${partner.name}-users_by_partner`}
												>
													<TableStyles.OptionLinkText
														data-rh='Ver quantitativo de colaboradores'
														to={`/partners/company/${companyStat.company.id}/quantitative/${partner.id}`}
													>
														{companyStat.users_by_partner.find(
															(p) => p.partner_data.name === partner.name
														)?.quantity ?? 0}
														<BsPeopleFill />
													</TableStyles.OptionLinkText>
												</TableStyles.TableData>
											))}
										</TableStyles.TableRow>
									))}
								</TableStyles.TableBody>
							</TableStyles.Table>
						</S.TableContainer>
					</S.InfoContainer>
				</S.InfoRow>

				<S.InfoRow>
					<S.InfoContainer>
						<S.TableTitleContainer>
							<S.TitleInfoContainer>
								<S.InfoTitle style={{ marginBottom: 0 }}>
									Colaboradores por parceria por mês
								</S.InfoTitle>

								<S.IconContainer>
									<AiOutlineExclamationCircle
										data-rh={`Valores obtidos no dia 01 de ${parseQuantitativeDateToMonthYear(
											date
										)} às 06:00:00`}
									/>
								</S.IconContainer>
							</S.TitleInfoContainer>

							<S.MonthInput
								type={'month'}
								value={date}
								onChange={(e) => setDate(e.target.value)}
								max={getCurrentMonth()}
							/>

							{getPartnersStatsByMonthQuery.data?.yearRegisterQuantity[0] && (
								<>
									<S.spreadSheetButton onClick={generateSpreadsheet}>
										Gerar Planilha - Mês
									</S.spreadSheetButton>

									<S.spreadSheetButton onClick={generateSpreadsheetByYear}>
										{getPartnersStatsByYearQuery.isLoading ? (
											<Loader size={15} />
										) : (
											'Gerar Planilha - Ano'
										)}
									</S.spreadSheetButton>
								</>
							)}
						</S.TableTitleContainer>

						{getPartnersStatsByMonthQuery.isLoading ? (
							<Loader />
						) : (
							<S.TableContainer>
								<TableStyles.Table>
									<TableStyles.TableHeader>
										<TableStyles.TableRow>
											<TableStyles.TableHeaderCell>
												EMPRESA
											</TableStyles.TableHeaderCell>
											{getPartners(true).map((partner) => (
												<TableStyles.TableHeaderCell key={partner.id}>
													{partner.name}
												</TableStyles.TableHeaderCell>
											))}
										</TableStyles.TableRow>
									</TableStyles.TableHeader>

									<TableStyles.TableBody>
										{getPartnersStatsByMonthQuery.data
											?.yearRegisterQuantity[0] ? (
											getPartnersStatsByMonthQuery.data?.yearRegisterQuantity[0].data.map(
												(companyStat) => (
													<TableStyles.TableRow key={companyStat.company.id}>
														<TableStyles.TableData>
															<CompanyCard
																company_name={companyStat.company.name}
																cnpj={companyStat.company.cnpj}
															/>
														</TableStyles.TableData>
														{getPartners(true).map((partner) => (
															<TableStyles.TableData
																key={companyStat.company.id + partner.id}
																style={{ textAlign: 'center' }}
																data-testid={`${companyStat.company.id}-${partner.name}-users_by_partner`}
															>
																{companyStat.partners.find(
																	(p) => p.name === partner.name
																)?.total_collaborator ?? 0}
															</TableStyles.TableData>
														))}
													</TableStyles.TableRow>
												)
											)
										) : (
											<TableStyles.TableRow>
												<TableStyles.TableData>
													<EmptyContent
														text={
															'Nenhum registro de colaboradores por parceria nesse mês.'
														}
													/>
												</TableStyles.TableData>
											</TableStyles.TableRow>
										)}
									</TableStyles.TableBody>
								</TableStyles.Table>
							</S.TableContainer>
						)}
					</S.InfoContainer>
				</S.InfoRow>
			</S.Content>
		</S.Container>
	);
}
