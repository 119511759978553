import { StyleSheet } from '@react-pdf/renderer';

export const PDFstyles = StyleSheet.create({
	page: {
		flexDirection: 'row',
		padding: 10,
	},
	section: {
		display: 'flex',
		flexGrow: 1,
	},
	heading: {
		fontSize: 24,
		marginBottom: 10,
		fontFamily: 'Poppins',
		color: '#575757',
		textAlign: 'center',
	},
	filterParamsContainer: {
		display: 'flex',
		width: '100%',
		marginBottom: 10,
	},
	filterParamsTitle: {
		fontSize: 14,
		fontFamily: 'Poppins',
		color: '#474747',
	},
	filterParamsValues: {
		fontSize: 12,
		fontFamily: 'Poppins',
		color: '#474747',
		marginLeft: 30,
	},
	resultsRow: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		columnGap: 10,
		marginTop: 10,
	},
	companyColumn: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		width: 200,
		height: 15,
		color: '#474747',
	},
	content: {
		fontSize: 11,
	},
	footer: {
		marginTop: 'auto',
		fontSize: 10,
		width: '100%',
		display: 'flex',
		color: '#575757',
		alignItems: 'center',
	},
	totalValues_container: {
		marginTop: 20,
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		flexDirection: 'column',
		rowGap: 10,
		color: '#474747',
	},
	totalValue: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '90%',
		fontSize: 12,
	},
	logo: {
		width: 80,
		position: 'absolute',
		marginTop: 10,
		left: 10,
	},
});

export function getColumnTitleStyle(width: number) {
	const column = StyleSheet.create({
		style: {
			paddingBottom: 5,
			borderBottomColor: '#C6C6C6',
			borderBottomStyle: 'solid',
			borderBottomWidth: 2,
			minWidth: width,
			color: '#373737',
		},
	});

	return column.style;
}

export function getColumnDataStyle(width: number) {
	const column = StyleSheet.create({
		style: {
			width: width,
			maxHeight: 60,
			color: '#474747',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	});

	return column.style;
}
