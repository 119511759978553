import { cnpjMask } from '../../utils/masks'
import * as S from './styles'


interface UserCardProps {
  company_name: string
  cnpj?: string
  avatar_url?: string
}

export default function CompanyCard({ company_name, cnpj, avatar_url }: UserCardProps) {
  return (
    <S.Container>
      {avatar_url && <S.Avatar src={avatar_url} alt="Company Avatar"  />}
      
      <S.InfoContainer>
        <S.Name data-rh={company_name}>{company_name}</S.Name>
        
        {cnpj && <S.Office data-rh={cnpj}>{cnpjMask(cnpj)}</S.Office>}
      </S.InfoContainer>
    </S.Container>
  )
}