import { InputHTMLAttributes } from 'react';
import { UseFormRegister } from 'react-hook-form';
import * as S from './styles';

interface ToggleSwitchProps extends InputHTMLAttributes<HTMLInputElement> {
	name?: string;
	register?: UseFormRegister<any>;
}

export default function ToggleSwitch({
	name,
	register,
	...props
}: ToggleSwitchProps) {
	return (
		<S.CheckBoxWrapper>
			<S.CheckBox
				id={name}
				type='checkbox'
				{...(register ? { ...register(name!) } : {})}
				{...props}
			/>
			<S.CheckBoxLabel htmlFor={name} />
		</S.CheckBoxWrapper>
	);
}
