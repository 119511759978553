import { useState } from 'react';
import { FaInfoCircle, FaPen, FaPlus, FaTimes } from 'react-icons/fa';
import { RiCloseLine } from 'react-icons/ri';
import { Shareholder } from '../../../@types';
import { useCompanyForm } from '../../../hooks/useCompanyForm';
import { useDialogModal } from '../../../hooks/useDialogModal';
import { EmptyContent } from '../../EmptyContent';
import Loader from '../../Loader';
import Modal from '../../Modal';
import PageTitle from '../../PageTitle';
import { ShareholdersForm } from './ShareholdersForm';
import * as S from './styles';

export function ShareholdersModal() {
	const { company, removeShareholderToCompanyCreation, deleteShareholder } =
		useCompanyForm();
	const { openOptionsDialog } = useDialogModal();
	const [isOpen, setIsOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [currentShareholder, setCurrentShareholder] =
		useState<Shareholder | null>(null);

	function handleClose() {
		setCurrentShareholder(null);
		setIsOpen(false);
	}

	function getModalTitle() {
		if (currentShareholder) {
			// Form is showing so the page title should be: Sócio #index
			let index: number;
			if (currentShareholder.cpf) {
				// Editing a shareholder, so get its index
				index =
					company!.shareholders.findIndex(
						(s) => s.cpf === currentShareholder.cpf
					) + 1;
			} else {
				// new shareholder so the index will be the length of shareholders plus 1
				index = company?.shareholders ? company.shareholders.length + 1 : 1;
			}
			return 'Sócio #' + index;
		}

		return 'Cadastro de sócios';
	}

	function setCurrentShareholderToForm(cpf?: string, cnpj?: string) {
		if (cpf) {
			setCurrentShareholder(
				company!.shareholders!.find((shareholder) => shareholder.cpf === cpf)!
			);
			return
		} else if (cnpj){
			setCurrentShareholder(
				company!.shareholders!.find((shareholder) => shareholder.cnpj === cnpj)!
			);
		}
			else {
			setCurrentShareholder({} as Shareholder);
			return;
		}
	}

	/* Show shareholders list or the current shareholder form */
	function getModalContent() {
		if (currentShareholder)
			return (
				<ShareholdersForm
					shareholder={{
						...currentShareholder,
						full_name:
							currentShareholder.first_name !== undefined
								? `${currentShareholder.first_name} ${currentShareholder.last_name}`
								: '',
					}}
					goBack={() => {
						setCurrentShareholder(null);
					}}
				/>
			);

		return getShareholdersList();
	}

	function getShareholdersList() {
		if (!company?.shareholders) {
			return (
				<>
					<S.EmptyContainer>
						<EmptyContent text='Não há ítens a serem exibidos.' />
						<S.AddNewShareholders onClick={() => setCurrentShareholderToForm()}>
							<FaPlus /> Novo
						</S.AddNewShareholders>
					</S.EmptyContainer>

					<S.BottomOptionsContainer>
						<S.SaveButton onClick={handleClose}>Confirmar</S.SaveButton>
					</S.BottomOptionsContainer>
				</>
			);
		} else {
			return (
				<>
					<S.ShareholdersList>
						{company?.shareholders.map((shareholder) => (
							<S.ShareholderContainer key={shareholder.cpf}>
								<span>{`${shareholder.first_name ?? shareholder.legal_name} ${shareholder.last_name ?? ""}`}</span>
								<S.ShareholdersOptions>
									<S.ShareholdersOption
										onClick={() => setCurrentShareholderToForm(shareholder.cpf, shareholder.cnpj)}
									>
										<div>
											<FaPen />{' '}
										</div>
										Editar
									</S.ShareholdersOption>
									<S.ShareholdersOption
										onClick={() => handleDeleteShareholder(shareholder)}
									>
										<div>
											<FaTimes />{' '}
										</div>
										Excluir
									</S.ShareholdersOption>
								</S.ShareholdersOptions>
							</S.ShareholderContainer>
						))}
					</S.ShareholdersList>

					<S.AddNewShareholders onClick={() => setCurrentShareholderToForm()}>
						<FaPlus /> Novo
					</S.AddNewShareholders>

					<S.BottomOptionsContainer>
						<S.SaveButton onClick={handleClose}>Confirmar</S.SaveButton>
					</S.BottomOptionsContainer>
				</>
			);
		}
	}

	async function handleDeleteShareholder(shareholder: Shareholder) {
		setIsOpen(false);

		openOptionsDialog(
			`Tem certeza que deseja excluir o sócio ${shareholder.first_name ?? shareholder.legal_name} ${shareholder.last_name ?? ""}?`,
			'Sim',
			async () => {
				setIsOpen(true);

				if (shareholder.id) {
					setLoading(true);
					await deleteShareholder(shareholder.id);
				} else removeShareholderToCompanyCreation(
					shareholder.type_of_social_contract!, shareholder.cpf === "" ? shareholder.cnpj! : shareholder.cpf!
				);
				setLoading(false);
			},
			'Não',
			() => {
				setIsOpen(true);
			}
		);
	}

	return (
		<>
			<S.OpenModalContainer>
				<S.OpenModalButton type='button' onClick={() => setIsOpen(true)}>
					Cadastrar sócios
				</S.OpenModalButton>
				<S.ShareholdersAmountInfo>
					<FaInfoCircle />{' '}
					{company?.shareholders
						? `${company.shareholders.length} sócios cadastrados`
						: `nenhum sócio cadastrado`}
				</S.ShareholdersAmountInfo>
			</S.OpenModalContainer>

			<Modal isOpen={isOpen} enableClose={false} onRequestClose={handleClose}>
				<S.Header>
					<PageTitle title={getModalTitle()} />
					<S.CloseModalBtn onClick={handleClose}>
						<RiCloseLine color='#FFF' size={20} />
					</S.CloseModalBtn>
				</S.Header>
				<S.Container>{loading ? <Loader /> : getModalContent()}</S.Container>
			</Modal>
		</>
	);
}
