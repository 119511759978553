import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { EmptyContent } from '../../../../../components/EmptyContent';
import Loader from '../../../../../components/Loader';
import PageTitle from '../../../../../components/PageTitle';
import { getPartnerQuantitative } from '../../../../../services/queries/Partners';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import * as S from './styles';
import * as TableStyle from '../../../../../components/Table/TableStyles';
import { parseDateToMonthYear } from '../../../../../utils/parseDate';

export function PartnerQuantitative() {
	const { partner_id, company_id } = useParams();

	const fetchQuantitativeQuery = useQuery(
		['fetch-quantitative', partner_id, company_id],
		() => getPartnerQuantitative(partner_id!, company_id!),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar o quantitativo do parceiro. '
				);
			},
			refetchOnWindowFocus: false,
			enabled: !!partner_id && !!company_id,
		}
	);

	if (!fetchQuantitativeQuery.data) {
		return (
			<S.Container>
				<PageTitle title='Quantitativo de parceiro' />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<PageTitle title='Quantitativo de parceiro' />
			{fetchQuantitativeQuery.data.length === 0 ? (
				<EmptyContent text='Não há ítens a serem exibidos.' />
			) : (
				<TableStyle.Table>
					<TableStyle.TableHeader>
						<TableStyle.TableRow>
							<TableStyle.TableHeaderCell>PERIODO</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								COLABORADORES
							</TableStyle.TableHeaderCell>
						</TableStyle.TableRow>
					</TableStyle.TableHeader>

					<TableStyle.TableBody>
						{fetchQuantitativeQuery.data.map((quantitative) => (
							<TableStyle.TableRow key={quantitative.partner_id}>
								<TableStyle.TableData>
									{parseDateToMonthYear(quantitative.registration_date)}
								</TableStyle.TableData>
								<TableStyle.TableData>
									{quantitative.total_collaborator}
								</TableStyle.TableData>
							</TableStyle.TableRow>
						))}
					</TableStyle.TableBody>
				</TableStyle.Table>
			)}
		</S.Container>
	);
}
