import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Company, GroupCompany, ProductOrigin } from '../../../../@types';
import Loader from '../../../../components/Loader';
import PageTitle from '../../../../components/PageTitle';
import { useAuth } from '../../../../hooks/useAuth';
import * as S from './styles';
import { SelectGroupCompany } from '../../../../components/SelectGroupCompany';
import { SelectCompany } from '../../../../components/SelectCompany';
import { useMutation } from 'react-query';
import {
	createTransaction,
	CreateTransactionArgs,
} from '../../../../services/queries/Tranfers';
import { FormCurrencyInput } from '../../../../components/Form/FormStyles';
import PreventTransitionPrompt from '../../../../components/PreventTransitionPrompt';
import * as FormStyles from '../../../../components/Form/FormStyles';
import { showErrorMessage } from '../../../../utils/ErrorHandler';

// interface FormSubmit {
//   value: number
//   description: string
//   date: Date
// }

export function CreateTransfer() {
	const { masterBalance, updateMasterBalance } = useAuth();

	const [value, setValue] = useState(0);
	const [selectedGroupCompany, setSelectedGroupCompany] =
		useState<GroupCompany | null>(null);
	const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
	const [selectedProduct, setSelectedProduct] =
		useState<ProductOrigin>('multiflex');

	const [allowNavigation, setAllowNavigation] = useState(true);

	useEffect(() => {
		setAllowNavigation(!(value || selectedGroupCompany));
	}, [value, selectedGroupCompany]);

	useEffect(() => {
		setSelectedCompany(null);
	}, [selectedGroupCompany]);

	const createTransactionMutation = useMutation(
		({ value, origin, companyId }: CreateTransactionArgs) => {
			return createTransaction({ value, origin, companyId });
		},
		{
			onSuccess: () => {
				setSelectedGroupCompany(null);
				setValue(0);
				updateMasterBalance.refetch();
				toast.info('Transferência realizada com sucesso');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar realizar a transferência. '
				);
			},
		}
	);

	async function handleSubmitTransfer(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		if (!selectedCompany) {
			toast.error('Selecione uma empresa');
			return;
		}
		if (value <= 0) {
			toast.error('Valor inválido');
			return;
		}
		if (masterBalance < value) {
			toast.error('Saldo insuficiente');
			return;
		}

		createTransactionMutation.mutate({
			value,
			origin: selectedProduct,
			companyId: selectedCompany.id!,
		});
	}

	function handleSelectCompany(company: Company) {
		setSelectedCompany(company);
	}
	function handleSelectGroupCompany(groupCompany: GroupCompany) {
		setSelectedGroupCompany(groupCompany);
	}

	if (createTransactionMutation.isLoading) {
		return (
			<S.Container>
				<PageTitle title='Criar transferência' />
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			<PreventTransitionPrompt when={!allowNavigation} />

			<PageTitle title='Criar transferência' />

			<S.FormContainer>
				<S.Form onSubmit={handleSubmitTransfer}>
					<FormStyles.Field>
						<FormStyles.Label htmlFor='company'>
							Grupo Corporativo
						</FormStyles.Label>
						<SelectGroupCompany
							selectedGroupCompany={selectedGroupCompany}
							onGroupCompanySelection={handleSelectGroupCompany}
						/>
					</FormStyles.Field>
					<FormStyles.Field>
						<FormStyles.Label htmlFor='company'>Empresa</FormStyles.Label>
						<SelectCompany
							groupCompanyId={
								selectedGroupCompany ? selectedGroupCompany.id : null
							}
							selectedCompany={selectedCompany}
							onCompanySelection={handleSelectCompany}
						/>
					</FormStyles.Field>

					<FormStyles.Field style={{ width: 'fit-content' }}>
						<FormStyles.Label htmlFor='product'>Produto</FormStyles.Label>

						<FormStyles.SelectInput
							id='product'
							value={selectedProduct}
							onChange={(e) =>
								setSelectedProduct(e.target.value as ProductOrigin)
							}
						>
							<option value='multiflex'>Benefícios Flexíveis</option>
							<option value='corpway'>Bounty Control</option>
						</FormStyles.SelectInput>
					</FormStyles.Field>

					<FormStyles.Field style={{ width: '40%' }}>
						<FormStyles.Label htmlFor='value'>Valor</FormStyles.Label>

						<S.InputValueContainer>
							<FormCurrencyInput
								value={value}
								onValueChange={({ floatValue }) => {
									setValue(floatValue);
								}}
								required
							/>
						</S.InputValueContainer>
					</FormStyles.Field>

					<S.SubmitButton type='submit'>Enviar</S.SubmitButton>
				</S.Form>
			</S.FormContainer>
		</S.Container>
	);
}
