// gets a month index from 1-12 and a year and returns the "<MONTH_NAME> <YEAR>"
export function parseReferenceDate(
	monthIndex: number,
	year: string,
	separator = ' '
) {
	// Create a date object with the year set to some constant value and the month set to the desired month index
	const date = new Date(new Date().getFullYear(), monthIndex - 1);

	// Use the `toLocaleString` method with 'pt-BR' (Brazilian Portuguese) locale to get the month name
	let monthName = date.toLocaleString('pt-BR', { month: 'long' });
	monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
	return `${monthName}${separator}${year}`;
}
