export const ACCESS_LEVELS = [
	{
		value: 'general_admin',
		title: 'Administrador',
	},
	{
		value: 'account_manager',
		title: 'Gerente',
	},
	{
		value: 'finance',
		title: 'Assistente',
	},
	{
		value: 'card_responsible',
		title: 'Colaborador',
	},
	{
		value: 'admin',
		title: 'Administrador',
	},
	{
		value: 'operator',
		title: 'Operador',
	},
];
