import { useState } from 'react';
import { SegmentationOptions } from '..';
import Modal from '../../../../components/Modal';
import * as S from './styles';
import { useDialogModal } from '../../../../hooks/useDialogModal';
import { UserToSelect, UsersSelector } from './components/UsersSelector';
import { toast } from 'react-toastify';
import {
	CompaniesSelector,
	CompanyToSelect,
} from './components/CompaniesSelector';
import {
	GroupCompaniesSelector,
	GroupToSelect,
} from './components/GroupCompaniesSelector';
import { GenericItem, SelectedItemsList } from './components/SelectedItemsList';
import ToggleSwitch from '../../../../components/ToggleSwitch';

export interface SegmentationModalProps {
	onChangeSegmentation: (
		option: SegmentationOptions,
		selectedUsers: GenericItem[],
		operators: boolean,
		collaborators: boolean
	) => void;
}

export function SegmentationModal({
	onChangeSegmentation,
}: SegmentationModalProps) {
	const { openOptionsDialog } = useDialogModal();

	const [isOpen, setIsOpen] = useState(false);

	const [lastOption, setLastOption] =
		useState<SegmentationOptions>('all_users');
	const [selectedOption, setSelectedOption] =
		useState<SegmentationOptions>(lastOption);

	const [selectedUsers, setSelectedUsers] = useState<UserToSelect[]>([]);
	const [selectedCompanies, setSelectedCompanies] = useState<CompanyToSelect[]>(
		[]
	);
	const [selectedGroups, setSelectedGroups] = useState<GroupToSelect[]>([]);

	const [reportOperators, setReportOperators] = useState(true);
	const [reportCollaborators, setReportCollaborators] = useState(true);

	function handleOpen() {
		setSelectedOption(lastOption);
		setIsOpen(true);
	}

	function handleClose() {
		if (lastOption === selectedOption) {
			setIsOpen(false);
			return;
		}

		setIsOpen(false);

		openOptionsDialog(
			'Alterações não salvas. Tem certeza que deseja sair?',
			'Confirmar',
			() => {},
			'Cancelar',
			() => setIsOpen(true)
		);
	}

	function handleSelectOption(option: SegmentationOptions) {
		setSelectedOption(option);
	}

	function handleAddAndRemoveUsers(
		usersToAdd: UserToSelect[],
		usersToRemove: UserToSelect[]
	) {
		const idsToRemove = usersToRemove.map((c) => c.id!);
		let remainingSelectedUsers = selectedUsers.filter(
			(c) => !idsToRemove.includes(c.id!)
		);

		setSelectedUsers([...remainingSelectedUsers, ...usersToAdd]);
	}

	function handleAddAndRemoveCompanies(
		companiesToAdd: CompanyToSelect[],
		companiesToRemove: CompanyToSelect[]
	) {
		const idsToRemove = companiesToRemove.map((c) => c.id!);
		let remainingSelectedCompanies = selectedCompanies.filter(
			(c) => !idsToRemove.includes(c.id!)
		);

		setSelectedCompanies([...remainingSelectedCompanies, ...companiesToAdd]);
	}

	function handleAddAndRemoveGroups(
		groupsToAdd: GroupToSelect[],
		groupsToRemove: GroupToSelect[]
	) {
		const idsToRemove = groupsToRemove.map((c) => c.id!);
		let remainingSelectedGroups = selectedGroups.filter(
			(c) => !idsToRemove.includes(c.id!)
		);

		setSelectedGroups([...remainingSelectedGroups, ...groupsToAdd]);
	}

	function handleSubmit() {
		const segmentationTypesMapping = {
			all_users: {
				array: [],
				errorMessage: '',
			},
			custom: {
				array: selectedUsers,
				errorMessage: 'Selecione os usuários para o envio customizado.',
			},
			company: {
				array: selectedCompanies,
				errorMessage: 'Selecione as empresas para o envio.',
			},
			group: {
				array: selectedGroups,
				errorMessage: 'Selecione os grupos corporativos para o envio.',
			},
		};

		const selectedOptionData = segmentationTypesMapping[selectedOption];

		if (selectedOption !== 'all_users' && !selectedOptionData.array.length) {
			toast.error(selectedOptionData.errorMessage);
			return;
		}

		onChangeSegmentation(
			selectedOption,
			selectedOptionData.array,
			reportOperators,
			reportCollaborators
		);
		setLastOption(selectedOption);
		setIsOpen(false);
	}

	return (
		<>
			<S.MainButton type='button' onClick={handleOpen}>
				Definir segmentação
			</S.MainButton>

			<Modal isOpen={isOpen} enableClose onRequestClose={handleClose}>
				<S.ModalContainer>
					<S.ModalHeader>Selecione a segmentação desejada</S.ModalHeader>

					<S.SegmentationOptionsList>
						<S.SegmentationOption
							onClick={() => handleSelectOption('all_users')}
							selected={selectedOption === 'all_users'}
						>
							<S.SegmentationHeader>Todos os usuários</S.SegmentationHeader>
							<span>Todos os usuários do sistema serão notificados.</span>
						</S.SegmentationOption>

						<S.SegmentationOption
							onClick={() => handleSelectOption('group')}
							selected={selectedOption === 'group'}
						>
							<S.SegmentationHeader>
								{selectedOption === 'group' ? (
									<>
										<GroupCompaniesSelector
											buttonText={`Grupos corporativos ${
												selectedGroups.length > 0
													? `- (${selectedGroups.length})`
													: ''
											}`}
											alreadyAddedGroups={selectedGroups}
											onAddAndRemoveGroups={handleAddAndRemoveGroups}
											allowRemoveGroup
										/>
										{selectedGroups.length > 0 && (
											<SelectedItemsList
												selectedItems={selectedGroups}
												listName={'Grupos corporativos selecionados'}
											/>
										)}
									</>
								) : (
									'Grupos corporativos'
								)}
							</S.SegmentationHeader>
							<span>
								Todos os usuários de todas as empresas do grupo selecionado
								serão notificados.
							</span>
						</S.SegmentationOption>

						<S.SegmentationOption
							onClick={() => handleSelectOption('company')}
							selected={selectedOption === 'company'}
						>
							<S.SegmentationHeader>
								{selectedOption === 'company' ? (
									<>
										<CompaniesSelector
											buttonText={`Empresas ${
												selectedCompanies.length > 0
													? `- (${selectedCompanies.length})`
													: ''
											}`}
											alreadyAddedCompanies={selectedCompanies}
											onAddAndRemoveCompanies={handleAddAndRemoveCompanies}
											allowRemoveCompany
										/>
										{selectedCompanies.length > 0 && (
											<SelectedItemsList
												selectedItems={selectedCompanies}
												listName={'Empresas selecionadas'}
											/>
										)}
									</>
								) : (
									'Empresas'
								)}
							</S.SegmentationHeader>
							<span>
								Todos os usuários da empresa selecionada serão notificados.
							</span>
						</S.SegmentationOption>

						<S.SegmentationOption
							onClick={() => handleSelectOption('custom')}
							selected={selectedOption === 'custom'}
						>
							<S.SegmentationHeader>
								{selectedOption === 'custom' ? (
									<>
										<UsersSelector
											buttonText={`Customizar usuários ${
												selectedUsers.length > 0
													? `- (${selectedUsers.length})`
													: ''
											}`}
											alreadyAddedUsers={selectedUsers}
											onAddAndRemoveUsers={handleAddAndRemoveUsers}
											allowRemoveUser
										/>
										{selectedUsers.length > 0 && (
											<SelectedItemsList
												selectedItems={selectedUsers}
												listName={'Usuários selecionados'}
											/>
										)}
									</>
								) : (
									'Customizar usuários'
								)}
							</S.SegmentationHeader>
							<span>Definir manualmente um grupo de usuários</span>
						</S.SegmentationOption>
					</S.SegmentationOptionsList>
					{selectedOption !== 'custom' && (
						<div style={{ marginTop: '2rem' }}>
							<S.AreaLabel>Tipo dos usuários notificados</S.AreaLabel>
							<S.TogglesContainer>
								<S.ToggleField>
									<S.ToggleLabel htmlFor='operators'>Operadores</S.ToggleLabel>
									<ToggleSwitch
										name={'operators'}
										data-testid='toggleButton_operators'
										defaultChecked={reportOperators}
										onChange={() => setReportOperators(!reportOperators)}
									/>
								</S.ToggleField>

								<S.ToggleField>
									<S.ToggleLabel htmlFor='collaborators'>
										Colaboradores
									</S.ToggleLabel>
									<ToggleSwitch
										name={'collaborators'}
										data-testid='toggleButton_collaborators'
										defaultChecked={reportCollaborators}
										onChange={() =>
											setReportCollaborators(!reportCollaborators)
										}
									/>
								</S.ToggleField>
							</S.TogglesContainer>
						</div>
					)}
					<S.Submit onClick={handleSubmit}>Confirmar</S.Submit>
				</S.ModalContainer>
			</Modal>
		</>
	);
}
