import { useState } from 'react';
import { EmptyContent } from '../../../../../../components/EmptyContent';
import Modal from '../../../../../../components/Modal';
import * as S from '../styles';
import avatarImg from '../../../../../../assets/avatar.svg';

export interface GenericItem {
	id: string;
	type: 'collaborator' | 'operator' | 'group' | 'company';
	name: string;
	email?: string;
	avatar?: string;
}

export interface SelectedItemsModalProps {
	selectedItems: GenericItem[];
	listName: string;
}

export function SelectedItemsList({
	selectedItems,
	listName,
}: SelectedItemsModalProps) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<S.ListButton onClick={() => setIsOpen((state) => !state)}>
				{listName}
			</S.ListButton>

			<Modal
				isOpen={isOpen}
				onRequestClose={() => setIsOpen(false)}
				enableClose
			>
				<S.ListContainer data-testid='selectedItemsListContainer-modal-container'>
					<S.ItemsGenericList>
						{selectedItems.map((item) => {
							if (item.type === 'group')
								return (
									<S.SelectedItem key={item.id}>
										<S.ItemCard>
											<S.ItemInfoContainer>
												<S.ItemName>{item.name}</S.ItemName>
											</S.ItemInfoContainer>
										</S.ItemCard>
									</S.SelectedItem>
								);

							return (
								<S.SelectedItem key={item.id}>
									<S.ItemCard>
										<S.ItemAvatar src={item.avatar ? item.avatar : avatarImg} />

										<S.ItemInfoContainer>
											<S.ItemName>{item.name}</S.ItemName>
											<S.ItemInfo>{item.email}</S.ItemInfo>
										</S.ItemInfoContainer>
									</S.ItemCard>
								</S.SelectedItem>
							);
						})}

						{!selectedItems.length && (
							<EmptyContent text='Nenhum item selecionado' />
						)}
					</S.ItemsGenericList>
				</S.ListContainer>
			</Modal>
		</>
	);
}
