import { Company, Shareholder } from "../../@types";
import api from "../api";

export interface AddShareholderParams {
    shareholder: Shareholder,
    companyId: string
}

export interface UpdateShareholderParams {
    shareholderId: string,
    companyId: string,
    shareholder: Shareholder
}

export interface DeleteShareholderParams {
    shareholderId: string,
    companyId: string
}

export interface UpdateShareholderReturn {
    shareholderId: string,
    shareholder: Shareholder
}


export interface AddShareholderReturn {
    company: Company,
    shareholder: Shareholder
}

export async function addShareholderToCompany({shareholder, companyId}: AddShareholderParams): Promise<AddShareholderReturn>{
    const { data } = await api.put<Company>(`/api/v1/companies/${companyId}/shareholders`, shareholder);

    return {
        company: data,
        shareholder
    }
}

export async function updateShareholderToCompany({shareholderId, companyId, shareholder}: UpdateShareholderParams): Promise<UpdateShareholderReturn>{
    const { data } = await api.put<Shareholder>(`/api/v1/companies/${companyId}/shareholders/${shareholderId}`,
        shareholder
      );

    return {
        shareholderId,
        shareholder: data
    }
}

export async function deleteShareholderFromCompany({shareholderId, companyId}: DeleteShareholderParams): Promise<string>{
    await api.delete(`/api/v1/companies/${companyId}/shareholders/${shareholderId}`);

    return shareholderId
}