import { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Company } from '../../@types';
import { cepMask, phoneMask, cnpjMask } from '../../utils/masks';
import { parseDateToInput } from '../../utils/parseDate';
import { ShareholdersModal } from './ShareholdersModal';
import { useCompanyForm } from '../../hooks/useCompanyForm';
import Loader from '../Loader';
import { RiUploadLine } from 'react-icons/ri';
import {
	ObrigatoryFieldsIndication,
	ObrigatoryIndicator,
} from '../ObrigatoryFieldsIndicator';
import * as FormStyle from '../Form/FormStyles';
import { useParams } from 'react-router-dom';
import { KYCFieldsIndication, KYCFieldsIndicator } from '../KYCFieldsIndicator';
import { UF_List } from '../../utils/CheckUF';
import PreventTransitionPrompt from '../PreventTransitionPrompt';
import { toast } from 'react-toastify';
import { useDialogModal } from '../../hooks/useDialogModal';
import { useQuery } from 'react-query';
import { getCompanyScheduledPaymentsInAFewDays } from '../../services/queries/Companies';
import {
	addressesFieldsCollaboratorSWAPPattern,
	addressNumberPattern,
	cepPattern,
	cnpjPattern,
	emailPattern,
	onlyLettersAndSpacesPattern,
	phonePattern,
	websitePattern,
} from '../../utils/patterns';
import { trimObjectData } from '../../utils/trimObjectData';
import { SearchCEPButton } from '../SearchCEPButton';
import { useGetAddressByCEP } from '../../hooks/useGetAddressByCEP';

export const WARNING_MESSAGE_NO_SCHEDULED_PAYMENTS =
	'Olá, usuário. A alteração solicitada exigirá uma nova análise de KYC da companhia, o que pode acarretar em impossibilidade de executar algumas ações, como programar pagamentos. Isso ocorrerá se algum dos dados atualizados estiver incorreto e permanecerá até regularização das informações e aprovação na análise de KYC. Deseja continuar com esta atualização?';
export const WARNING_MESSAGE_SCHEDULED_PAYMENTS =
	'Olá, usuário. Existem solicitações de pagamentos agendadas pela sua empresa para os próximos dias. A alteração solicitada exigirá uma nova análise de KYC da companhia, o que pode acarretar em impossibilidade de executar os pagamentos já programados. Isso ocorrerá se algum dos dados atualizados estiver incorreto e permanecerá até regularização das informações e aprovação na análise de KYC. Deseja continuar com esta atualização?';
interface CompanyFormProps {
	update: boolean;
}
export function CompanyForm({ update }: CompanyFormProps) {
	const {
		loading,
		company,
		toggleStatus,
		submitCompany,
		updateCompany,
		handleUpdateAvatar,
	} = useCompanyForm();
	const [avatarImg, setAvatarImg] = useState<File>();
	const { groupCompanyId } = useParams();
	const { openOptionsDialog } = useDialogModal();
	const { register, handleSubmit, reset, formState, getValues } =
		useForm<Company>({
			defaultValues: {
				...company,
				cnpj: cnpjMask(company?.cnpj || ''),
				cep: cepMask(company?.cep || ''),
				first_phone: phoneMask(company?.first_phone || ''),
				second_phone: phoneMask(company?.second_phone || ''),
				founding_date: parseDateToInput(company?.founding_date || ''),
				uf: company?.uf || '',
			},
		});

	const { isDirty, errors } = formState;
	const [allowNavigation, setAllowNavigation] = useState(!isDirty);

	useEffect(() => {
		setAllowNavigation(!(isDirty || avatarImg));
	}, [isDirty, avatarImg]);

	useEffect(() => {
		if (company?.id)
			reset({
				...company,
				cnpj: cnpjMask(company?.cnpj || ''),
				cep: cepMask(company?.cep || ''),
				first_phone: phoneMask(company?.first_phone || ''),
				second_phone: phoneMask(company?.second_phone || ''),
				founding_date: parseDateToInput(company?.founding_date || ''),
				uf: company?.uf || '',
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [company]);

	const { searchAddressByCEP, isSearchingCEP } = useGetAddressByCEP({
		onAddressFoundCallback: (address) => {
			reset({ ...getValues(), ...address }); // reset the form filling with the fetched address
		},
	});

	const fetchCompanyScheduledPaymentsQuery = useQuery(
		'companyScheduledPayments',
		() => {
			return getCompanyScheduledPaymentsInAFewDays(company!.id!);
		},
		{
			enabled: false,
		}
	);

	function onAvatarUpload(e: ChangeEvent<HTMLInputElement>) {
		if (e.target.files!.length === 0) {
			setAvatarImg(undefined);
			return;
		}

		const file = e.target.files![0];
		const fileMb = file.size / 1024 ** 2;
		const avatarMbLimit = 1;
		if (fileMb > avatarMbLimit) {
			toast.error('Não é possível enviar imagens maiores que 1MB.');
			return;
		}

		setAvatarImg(file);
	}
	async function handleUpdateCompany(data: Company) {
		if (avatarImg) {
			await handleUpdateAvatar(avatarImg);
		}

		if (isDirty) {
			await updateCompany(trimObjectData(data));
		}

		toast.info('Informações da empresa atualizadas com sucesso!');
	}

	async function handleSubmitForm(data: Company) {
		if (!update) {
			submitCompany(groupCompanyId!, trimObjectData(data), avatarImg);
		} else {
			if (!isDirty && !avatarImg) {
				toast.error(
					'Modifique alguma informação antes de requisitar a atualização!'
				);
				return;
			}
			// fetch if company has payments scheduled in a few day warning
			const scheduledPaymentsInAFewDays = (
				await fetchCompanyScheduledPaymentsQuery.refetch()
			).data;

			openOptionsDialog(
				scheduledPaymentsInAFewDays
					? WARNING_MESSAGE_SCHEDULED_PAYMENTS
					: WARNING_MESSAGE_NO_SCHEDULED_PAYMENTS,
				'Confirmar',
				async () => await handleUpdateCompany(data),
				'Cancelar',
				() => {}
			);
		}
	}

	function onInvalidInput(message: string) {
		toast.error(message, { style: { fontSize: '1.3rem' } });
	}

	if (loading || fetchCompanyScheduledPaymentsQuery.isLoading) {
		return <Loader />;
	}

	return (
		<>
			<PreventTransitionPrompt when={!allowNavigation} />

			<FormStyle.Form
				onSubmit={handleSubmit(handleSubmitForm)}
				autoComplete='off'
				autoCorrect='off'
				data-testid='company_form_test_id'
			>
				<FormStyle.FormContainer>
					{/* COMPANY DATA FIELDSET */}
					<FormStyle.FieldSet>
						<FormStyle.FieldGroup>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='name'>
									Razão Social <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</FormStyle.Label>
								<FormStyle.Input
									maxLength={70}
									type='text'
									{...register('name')}
									name='name'
									id='name'
									data-testid='nameInput_test_id'
									required
								/>
							</FormStyle.Field>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='email'>
									Email <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</FormStyle.Label>
								<FormStyle.Input
									type='email'
									{...register('email')}
									name='email'
									pattern={emailPattern}
									id='email'
									data-testid='emailInput_test_id'
									required
								/>
							</FormStyle.Field>
						</FormStyle.FieldGroup>
						<FormStyle.FieldGroup>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='corporate_name'>
									Nome Fantasia <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</FormStyle.Label>
								<FormStyle.Input
									maxLength={70}
									type='text'
									{...register('corporate_name', {
										maxLength: {
											value: 70,
											message: 'O campo deve ter no máximo 70 caracteres.',
										},
									})}
									name='corporate_name'
									id='corporate_name'
									data-testid='fantasyInput_test_id'
									required
								/>
								{errors.corporate_name && (
									<span>{errors.corporate_name.message}</span>
								)}
							</FormStyle.Field>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='cnpj'>
									CNPJ <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</FormStyle.Label>
								<FormStyle.Input
									type='text'
									{...register('cnpj')}
									name='cnpj'
									pattern={cnpjPattern}
									id='cnpj'
									required
									onChange={(event) => {
										const { value } = event.target;
										event.target.value = cnpjMask(value);
									}}
									data-testid='cnpjInput_test_id'
									disabled={update}
								/>
							</FormStyle.Field>
						</FormStyle.FieldGroup>
						<FormStyle.FieldGroup>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='founding_date'>
									Data de Fundação <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</FormStyle.Label>
								<FormStyle.Input
									type='date'
									{...register('founding_date')}
									name='founding_date'
									max={new Date().toISOString().split('T')[0]}
									id='founding_date'
									data-testid='foundingInput_test_id'
									required
								/>
							</FormStyle.Field>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='business_type'>
									Segmento Comercial <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</FormStyle.Label>
								<FormStyle.Input
									maxLength={255}
									type='text'
									{...register('business_type')}
									name='business_type'
									id='business_type'
									data-testid='businessInput_test_id'
									required
								/>
							</FormStyle.Field>
						</FormStyle.FieldGroup>
						<FormStyle.FieldGroup>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='main_activity'>
									Número do CNAE <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</FormStyle.Label>
								<FormStyle.Input
									maxLength={50}
									type='text'
									{...register('main_activity')}
									name='main_activity'
									id='main_activity'
									data-testid='activityInput_test_id'
									required
								/>
							</FormStyle.Field>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='website'>
									Website <KYCFieldsIndicator />
								</FormStyle.Label>
								<FormStyle.Input
									maxLength={255}
									type='text'
									{...register('website')}
									name='website'
									id='website'
									pattern={websitePattern}
									placeholder='Ex: www.exemplo.com'
									data-testid='websiteInput_test_id'
								/>
							</FormStyle.Field>
						</FormStyle.FieldGroup>
						<FormStyle.FieldGroup>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='financial_sector_email'>
									Email do Setor Financeiro <ObrigatoryIndicator />
								</FormStyle.Label>
								<FormStyle.Input
									type='email'
									{...register('financial_sector_email')}
									name='financial_sector_email'
									pattern={emailPattern}
									id='financial_sector_email'
									data-testid='financial_sector_emailInput_test_id'
									required
								/>
							</FormStyle.Field>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='financial_sector_email'>
									Email do Setor de Recursos Humanos <ObrigatoryIndicator />
								</FormStyle.Label>
								<FormStyle.Input
									type='email'
									{...register('hr_sector_email')}
									name='hr_sector_email'
									pattern={emailPattern}
									id='hr_sector_email'
									data-testid='hr_sector_emailInput_test_id'
									required
								/>
							</FormStyle.Field>
						</FormStyle.FieldGroup>
						<FormStyle.FieldGroup>
							<FormStyle.Field>
								<FormStyle.ImageUploadContainer>
									<FormStyle.Label>Logo</FormStyle.Label>
									{
										// PREVIEW IMAGEM
										avatarImg !== undefined ? (
											<FormStyle.ImagePreview
												src={URL.createObjectURL(avatarImg)}
											/>
										) : (
											company?.avatar_url && (
												<FormStyle.ImagePreview src={company.avatar_url} />
											)
										)
									}
									<>
										<FormStyle.UploadIcon htmlFor='logo'>
											<RiUploadLine />
										</FormStyle.UploadIcon>
										<input
											type='file'
											id='logo'
											onChange={onAvatarUpload}
											name='logo'
											multiple={false}
											accept='.jpg, .jpeg, .png'
											style={{ display: 'none' }}
											data-testid='imageInput_test_id'
										/>
									</>
								</FormStyle.ImageUploadContainer>
							</FormStyle.Field>
						</FormStyle.FieldGroup>
					</FormStyle.FieldSet>

					<div
						style={{
							width: '100%',
							height: '0.1rem',
							background: 'var(--light-gray)',
							margin: '0 0 1.5rem 0',
						}}
					/>

					{/* ADDRESS */}
					<FormStyle.FieldSet>
						<FormStyle.FieldGroup>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='address'>
									Endereço <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</FormStyle.Label>
								<FormStyle.Input
									maxLength={50}
									type='text'
									{...register('address', {
										maxLength: {
											value: 50,
											message: 'O campo deve ter no máximo 50 caracteres.',
										},
									})}
									name='address'
									id='address'
									pattern={addressesFieldsCollaboratorSWAPPattern}
									onInvalid={(e) => {
										if (e.currentTarget.value === '') return;
										onInvalidInput(
											"Utilize apenas letras, espaços e (.)(,)(')(/)(-) no campo de endereço"
										);
									}}
									data-testid='addressInput_test_id'
									required
								/>
								{errors.address && <span>{errors.address.message}</span>}
							</FormStyle.Field>
							<FormStyle.Field style={{ maxWidth: '25%' }}>
								<FormStyle.Label htmlFor='number'>
									Número <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</FormStyle.Label>
								<FormStyle.Input
									maxLength={10}
									type='text'
									{...register('number')}
									name='number'
									id='number'
									pattern={addressNumberPattern}
									onInvalid={(e) => {
										if (e.currentTarget.value === '') return;
										onInvalidInput(
											'Utilize apenas caracteres numéricos no campo de número.'
										);
									}}
									data-testid='numberInput_test_id'
									required
								/>
							</FormStyle.Field>
						</FormStyle.FieldGroup>
						<FormStyle.FieldGroup>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='district'>
									Bairro <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</FormStyle.Label>
								<FormStyle.Input
									maxLength={30}
									type='text'
									{...register('district', {
										maxLength: {
											value: 30,
											message: 'O campo deve ter no máximo 30 caracteres.',
										},
									})}
									name='district'
									id='district'
									pattern={addressesFieldsCollaboratorSWAPPattern}
									onInvalid={(e) => {
										if (e.currentTarget.value === '') return;
										onInvalidInput(
											"Utilize apenas letras, espaços e (.)(,)(')(/)(-) no campo de bairro"
										);
									}}
									data-testid='districtInput_test_id'
									required
								/>
								{errors.district && <span>{errors.district.message}</span>}
							</FormStyle.Field>
							<FormStyle.Field style={{ maxWidth: '25%' }}>
								<FormStyle.Label htmlFor='cep'>
									CEP <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</FormStyle.Label>
								<FormStyle.Input
									type='text'
									pattern={cepPattern}
									placeholder='Ex: 99999-999'
									{...register('cep')}
									name='cep'
									id='cep'
									required
									onChange={(event) => {
										const { value } = event.target;
										event.target.value = cepMask(value);
									}}
									data-testid='cepInput_test_id'
								/>
							</FormStyle.Field>
							<SearchCEPButton
								isLoading={isSearchingCEP}
								onClick={() => searchAddressByCEP(getValues().cep)}
							/>
						</FormStyle.FieldGroup>
						<FormStyle.FieldGroup>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='complement'>
									Complemento
								</FormStyle.Label>
								<FormStyle.Input
									maxLength={30}
									type='text'
									{...register('complement')}
									name='complement'
									id='complement'
									pattern={addressesFieldsCollaboratorSWAPPattern}
									onInvalid={(e) => {
										if (e.currentTarget.value === '') return;
										onInvalidInput(
											"Utilize apenas letras, espaços e (.)(,)(')(/)(-) no campo de complemento"
										);
									}}
									data-testid='complementInput_test_id'
								/>
							</FormStyle.Field>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='reference'>
									Referência
								</FormStyle.Label>
								<FormStyle.Input
									maxLength={30}
									type='text'
									{...register('reference')}
									name='reference'
									id='reference'
									data-testid='referenceInput_test_id'
								/>
							</FormStyle.Field>
						</FormStyle.FieldGroup>
						<FormStyle.FieldGroup>
							<FormStyle.Field style={{ maxWidth: '38%' }}>
								<FormStyle.Label htmlFor='city'>
									Cidade <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</FormStyle.Label>
								<FormStyle.Input
									maxLength={30}
									type='text'
									{...register('city', {
										maxLength: {
											value: 30,
											message: 'O campo deve ter no máximo 30 caracteres.',
										},
									})}
									name='city'
									id='city'
									pattern={onlyLettersAndSpacesPattern}
									onInvalid={(e) => {
										if (e.currentTarget.value === '') return;
										onInvalidInput(
											'Utilize apenas letras e espaços no campo de cidade'
										);
									}}
									data-testid='cityInput_test_id'
									required
								/>
								{errors.city && <span>{errors.city.message}</span>}
							</FormStyle.Field>
							<FormStyle.Field style={{ maxWidth: '10%' }}>
								<FormStyle.Label htmlFor='uf'>
									UF <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</FormStyle.Label>
								<FormStyle.SelectInput
									{...register('uf')}
									name='uf'
									id='uf'
									data-testid='ufInput_test_id'
									required
								>
									<option disabled value=''>
										Selecione uma opção
									</option>
									{UF_List.map((uf) => (
										<option key={uf} value={uf}>
											{uf}
										</option>
									))}
								</FormStyle.SelectInput>
							</FormStyle.Field>
						</FormStyle.FieldGroup>
					</FormStyle.FieldSet>

					<FormStyle.Divider />

					<FormStyle.FieldSet>
						<FormStyle.FieldGroup>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='first_phone'>
									Telefone 1 <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</FormStyle.Label>
								<FormStyle.Input
									type='tel'
									placeholder='Ex: +99 99 99999-9999'
									pattern={phonePattern}
									{...register('first_phone')}
									name='first_phone'
									id='first_phone'
									required
									data-testid='firstPhoneInput_test_id'
									onChange={(event) => {
										const { value } = event.target;
										event.target.value = phoneMask(value);
									}}
								/>
							</FormStyle.Field>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='second_phone'>
									Telefone 2
								</FormStyle.Label>
								<FormStyle.Input
									type='tel'
									placeholder='Ex: +99 99 99999-9999'
									pattern={phonePattern}
									{...register('second_phone')}
									name='second_phone'
									id='second_phone'
									onChange={(event) => {
										const { value } = event.target;
										event.target.value = phoneMask(value);
									}}
									data-testid='secondPhoneInput_test_id'
								/>
							</FormStyle.Field>
						</FormStyle.FieldGroup>
					</FormStyle.FieldSet>
					<FormStyle.Divider />
				</FormStyle.FormContainer>

				<ObrigatoryFieldsIndication />
				<KYCFieldsIndication />

				<FormStyle.ButtonsContainer>
					<FormStyle.LeftButtonsContainer>
						<FormStyle.FormButton type='submit'>Salvar</FormStyle.FormButton>
						{update && (
							<>
								<FormStyle.Option
									onClick={(e) => {
										e.preventDefault();
										toggleStatus.mutate();
									}}
								>
									{company?.status ? 'Desativar' : 'Ativar'}
								</FormStyle.Option>
							</>
						)}
					</FormStyle.LeftButtonsContainer>

					<ShareholdersModal />
				</FormStyle.ButtonsContainer>
			</FormStyle.Form>
		</>
	);
}
