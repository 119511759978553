import { FaRegCalendarAlt } from 'react-icons/fa';
import { CompanyForm } from '../../../../../../components/CompanyForm';
import Loader from '../../../../../../components/Loader';
import PageTitle from '../../../../../../components/PageTitle';
import { useCompanyForm } from '../../../../../../hooks/useCompanyForm';
import {
	getStatusIcon,
	parseStatusString,
} from '../../../../../../utils/parseKycStatus';
import { ModalKycReprovedDetails } from './KycReprovedDetails/ModalKycReprovedDetails';
import * as S from './styles';
import { useState } from 'react';
import Modal from '../../../../../../components/Modal';
import { useQuery } from 'react-query';
import { fetchCompanyRegistrationDates } from '../../../../../../services/queries/Companies';
import { formatDateDDMMYYY } from '../../../../../../utils/parseDate';

export function CompanyDetails() {
	const { loading, company } = useCompanyForm();
	const [isOpen, setOpen] = useState(false);

	const fetchCompanyDatesQuery = useQuery(
		['companyRegistrationDates', company?.id, company?.cnpj],
		() => {
			if (company?.id && company?.cnpj) {
				return fetchCompanyRegistrationDates(company.id, company.cnpj);
			} else {
				return Promise.reject('Company ID or CNPJ is undefined');
			}
		},
		{
			enabled: !!company?.id && !!company?.cnpj,
		}
	);

	if (loading || !company)
		return (
			<S.Container>
				<PageTitle title='Detalhes da empresa' />
				<Loader />
			</S.Container>
		);

	return (
		<S.Container>
			<S.HeaderContainer>
				<PageTitle title='Detalhes da empresa' />
				<S.KycIndicationContainer>
					<S.StatusContainer>
						{getStatusIcon(company.kyc_status)}
						<span>{parseStatusString(company.kyc_status)}</span>
					</S.StatusContainer>
					<button type='button' onClick={() => setOpen(true)}>
						<FaRegCalendarAlt size={20} />
					</button>
					{company.kyc_status === 'reproved' && (
						<ModalKycReprovedDetails kycDetails={company.kyc_result_detail} />
					)}
				</S.KycIndicationContainer>
			</S.HeaderContainer>
			<CompanyForm update={true} />

			{isOpen && (
				<Modal
					isOpen={isOpen}
					enableClose={true}
					onRequestClose={() => setOpen(false)}
				>
					<div
						style={{
							width: '30rem',
						}}
					>
						<S.HeaderModal>
							<h1>Datas</h1>
						</S.HeaderModal>
						<div
							style={{
								width: '100%',
								height: '0.1rem',
								background: 'var(--light-gray)',
								margin: '0 0 1.5rem 0',
							}}
						/>
						<S.BodyModal>
							<S.DateContainer>
								<S.DateTitle>Data do pré-registro:</S.DateTitle>
								<S.DateValue>
									{fetchCompanyDatesQuery.data?.preRegistrationDate
										? formatDateDDMMYYY(
												fetchCompanyDatesQuery.data.preRegistrationDate
										  )
										: 'N/A'}
								</S.DateValue>
							</S.DateContainer>
							<S.DateContainer>
								<S.DateTitle>Data do registro:</S.DateTitle>
								<S.DateValue>
									{formatDateDDMMYYY(
										fetchCompanyDatesQuery.data?.registrationDate || ''
									)}
								</S.DateValue>
							</S.DateContainer>
						</S.BodyModal>
					</div>
				</Modal>
			)}
		</S.Container>
	);
}
