import { useState } from 'react';
import * as TableStyle from '../../../../../components/Table/TableStyles';
import * as S from '../styles';
import avatarImg from '../../../../../assets/avatar.svg';
import ToggleSwitch from '../../../../../components/ToggleSwitch';
import { Operator } from '../../../../../@types';
import {
	UseFormRegister,
	UseFormSetValue,
	UseFormWatch,
} from 'react-hook-form';
import { OperatorAccessForm } from '../index';
import * as FormStyle from '../../../../../components/Form/FormStyles';
import { ObrigatoryIndicator } from '../../../../../components/ObrigatoryFieldsIndicator';
import Modal from '../../../../../components/Modal';
import { toast } from 'react-toastify';
import { ACCESS_LEVELS } from '../../../../../utils/AccessLevelsMapping';

export default function UserAccess({
	operator,
	register,
	setValue,
	isFlexAdmin,
	isCorpwayAdmin,
	hasControlAdmin,
	hasFlexAdmin,
	watch,
}: {
	operator: Operator;
	register: UseFormRegister<OperatorAccessForm>;
	setValue: UseFormSetValue<OperatorAccessForm>;
	isFlexAdmin: boolean;
	isCorpwayAdmin: boolean;
	hasControlAdmin: boolean;
	hasFlexAdmin: boolean;
	watch: UseFormWatch<OperatorAccessForm>;
}) {
	const [isOpen, setIsOpen] = useState(false);
	const [isFlexOpen, setIsFlexOpen] = useState(false);

	const onRequestModalClose = () => {
		setIsOpen(false);
		setValue(`operators.${operator.id}.corporate_expenses_access`, false);
		setValue(`operators.${operator.id}.corpway_access_level`, undefined);
	};

	const onRequestFlexModalClose = () => {
		setIsFlexOpen(false);
		setValue(`operators.${operator.id}.flexible_benefits_access`, false);
	};

	const getAccessLevelTitle = (accessLevelValue: string) => {
		const level = ACCESS_LEVELS.find(
			(level) => level.value === accessLevelValue
		);
		return level ? level.title : accessLevelValue;
	};

	return (
		<>
			<CorpwayAccessLevelModal
				isOpen={isOpen}
				register={register}
				id={operator.id || ''}
				onClose={() => setIsOpen(false)}
				onFullExit={onRequestModalClose}
				watch={watch}
				onValueChange={(e) =>
					setValue(`operators.${operator.id}.access_level`, e)
				}
			/>
			<FlexAccessLevelModal
				isOpen={isFlexOpen}
				onClose={() => setIsFlexOpen(false)}
				onFullExit={onRequestFlexModalClose}
			/>
			<TableStyle.TableRow key={operator.id}>
				<TableStyle.TableData style={{ maxWidth: '30rem', minWidth: '20rem' }}>
					<S.ItemCard>
						<S.ItemAvatar src={operator.avatar ? operator.avatar : avatarImg} />

						<S.ItemInfoContainer>
							<S.ItemName>{operator.name}</S.ItemName>
							<S.ItemInfo>{operator.email}</S.ItemInfo>
						</S.ItemInfoContainer>
					</S.ItemCard>
				</TableStyle.TableData>
				<TableStyle.TableData>
					<S.ItemCard>
						<S.ItemInfoContainer>
							<S.ItemName
								style={{ color: 'var(--primary-red)', fontSize: '1.4rem' }}
							>
								{getAccessLevelTitle(operator.access_level!)}
							</S.ItemName>
							<S.ItemInfo style={{ color: 'var(--primary-blue)' }}>
								{getAccessLevelTitle(operator.corpway_access_level!)}
							</S.ItemInfo>
						</S.ItemInfoContainer>
					</S.ItemCard>
				</TableStyle.TableData>

				<TableStyle.TableData>
					<ToggleSwitch
						name={`operators.${operator.id}.flexible_benefits_access`}
						register={register}
						onChange={(e) => {
							if (e.target.checked && !hasFlexAdmin) {
								return;
							}
							if (e.target.checked && !isFlexAdmin) {
								setIsFlexOpen(true);
							}
						}}
					/>
				</TableStyle.TableData>
				<TableStyle.TableData>
					<ToggleSwitch
						name={`operators.${operator.id}.corporate_expenses_access`}
						register={register}
						onChange={(e) => {
							if (e.target.checked && !hasControlAdmin) {
								setValue(
									`operators.${operator.id}.corpway_access_level`,
									undefined
								);
								return;
							}
							if (e.target.checked && !isCorpwayAdmin) {
								setIsOpen(true);
							}
							if (!e.target.checked) {
								setValue(
									`operators.${operator.id}.corpway_access_level`,
									undefined
								);
							}
						}}
					/>
				</TableStyle.TableData>
			</TableStyle.TableRow>
		</>
	);
}

function CorpwayAccessLevelModal({
	isOpen,
	onClose,
	onFullExit,
	register,
	id,
	watch,
}: {
	isOpen: boolean;
	onClose: () => void;
	onValueChange: (value: string) => void;
	onFullExit: () => void;
	register: UseFormRegister<OperatorAccessForm>;
	id: string;
	watch: UseFormWatch<OperatorAccessForm>;
}) {
	const accessLevel = watch(`operators.${id}.corpway_access_level`);
	return (
		<Modal isOpen={isOpen} onRequestClose={onFullExit} enableClose>
			<S.Container>
				<FormStyle.Field>
					<FormStyle.Label htmlFor='contract_type'>
						Nível de Acesso do usuário control <ObrigatoryIndicator />
					</FormStyle.Label>
					<FormStyle.SelectInput
						defaultValue={''}
						{...register(`operators.${id}.corpway_access_level`)}
					>
						<option disabled value=''>
							Selecione uma opção
						</option>
						<option value='account_manager'>Gerente</option>
						<option value='finance'>Assistente</option>
						<option value='card_responsible'>Colaborador</option>
					</FormStyle.SelectInput>
				</FormStyle.Field>
				<S.SaveButton
					onClick={() => {
						if (accessLevel) {
							onClose();
						} else {
							toast.error(
								`Por favor, selecione uma opção para poder continuar.`
							);
						}
					}}
					style={{ width: '14rem' }}
				>
					Salvar
				</S.SaveButton>
			</S.Container>
		</Modal>
	);
}

function FlexAccessLevelModal({
	isOpen,
	onClose,
	onFullExit,
}: {
	isOpen: boolean;
	onClose: () => void;
	onFullExit: () => void;
}) {
	return (
		<Modal isOpen={isOpen} onRequestClose={onFullExit} enableClose>
			<S.Container
				style={{ justifyContent: 'center', alignItems: 'center', gap: '2rem' }}
			>
				<h2 style={{ textAlign: 'center' }}>
					Atenção, este usuário será um operador, confirma esta ação?
				</h2>
				<S.SaveButton onClick={onClose} style={{ width: '14rem' }}>
					Confirmar
				</S.SaveButton>
			</S.Container>
		</Modal>
	);
}
