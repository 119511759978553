import { useState, ComponentType } from 'react';
import * as S from './styles';
import { IconBaseProps } from 'react-icons';
import { SidebarLink } from '../SidebarLink';
import { useLocation } from 'react-router-dom';
import { FaMinus, FaPlus } from 'react-icons/fa';

interface MenuItem {
	title: string;
	icon: ComponentType<IconBaseProps>;
	path: string;
	strictPathCompare?: boolean;
	product?: 'control' | 'multiflex';
}

interface Props extends Omit<MenuItem, 'path'> {
	subMenuItems: MenuItem[];
}

export function SidebarDropdown({ title, icon: Icon, subMenuItems }: Props) {
	const [isOpen, setOpen] = useState(false);
	const { pathname } = useLocation();

	const toggleOpen = () => setOpen((state) => !state);

	const currentPath =
		pathname.at(-1) === '/' ? pathname.slice(0, pathname.length - 1) : pathname;

	return (
		<>
			<S.OpenButtonContainer onClick={toggleOpen}>
				<Icon />
				<S.Title>{title}</S.Title>
				<S.DropdownIndicatorContainer>
					{isOpen ? <FaMinus /> : <FaPlus />}
				</S.DropdownIndicatorContainer>
			</S.OpenButtonContainer>

			{isOpen && (
				<S.SubMenuItemsContainer>
					{subMenuItems.map((item) => (
						<SidebarLink
							icon={item.icon}
							title={item.title}
							path={item.path}
							product={item.product}
							currentPage={
								item.strictPathCompare
									? currentPath === item.path
									: currentPath.includes(item.path)
							}
							key={item.title + item.path}
						/>
					))}
				</S.SubMenuItemsContainer>
			)}
		</>
	);
}
