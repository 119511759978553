import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 90rem;
	width: 60vw;
	padding: 1rem;
`;

export const ItemContainer = styled.li`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	border-bottom: 0.1rem solid var(--primary-blue);
`;

export const ItemCard = styled.div`
	display: flex;
	align-items: center;
`;

export const ItemAvatar = styled.img`
	border: 0.2rem solid var(--primary-blue);
	border-radius: 50%;
	height: 5rem;
	width: 5rem;
	margin-right: 1rem;
	object-fit: cover;
`;

export const ItemInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	word-break: break-all;
`;

export const ItemName = styled.span`
	color: var(--primary-blue);
	font-size: 1.6rem;
`;

export const ItemInfo = styled.span`
	font-size: 1.4rem;
	color: var(--mid-gray);
`;

export const SaveButton = styled.button`
	${OptionsButton}
`;

export const BttnsContainer = styled.div`
	gap: 2rem;
	display: flex;
	align-items: center;
	justify-content: end;
`;

export const WarningText = styled.span`
  color: var(--primary-red);
  font-size: 1.2rem;
  font-weight: normal;
`
