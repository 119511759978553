import { FaInfoCircle } from "react-icons/fa";
import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 4rem 7rem 2rem 7rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  font-size: 1.4rem;
  text-align: justify;
  row-gap: 3rem;
`;

export const DetailsHeader = styled.summary`
  text-decoration: underline;
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
`;

export const Field = styled.span`
  display: block;
  & > span {
    font-weight: bold;
  }
`;

export const DownloadModelContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  color: #884141;
  font-size: 1.4rem;
`;

export const InfoIcon = styled(FaInfoCircle)`
  margin-right: 0.8rem;
  color: #5d5fef;
`;

export const Link = styled.a`
  color: #5d5fef;
  text-decoration: underline;
`;
