import { useQuery, useQueryClient } from 'react-query';
import { EmptyContent } from '../../../components/EmptyContent';
import Loader from '../../../components/Loader';
import PageTitle from '../../../components/PageTitle';
import {
	getRequestedUnderageDocuments,
	getReviewedUnderageDocuments,
} from '../../../services/queries/UnderageDocuments';
import * as TableStyle from '../../../components/Table/TableStyles';
import * as S from './styles';
import { MdNotes } from 'react-icons/md';
import { Pagination } from '../../../components/Pagination';
import { useState } from 'react';
import { Filter } from '../../../components/Filter';
import { UnderageDocument } from '../../../@types';
import CompanyCard from '../../../components/CompanyCard';
import {
	getStatusIcon,
	parseUnderageDocumentStatus,
} from '../../../utils/parseUnderageDocumentStatus';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { useUnderageDocsListStore } from '../../../stores/useUnderageDocsListStore';
import { useSetHistoryNonMatchCallbackLocation } from '../../../hooks/useSetHistoryNonMatchCallbackLocation';
import { useSortColumnHook } from '../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../components/SortColumnButton';

export function UnderageDocuments() {
	const queryClient = useQueryClient();
	const [showingTable, setShowingTable] = useState<string>();
	const [
		underageRequestedDocsCurrentPage,
		setUnderageRequestedDocsCurrentPage,
	] = useState(1);
	const [underageReviewedDocsCurrentPage, setUnderageReviewedDocsCurrentPage] =
		useState(1);
	const {
		currentSortColumn: currentSortColumnPending,
		toggleSort: toggleSortPending,
	} = useSortColumnHook();
	const {
		currentSortColumn: currentSortColumnReviewed,
		toggleSort: toggleSortReviewed,
	} = useSortColumnHook();
	const [
		filtersParamsRequested,
		setFiltersParamsRequested,
		filtersParamsReviewed,
		setFiltersParamsReviewed,
		resetFilters,
	] = useUnderageDocsListStore((state) => [
		state.filtersParamsRequested,
		state.setFiltersParamsRequested,
		state.filtersParamsReviewed,
		state.setFiltersParamsReviewed,
		state.resetFilters,
	]);

	useSetHistoryNonMatchCallbackLocation('underage-documents', resetFilters);

	const fetchRequestedUnderagesQuery = useQuery(
		[
			'requestedUnderageDocumentsList',
			underageRequestedDocsCurrentPage,
			filtersParamsRequested,
			currentSortColumnPending,
		],
		() =>
			getRequestedUnderageDocuments(
				underageRequestedDocsCurrentPage,
				filtersParamsRequested,
				currentSortColumnPending
			),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os documentos de menores. '
				);
			},
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		}
	);
	const fetchNonRequestedUnderagesQuery = useQuery(
		[
			'nonRequestedUnderageDocumentsList',
			underageReviewedDocsCurrentPage,
			filtersParamsReviewed,
			currentSortColumnReviewed,
		],
		() =>
			getReviewedUnderageDocuments(
				underageReviewedDocsCurrentPage,
				filtersParamsReviewed,
				currentSortColumnReviewed
			),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os documentos de menores. '
				);
			},
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		}
	);

	function refreshPage() {
		setUnderageRequestedDocsCurrentPage(1);
		setUnderageReviewedDocsCurrentPage(1);
		queryClient.resetQueries('requestedUnderageDocumentsList');
		queryClient.resetQueries('nonRequestedUnderageDocumentsList');
	}

	function getSortColumnOrder(columnName: string, tableName: string) {
		if (tableName === 'Colaboradores menores pendentes') {
			return currentSortColumnPending?.name === columnName
				? currentSortColumnPending.order
				: null;
		}
		return currentSortColumnReviewed?.name === columnName
			? currentSortColumnReviewed.order
			: null;
	}
	function handleToggleSortColumn(columnName: string, tableName: string) {
		if (tableName === 'Colaboradores menores pendentes')
			toggleSortPending(columnName);
		else toggleSortReviewed(columnName);
	}

	function generateRow(underageDocument: UnderageDocument) {
		return (
			<TableStyle.TableRow style={{ height: '60px' }} key={underageDocument.id}>
				<TableStyle.TableData>
					<CompanyCard
						company_name={underageDocument.company.name}
						avatar_url={undefined}
						cnpj={underageDocument.company.cnpj}
					/>
				</TableStyle.TableData>
				<TableStyle.TableData>
					{underageDocument.collaborator.name}
				</TableStyle.TableData>
				<TableStyle.TableData>
					{underageDocument.collaborator.email}
				</TableStyle.TableData>
				<TableStyle.TableData>
					<TableStyle.StatusContainer>
						{getStatusIcon(underageDocument.status)}
						<span>{parseUnderageDocumentStatus(underageDocument.status)}</span>
					</TableStyle.StatusContainer>
				</TableStyle.TableData>
				<TableStyle.TableData>
					<TableStyle.OptionLink
						to={`/underage-documents/${underageDocument.id}`}
						data-rh={
							underageDocument.status === 'requested'
								? 'Revisar documento'
								: 'Ver detalhamento'
						}
					>
						<MdNotes />
					</TableStyle.OptionLink>
				</TableStyle.TableData>
			</TableStyle.TableRow>
		);
	}

	function getTableBody(
		tableName: string,
		underageDocuments: UnderageDocument[]
	) {
		if (underageDocuments.length === 0)
			return <EmptyContent text='Não há ítens a serem exibidos.' />;

		return (
			<TableStyle.Table>
				<TableStyle.TableHeader>
					<TableStyle.TableRow>
						<TableStyle.TableHeaderCell>
							<SortColumnButton
								order={getSortColumnOrder('company', tableName)}
								onToggleSort={() =>
									handleToggleSortColumn('company', tableName)
								}
							/>
							EMPRESA
						</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>
							<SortColumnButton
								order={getSortColumnOrder('name', tableName)}
								onToggleSort={() => handleToggleSortColumn('name', tableName)}
							/>
							NOME DO COLABORADOR
						</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>
							<SortColumnButton
								order={getSortColumnOrder('email', tableName)}
								onToggleSort={() => handleToggleSortColumn('email', tableName)}
							/>
							EMAIL DO COLABORADOR
						</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>STATUS</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>OPÇÕES</TableStyle.TableHeaderCell>
					</TableStyle.TableRow>
				</TableStyle.TableHeader>

				<TableStyle.TableBody>
					{showingTable === tableName
						? underageDocuments.map((underageDocuments) =>
								generateRow(underageDocuments)
						  )
						: generateRow(underageDocuments[0])}
				</TableStyle.TableBody>
			</TableStyle.Table>
		);
	}

	function getTableRequestedDocuments(tableName: string) {
		return (
			<>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle
							title='Colaboradores menores pendentes'
							totalRecords={
								fetchRequestedUnderagesQuery.data?.totalDocumentsRequested
							}
						/>
						<Filter
							filterParams={filtersParamsRequested}
							onFiltersChanged={(updatedFilters) => {
								setUnderageRequestedDocsCurrentPage(1);
								setFiltersParamsRequested(updatedFilters);
							}}
						/>
						{showingTable === tableName ? (
							<TableStyle.SeeLess
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver menos
							</TableStyle.SeeLess>
						) : (
							<TableStyle.SeeMore
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver mais
							</TableStyle.SeeMore>
						)}
					</TableStyle.TitleWrapper>

					{showingTable === 'Colaboradores menores pendentes' && (
						<Pagination
							onPageChange={(page) => setUnderageRequestedDocsCurrentPage(page)}
							currentPage={underageRequestedDocsCurrentPage}
							totalCount={
								fetchRequestedUnderagesQuery.data
									? fetchRequestedUnderagesQuery.data.totalDocumentsRequested!
									: 1
							}
						/>
					)}

					<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
				</TableStyle.TableHeaderContainer>

				{!fetchRequestedUnderagesQuery.data ||
				fetchRequestedUnderagesQuery.isPreviousData ? (
					<Loader />
				) : (
					getTableBody(
						tableName,
						fetchRequestedUnderagesQuery.data.documentsRequested
					)
				)}
			</>
		);
	}

	function getTableReviewedDocuments(tableName: string) {
		return (
			<>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle
							title='Colaboradores menores revisados'
							totalRecords={
								fetchNonRequestedUnderagesQuery.data?.totalDocumentsNonRequested
							}
						/>
						<Filter
							filterParams={filtersParamsReviewed}
							onFiltersChanged={(updatedFilters) => {
								setUnderageReviewedDocsCurrentPage(1);
								setFiltersParamsReviewed(updatedFilters);
							}}
						/>
						{showingTable === tableName ? (
							<TableStyle.SeeLess
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver menos
							</TableStyle.SeeLess>
						) : (
							<TableStyle.SeeMore
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver mais
							</TableStyle.SeeMore>
						)}
					</TableStyle.TitleWrapper>

					{showingTable === 'Colaboradores menores revisados' && (
						<Pagination
							onPageChange={(page) => setUnderageReviewedDocsCurrentPage(page)}
							currentPage={underageReviewedDocsCurrentPage}
							totalCount={
								fetchNonRequestedUnderagesQuery.data
									? fetchNonRequestedUnderagesQuery.data
											.totalDocumentsNonRequested!
									: 1
							}
						/>
					)}
				</TableStyle.TableHeaderContainer>

				{!fetchNonRequestedUnderagesQuery.data ||
				fetchNonRequestedUnderagesQuery.isPreviousData ? (
					<Loader />
				) : (
					getTableBody(
						tableName,
						fetchNonRequestedUnderagesQuery.data.documentsNonRequested
					)
				)}
			</>
		);
	}

	return (
		<S.Container>
			{getTableRequestedDocuments('Colaboradores menores pendentes')}
			{getTableReviewedDocuments('Colaboradores menores revisados')}
		</S.Container>
	);
}
