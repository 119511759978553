import { useState } from 'react';
import { useQuery } from 'react-query';
import { Company, GroupCompany } from '../../@types';
import { fetchGroupCompany } from '../../services/queries/GroupCompany';
import { cnpjMask } from '../../utils/masks';
import { EmptyContent } from '../EmptyContent';
import Loader from '../Loader';
import Modal from '../Modal';
import * as S from './styles';
import { convertCentsToReais } from '../../utils/CurrencyConvert';
import { showErrorMessage } from '../../utils/ErrorHandler';

export interface SelectCompanyProps {
	groupCompanyId: string | null;
	selectedCompany: Company | null;
	onCompanySelection: (c: Company) => void;
}
export function SelectCompany({
	groupCompanyId,
	selectedCompany,
	onCompanySelection,
}: SelectCompanyProps) {
	const [isOpen, setIsOpen] = useState(false);

	const getGroupCompanyQuery = useQuery<GroupCompany, Error>(
		['fetchGroupCompany', groupCompanyId],
		() => fetchGroupCompany(groupCompanyId!),
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar as empresas. ');
			},
			enabled: !!groupCompanyId,
			refetchOnWindowFocus: false,
		}
	);

	function getNoContentIndicator() {
		if (getGroupCompanyQuery.isLoading || getGroupCompanyQuery.isPreviousData)
			return <Loader />;

		if (!getGroupCompanyQuery.data?.companies.activeCompanies.length)
			return <EmptyContent text='Nenhuma empresa disponível' />;

		return null;
	}

	return (
		<>
			<S.OpenCompaniesModalButton
				disabled={!groupCompanyId}
				onClick={(e) => {
					e.preventDefault();
					setIsOpen(true);
				}}
			>
				{selectedCompany ? (
					<S.CompanyInfoName
						style={{
							margin: '0 auto',
						}}
						data-rh={
							!selectedCompany
								? undefined
								: `${selectedCompany.name} - ${cnpjMask(selectedCompany.cnpj)}`
						}
					>
						{selectedCompany.name} - {cnpjMask(selectedCompany.cnpj)}
					</S.CompanyInfoName>
				) : (
					'Selecionar Empresa'
				)}
			</S.OpenCompaniesModalButton>
			{/* COMPANIES MODAL */}
			<Modal
				isOpen={isOpen}
				onRequestClose={() => {
					setIsOpen(false);
				}}
				enableClose
			>
				<S.CompanyContainer>
					{getGroupCompanyQuery.data?.companies.activeCompanies.map(
						(company) => (
							<S.CompanyOption
								onClick={() => {
									onCompanySelection(company);
									setIsOpen(false);
								}}
								key={company.id!!}
							>
								<img src={company.avatar_url} alt={company.name} />
								<S.CompanyInfo>
									<span>
										{company.name} - {cnpjMask(company.cnpj)}
									</span>
									<S.CompanyBalanceContainer>
										<span>
											Saldo Benefícios Flexíveis:{' '}
											{convertCentsToReais(company.multiflex_balance)}
										</span>
										<span>
											Saldo Bounty Control:{' '}
											{convertCentsToReais(company.corpway_balance)}
										</span>
									</S.CompanyBalanceContainer>
								</S.CompanyInfo>
							</S.CompanyOption>
						)
					)}
					{getNoContentIndicator()}
				</S.CompanyContainer>
			</Modal>
		</>
	);
}
