import { createContext, ReactNode, useState } from 'react';
import {
	useMutation,
	UseMutationResult,
	useQuery,
	useQueryClient,
} from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Company, Shareholder } from '../@types';
import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator';
import {
	createCompany,
	CreateCompanyParams,
	fetchCompany,
	handleToggleStatus,
	handleUpdateCompanyAvatarQuery,
	handleUpdateCompanyQuery,
	UpdateCompanyAvatarParams,
	UpdateCompanyParams,
} from '../services/queries/Companies';
import {
	AddShareholderParams,
	addShareholderToCompany,
	deleteShareholderFromCompany,
	UpdateShareholderParams,
	updateShareholderToCompany,
} from '../services/queries/Shareholders';
import {
	parseMaskedCEPToRaw,
	parseMaskedCnpjToRaw,
	parseMaskedCompanyPhoneToRaw,
	parseMaskedCPFToRaw,
	parseMaskedPhoneToRaw,
} from '../utils/masks';
import { parseInputToDate } from '../utils/parseDate';
import { showErrorMessage } from '../utils/ErrorHandler';

type Props = {
	children: ReactNode;
};

export interface ShareholderFormData
	extends Omit<Shareholder, 'first_name' | 'last_name'> {
}

export type CompanyFormData = {
	loading: boolean;
	company?: Company;
	submitCompany: (
		groupCompanyId: string,
		company: Company,
		logo: File | undefined
	) => Promise<void>;
	updateCompany: (company: Company) => Promise<void>;
	handleUpdateAvatar: (logo: File) => Promise<void>;
	toggleStatus: UseMutationResult<void, unknown, void, unknown>;

	createShareholderToCompanyCreation: (
		shareholder: ShareholderFormData
	) => boolean;
	updateShareholderToCompanyCreation: (
		previousCPF: string,
		shareholder: ShareholderFormData
	) => boolean;
	removeShareholderToCompanyCreation: (type: "PF" | "PJ", cpfOrCnpj: string) => boolean;

	addShareholder: (shareholder: ShareholderFormData) => Promise<boolean>;
	updateShareholder: (shareholder: ShareholderFormData) => Promise<boolean>;
	deleteShareholder: (id: string) => Promise<boolean>;
};
export const CompanyFormContext = createContext({} as CompanyFormData);

interface UpdateCompanyFields {
	corporate_name: string;
	name: string;
	email: string;
	financial_sector_email?: string;
	hr_sector_email?: string;
	email_domain?: string;
	cep: string;
	address: string;
	number: string;
	complement?: string;
	reference?: string;
	district: string;
	city: string;
	uf: string;
	first_phone: string;
	second_phone?: string;
	website?: string;
	founding_date: string;
	business_type: string;
	main_activity: string;
}
export function CompanyFormProvider({ children }: Props) {
	const [company, setCompany] = useState<Company>();
	const { id } = useParams();
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const fetchCompanyQuery = useQuery(
		['fetchCompany', id],
		() => {
			return fetchCompany(id!);
		},
		{
			onSuccess: (data) => {
				setCompany({ ...data });
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar buscar a empresa. '
				);
			},
			enabled: !!id,
			refetchOnWindowFocus: false,
		}
	);

	const createCompanyMutation = useMutation(
		({ groupCompanyId, companyData }: CreateCompanyParams) =>
			createCompany({ groupCompanyId, companyData }),
		{
			onSuccess: (groupCompanyId) => {
				toast.info('Companhia criada');
				navigate(`/group-companies/${groupCompanyId}`);
			},
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível criar a empresa. ');
			},
		}
	);

	const updateCompanyMutation = useMutation(
		({ companyId, company }: UpdateCompanyParams) => {
			return handleUpdateCompanyQuery({ companyId, company });
		},
		{
			onSuccess: (data) => {
				setCompany({ ...data });
				queryClient.invalidateQueries(['fetchCompany']);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível atualizar as informações da empresa. '
				);
			},
		}
	);

	const updateCompanyAvatarQuery = useMutation(
		({ companyId, avatar }: UpdateCompanyAvatarParams) => {
			return handleUpdateCompanyAvatarQuery({ companyId, avatar });
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['fetchCompany']);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível atualizar o avatar da empresa. '
				);
			},
		}
	);

	const toggleStatus = useMutation(
		() => {
			return handleToggleStatus(company!);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchCompany');
				toast.info(
					`Empresa ${company?.status ? 'desativada' : 'ativada'} com sucesso`
				);
			},
			onError: (err) => {
				console.log(err);
				toast.error(
					`Ocorreu um problema ao tentar ${
						company?.status ? 'desativar' : 'ativar'
					} a empresa`
				);
			},
		}
	);

	const addShareholderQuery = useMutation(
		({ shareholder, companyId }: AddShareholderParams) => {
			return addShareholderToCompany({ shareholder, companyId });
		},
		{
			onSuccess: (data) => {
				setCompany({ ...company!, shareholders: data.company.shareholders });
				toast.info(
					`O sócio ${data.shareholder.first_name} ${data.shareholder.last_name} foi criado com sucesso!`
				);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível adicionar novo sócio. '
				);
			},
		}
	);

	const updateShareholderQuery = useMutation(
		({ shareholderId, companyId, shareholder }: UpdateShareholderParams) => {
			return updateShareholderToCompany({
				shareholderId,
				companyId,
				shareholder,
			});
		},
		{
			onSuccess: (data) => {
				const updatedShareholdersList = company!.shareholders.map((s) => {
					if (s.id === data.shareholderId) {
						return data.shareholder;
					}
					return s;
				});

				setCompany({ ...company!, shareholders: updatedShareholdersList });
				toast.info(
					`Sócio ${data.shareholder.first_name} ${data.shareholder.last_name} atualizado com sucesso!`
				);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível atualizar as informações de um sócio. '
				);
			},
		}
	);

	const deleteShareholderQuery = useMutation(
		(id: string) => {
			return deleteShareholderFromCompany({
				shareholderId: id,
				companyId: company!.id!,
			});
		},
		{
			onSuccess(data) {
				const updatedShareholdersList = company!.shareholders.filter(
					(s) => s.id !== data
				);
				setCompany({ ...company!, shareholders: updatedShareholdersList });
				toast.info(`Sócio removido com sucesso!`);
			},
			onError(error) {
				showErrorMessage(error as Error, 'Não foi possível remover o sócio. ');
			},
		}
	);

	function extractEmailDomain(email: string) {
		const email_domain = email.substring(email.indexOf('@'), email.length);
		return email_domain;
	}

	async function submitCompany(
		groupCompanyId: string,
		companyData: Company,
		logo: File | undefined
	) {
		if (!company?.shareholders.length) {
			toast.error('É necessário cadastrar ao menos um sócio para a empresa!');
			return;
		}

		companyData.first_phone = parseMaskedCompanyPhoneToRaw(
			companyData.first_phone
		);
		companyData.second_phone = parseMaskedCompanyPhoneToRaw(
			companyData.second_phone!
		);
		companyData.cep = parseMaskedCEPToRaw(companyData?.cep || '');
		companyData.founding_date = parseInputToDate(companyData.founding_date);
		companyData.cnpj = parseMaskedCnpjToRaw(companyData.cnpj);

		const companyFormData = new FormData();
		companyFormData.append('address', companyData.address);
		if (logo) companyFormData.append('avatar', logo);
		companyFormData.append('cep', companyData.cep);
		companyFormData.append('city', companyData.city);
		companyFormData.append('cnpj', companyData.cnpj);
		companyFormData.append(
			'complement',
			companyData.complement ? companyData.complement : ''
		);
		companyFormData.append(
			'reference',
			companyData.reference ? companyData.reference : ''
		);
		companyFormData.append('corporate_name', companyData.corporate_name);
		companyFormData.append('district', companyData.district);
		companyFormData.append('email', companyData.email);
		companyFormData.append(
			'financial_sector_email',
			companyData.financial_sector_email
		);
		companyFormData.append('hr_sector_email', companyData.hr_sector_email);
		companyFormData.append(
			'email_domain',
			extractEmailDomain(companyData.email)
		);
		companyFormData.append('first_phone', companyData.first_phone);
		companyFormData.append('name', companyData.name);
		companyFormData.append('number', companyData.number);
		companyFormData.append(
			'second_phone',
			companyData.second_phone ? companyData.second_phone : ''
		);
		companyFormData.append('uf', companyData.uf);
		companyFormData.append('website', companyData.website ?? '');
		companyFormData.append('founding_date', companyData.founding_date);
		companyFormData.append('business_type', companyData.business_type);
		companyFormData.append('main_activity', companyData.main_activity);
		companyFormData.append(
			'product_flexible_benefits_enabled',
			Boolean(
				companyData.company_products?.product_flexible_benefits_enabled
			).toString()
		);
		companyFormData.append(
			'product_corporate_expenses_enabled',
			Boolean(
				companyData.company_products?.product_corporate_expenses_enabled
			).toString()
		);

		if (company?.shareholders) {
			company!.shareholders.forEach((shareholder, index) => {
				if (shareholder.type_of_social_contract === "PF"){
					shareholder.cpf = parseMaskedCPFToRaw(shareholder.cpf!);

					companyFormData.append(
						`shareholders[${index}][first_name]`,
						shareholder.first_name!
					);
					companyFormData.append(
						`shareholders[${index}][last_name]`,
						shareholder.last_name!
					);

					companyFormData.append(
						`shareholders[${index}][mother_name]`,
						shareholder.mother_name!
					);

					companyFormData.append(`shareholders[${index}][cpf]`, shareholder.cpf!);
					companyFormData.append(
						`shareholders[${index}][birth_date]`,
						shareholder.birth_date!
					);
				}else {
					shareholder.cnpj = parseMaskedCnpjToRaw(shareholder.cnpj!);

					companyFormData.append(
						`shareholders[${index}][legal_name]`,
						shareholder.legal_name!
					);
					companyFormData.append(
						`shareholders[${index}][trading_name]`,
						shareholder.trading_name!
					);

					companyFormData.append(
						`shareholders[${index}][main_activity]`,
						shareholder.main_activity!
					);

					companyFormData.append(`shareholders[${index}][cnpj]`, shareholder.cnpj!);

					companyFormData.append(
						`shareholders[${index}][founding_date]`,
						shareholder.founding_date!
					);
					companyFormData.append(
						`shareholders[${index}][website]`,
						shareholder.website!
					);
				}

				shareholder.phone_number = parseMaskedPhoneToRaw(
					shareholder.phone_number
				);
				shareholder.cep = parseMaskedCEPToRaw(shareholder.cep);
				companyFormData.append(
					`shareholders[${index}][type_of_social_contract]`,
					shareholder.type_of_social_contract!
				);

				companyFormData.append(
					`shareholders[${index}][type]`,
					shareholder.type
				);
				companyFormData.append(
					`shareholders[${index}][email]`,
					shareholder.email
				);
				companyFormData.append(
					`shareholders[${index}][phone_number]`,
					shareholder.phone_number
				);
				companyFormData.append(`shareholders[${index}][cep]`, shareholder.cep);
				companyFormData.append(
					`shareholders[${index}][address]`,
					shareholder.address
				);
				companyFormData.append(
					`shareholders[${index}][number]`,
					shareholder.number
				);
				companyFormData.append(
					`shareholders[${index}][complement]`,
					shareholder.complement
				);
				companyFormData.append(
					`shareholders[${index}][district]`,
					shareholder.district
				);
				companyFormData.append(
					`shareholders[${index}][city]`,
					shareholder.city
				);
				companyFormData.append(`shareholders[${index}][uf]`, shareholder.uf);
			});
		}

		setCompany(company);
		await createCompanyMutation.mutateAsync({
			groupCompanyId,
			companyData: companyFormData,
		});
	}

	async function updateCompany(companyToUpdate: Company) {
		const {
			corporate_name,
			name,
			email,
			financial_sector_email,
			hr_sector_email,
			email_domain,
			cep,
			address,
			number,
			complement,
			reference,
			district,
			city,
			uf,
			first_phone,
			second_phone,
			website,
			founding_date,
			business_type,
			main_activity,
		}: UpdateCompanyFields = {
			...companyToUpdate,
			email_domain: extractEmailDomain(companyToUpdate.email),
			first_phone: parseMaskedPhoneToRaw(companyToUpdate.first_phone),
			second_phone: parseMaskedPhoneToRaw(companyToUpdate.second_phone!),
			cep: parseMaskedCEPToRaw(companyToUpdate.cep),
			founding_date: parseInputToDate(companyToUpdate.founding_date),
		};

		const updatedCompany = {
			corporate_name,
			name,
			email,
			financial_sector_email,
			hr_sector_email,
			email_domain,
			cep,
			address,
			number,
			complement,
			reference,
			district,
			city,
			uf,
			first_phone,
			second_phone,
			website,
			founding_date,
			business_type,
			main_activity,
		};

		await updateCompanyMutation.mutateAsync({
			companyId: company!.id!,
			company: updatedCompany,
		});
	}

	async function handleUpdateAvatar(logo: File) {
		const companyAvatarFormData = new FormData();
		companyAvatarFormData.append('avatar', logo);
		await updateCompanyAvatarQuery.mutateAsync({
			companyId: company!.id!,
			avatar: companyAvatarFormData,
		});
	}

	/* SHAREHOLDERS FUNCTIONS */

	// This function adds a shareholder to a company that's being created (so doesn't exist yet)
	function createShareholderToCompanyCreation(
		shareholder: ShareholderFormData
	) {
		if (shareholder.type_of_social_contract === "PF"){
			const newShareholder = {
				...shareholder,
				birth_date: parseInputToDate(shareholder.birth_date!),
			};
			if (company?.shareholders.find((s) => s.cpf === shareholder.cpf && s.cpf)) {
				toast.error('CPF do sócio já adicionado.');
				return false;
			}

			if (!cpfValidator.isValid(shareholder.cpf!)) {
				toast.error('CPF do sócio não é válido.');
				return false;
			}

			let [first_name, ...last_name_array] = shareholder.full_name!.split(' ');
			const last_name = last_name_array.join(' ');

			if (!first_name.trim() || !last_name.trim()) {
				toast.error('Insira o nome completo.');
				return false;
			}

			let shareholdersList = [
				...(company?.shareholders ? company.shareholders : []),
				{ ...newShareholder, first_name, last_name },
			];

			setCompany({ shareholders: shareholdersList } as Company);

			return true;
		}else{
			const newShareholder = {
				...shareholder,
				founding_date: parseInputToDate(shareholder.founding_date!),
			};

			if (!validateUserPJ(shareholder)) return false

			if (company?.shareholders.find((s) => s.cnpj === shareholder.cnpj && s.cnpj)) {
				toast.error('CNPJ da empresa já adicionado.');
				return false;
			}

			let shareholdersList = [
				...(company?.shareholders ? company.shareholders : []),
				{ ...newShareholder },
			];

			setCompany({ shareholders: shareholdersList } as Company);

			return true;
		}

	}

	function validateUserPJ(shareholder: Shareholder){
		if (!shareholder.legal_name?.trim()) {
			toast.error('Insira o nome legal.');
			return false;
		}

		if (!shareholder.trading_name?.trim()){
			toast.error('Insira o nome fantasia.');
			return false;
		}

		if (!shareholder.main_activity?.trim()){
			toast.error('Insira o segmento comercial.');
			return false;
		}

		return true
	}
	// This function updates a shareholder to a company that's being created (so doesn't exist yet)
	function updateShareholderToCompanyCreation(
		previousCPF: string,
		shareholder: ShareholderFormData
	) {
		if (shareholder.type_of_social_contract === "PF"){
			let [first_name, ...last_name_array] = shareholder.full_name!.split(' ');
			const last_name = last_name_array.join(' ');

			if (!first_name.trim() || !last_name.trim()) {
				toast.error('Insira o nome completo.');
				return false;
			}

			const updatedShareholder = {
				...shareholder,
				birth_date: parseInputToDate(shareholder.birth_date!),
				first_name,
				last_name,
			};

			let shareholdersList = [
				...company!.shareholders.filter((s) => s.cpf !== previousCPF),
				updatedShareholder,
			];


			if (shareholdersList.filter((s) => s.cpf === shareholder.cpf).length > 1) {
				toast.error('CPF do sócio já adicionado.');
				return false;
			}

			if (!cpfValidator.isValid(shareholder.cpf!)) {
				toast.error('CPF do sócio não é válido.');
				return false;
			}

			setCompany({ shareholders: shareholdersList } as Company);

			return true;
		} else {
			if (!validateUserPJ(shareholder)) return false

			const updatedShareholder = {
				...shareholder,
				founding_date: parseInputToDate(shareholder.founding_date!),
			};

			let shareholdersList = [
				...company!.shareholders.filter((s) => s.cnpj !== previousCPF),
				updatedShareholder,
			];

			if (shareholdersList.filter((s) => s.cnpj === shareholder.cnpj && s.cnpj).length > 1) {
				toast.error('CNPJ do sócio já adicionado.');
				return false;
			}

			setCompany({ shareholders: shareholdersList } as Company);
			return true
		}
	}
	// This function removes a shareholder to a company that's being created (so doesn't exist yet)
	function removeShareholderToCompanyCreation(type: "PF" | "PJ", cpfOrCnpj: string) {
		let shareholdersList: any[]
		if (type === "PF") shareholdersList = company!.shareholders.filter((s) => s.cpf !== cpfOrCnpj && s.cpf);
		else shareholdersList = company!.shareholders.filter((s) => s.cnpj !== cpfOrCnpj && s.cnpj);

		if (shareholdersList.length)
			setCompany({ shareholders: shareholdersList } as Company);
		else setCompany(undefined);
		return true;
	}

	async function addShareholder(newShareholder: ShareholderFormData) {
		const {
			type,
			email,
			phone_number,
			cep,
			address,
			number,
			complement,
			district,
			city,
			uf,
			type_of_social_contract
		} = {
			...newShareholder,
			phone_number: parseMaskedPhoneToRaw(newShareholder.phone_number),
			cep: parseMaskedCEPToRaw(newShareholder.cep),
		};

		if (newShareholder.type_of_social_contract === "PF"){
			const {
				cpf,
				full_name,
				mother_name,
				birth_date,
			} = {
				...newShareholder,
				cpf: parseMaskedCPFToRaw(newShareholder.cpf!),
				birth_date: parseInputToDate(newShareholder.birth_date!),
			};

			let [first_name, ...last_name_array] = full_name!.split(' ');
			const last_name = last_name_array.join(' ');

			if (!first_name.trim() || !last_name.trim()) {
				toast.error('Insira o nome completo.');
				return false;
			}

			const shareholder = {
				cpf,
				first_name,
				last_name,
				type,
				mother_name,
				birth_date,
				email,
				phone_number,
				cep,
				address,
				number,
				complement,
				district,
				city,
				uf,
				type_of_social_contract
			};

			try {
				await addShareholderQuery.mutateAsync({
					shareholder,
					companyId: company!.id!,
				});
				return true;
			} catch (err) {
				console.log(err);
				return false;
			}
		}else {
			if (!validateUserPJ(newShareholder)) return false
			const {
				cnpj,
				mother_name,
				birth_date,
				founding_date,
				legal_name,
				trading_name,
				main_activity,
				website,
			} = {
				...newShareholder,
				cnpj: parseMaskedCnpjToRaw(newShareholder.cnpj!),
				founding_date: parseInputToDate(newShareholder.founding_date!),
			};
			const shareholder = {
				cnpj,
				founding_date,
				legal_name,
				trading_name,
				main_activity,
				website,
				type,
				mother_name,
				birth_date,
				email,
				phone_number,
				cep,
				address,
				number,
				complement,
				district,
				city,
				uf,
				type_of_social_contract
			};

			try {
				await addShareholderQuery.mutateAsync({
					shareholder,
					companyId: company!.id!,
				});
				return true;
			} catch (err) {
				console.log(err);
				return false;
			}
		}

	}

	async function updateShareholder(shareholder: ShareholderFormData) {
		if (shareholder.type_of_social_contract === "PF"){
			const {
				cpf,
				full_name,
				type,
				mother_name,
				birth_date,
				email,
				phone_number,
				cep,
				address,
				number,
				complement,
				district,
				city,
				uf,
			} = {
				...shareholder,
				cpf: parseMaskedCPFToRaw(shareholder.cpf!),
				birth_date: parseInputToDate(shareholder.birth_date!),
				phone_number: parseMaskedPhoneToRaw(shareholder.phone_number),
				cep: parseMaskedCEPToRaw(shareholder.cep),
			};

			let [first_name, ...last_name_array] = full_name!.split(' ');
			const last_name = last_name_array.join(' ');

			if (!first_name.trim() || !last_name.trim()) {
				toast.error('Insira o nome completo.');
				return false;
			}

			const shareholderToUpdate = {
				cpf,
				first_name,
				last_name,
				type,
				mother_name,
				birth_date,
				email,
				phone_number,
				cep,
				address,
				number,
				complement,
				district,
				city,
				uf
			};

			try {
				await updateShareholderQuery.mutateAsync({
					shareholderId: shareholder.id!,
					companyId: company!.id!,
					shareholder: shareholderToUpdate,
				});
				return true;
			} catch (err) {
				console.log(err);
				return false;
			}
		}else {
			const {
				cnpj,
				founding_date,
				legal_name,
				trading_name,
				main_activity,
				website,
				type,
				mother_name,
				birth_date,
				email,
				phone_number,
				cep,
				address,
				number,
				complement,
				district,
				city,
				uf
			} = {
				...shareholder,
				cnpj: parseMaskedCnpjToRaw(shareholder.cnpj!),
				founding_date: parseInputToDate(shareholder.founding_date!),
				phone_number: parseMaskedPhoneToRaw(shareholder.phone_number),
				cep: parseMaskedCEPToRaw(shareholder.cep),
			};

			const shareholderToUpdate = {
				cnpj,
				founding_date,
				legal_name,
				trading_name,
				main_activity,
				website,
				type,
				mother_name,
				birth_date,
				email,
				phone_number,
				cep,
				address,
				number,
				complement,
				district,
				city,
				uf
			};

			try {
				await updateShareholderQuery.mutateAsync({
					shareholderId: shareholder.id!,
					companyId: company!.id!,
					shareholder: shareholderToUpdate,
				});
				return true;
			} catch (err) {
				console.log(err);
				return false;
			}
		}
	}

	async function deleteShareholder(id: string) {
		try {
			await deleteShareholderQuery.mutateAsync(id);
			return true;
		} catch (err) {
			console.log(err);
			return false;
		}
	}

	return (
		<CompanyFormContext.Provider
			value={{
				loading:
					deleteShareholderQuery.isLoading ||
					createCompanyMutation.isLoading ||
					updateCompanyMutation.isLoading ||
					updateCompanyAvatarQuery.isLoading ||
					addShareholderQuery.isLoading ||
					updateShareholderQuery.isLoading ||
					fetchCompanyQuery.isLoading ||
					toggleStatus.isLoading,
				company,
				submitCompany,
				updateCompany,
				handleUpdateAvatar,
				toggleStatus,
				createShareholderToCompanyCreation,
				updateShareholderToCompanyCreation,
				removeShareholderToCompanyCreation,
				addShareholder,
				updateShareholder,
				deleteShareholder,
			}}
		>
			{children}
		</CompanyFormContext.Provider>
	);
}
