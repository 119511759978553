import { FaCheckCircle, FaExclamationCircle, FaQuestionCircle, FaRegClock, } from "react-icons/fa"

export function getStatusIcon(status: string) {
  if (status==="approved")
    return <FaCheckCircle color="#1CCA0D"/>

  if (status==="reproved")
    // return   <FaTimesCircle color="#000"/>
    return <FaExclamationCircle color="#FB1313"/>

  if (status==="in_analysis")
    return <FaRegClock color="#E0E414"/>

  return <FaQuestionCircle />
}

export function parseStatusString(status: string) {
  if (status==='approved') 
    return 'Aprovado'
  if (status==='reproved')
    return 'Reprovado'
  if (status==='in_analysis')
    return 'Em análise'
  return '?????'      
}