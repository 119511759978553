import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import {
	getMessaging,
	getToken,
	Messaging,
	isSupported as isMessagingSupported,
} from 'firebase/messaging';
import { getAuth } from 'firebase/auth';
import api from './api';
import { toast } from 'react-toastify';

const firebaseConfig = {
	apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
	authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
	databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}`,
	projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
	storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
	messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
	appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
	measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
};

export const app = initializeApp(firebaseConfig);

let messaging: Messaging | null = null;
isMessagingSupported()
	.then((supported) => {
		if (supported) messaging = getMessaging(app);
		else
			toast.warn(
				'As notificações em tempo real não são suportadas no seu navegador.'
			);
	})
	.catch((err) => {
		console.log(err);
		toast.warn(
			'As notificações em tempo real não são suportadas no seu navegador.'
		);
	});

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
export function getNotificationToken(messaging: Messaging) {
	getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY })
		.then(async (currentToken) => {
			if (currentToken) {
				// Send the token to your server and update the UI if necessary
				// ...
				await api.post('/api/v1/master/notification/token', {
					registration_token: currentToken,
				});
			} else {
				// Show permission request UI
				console.log(
					'No registration token available. Request permission to generate one.'
				);
				// ...
			}
		})
		.catch((err) => {
			console.log('An error occurred while retrieving token. ', err);
			// ...
		});
}

export const db = getDatabase(app);
export const firebaseAuth = getAuth();
export { messaging };
