import styled from 'styled-components';
import { Input } from '../../components/Form/FormStyles';

export const Container = styled.div`
	background-color: var(--primary-red);
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

export const Form = styled.form`
	background-color: var(--white);
	box-shadow: 0px 13px 27px -5px rgba(50, 50, 93, 0.25),
		0px 8px 16px -8px rgba(0, 0, 0, 0.3),
		0px -6px 16px -6px rgba(0, 0, 0, 0.025);
	padding: 2rem;
	/* height: 40rem; */
	width: 40rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	border-radius: 0.4rem;
`;

export const LogoContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 2rem;
	color: var(--white);
	font-weight: bold;
	margin-bottom: 3rem;
`;

export const LogoImage = styled.img`
	width: 24rem;
`;

export const ContentForm = styled.div`
	width: 100%;
	margin-top: 4rem;
	margin-bottom: 3rem;
`;

export const FormInput = styled(Input)`
	height: 3.6rem;
	margin-bottom: 2rem;
`;

export const SubmitButton = styled.button`
	background-color: var(--primary-blue);
	color: var(--white);
	border: 0;
	border-radius: 0.4rem;
	font-weight: bold;
	font-size: 1.6rem;
	height: 4rem;
	width: 50%;
`;
