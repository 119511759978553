import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
	margin-bottom: 2rem;
`;

export const StatsCaptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	font-size: 1.2rem;
	row-gap: 0.5rem;
`;
