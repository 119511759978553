import { useState } from 'react';
import { useQuery } from 'react-query';
import { GroupCompany } from '../../@types';
import {
	getGroupsCompany,
	GroupCompanyListReturn,
} from '../../services/queries/GroupCompany';
import { EmptyContent } from '../EmptyContent';
import Loader from '../Loader';
import Modal from '../Modal';
import { Pagination } from '../Pagination';
import * as S from './styles';
import { showErrorMessage } from '../../utils/ErrorHandler';

export interface SelectGroupCompanyProps {
	selectedGroupCompany: GroupCompany | null;
	onGroupCompanySelection: (c: GroupCompany) => void;
}
export function SelectGroupCompany({
	selectedGroupCompany,
	onGroupCompanySelection,
}: SelectGroupCompanyProps) {
	const [isOpen, setIsOpen] = useState(false);
	const [groupCompaniesCurrentPage, setGroupCompaniesCurrentPage] = useState(1);

	const getGroupsComapaniesQuery = useQuery<GroupCompanyListReturn, Error>(
		['groupCompanyList', groupCompaniesCurrentPage],
		() => {
			return getGroupsCompany(groupCompaniesCurrentPage);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um erro ao buscar os grupos corporativos. '
				);
			},
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		}
	);

	function getNoContentIndicator() {
		if (
			getGroupsComapaniesQuery.isLoading ||
			getGroupsComapaniesQuery.isPreviousData
		)
			return <Loader />;

		if (!getGroupsComapaniesQuery.data?.corporationGroup.length)
			return <EmptyContent text='Nenhum grupo corporativo disponível' />;

		return null;
	}

	return (
		<>
			{selectedGroupCompany ? (
				<S.SelectedGroupCompany
					onClick={(e) => {
						e.preventDefault();
						setIsOpen(true);
					}}
				>
					<S.GroupCompanyInfo>
						<S.GroupInfoName>{selectedGroupCompany.name}</S.GroupInfoName>
					</S.GroupCompanyInfo>
				</S.SelectedGroupCompany>
			) : (
				<S.OpenGroupsCompaniesModalButton
					onClick={(e) => {
						e.preventDefault();
						setIsOpen(true);
					}}
				>
					Selecionar Grupo Corporativo
				</S.OpenGroupsCompaniesModalButton>
			)}

			<Modal
				isOpen={isOpen}
				onRequestClose={() => {
					setIsOpen(false);
				}}
				enableClose
			>
				<S.GroupCompanyContainer data-testid='group-company-container'>
					<div style={{ margin: '1rem 0 1rem 0', alignSelf: 'end' }}>
						<Pagination
							onPageChange={(page) => setGroupCompaniesCurrentPage(page)}
							currentPage={groupCompaniesCurrentPage}
							totalCount={
								getGroupsComapaniesQuery.data
									? getGroupsComapaniesQuery.data.totalCorporateGroup
									: 1
							}
						/>
					</div>
					{getGroupsComapaniesQuery.data?.corporationGroup.map(
						(groupCompany) => (
							<S.GroupCompanyOption
								onClick={() => {
									onGroupCompanySelection(groupCompany);
									setIsOpen(false);
								}}
								key={groupCompany.id!!}
							>
								<S.GroupCompanyInfo>
									<S.GroupInfoName>{groupCompany.name}</S.GroupInfoName>
								</S.GroupCompanyInfo>
							</S.GroupCompanyOption>
						)
					)}
					{getNoContentIndicator()}
				</S.GroupCompanyContainer>
			</Modal>
		</>
	);
}
