import styled from 'styled-components';
import { OptionsButton } from '../Button';

export const OpenGroupsCompaniesModalButton = styled.button`
	background-color: var(--primary-blue);
	height: 3rem;
	width: 24rem;
	color: white;
	border-radius: 10rem;
	font-weight: bold;
`;

// MODAL
export const GroupCompanyContainer = styled.ul`
	display: flex;
	flex-direction: column;
	min-width: 50rem;
	& li {
		border-bottom: 0.1rem solid var(--primary-blue);
	}
`;

export const SelectedGroupCompany = styled.button`
	${OptionsButton}
	min-width: 24rem;
	max-width: 24rem;
	padding: 0.5rem 6rem;
	border-radius: 10rem;
	display: flex;
	justify-content: center;
`;
export const GroupCompanyOption = styled.li`
	display: flex;
	list-style: none;
	align-items: center;
	padding: 1rem;
	font-size: 1.4rem;
	cursor: pointer;
	transition: background-color 0.4s;
	border-radius: 10px;
	& img {
		width: 5rem;
		height: 5rem;
		border-radius: 50%;
		margin-right: 2rem;
		border: 1px solid #7d7d7d;
		object-fit: cover;
	}

	&:hover {
		background-color: #c5c5c5;
	}
`;

export const GroupCompanyInfo = styled.div`
	display: flex;
	flex-direction: column;
`;

export const GroupInfoName = styled.span`
	max-width: 21rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
