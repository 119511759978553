import {
	FaCheckCircle,
	FaExclamationCircle,
	FaQuestionCircle,
	FaRegClock,
} from 'react-icons/fa';

export function parseUnderageDocumentStatus(status: string) {
	if (status === 'disapproved') return 'Reprovado';
	if (status === 'approved') return 'Aprovado';
	if (status === 'requested') return 'Pendente';

	return '????';
}

export function getStatusIcon(status: string) {
	if (status === 'approved') return <FaCheckCircle color='#1CCA0D' />;

	if (status === 'disapproved') return <FaExclamationCircle color='#FB1313' />;

	if (status === 'requested') return <FaRegClock color='#E0E414' />;

	return <FaQuestionCircle />;
}
