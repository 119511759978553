import { create } from 'zustand';

type Store = {
	matchLocation: string;
	setNewMatchLocationAndCallback: (l: string, c: () => void) => void;
	onHistoryNonMatchCallback: () => void;
	onLocationChange: (l: string) => void;
};

const useHistoryNonMatchCallbackStore = create<Store>()((set, get) => ({
	matchLocation: '',
	onHistoryNonMatchCallback: () => {}, // passed callback for each
	setNewMatchLocationAndCallback: (l, c) => {
		// matchLocation can change before onLocationChange is called from App.tsx (top component),
		// so call onHistoryNonMatchCallback here as well
		if (!l.includes(get().matchLocation)) get().onHistoryNonMatchCallback();

		set({ matchLocation: l, onHistoryNonMatchCallback: c });
	},
	onLocationChange: (location) => {
		if (!location.includes(get().matchLocation)) {
			get().onHistoryNonMatchCallback();
		}
	},
}));

export { useHistoryNonMatchCallbackStore };
