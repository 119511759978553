import { toast } from 'react-toastify';
import * as S from '../manageStockStyles';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useManageCardStockStore } from '../../../../../stores/useManageCardStock';

export interface ValuesQuantityUpdate {
	quantityAvailable: number;
	quantityEmbossed: number;
	quantityRaw: number;
	quantityLost: number;
}

type FormData = {
	quantity_raw: string;
	quantity_embossed: string;
	quantity_lost: string;
};

export type Props = {
	cardStockStats: {
		quantityAvailable: number;
		quantityEmbossed: number;
		quantityRaw: number;
		quantityLost: number;
	};
};

export function ManageCardStock({ cardStockStats }: Props) {
	/* --- SCENARIOS ---
		RAW: plásticos
		AVAILABLE: personalizados
		EMBOSSED: prontos não utilizados
		LOST: perdidos

		1 - Adding and removing from RAW also adds and removes from AVAILABLE
		2 - Adding to EMBOSSED or LOST removes from AVAILABLE
	*/
	const { state, actions } = useManageCardStockStore();

	const { register, resetField, getValues } = useForm<FormData>({
		defaultValues: {
			quantity_raw: '',
			quantity_embossed: '',
			quantity_lost: '',
		},
	});

	useEffect(() => {
		actions.setState({ ...cardStockStats, editedFlag: false });
	}, [cardStockStats]); // eslint-disable-line react-hooks/exhaustive-deps

	function validateInput(inputValue: string) {
		if (inputValue === '' || Number(inputValue) <= 0) {
			toast.error('Insira um valor válido positivo.');
			return false;
		}
		return true;
	}

	const handleAddRawClick = () => {
		const inputValue = getValues('quantity_raw');

		if (!validateInput(inputValue)) return;

		const quantityRawValue = parseInt(inputValue);

		actions.updateQuantityRaw(quantityRawValue);
		resetField('quantity_raw');
	};

	const handleSubRawClick = () => {
		const inputValue = getValues('quantity_raw');
		const quantityRawValue = parseInt(inputValue);

		if (!validateInput(inputValue)) return;
		if (state.quantityRaw - quantityRawValue <= 0) {
			toast.error(
				'O valor subtraído não pode exceder a quantidade de plásticos.'
			);
			return;
		}
		if (state.quantityAvailable - quantityRawValue <= 0) {
			toast.error(
				'O valor subtraído não pode exceder a quantidade de personalizados.'
			);
			return;
		}

		actions.updateQuantityRaw(-quantityRawValue);
		resetField('quantity_raw');
	};

	const handleAddEmbossedClick = () => {
		const inputValue = getValues('quantity_embossed');
		const quantityEmbossedValue = parseInt(inputValue);

		if (!validateInput(inputValue)) return;
		if (state.quantityAvailable - quantityEmbossedValue < 0) {
			toast.error(
				'O valor adicionado não pode exceder a quantidade de cartões personalizados.'
			);
			return;
		}

		actions.updateQuantityEmbossed(quantityEmbossedValue);
		resetField('quantity_embossed');
	};

	const handleAddLostClick = () => {
		const inputValue = getValues('quantity_lost');
		const quantityLostValue = parseInt(inputValue);

		if (!validateInput(inputValue)) return;

		if (state.quantityAvailable - quantityLostValue < 0) {
			toast.error(
				'O valor adicionado não pode exceder a quantidade de cartões personalizados.'
			);
			return;
		}

		actions.updateQuantityLost(quantityLostValue);
		resetField('quantity_lost');
	};

	return (
		<>
			<S.FieldGroup>
				<S.Field>
					<S.Label htmlFor='quantity_raw'>
						Adicionar quantidade de plástico
					</S.Label>
					<S.Label>Valor total: {state.quantityRaw}</S.Label>
					<S.DivInput>
						<S.QuantityInput
							id='quantity_raw'
							type='number'
							required
							{...register('quantity_raw')}
						/>
						<div>
							<S.ButtonRaw
								onClick={handleAddRawClick}
								data-testid='addQuantityRaw'
							>
								Adicionar
							</S.ButtonRaw>
							<S.ButtonRaw onClick={handleSubRawClick}>Subtrair</S.ButtonRaw>
						</div>
					</S.DivInput>
				</S.Field>

				<S.Field>
					<S.Label>Quantidade de cartões para personalização</S.Label>
					<S.Label>Valor total: {state.quantityAvailable}</S.Label>
				</S.Field>

				<S.Field>
					<S.Label htmlFor='quantity_embossed'>
						Adicionar cartões prontos não utilizados
					</S.Label>
					<S.Label>Valor total: {state.quantityEmbossed}</S.Label>
					<S.DivInput>
						<S.QuantityInput
							id='quantity_embossed'
							type='number'
							required
							{...register('quantity_embossed')}
						/>
						<div>
							<S.ButtonRaw
								onClick={handleAddEmbossedClick}
								data-testid='addQuantityEmbossed'
							>
								Adicionar
							</S.ButtonRaw>
						</div>
					</S.DivInput>
				</S.Field>

				<S.Field>
					<S.Label htmlFor='quantity_lost'>Adicionar cartões perdidos</S.Label>
					<S.Label>Valor total: {state.quantityLost}</S.Label>
					<S.DivInput>
						<S.QuantityInput
							id='quantity_lost'
							type='number'
							required
							{...register('quantity_lost')}
						/>
						<div>
							<S.ButtonRaw
								onClick={handleAddLostClick}
								data-testid='addQuantityLost'
							>
								Adicionar
							</S.ButtonRaw>
						</div>
					</S.DivInput>
				</S.Field>
			</S.FieldGroup>
		</>
	);
}
