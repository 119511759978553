import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
`;

export const Avatar = styled.img`
	min-width: 4rem;
	max-width: 4rem;
	min-height: 4rem;
	max-height: 4rem;
	border-radius: 50%;
	border: 0.2rem solid var(--terciary);
	margin-right: 1rem;
	object-fit: cover;
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Name = styled.span`
	font-size: 1.6rem;
`;

export const Office = styled.span`
	font-size: 1.4rem;
	max-width: 19rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--mid-gray);
`;
