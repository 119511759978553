import { useQuery } from 'react-query';
import PageTitle from '../../../../components/PageTitle';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import * as S from './styles';
import { getFilteredDuplicateCardsStats } from '../../../../services/queries/CardsBatchs';
import { useState } from 'react';
import * as TableStyle from '../../../../components/Table/TableStyles';
import { Filter, FilterParams } from '../../../../components/Filter';
import Loader from '../../../../components/Loader';
import { getCurrentMonthYYYYMMValue } from '../utils/getCurrentMonthYYYYMMValue';
import { cnpjMask } from '../../../../utils/masks';
import { PeriodsDetailsModal } from './PeriodsDetailsModal';
import { parseReferenceDate } from '../utils/parseReferenceDate';
import { EmptyContent } from '../../../../components/EmptyContent';

export function DuplicateCardsStats() {
	const currentMonthValue = getCurrentMonthYYYYMMValue();

	const [filterParams, setFilterParams] = useState<FilterParams[]>([
		{
			name: 'Intervalo de datas',
			filter: 'month_interval',
			value: currentMonthValue,
			second_value: currentMonthValue,
			type: 'month_interval',
			selected: true,
			alwaysFilled: true,
		},
		{
			name: 'Empresa',
			filter: 'company_id',
			value: '',
			type: 'company_id',
			selected: false,
		},
	]);

	const duplicateCardsStatsQuery = useQuery(
		['duplicateCardsStats', filterParams],
		() => getFilteredDuplicateCardsStats(filterParams),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os quantitativos de segundas vias de cartão. '
				);
			},
		}
	);

	function parseFilterMonthIntervalToString(monthIntervalValue: string) {
		const month = Number(
			monthIntervalValue.substring(monthIntervalValue.length - 2)
		);
		const year = monthIntervalValue.substring(0, 4);

		return parseReferenceDate(month, year, ' de ');
	}

	function getFilterCaption() {
		const filterStartDate = filterParams[0].value;
		const filterEndDate = filterParams[0].second_value!;

		let dateCaption = parseFilterMonthIntervalToString(filterStartDate);
		if (filterStartDate !== filterEndDate) {
			dateCaption += ` - ${parseFilterMonthIntervalToString(filterEndDate)}`;
		}

		const companyCaption =
			filterParams[1].value && duplicateCardsStatsQuery.data?.length
				? `, ${duplicateCardsStatsQuery.data[0].items[0].company_name}`
				: '';

		return `Exibindo resultados para: ${dateCaption + companyCaption}`;
	}

	if (!duplicateCardsStatsQuery.data)
		return (
			<S.Container>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle title='Quantitativos de segundas vias de cartão' />
						<Filter
							filterParams={filterParams}
							onFiltersChanged={(updatedFilters) => {
								setFilterParams(updatedFilters);
							}}
						/>
					</TableStyle.TitleWrapper>
				</TableStyle.TableHeaderContainer>

				<Loader />
			</S.Container>
		);

	return (
		<S.Container>
			<TableStyle.TableHeaderContainer>
				<TableStyle.TitleWrapper>
					<PageTitle title='Quantitativos de segundas vias de cartão' />
					<Filter
						filterParams={filterParams}
						onFiltersChanged={(updatedFilters) => {
							setFilterParams(updatedFilters);
						}}
					/>
				</TableStyle.TitleWrapper>
			</TableStyle.TableHeaderContainer>

			<S.FilterCaption>{getFilterCaption()}</S.FilterCaption>

			{duplicateCardsStatsQuery.data.map((referenceStat) => (
				<S.TablesContainer
					key={referenceStat.reference_month + referenceStat.reference_year}
				>
					<S.ReferenceDate>
						{parseReferenceDate(
							Number(referenceStat.reference_month),
							referenceStat.reference_year
						).toUpperCase()}
					</S.ReferenceDate>
					<TableStyle.Table mBottom='0'>
						<TableStyle.TableHeader>
							<TableStyle.TableRow>
								<TableStyle.TableHeaderCell>EMPRESA</TableStyle.TableHeaderCell>
								<TableStyle.TableHeaderCell>PERÍODO</TableStyle.TableHeaderCell>
								<TableStyle.TableHeaderCell>
									QUANTIDADE
								</TableStyle.TableHeaderCell>
								<TableStyle.TableHeaderCell>
									DETALHAR
								</TableStyle.TableHeaderCell>
							</TableStyle.TableRow>
						</TableStyle.TableHeader>

						<TableStyle.TableBody>
							{referenceStat.items.map((item) => (
								<TableStyle.TableRow key={item.id}>
									<TableStyle.TableData>
										<span>
											{item.company_name + ' - ' + cnpjMask(item.company_cnpj)}
										</span>
									</TableStyle.TableData>
									<TableStyle.TableData>
										<span>
											{parseReferenceDate(
												Number(item.reference_month),
												item.reference_year
											)}
										</span>
									</TableStyle.TableData>
									<TableStyle.TableData style={{ textAlign: 'center' }}>
										<span>{item.quantity}</span>
									</TableStyle.TableData>
									<TableStyle.TableData>
										<PeriodsDetailsModal periodsIds={[item.id]} smallBtn />
									</TableStyle.TableData>
								</TableStyle.TableRow>
							))}
						</TableStyle.TableBody>
					</TableStyle.Table>
					<S.Total>TOTAL: {referenceStat.total}</S.Total>
				</S.TablesContainer>
			))}

			{duplicateCardsStatsQuery.data.length === 0 && (
				<EmptyContent text='Não há ítens a serem exibidos.' />
			)}

			{duplicateCardsStatsQuery.data.length > 0 && (
				<PeriodsDetailsModal
					periodsIds={duplicateCardsStatsQuery.data.flatMap((d) =>
						d.items.map((i) => i.id)
					)}
				/>
			)}
		</S.Container>
	);
}
