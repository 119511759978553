import { NotificationsPreferences } from '../../@types';
import api from '../api';

export async function getNotificationsPreferences(): Promise<NotificationsPreferences> {
	const { data } = await api.get(`/api/v1/master/notifications/preferences`);

	return data;
}

export async function updateNotificationsPreferences(
	preferences: NotificationsPreferences
) {
	await api.put('/api/v1/master/notifications/preferences', preferences);
}
