import { UnderageDocument } from '../../@types';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import { SortColumn } from '../../hooks/useSortColumnHook';
import api from '../api';

interface UnderageDocumentsListReturn {
	documentsRequested: UnderageDocument[];
	documentsNonRequested: UnderageDocument[];
	totalDocumentsRequested: number;
	totalDocumentsNonRequested: number;
}

export async function getRequestedUnderageDocuments(
	pageRequested: number,
	filtersParamsRequested: FilterParams[],
	sortColumn: SortColumn | null
) {
	let shouldFilterRequested = !!filtersParamsRequested.find((f) => !!f.value);

	let params: any = {
		pageRequested,
		pageNonRequested: 1,
	};

	if (sortColumn) {
		params = {
			...params,
			sort_by_requested: sortColumn?.name,
			sort_order_requested: sortColumn?.order,
		};
	}

	if (shouldFilterRequested)
		params = {
			...params,
			requestedFilters: {
				...parseFilterParamsToParams(filtersParamsRequested),
			},
		};

	const { data } = await api.get<UnderageDocumentsListReturn>(
		`/api/v1/master/documents/all/q`,
		{ params }
	);
	return data;
}
export async function getReviewedUnderageDocuments(
	pageNonRequested: number,
	filtersParamsReviwed: FilterParams[],
	sortColumn: SortColumn | null
) {
	let shouldFilterReviwed = !!filtersParamsReviwed.find((f) => !!f.value);

	let params: any = {
		pageRequested: 1,
		pageNonRequested,
	};

	if (sortColumn) {
		params = {
			...params,
			sort_by_nonRequested: sortColumn?.name,
			sort_order_nonRequested: sortColumn?.order,
		};
	}

	if (shouldFilterReviwed)
		params = {
			...params,
			nonRequestedFilters: {
				...parseFilterParamsToParams(filtersParamsReviwed),
			},
		};

	const { data } = await api.get<UnderageDocumentsListReturn>(
		`/api/v1/master/documents/all/q`,
		{ params }
	);
	return data;
}

export async function getUnderageDocument(
	id: string
): Promise<UnderageDocument> {
	const { data } = await api.get(`/api/v1/master/documents/${id}`);

	return data;
}

export async function reviewUnderageDocument(
	id: string,
	review: 'disapproved' | 'approved'
): Promise<void> {
	return await api.patch(`/api/v1/master/documents/${id}`, {
		status: review,
	});
}
