import * as S from './styles';
import * as TableStyle from '../../../components/Table/TableStyles';
import PageTitle from '../../../components/PageTitle';
import { MdNotes } from 'react-icons/md';
import { useQuery } from 'react-query';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { listPreRegister } from '../../../services/queries/PreRegister';
import Loader from '../../../components/Loader';
import { cnpjMask } from '../../../utils/masks';
import { preRegisterStatusToText } from '../../../utils/preRegister';
import { parseInputToDate } from '../../../utils/parseDate';

export function PreRegisterCompanies() {
	const getPreRegister = useQuery(['pre-register'], listPreRegister, {
		onError: (err) => {
			showErrorMessage(
				err as Error,
				'Não foi possível buscar os pre registros. '
			);
		},
	});

	if (getPreRegister.isLoading || !getPreRegister.data) {
		return (
			<S.Container>
				<PageTitle title='Pré-Cadastro de empresas' />
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			<TableStyle.TableHeaderContainer>
				<TableStyle.TitleWrapper>
					<PageTitle title='Pré-Cadastro de empresas' totalRecords={0} />
				</TableStyle.TitleWrapper>
			</TableStyle.TableHeaderContainer>

			<TableStyle.Table>
				<TableStyle.TableHeader>
					<TableStyle.TableRow>
						<TableStyle.TableHeaderCell>
							{/* <SortColumnButton
                //   order={
                //     currentSortColumn?.name === 'name'
                //       ? currentSortColumn.order
                //       : null
                //   }
                //   onToggleSort={() => toggleSort('name')}
                /> */}
							Razão Social
						</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>
							{/* <SortColumnButton
                //   order={
                //     currentSortColumn?.name === 'name'
                //       ? currentSortColumn.order
                //       : null
                //   }
                //   onToggleSort={() => toggleSort('name')}
                /> */}
							CNPJ
						</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>
							STATUS
						</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell style={{ textAlign: "center"}}>
							DATA DE SOLICITAÇÃO
						</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell style={{ textAlign: "center"}}>OPÇÕES</TableStyle.TableHeaderCell>
					</TableStyle.TableRow>
				</TableStyle.TableHeader>

				<TableStyle.TableBody>
					{getPreRegister.data.map((data, index) => (
						<TableStyle.TableRow key={index}>
							<TableStyle.TableData style={{ maxWidth: '24rem' }}>
								{data.legal_name}
							</TableStyle.TableData>
							<TableStyle.TableData style={{ maxWidth: '24rem' }}>
								{cnpjMask(data.document)}
							</TableStyle.TableData>
							<TableStyle.TableData style={{ maxWidth: '24rem', color: preRegisterStatusToText(data.registration_status).color }}>
								{preRegisterStatusToText(data.registration_status).message}
							</TableStyle.TableData>
							<TableStyle.TableData style={{ maxWidth: '24rem', textAlign: "center" }}>
								{new Date(data.created_at).toLocaleDateString()}
							</TableStyle.TableData>
							<TableStyle.TableData>
								<TableStyle.OptionsContainer style={{width: "100%"}}>
									<TableStyle.OptionLink
										data-rh='Detalhar empresa'
										to={`/pre-register/${data.id}`}
									>
										<MdNotes />
									</TableStyle.OptionLink>
								</TableStyle.OptionsContainer>
							</TableStyle.TableData>
						</TableStyle.TableRow>
					))}
				</TableStyle.TableBody>
			</TableStyle.Table>
		</S.Container>
	);
}
