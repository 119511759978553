import styled from 'styled-components';
import { OptionsButton } from '../../../components/Button';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
`;

export const PreferencesSectionTitle = styled.h2`
	font-size: 1.8rem;
	font-weight: 400;
	margin-bottom: 1.5rem;
`;

export const PreferencesForm = styled.form`
	flex: 1;
	display: flex;
	flex-direction: column;
`;
export const PreferencesSectionContainer = styled.div`
	margin-top: 2rem;
	width: 100%;

	&:first-child {
		margin-bottom: 3rem;
	}
`;

export const ToggleButtonsContainer = styled.div`
	margin-right: 3rem;
	margin-left: 3rem;
`;

export const FieldContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	font-size: 1.6rem;
	border-radius: 50px;
	margin: 0.5rem 0rem;

	padding: 0.3rem;
	justify-content: space-between;
`;
export const FormButton = styled.button`
	${OptionsButton}
	max-width: 10rem;
	padding: 0.8rem 3rem;
	margin-top: 2.5rem;
`;
