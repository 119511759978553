import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 4rem 7rem 2rem 7rem;
`;

export const HeaderContainer = styled.div`
	display: flex;
	max-width: 90rem;
	width: 100%;
	justify-content: space-between;
	margin-right: auto;
`;

export const StatusContainer = styled.div`
	display: flex;
	align-items: center;
	font-size: 1.6rem;
	justify-content: space-between;
	> svg {
		width: 2rem;
		height: 2rem;
		margin-right: 1rem;
	}
`;

export const KycIndicationContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 3rem;
	> svg {
		width: 2rem;
		height: 2rem;

		&:hover {
			filter: brightness(0.4);
		}
	}
`;

export const HeaderModal = styled.div`
	display: flex;
	align-items: center;
`;

export const BodyModal = styled.div`
	display: flex;
	height: 10rem;
	flex-direction: column;
	justify-content: center;
	padding-top: 1rem;
	padding-bottom: 4rem;
	padding-left: 2.4rem
	padding-right: 2.4remg
	gap: 4rem;
`;

export const DateContainer = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	margin-top: 1rem;
`;

export const DateTitle = styled.span`
	font-weight: bold;
	font-size: 1.6rem;
	margin-bottom: 1rem;
`;

export const DateValue = styled.span`
	font-weight: normal;
	font-size: 1.6rem;
	margin-bottom: 1rem;
`;
