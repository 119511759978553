import * as FormStyle from '../../../../components/Form/FormStyles';
import { useState } from 'react';
import Modal from '../../../../components/Modal';
import styled from 'styled-components';
import PageTitle from '../../../../components/PageTitle';
import { ObrigatoryFieldsIndication, ObrigatoryIndicator } from '../../../../components/ObrigatoryFieldsIndicator';
import { useForm } from 'react-hook-form';
import { approveRegister, ApproveRegister } from '../../../../services/queries/PreRegister';
import { useNavigate, useParams } from 'react-router-dom';
import { parseInputToDate } from '../../../../utils/parseDate';
import { useMutation } from 'react-query';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { toast } from 'react-toastify';

const defaultValues : ApproveRegister = {
	approved: true,
	founding_date: "",
	main_activity: "",
	trading_name: ""
}

export default function ApproveModal({step} : { step: string}) {
	const { id } = useParams();
	const [showModal, setShowModal] = useState(false);
	const { register, handleSubmit, reset } = useForm<ApproveRegister>({
		defaultValues
	});

	const navigate = useNavigate();

	const handleClose = () => {
		setShowModal(false)
		reset(defaultValues)
	}

	const aproveRegisterMutation =
		useMutation(
			['aprove-register', id!],
			(data: ApproveRegister) => approveRegister(id!, data),
			{
				onSuccess: () => {
					navigate(-1)
					toast.success("Pré-registro aprovado com sucesso")
				},
				onError: (err) => {
					showErrorMessage(
						err as Error,
						'Não foi possível aprovar este pré-registro. ',
					);
				},
			},
		);


	const approvePreregister = (data: ApproveRegister) => {
		if (!data.founding_date || !data.main_activity || !data.trading_name) {
			toast.error("Preencha todos os campos obrigatórios")
			return
		}
		data.founding_date = parseInputToDate(data.founding_date!);
		aproveRegisterMutation.mutate(data);
	};


	return (
		<>
			{
				step === 'step-one'  && (
					<FormStyle.FormButton
						type="button"
						onClick={() => {
							aproveRegisterMutation.mutate({approved: true});
						}}
					>
						Aprovar
					</FormStyle.FormButton>
				)
			}
			{
				step === 'step-two'  && (
					<FormStyle.FormButton
						type="button"
						onClick={() => {
							setShowModal(true);
						}}
					>
						Aprovar
					</FormStyle.FormButton>
				)
			}


			<Modal
				isOpen={showModal}
				onRequestClose={handleClose}
				enableClose
			>
				<Container>
					<PageTitle title="Informações para aprovação" />

					<div
						style={{
							width: '100%',
							height: '0.1rem',
							background: 'var(--light-gray)',
							margin: '1rem 0 1.5rem 0',
						}}
					/>

					<FormStyle.FieldSet>

						<FormStyle.FieldGroup>
							<FormStyle.Field>
								<FormStyle.Label htmlFor="trading_name">
									Nome fantasia <ObrigatoryIndicator />
								</FormStyle.Label>
								<FormStyle.Input
									maxLength={70}
									type="text"
									{...register('trading_name')}
									required
								/>
							</FormStyle.Field>

						</FormStyle.FieldGroup>

						<FormStyle.FieldGroup>
							<FormStyle.Field>
								<FormStyle.Label htmlFor="founding_date">
									Data de Fundação <ObrigatoryIndicator />
								</FormStyle.Label>
								<FormStyle.Input
									type="date"
									{...register('founding_date')}
									max={new Date().toISOString().split('T')[0]}
									id="founding_date"
									data-testid="foundingInput_test_id"
									required
								/>
							</FormStyle.Field>
							<FormStyle.Field>
								<FormStyle.Label htmlFor="main_activity">
									Número do CNAE <ObrigatoryIndicator />
								</FormStyle.Label>
								<FormStyle.Input
									maxLength={50}
									type="text"
									{...register('main_activity')}
									id="main_activity"
									data-testid="activityInput_test_id"
									required
								/>
							</FormStyle.Field>
						</FormStyle.FieldGroup>
					</FormStyle.FieldSet>
					<ObrigatoryFieldsIndication />
					<FormStyle.FormButton
						type="button"
						style={{marginTop: "1rem"}}
						onClick={handleSubmit(approvePreregister)}
					>
						Aprovar
					</FormStyle.FormButton>
				</Container>
			</Modal>
		</>
	);
}


const Container = styled.div`
	padding: 2rem;
	width: 50rem;
	flex-direction: column;
	display: flex;
`