import styled from 'styled-components';
import {
	DataStyles,
	HeaderCellStyles,
} from '../../../../components/Table/TableStyles';
import { OptionsButton } from '../../../../components/Button';

export const ContainerBody = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
	margin-bottom: 2rem;
`;

export const HeaderContainer = styled.div`
	display: flex;
	align-items: center;
	column-gap: 6rem;
`;

export const TableHeaderCell = styled.th`
	${HeaderCellStyles}
	white-space: nowrap;
	text-align: center;
	background-color: #393939;
	color: white;
`;

export const TableData = styled.td`
	${DataStyles}
	text-align: center;
	vertical-align: center;
	position: relative;
`;

export const ArrowContainer = styled.div`
	display: flex;
	width: 1.5rem;
	height: 1.5rem;
	position: absolute;
	top: 50%;
	right: 1rem;
	transform: translateY(-50%);
`;

export const LabelHeader = styled.label`
	font-size: 1rem;
	font-weight: 500;
`;

export const OptionsContainer = styled.div`
	display: flex;
	/* align-items: center; */
	width: fit-content;
	column-gap: 1rem;
	/* margin: 0 auto; */
	/* justify-content: space-between; */
`;

export const Divider = styled.div`
	width: 100%;
	height: 0.1rem;
	background: var(--light-gray);
	margin: 1rem 0 1rem 0;
`;

export const SubmitContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const ResetBtn = styled.button`
	height: min-content;
	width: fit-content;
	border-radius: 0.4rem;
	border: 0.2rem solid var(--primary-blue);
	padding: 1.2rem 2rem;
	font-weight: bold;
	background-color: var(--primary-white);
	color: var(--blue);
	width: 16rem;
	margin-top: 2rem;
	margin-bottom: 2rem;
	margin-right: 2rem;
`;

export const MainButton = styled.button`
	${OptionsButton}
	width: 16rem;
	margin-top: 2rem;
	margin-bottom: 2rem;
	margin-right: 2rem;
`;
