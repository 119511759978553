import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';

export const MainButton = styled.button`
	${OptionsButton}
	width: fit-content;
`;

export const ModalContainer = styled.div`
	display: flex;

	flex-direction: column;
	min-width: 60vw;
	padding: 0 4rem;
`;

export const ModalHeader = styled.h2`
	width: 100%;
	text-align: center;
	font-weight: 500;
	margin-bottom: 2rem;
	font-size: 2rem;
`;

export const SegmentationOptionsList = styled.div`
	display: flex;
	flex-direction: column;
`;

interface SegmentationOptionInterface {
	selected: boolean;
}
export const SegmentationOption = styled.div<SegmentationOptionInterface>`
	display: flex;
	flex-direction: column;
	font-size: 1.4rem;
	padding: 1.2rem 0.5rem;
	background-color: ${({ selected }) =>
		selected ? '#E5E5E5' : 'var(--white)'};
	border-bottom: 0.1rem solid var(--light-gray);
	cursor: pointer;
	transition: filter 0.2s;

	&:hover {
		filter: brightness(0.9);
	}

	${({ selected }) => (selected ? '&:hover { filter: none; }' : '')}

	& > span {
		color: var(--dark-gray);
	}
`;

export const SegmentationHeader = styled.div`
	margin-bottom: 0.25rem;
	color: var(--dark-gray);
	display: flex;
	column-gap: 2rem;
	font-weight: 500;
	font-size: 1.8rem;
	align-items: center;
`;

export const Submit = styled.button`
	${OptionsButton}
	margin-top: 4rem;
	margin-bottom: 1rem;
	padding: 1rem 10rem;
	width: fit-content;
	align-self: center;
`;

export const CollaboratorsListButton = styled.span`
	background: none;
	color: var(--primary-blue);
	font-size: 1.4rem;
`;

export const CollaboratorsListContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 2rem;
	min-width: 30rem;
	padding: 2rem 0;
`;

export const CollaboratorsList = styled.ul`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
`;

export const SelectedCollaborator = styled.li`
	display: flex;
`;

export const DeleteButton = styled.button`
	background: none;
	padding: 1rem;
	margin-left: auto;
	& > svg {
		width: 1.6rem;
		height: 1.6rem;
	}
`;

export const AreaLabel = styled.span`
	font-size: 1.7rem;
	font-weight: 500;
`;

export const TogglesContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;

	margin: 2rem 0;
`;

export const ToggleField = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
`;

export const ToggleLabel = styled.label`
	font-weight: 500;
	font-size: 1.3rem;
`;
