import { ComponentType } from 'react';
import { IconBaseProps } from 'react-icons';

import * as S from './styles';

type Props = {
	icon: ComponentType<IconBaseProps>;
	title: string;
	path: string;
	currentPage?: boolean;
	product?: 'control' | 'multiflex';
};

export function SidebarLink({
	icon: Icon,
	title,
	path,
	currentPage = false,
	product,
}: Props) {
	return (
		<S.Container to={path} $currentPage={currentPage}>
			{Icon && <Icon />}
			<S.Title>
				{title}
				{product && (
					<S.ProductLabel
						style={{
							color:
								product === 'control'
									? 'var(--primary-blue)'
									: 'var(--primary-red)',
						}}
					>
						{product === 'control' ? 'Control' : 'Benefícios'}
					</S.ProductLabel>
				)}
			</S.Title>
		</S.Container>
	);
}
