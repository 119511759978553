import { Notification } from '../../contexts/NotificationsContext';
import { GenericItem } from '../../pages/Main/ReportUsers/SegmentationModal/components/SelectedItemsList';
import api from '../api';

export interface NotificationsFetchData {
	notificationsMaster: Notification[];
	totalNotifications: number;
	totalNotificationsUnread: number;
}

export interface ReportUsersBody {
	title: string;
	message: string;
	segmentation: string;
	recipients: GenericItem[];
	operators?: boolean;
	collaborators?: boolean;
}

export async function getNotifications(
	pageNumber?: number
): Promise<NotificationsFetchData> {
	const { data } = await api.get<NotificationsFetchData>(
		`/api/v1/master/notifications/q?page=${pageNumber}`
	);
	const { totalNotifications, notificationsMaster, totalNotificationsUnread } =
		data;
	return { totalNotifications, notificationsMaster, totalNotificationsUnread };
}

export async function toggleNotificationStatus(
	notificationId: string,
	status: boolean
): Promise<void> {
	return await api.patch(`/api/v1/notifications/${notificationId}/status`, {
		status,
	});
}

export async function readAllNotifications(): Promise<void> {
	return await api.put(`/api/v1/notifications/all/status`, { status: true });
}

export async function reportUsers({
	title,
	message,
	segmentation,
	recipients,
	operators,
	collaborators,
}: ReportUsersBody): Promise<void> {
	return await api.post(`/api/v1/master/report-users`, {
		title,
		message,
		segmentation,
		recipients,
		operators,
		collaborators,
	});
}
