import { useState } from 'react';
import PreventTransitionPrompt from '../../../components/PreventTransitionPrompt';
import * as S from './styles';
import { useForm } from 'react-hook-form';
import PageTitle from '../../../components/PageTitle';
import Loader from '../../../components/Loader';
import * as FormStyles from '../../../components/Form/FormStyles';
import { SegmentationModal } from './SegmentationModal';
import { FaCheckCircle } from 'react-icons/fa';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import {
	ReportUsersBody,
	reportUsers,
} from '../../../services/queries/Notifications';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { GenericItem } from './SegmentationModal/components/SelectedItemsList';

export type SegmentationOptions = 'all_users' | 'group' | 'company' | 'custom';
type FormData = {
	title: string;
	message: string;
};

const segmentationOptionString = {
	all_users: {
		title: 'Todos os usuários',
		description: 'Todos os usuários do sistema serão notificados.',
	},
	group: {
		title: 'Grupo corporativo',
		description:
			'Todos os usuários das empresas dos grupos selecionados serão notificados.',
	},
	company: {
		title: `Empresa`,
		description:
			'Todos os usuários das empresas selecionadas serão notificados.',
	},
	custom: {
		title: 'Customizado',
		description:
			'Apenas o grupo de usuários manualmente selecionados serão notificados.',
	},
};

export function ReportUsers() {
	const [itemsToReport, setItemsToReport] = useState<GenericItem[]>([]);
	const [reportOperators, setReportOperators] = useState(true);
	const [reportCollaborators, setReportCollaborators] = useState(true);

	const [segmentationOption, setSegmentationOption] =
		useState<SegmentationOptions>('all_users');

	const { register, handleSubmit, formState, reset, watch } = useForm<FormData>(
		{
			defaultValues: {
				title: '',
				message: '',
			},
		}
	);

	const { isDirty } = formState;
	const [allowNavigation, setAllowNavigation] = useState(!isDirty);

	const reportUsersQuery = useMutation(
		(body: ReportUsersBody) => {
			return reportUsers(body);
		},
		{
			onSuccess: () => {
				setAllowNavigation(true);
				toast.info(
					'Operação concluída. Em breve todos os usuários designados serão notificados.'
				);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar concluir a operação. '
				);
			},
		}
	);

	function handleSubmitForm(data: FormData) {
		reportUsersQuery.mutate({
			title: data.title.trim(),
			message: data.message.trim(),
			segmentation: segmentationOption,
			recipients: itemsToReport,
			operators: reportOperators,
			collaborators: reportCollaborators,
		});

		reset();
	}

	function handleSegmentationOptionSelection(
		option: SegmentationOptions,
		selectedItems: GenericItem[],
		reportOperators: boolean,
		reportCollaborators: boolean
	) {
		setSegmentationOption(option);
		if (option !== 'all_users') {
			setItemsToReport(selectedItems);
		}

		setReportOperators(reportOperators);
		setReportCollaborators(reportCollaborators);
	}

	return (
		<S.Container>
			<PreventTransitionPrompt when={!allowNavigation} />

			<PageTitle title='Notificar usuários' />

			<S.FormContainer onSubmit={handleSubmit(handleSubmitForm)}>
				<S.Field>
					<FormStyles.Label htmlFor='title'>Título</FormStyles.Label>
					<FormStyles.Input
						id='title'
						{...register('title')}
						maxLength={50}
						required
					/>
					<span>{watch('title').length} / 50</span>
				</S.Field>

				<S.Field>
					<S.FieldHeader>
						<FormStyles.Label>Corpo da mensagem</FormStyles.Label>
					</S.FieldHeader>
					<FormStyles.InputTextArea
						id='message'
						{...register('message')}
						maxLength={150}
						className='bodyInputArea'
						required
					/>
					<span>{watch('message').length} / 150</span>
				</S.Field>

				<S.Field>
					<SegmentationModal
						onChangeSegmentation={handleSegmentationOptionSelection}
					/>

					<S.SegmentationOptionContainer>
						<FaCheckCircle color='#1CCA0D' />

						<>
							<S.SegmentationOptionTitle>
								{segmentationOptionString[segmentationOption].title}
							</S.SegmentationOptionTitle>
							<span>
								{segmentationOptionString[segmentationOption].description}
							</span>
						</>
					</S.SegmentationOptionContainer>
				</S.Field>

				<S.SubmitForm type='submit' disabled={reportUsersQuery.isLoading}>
					{reportUsersQuery.isLoading ? (
						<Loader size={20} color='var(--white)' />
					) : (
						'Enviar'
					)}
				</S.SubmitForm>
			</S.FormContainer>
		</S.Container>
	);
}
