import { create } from 'zustand';
import { FilterParams } from '../components/Filter';
import { StatusOptionsFilters } from '../components/Filter/utils/SelectFilters';

type Store = {
	filtersParams: FilterParams[];
	setFiltersParams: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const filtersParams: FilterParams[] = [
	{ name: 'Nome', filter: 'name', value: '', type: 'text', selected: false },
	{ name: 'CPNJ', filter: 'cnpj', value: '', type: 'cnpj', selected: false },
	{
		name: 'Status',
		filter: 'status',
		value: '',
		type: 'select',
		selectOptions: StatusOptionsFilters,
		selected: false,
	},
];

const filtersParamsAllCompanies: FilterParams[] = [
	{ name: 'Nome', filter: 'name', value: '', type: 'text', selected: false },
	{ name: 'CPNJ', filter: 'cnpj', value: '', type: 'cnpj', selected: false },
	{
		name: 'Grupo Corporativo',
		filter: 'corporate_group_id',
		value: '',
		type: 'group_company_id',
		selected: false,
	},
];

const useCompaniesListStore = create<Store>()((set) => ({
	filtersParams,
	setFiltersParams: (f) => set({ filtersParams: f }),
	resetFilters: () => set({ filtersParams }),
}));

const useAllCompaniesListStore = create<Store>()((set) => ({
	filtersParams: filtersParamsAllCompanies,
	setFiltersParams: (f) => set({ filtersParams: f }),
	resetFilters: () => set({ filtersParams: filtersParamsAllCompanies }),
}));

export { useCompaniesListStore, useAllCompaniesListStore };
