import {
	Document,
	Page,
	Text,
	View,
	Font,
	Image,
	pdf,
} from '@react-pdf/renderer';
import * as S from '../styles';
import Loader from '../../../../components/Loader';
import { useQuery } from 'react-query';
import { FilterParams } from '../../../../components/Filter';
import { toast } from 'react-toastify';
import { convertCentsToReais } from '../../../../utils/CurrencyConvert';
import {
	BillingListReturn,
	getBillingToReport,
} from '../../../../services/queries/Billing';
import { cnpjMask } from '../../../../utils/masks';
import BountyLogo from '../../../../assets/reports/FlexibleBenefitsLogo.png';
import ReportIcon from '../../../../assets/reports/ReportIcon.png';
import { billingsReportStyle } from './styles';

Font.register({
	family: 'Poppins',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/hlvAxH6aIdOjWlLzgm0jqg.ttf',
		},
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/4WGKlFyjcmCFVl8pRsgZ9vesZW2xOQ-xsNqO47m55DA.ttf',
			fontWeight: 500,
		},
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/-zOABrCWORC3lyDh-ajNnPesZW2xOQ-xsNqO47m55DA.ttf',
			fontWeight: 600,
		},
	],
});

interface ReportProps {
	filterParams: FilterParams[];
}

export function ReportGeneratorBillingPDF({ filterParams }: ReportProps) {
	const fetchBillingToReportPDFQuery = useQuery<BillingListReturn>(
		['billingListToReportPDF', filterParams],
		() => {
			return getBillingToReport(filterParams);
		},
		{
			enabled: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);

	async function handleGeneratePDF() {
		try {
			const PDFGenerated = await generatePDFContent();
			const blob = await pdf(PDFGenerated).toBlob();

			// Create a temporary URL for the blob
			const url = URL.createObjectURL(blob);

			// Create a link element
			const link = document.createElement('a');
			link.href = url;
			link.download = 'relatorio_de_boletos.pdf';

			// Simulate a click event to trigger the download
			link.dispatchEvent(new MouseEvent('click'));

			// Clean up the temporary URL
			URL.revokeObjectURL(url);
		} catch (error) {
			console.log(error);
			toast.error(
				'Ocorreu um erro ao tentar gerar o arquivo de relátorio. Tente novamente'
			);
		}
	}

	async function generatePDFContent() {
		const reportFetching = await fetchBillingToReportPDFQuery.refetch();
		const { billings, totalBillings, totalReports } = reportFetching.data!;

		let dateMessage = 'Todo o período';

		const dateFilter = filterParams.find(
			(filter) => filter.name === 'Data' && filter.selected
		);

		if (dateFilter) {
			const formattedStartDate = Intl.DateTimeFormat('pt-BR', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			}).format(new Date(dateFilter.value.replace(/-/g, '/')));

			const formattedEndDate = Intl.DateTimeFormat('pt-BR', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			}).format(new Date(dateFilter.second_value!.replace(/-/g, '/')));

			dateMessage = `${formattedStartDate} - ${formattedEndDate}`;
		}

		return (
			<Document>
				<Page
					size='A4'
					style={billingsReportStyle.page}
					orientation='landscape'
				>
					<View style={billingsReportStyle.header} fixed>
						<Image src={BountyLogo} style={billingsReportStyle.logo} />
						<View style={billingsReportStyle.headerInfo}>
							<Text style={billingsReportStyle.headerInfoLabel}>
								N° Página:
							</Text>
							<Text
								style={billingsReportStyle.headerInfoData}
								render={({ pageNumber, totalPages }) =>
									`${String(pageNumber).padStart(2, '0')}/${String(
										totalPages
									).padStart(2, '0')}`
								}
							/>
						</View>
						<View style={billingsReportStyle.headerInfo}>
							<Text style={billingsReportStyle.headerInfoLabel}>
								Data do pedido:
							</Text>
							<Text style={billingsReportStyle.headerInfoData}>
								{Intl.DateTimeFormat('pt-BR', {
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
									hour12: false, // 24-hour format
								}).format(new Date())}
							</Text>
						</View>
						<View style={{ ...billingsReportStyle.headerInfo, width: '120%' }}>
							<Text style={billingsReportStyle.headerInfoLabel}>
								Intervalo de busca:
							</Text>
							<Text style={billingsReportStyle.headerInfoData}>
								{dateMessage}
							</Text>
						</View>
						<View style={{ ...billingsReportStyle.headerInfo, width: '60%' }}>
							<Text style={billingsReportStyle.headerInfoLabel}>
								N° do pedido:
							</Text>
							<Text style={billingsReportStyle.headerInfoData}>
								{String(totalReports).padStart(7, '0')}
							</Text>
						</View>
					</View>

					<View style={billingsReportStyle.subHeader}>
						<View style={billingsReportStyle.titleContainer}>
							<Image src={ReportIcon} style={billingsReportStyle.reportLogo} />
							<View style={billingsReportStyle.reportTitleContainer}>
								<Text
									style={{
										...billingsReportStyle.reportTitle,
										color: '#FF0037',
									}}
								>
									Relatório de
								</Text>

								<Text> </Text>

								<Text style={billingsReportStyle.reportTitle}>Boletos</Text>
							</View>
						</View>

						<View style={billingsReportStyle.subheaderDataContainer}>
							<View style={{ ...billingsReportStyle.subheaderInfo }}>
								<Text style={billingsReportStyle.subheaderLabel}>
									Total de registros:
								</Text>
								<Text style={billingsReportStyle.subheaderData}>
									{totalBillings}
								</Text>
							</View>
						</View>
					</View>
					<View style={billingsReportStyle.tableContainer}>
						<View style={billingsReportStyle.tableHeader}>
							<View
								style={{
									...billingsReportStyle.columnTitleContainer,
									width: '20%',
								}}
							>
								<Text style={billingsReportStyle.columnTitle}>Nº</Text>
							</View>

							<View
								style={{
									...billingsReportStyle.columnTitleContainer,
									width: '60%',
								}}
							>
								<Text style={billingsReportStyle.columnTitle}>Data</Text>
							</View>

							<View
								style={{
									...billingsReportStyle.columnTitleContainer,
									width: '180%',
								}}
							>
								<Text style={billingsReportStyle.columnTitle}>Empresa</Text>
							</View>

							<View style={billingsReportStyle.columnTitleContainer}>
								<Text style={billingsReportStyle.columnTitle}>CNPJ</Text>
							</View>

							<View style={billingsReportStyle.columnTitleContainer}>
								<Text style={billingsReportStyle.columnTitle}>Produto</Text>
							</View>

							<View
								style={{
									...billingsReportStyle.columnTitleContainer,
									width: '70%',
								}}
							>
								<Text style={billingsReportStyle.columnTitle}>Valor</Text>
							</View>

							<View
								style={{
									...billingsReportStyle.columnTitleContainer,
									width: '70%',
								}}
							>
								<Text style={billingsReportStyle.columnTitle}>Status</Text>
							</View>
						</View>

						{billings.map((billing, index) => (
							<View
								style={billingsReportStyle.tableRow}
								key={billing.id}
								wrap={false}
							>
								<Text
									style={{
										...billingsReportStyle.columnData,
										width: '20%',
									}}
								>
									{index + 1}
								</Text>

								<Text
									style={{
										...billingsReportStyle.columnData,
										width: '60%',
									}}
								>
									{new Date(billing.created_at).toLocaleDateString()}
								</Text>

								<Text
									style={{ ...billingsReportStyle.columnData, width: '180%' }}
								>
									{billing.company?.name ?? 'N/A'}
								</Text>

								<Text style={billingsReportStyle.columnData}>
									{cnpjMask(billing.company?.cnpj ?? 'N/A')}
								</Text>

								<Text
									style={{
										...billingsReportStyle.columnData,
										color: billing.origin === 'corpway' ? '#27ADFF' : '#FF0037',
									}}
								>
									{billing.origin === 'corpway'
										? 'Bounty Control'
										: 'Benefícios Flexíveis'}
								</Text>

								<Text
									style={{
										...billingsReportStyle.columnData,
										fontWeight: 600,
										width: '70%',
									}}
								>
									{convertCentsToReais(Number(billing.amount))}
								</Text>

								<Text
									style={{
										...billingsReportStyle.columnData,
										fontWeight: 600,
										width: '70%',
									}}
								>
									{billing.status === 'paid' ? 'PAGO' : 'EMITIDO'}
								</Text>
							</View>
						))}
					</View>
				</Page>
			</Document>
		);
	}

	return (
		<>
			<S.ReportButton onClick={handleGeneratePDF}>
				{fetchBillingToReportPDFQuery.isLoading ? (
					<Loader color='#fff' size={15} />
				) : (
					'Gerar relatório PDF'
				)}
			</S.ReportButton>
		</>
	);
}
