import { MdNotes } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { Company, GroupCompany } from '../../../../@types';
import CompanyCard from '../../../../components/CompanyCard';
import { EmptyContent } from '../../../../components/EmptyContent';
import Loader from '../../../../components/Loader';
import PageTitle from '../../../../components/PageTitle';
import * as TableStyle from '../../../../components/Table/TableStyles';
import { cnpjMask } from '../../../../utils/masks';
import {
	getStatusIcon,
	parseStatusString,
} from '../../../../utils/parseKycStatus';
import * as S from './styles';
import { Filter } from '../../../../components/Filter';
import { useQuery } from 'react-query';
import { fetchGroupCompany } from '../../../../services/queries/GroupCompany';
import { getFilteredCompanies } from '../../../../services/queries/Companies';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { ManageBenefitsModal } from '../../../../components/ManageBenefitsModal';
import { getPartners } from '../../../../services/queries/Partners';
import { FaHandsHelping } from 'react-icons/fa';
import { useCompaniesListStore } from '../../../../stores/useCompaniesListStore';
import { useSetHistoryNonMatchCallbackLocation } from '../../../../hooks/useSetHistoryNonMatchCallbackLocation';
import { useSortColumnHook } from '../../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../../components/SortColumnButton';
import { UpdateProductsModal } from '../../../../components/CompanyForm/UpdateProductsModal';

export function GroupCompanyDetails() {
	const { id } = useParams();
	const { currentSortColumn, toggleSort, sortList } = useSortColumnHook();
	const [filtersParams, setFiltersParams, resetFilters] = useCompaniesListStore(
		(state) => [state.filtersParams, state.setFiltersParams, state.resetFilters]
	);

	useSetHistoryNonMatchCallbackLocation(`group-companies/${id}`, resetFilters);

	const getPartnersQuery = useQuery(['fetch-partners'], getPartners);

	const fetchGroupCompanyQuery = useQuery<GroupCompany, Error>(
		['fetchGroupCompany', id],
		() => {
			return fetchGroupCompany(id!);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar informações do grupo corporativo. '
				);
			},
		}
	);

	const filterCompanies = useQuery<Company[], Error>(
		['filteredCompanies', id, filtersParams],
		() => {
			return getFilteredCompanies({
				groupCompanyId: id!,
				companyFiltersParams: filtersParams,
			});
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar as empresas. '
				);
			},
			enabled: !!filtersParams.find((f) => !!f.value),
		}
	);

	function getCompanies() {
		if (filtersParams.find((f) => !!f.value)) {
			return sortList(filterCompanies.data ?? []);
		}
		return sortList([
			...fetchGroupCompanyQuery.data!.companies.activeCompanies,
			...fetchGroupCompanyQuery.data!.companies.inactiveCompanies,
		]);
	}

	if (
		fetchGroupCompanyQuery.isLoading ||
		filterCompanies.isLoading ||
		!fetchGroupCompanyQuery.data
	) {
		return (
			<S.Container>
				<PageTitle title='Detalhes do Grupo Corporativo' />
				<Loader />
				<S.CreateCompanyButton to={`/group-companies/${id}/companies/create`}>
					Criar empresa
				</S.CreateCompanyButton>
			</S.Container>
		);
	}

	return (
		<S.Container>
			<TableStyle.TitleWrapper>
				<PageTitle
					title={`Detalhes do Grupo Corporativo - ${fetchGroupCompanyQuery.data?.name}`}
					totalRecords={getCompanies().length ?? 0}
				/>
				<Filter
					filterParams={filtersParams}
					onFiltersChanged={(updatedFilters) => {
						setFiltersParams(updatedFilters);
					}}
				/>
			</TableStyle.TitleWrapper>
			{getCompanies().length ? (
				<TableStyle.Table>
					<TableStyle.TableHeader>
						<TableStyle.TableRow>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'name'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('name')}
								/>
								EMPRESA
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'email'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('email')}
								/>
								EMAIL
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>CNPJ</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								COMPLIANCE
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>OPÇÕES</TableStyle.TableHeaderCell>
						</TableStyle.TableRow>
					</TableStyle.TableHeader>

					<TableStyle.TableBody>
						{getCompanies().map((company) => (
							<TableStyle.TableRow key={company.id}>
								<TableStyle.TableData>
									<CompanyCard
										company_name={company.name}
										avatar_url={company.avatar_url}
									/>
								</TableStyle.TableData>

								<TableStyle.TableData>{company.email}</TableStyle.TableData>

								<TableStyle.TableData>
									{cnpjMask(company.cnpj)}
								</TableStyle.TableData>

								<TableStyle.TableData>
									<S.StatusContainer>
										{getStatusIcon(company.kyc_status)}
										<span>{parseStatusString(company.kyc_status)}</span>
									</S.StatusContainer>
								</TableStyle.TableData>

								<TableStyle.TableData style={{ textAlign: 'center' }}>
									<TableStyle.OptionsContainer>
										<TableStyle.OptionLink
											data-rh='Detalhes da empresa'
											to={`/group-companies/${id}/companies/${company.id}`}
										>
											<MdNotes />
										</TableStyle.OptionLink>
										<ManageBenefitsModal
											companyId={company.id!}
											smallButton={true}
										/>
										{/* conditionally hide */}
										{!!getPartnersQuery.data &&
											getPartnersQuery.data.length > 0 && (
												<TableStyle.OptionLink
													data-rh='Gerenciar Parceiros'
													to={`/partners/company/${company!.id}/manage`}
												>
													<FaHandsHelping />
												</TableStyle.OptionLink>
											)}

										<UpdateProductsModal
											companyId={company!.id!}
											product_corporate_expenses_enabled={Boolean(
												company?.company_products
													?.product_corporate_expenses_enabled
											)}
											product_flexible_benefits_enabled={Boolean(
												company?.company_products
													?.product_flexible_benefits_enabled
											)}
											smallButton={true}
										/>
									</TableStyle.OptionsContainer>
								</TableStyle.TableData>
							</TableStyle.TableRow>
						))}
					</TableStyle.TableBody>
				</TableStyle.Table>
			) : (
				<EmptyContent text='Não há ítens a serem exibidos.' />
			)}

			<S.CreateCompanyButton to={`/group-companies/${id}/companies/create`}>
				Criar empresa
			</S.CreateCompanyButton>
		</S.Container>
	);
}
