import { GroupCompany } from '../../@types';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../hooks/useSortColumnHook';
import api from '../api';

export interface GroupCompanyListReturn {
	corporationGroup: GroupCompany[];
	totalCorporateGroup: number;
}

export interface GroupCompanyCreationBodyQuery {
	name: string;
}

export interface GroupCompanyFilterReturn {
	totalCorpGroups: number;
	corpGroups: GroupCompany[];
}

export async function fetchGroupCompany(
	groupCompanyId: string
): Promise<GroupCompany> {
	const { data } = await api.get<GroupCompany>(
		`/api/v1/master/groups-companies/${groupCompanyId}`
	);

	return data;
}

export async function getGroupsCompany(
	groupCompaniesCurrentPage: number,
	sortColumn: SortColumn | null = null
): Promise<GroupCompanyListReturn> {
	const { data } = await api.get(`/api/v1/master/groups-companies/q`, {
		params: {
			page: groupCompaniesCurrentPage,
			...parseSortColumnToParams(sortColumn),
		},
	});

	const corporationGroup = data.corporationGroup;
	const totalCorporateGroup = data.totalCorporateGroup;

	return {
		corporationGroup,
		totalCorporateGroup,
	};
}

export async function getGroupsCompanyLookUp() {
	const { data } = await api.get<GroupCompany[]>(
		`/api/v1/master/groups-companies/lookup`
	);

	return data;
}

export async function getFilteredGroupCompanies(
	groupCompanyFiltersParams: FilterParams[],
	page: number,
	sortColumn: SortColumn | null = null
): Promise<GroupCompanyFilterReturn> {
	const { data } = await api.get(`/api/v1/master/groups-companies/filter/q`, {
		params: {
			page,
			...parseFilterParamsToParams(groupCompanyFiltersParams),
			...parseSortColumnToParams(sortColumn),
		},
	});

	return data;
}

export async function createGroupCompany(
	bodyData: GroupCompanyCreationBodyQuery
): Promise<void> {
	const { data } = await api.post('/api/v1/master/groups-companies', bodyData);

	return data;
}

export async function getGroupCompaniesSelect(): Promise<GroupCompany[]> {
	const { data } = await api.get('/api/v1/master/list/groups-companies');

	return data;
}
