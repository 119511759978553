import PageTitle from '../../../components/PageTitle';
import * as S from './styles';

export function Support() {
	return (
		<S.Container>
			<PageTitle title='Suporte MASTER' />

			<S.Content>
				<p>Conteúdo em construção...</p>
			</S.Content>
		</S.Container>
	);
}
