import { useState, useEffect } from 'react';
import * as S from './styles';
import PageTitle from '../../../components/PageTitle';
import { useForm } from 'react-hook-form';
import ToggleSwitch from '../../../components/ToggleSwitch';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { NotificationsPreferences as NotificationsPreferencesType } from '../../../@types';
import { toast } from 'react-toastify';
import {
	getNotificationsPreferences,
	updateNotificationsPreferences,
} from '../../../services/queries/NotificationsPreferences';
import Loader from '../../../components/Loader';
import PreventTransitionPrompt from '../../../components/PreventTransitionPrompt';
import { showErrorMessage } from '../../../utils/ErrorHandler';

export default function NotificationsPreferences() {
	const updatePreferencesQuery = useMutation(
		(preferences: NotificationsPreferencesType) =>
			updateNotificationsPreferences(preferences)
	);

	const queryClient = useQueryClient();
	const { register, handleSubmit, reset, formState } =
		useForm<NotificationsPreferencesType>();

	const { isDirty } = formState;
	const [allowNavigation, setAllowNavigation] = useState(!isDirty);

	useEffect(() => {
		setAllowNavigation(!isDirty);
	}, [isDirty]);

	async function submitPreferences(dataForm: NotificationsPreferencesType) {
		try {
			await updatePreferencesQuery.mutateAsync(dataForm);
			queryClient.resetQueries('fetchPreferences');
			reset();
			toast.info('Preferências de notificações atualizadas.');
		} catch (err) {
			showErrorMessage(
				err as Error,
				'Não foi possível atualizar suas preferências. '
			);
		}
	}

	const { data, isLoading } = useQuery<NotificationsPreferencesType, Error>(
		'fetchPreferences',
		() => {
			return getNotificationsPreferences();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar as configurações de notificações. '
				);
			},
		}
	);

	if (!data || isLoading || updatePreferencesQuery.isLoading) {
		return (
			<S.Container>
				<PageTitle title='Configurar notificações' />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<PreventTransitionPrompt when={!allowNavigation} />

			<PageTitle title='Configurar notificações' />

			<S.PreferencesForm onSubmit={handleSubmit(submitPreferences)}>
				<S.PreferencesSectionContainer>
					<S.PreferencesSectionTitle>
						Recebimento via dashboard web
					</S.PreferencesSectionTitle>
					<S.ToggleButtonsContainer>
						<S.FieldContainer>
							<p>Notificações sobre remessa de cartões</p>
							<ToggleSwitch
								name={'notification_card_batch'}
								register={register}
								defaultChecked={data.notification_card_batch}
								data-testid='toggleButton_notification_card_batch'
							/>
						</S.FieldContainer>
						<S.FieldContainer>
							<p>Notificações sobre cartões</p>
							<ToggleSwitch
								name={'notification_card'}
								register={register}
								defaultChecked={data.notification_card}
								data-testid='toggleButton_notification_card'
							/>
						</S.FieldContainer>
						<S.FieldContainer>
							<p>Notificações sobre atualizações de KYC</p>
							<ToggleSwitch
								name={'notification_kyc'}
								register={register}
								defaultChecked={data.notification_kyc}
								data-testid='toggleButton_notification_kyc'
							/>
						</S.FieldContainer>
						<S.FieldContainer>
							<p>Notificações sobre fundos</p>
							<ToggleSwitch
								name={'notification_fund'}
								register={register}
								defaultChecked={data.notification_fund}
								data-testid='toggleButton_notification_fund'
							/>
						</S.FieldContainer>
					</S.ToggleButtonsContainer>
				</S.PreferencesSectionContainer>
				<S.PreferencesSectionContainer>
					<S.PreferencesSectionTitle>
						Recebimento via email
					</S.PreferencesSectionTitle>
					<S.ToggleButtonsContainer>
						<S.FieldContainer>
							<p>Emails sobre remessas de cartões</p>
							<ToggleSwitch
								name={'email_card_batch'}
								register={register}
								defaultChecked={data.email_card_batch}
								data-testid='toggleButton_email_card_batch'
							/>
						</S.FieldContainer>
						<S.FieldContainer>
							<p>Emails sobre cartões</p>
							<ToggleSwitch
								name={'email_card'}
								register={register}
								defaultChecked={data.email_card}
								data-testid='toggleButton_email_card'
							/>
						</S.FieldContainer>
						<S.FieldContainer>
							<p>Emails sobre atualizações de KYC</p>
							<ToggleSwitch
								name={'email_kyc'}
								register={register}
								defaultChecked={data.email_kyc}
								data-testid='toggleButton_email_kyc'
							/>
						</S.FieldContainer>
						<S.FieldContainer>
							<p>Emails sobre cadastro de colaboradores menores</p>
							<ToggleSwitch
								name={'email_underage_collaborator'}
								register={register}
								defaultChecked={data.email_underage_collaborator}
								data-testid='toggleButton_email_underageCollab'
							/>
						</S.FieldContainer>
					</S.ToggleButtonsContainer>
				</S.PreferencesSectionContainer>

				<S.FormButton type='submit'>Salvar</S.FormButton>
			</S.PreferencesForm>
		</S.Container>
	);
}
