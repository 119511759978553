import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import * as TableStyle from '../Table/TableStyles';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from 'react-icons/ai';

export interface PaginationProps {
	onPageChange(page: number): void;
	currentPage: number;
	totalCount: number;
	onFirstPageButton?: boolean;
	onLastPageButton?: boolean;
}

export function Pagination({
	onPageChange,
	currentPage,
	totalCount,
	onFirstPageButton,
	onLastPageButton,
}: PaginationProps) {
	const pagesLimit = totalCount === 0 ? 1 : Math.ceil(totalCount / 10);

	function onNext() {
		if (currentPage === pagesLimit) return;
		onPageChange(currentPage + 1);
	}

	function onPrevious() {
		if (currentPage === 1) return;
		onPageChange(currentPage - 1);
	}

	function onFirstPage() {
		if (currentPage === 1) return;
		onPageChange(currentPage - (currentPage - 1));
	}

	function onLastPage() {
		if (currentPage === pagesLimit) return;
		onPageChange(pagesLimit);
	}

	return (
		<TableStyle.PaginationContainer data-testid='pagination-container'>
			<TableStyle.TableText>
				Página {currentPage} de {pagesLimit}
			</TableStyle.TableText>
			{onFirstPageButton && (
				<AiOutlineDoubleLeft
					onClick={onFirstPage}
					color='#000'
					data-testid='pagination-previous-max'
				/>
			)}
			<FaAngleLeft
				onClick={() => onPrevious()}
				color='#070606'
				data-testid='pagination-previous'
			/>
			<FaAngleRight
				onClick={() => onNext()}
				color='#000'
				data-testid='pagination-next'
			/>
			{onLastPageButton && (
				<AiOutlineDoubleRight
					onClick={onLastPage}
					color='#000'
					data-testid='pagination-next-max'
				/>
			)}
		</TableStyle.PaginationContainer>
	);
}
