// expected format: yyyy-mm
export function getLastDayOfMonth(monthDateString: string) {
	// Parse the year and month from the input string
	const [year, month] = monthDateString.split('-').map(Number);

	// Create a Date object for the last day of the month
	const lastDayOfMonth = new Date(year, month, 0);

	// Extract the day part from the Date objects
	const lastDay = lastDayOfMonth.getDate();

	return lastDay;
}
