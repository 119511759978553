import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';
import { Input } from '../../../../components/Form/FormStyles';

export const EditQuantity = styled.button`
	${OptionsButton}
	width: 9rem;
	height: 2rem;
	padding: 0;
	align-self: center;
`;

export const QuantityInput = styled(Input)`
	width: 9rem;
`;

export const Field = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 0.5rem 1.2rem 0.5rem;
	&:first-child {
		margin-left: 0;
	}
	&:last-child {
		margin-right: 0;
	}
`;

export const DivInput = styled.div`
	display: flex;
	align-items: center;
`;

export const ButtonRaw = styled.button`
	height: min-content;
	width: fit-content;
	border-radius: 0.4rem;
	border: 0.2rem solid var(--primary-blue);
	padding: 0.2rem 1rem;
	font-weight: bold;
	background-color: var(--primary-blue);
	color: var(--white);
	width: 8rem;
	margin-top: 1rem;
	display: block;
	margin-left: 1rem;
`;

export const FieldGroup = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	margin-top: 1rem;
	column-gap: 2rem;
	width: 100%;
`;

export const Label = styled.label`
	font-size: 1.6rem;
	margin-bottom: 0.5rem;
	color: var(--dark-gray);
	font-weight: 600;
`;
