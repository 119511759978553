import { useEffect } from 'react';
import { LeafletStock } from '../../../../../@types';
import * as S from '../manageStockStyles';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { GenerateHistoryFile } from './GenerateHistoryFile';
import {
	ManageLeafletStockState,
	useManageLeafletStockStore,
} from '../../../../../stores/useManageLeafletStock';

type Props = {
	leafletStock: LeafletStock;
};

export type FormData = {
	quantity_total_envelopes: string;
	quantity_envelopes_lost: string;
	quantity_total_accompanying_letters: string;
	quantity_accompanying_letters_lost: string;
};

export function ManageLeafletStock({ leafletStock }: Props) {
	const initialState: ManageLeafletStockState = {
		quantityTotalAccompanyingLetters:
			leafletStock.quantityTotalAccompanyingLetters,
		quantityAccompanyingLettersAvailable:
			leafletStock.quantityAccompanyingLettersAvailable,
		quantityAccompanyingLettersPersonalized:
			leafletStock.quantityAccompanyingLettersPersonalized,
		quantityAccompanyingLettersLost:
			leafletStock.quantityAccompanyingLettersLost,

		quantityTotalEnvelopes: leafletStock.quantityTotalEnvelopes,
		quantityEnvelopesAvailable: leafletStock.quantityEnvelopesAvailable,
		quantityEnvelopesPersonalized: leafletStock.quantityEnvelopesPersonalized,
		quantityEnvelopesLost: leafletStock.quantityEnvelopesLost,

		editedFlag: false,
	};

	const { state, actions } = useManageLeafletStockStore();
	const { register, resetField, getValues } = useForm<FormData>({
		defaultValues: {
			quantity_total_envelopes: '',
			quantity_envelopes_lost: '',
		},
	});

	useEffect(() => {
		actions.setState(initialState);
	}, [leafletStock]); // eslint-disable-line react-hooks/exhaustive-deps

	function validateInput(inputValue: string) {
		if (inputValue === '' || Number(inputValue) <= 0) {
			toast.error('Insira um valor válido positivo.');
			return false;
		}
		return true;
	}

	function handleAddTotalEnvelopes() {
		const inputValue = getValues('quantity_total_envelopes');
		if (!validateInput(inputValue)) return;

		const quantityTotalEnvelopesValue = parseInt(inputValue);
		actions.updateQuantityTotalEnvelopes(quantityTotalEnvelopesValue);
		resetField('quantity_total_envelopes');
	}

	function handleSubTotalEnvelopes() {
		const inputValue = getValues('quantity_total_envelopes');
		if (!validateInput(inputValue)) return;

		const quantityTotalEnvelopesValue = parseInt(inputValue);

		if (state.quantityTotalEnvelopes - quantityTotalEnvelopesValue <= 0) {
			toast.error(
				'O valor subtraído não pode exceder a quantidade de envelopes.'
			);
			return;
		}
		if (state.quantityEnvelopesAvailable - quantityTotalEnvelopesValue <= 0) {
			toast.error(
				'O valor subtraído não pode exceder a quantidade de envelopes disponíveis.'
			);
			return;
		}

		actions.updateQuantityTotalEnvelopes(-quantityTotalEnvelopesValue);
		resetField('quantity_total_envelopes');
	}

	function handleAddEnvelopesLost() {
		const inputValue = getValues('quantity_envelopes_lost');
		if (!validateInput(inputValue)) return;

		const quantityEnvelopesLost = parseInt(inputValue);

		if (state.quantityEnvelopesAvailable - quantityEnvelopesLost <= 0) {
			toast.error(
				'O valor subtraído não pode exceder a quantidade de envelopes disponíveis.'
			);
			return;
		}

		actions.updateQuantityEnvelopesLost(quantityEnvelopesLost);
		resetField('quantity_envelopes_lost');
	}

	function handleAddTotalAccompanyingLetters() {
		const inputValue = getValues('quantity_total_accompanying_letters');
		if (!validateInput(inputValue)) return;

		const quantityTotalAccompanyingLettersValue = parseInt(inputValue);
		actions.updateQuantityTotalAccompanyingLetters(
			quantityTotalAccompanyingLettersValue
		);
		resetField('quantity_total_accompanying_letters');
	}

	function handleSubTotalAccompanyingLetters() {
		const inputValue = getValues('quantity_total_accompanying_letters');
		if (!validateInput(inputValue)) return;

		const quantityTotalAccompanyingLettersValue = parseInt(inputValue);

		if (
			state.quantityTotalAccompanyingLetters -
				quantityTotalAccompanyingLettersValue <=
			0
		) {
			toast.error(
				'O valor subtraído não pode exceder a quantidade de cartas berço.'
			);
			return;
		}
		if (
			state.quantityEnvelopesAvailable -
				quantityTotalAccompanyingLettersValue <=
			0
		) {
			toast.error(
				'O valor subtraído não pode exceder a quantidade de cartas berço disponíveis.'
			);
			return;
		}

		actions.updateQuantityTotalAccompanyingLetters(
			-quantityTotalAccompanyingLettersValue
		);
		resetField('quantity_total_accompanying_letters');
	}

	function handleAddAccompanyingLettersLost() {
		const inputValue = getValues('quantity_accompanying_letters_lost');
		if (!validateInput(inputValue)) return;

		const quantityAccompanyingLettersLost = parseInt(inputValue);

		if (
			state.quantityAccompanyingLettersAvailable -
				quantityAccompanyingLettersLost <=
			0
		) {
			toast.error(
				'O valor subtraído não pode exceder a quantidade de cartas berço disponíveis.'
			);
			return;
		}

		actions.updateQuantityAccompanyingLettersLost(
			quantityAccompanyingLettersLost
		);
		resetField('quantity_accompanying_letters_lost');
	}

	return (
		<div style={{ marginBottom: '2rem' }}>
			{/* ENVELOPES */}
			<S.FieldGroup>
				<S.Field>
					<S.Label htmlFor='quantity_total_envelopes'>
						Adicionar quantidade de Envelopes (Total)
					</S.Label>
					<S.Label>Valor total: {state.quantityTotalEnvelopes}</S.Label>
					<S.DivInput>
						<S.QuantityInput
							id='quantity_total_envelopes'
							type='number'
							required
							{...register('quantity_total_envelopes')}
						/>
						<div>
							<S.ButtonRaw
								onClick={handleAddTotalEnvelopes}
								data-testid='addTotalEnvelopes'
							>
								Adicionar
							</S.ButtonRaw>
							<S.ButtonRaw onClick={handleSubTotalEnvelopes}>
								Subtrair
							</S.ButtonRaw>
						</div>
					</S.DivInput>
				</S.Field>

				<S.Field>
					<S.Label>Envelopes disponíveis</S.Label>
					<S.Label>Valor total: {state.quantityEnvelopesAvailable}</S.Label>
				</S.Field>

				<S.Field>
					<S.Label>Envelopes personalizados</S.Label>
					<S.Label>Valor total: {state.quantityEnvelopesPersonalized}</S.Label>
				</S.Field>

				<S.Field>
					<S.Label htmlFor='quantity_envelopes_lost'>
						Adicionar Envelopes perdidos
					</S.Label>
					<S.Label>Valor total: {state.quantityEnvelopesLost}</S.Label>
					<S.DivInput>
						<S.QuantityInput
							id='quantity_envelopes_lost'
							type='number'
							required
							{...register('quantity_envelopes_lost')}
						/>
						<div>
							<S.ButtonRaw
								onClick={handleAddEnvelopesLost}
								data-testid='addEnvelopesLost'
							>
								Adicionar
							</S.ButtonRaw>
						</div>
					</S.DivInput>
				</S.Field>
			</S.FieldGroup>
			{/* ACCOMPANYING LETTERS */}
			<S.FieldGroup>
				<S.Field>
					<S.Label htmlFor='quantity_total_accompanying_letters'>
						Adicionar quantidade de Cartas Berço (Total)
					</S.Label>
					<S.Label>
						Valor total: {state.quantityTotalAccompanyingLetters}
					</S.Label>
					<S.DivInput>
						<S.QuantityInput
							id='quantity_total_accompanying_letters'
							type='number'
							required
							{...register('quantity_total_accompanying_letters')}
						/>
						<div>
							<S.ButtonRaw
								onClick={handleAddTotalAccompanyingLetters}
								data-testid='addTotalAccompanyingLetters'
							>
								Adicionar
							</S.ButtonRaw>
							<S.ButtonRaw onClick={handleSubTotalAccompanyingLetters}>
								Subtrair
							</S.ButtonRaw>
						</div>
					</S.DivInput>
				</S.Field>

				<S.Field>
					<S.Label>Cartas Berço disponíveis</S.Label>
					<S.Label>
						Valor total: {state.quantityAccompanyingLettersAvailable}
					</S.Label>
				</S.Field>

				<S.Field>
					<S.Label>Cartas Berço personalizadas</S.Label>
					<S.Label>
						Valor total: {state.quantityAccompanyingLettersPersonalized}
					</S.Label>
				</S.Field>

				<S.Field>
					<S.Label htmlFor='quantity_accompanying_letters_lost'>
						Adicionar Cartas Berço perdidas
					</S.Label>
					<S.Label>
						Valor total: {state.quantityAccompanyingLettersLost}
					</S.Label>
					<S.DivInput>
						<S.QuantityInput
							id='quantity_accompanying_letters_lost'
							type='number'
							required
							{...register('quantity_accompanying_letters_lost')}
						/>
						<div>
							<S.ButtonRaw
								onClick={handleAddAccompanyingLettersLost}
								data-testid='addAccompanyingLettersLost'
							>
								Adicionar
							</S.ButtonRaw>
						</div>
					</S.DivInput>
				</S.Field>
			</S.FieldGroup>

			<GenerateHistoryFile />
		</div>
	);
}
