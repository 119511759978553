import { onMessage } from 'firebase/messaging';
import { createContext, ReactNode, useEffect } from 'react';
import {
	useMutation,
	useQuery,
	useQueryClient,
	UseQueryResult,
} from 'react-query';
import { toast } from 'react-toastify';
import { useAuth } from '../hooks/useAuth';
import { getNotificationToken, messaging } from '../services/firebase';
import {
	getNotifications,
	toggleNotificationStatus,
} from '../services/queries/Notifications';

export interface Notification {
	id: string;
	company_id: string | null;
	company: {
		corporate_name: string;
	} | null;
	corporate_group_id: string | null;
	operator_id: string;
	master_id: string | null;
	collaborator_id: string | null;
	message: string;
	was_read: boolean;
	event_type: string;
	resource_id: string | null;
	created_at: Date;
	// updated_at: 2022-05-17T11:40:11.452Z
}

export interface NotificationData {
	unreadNotifications: number;
	toggleNotificationsStatus: (ids: string[], status: boolean) => Promise<void>;
	fetchUnreadNotifications: UseQueryResult<any, unknown>;
}

interface ToggleNotificationStatusParams {
	notificationId: string;
	status: boolean;
}

export const NotificationContext = createContext({} as NotificationData);

type NotificationProps = {
	children: ReactNode;
};
export function NotificationProvider({ children }: NotificationProps) {
	const { user } = useAuth();
	const queryClient = useQueryClient();
	const notificationSound = new Audio('/notification_sound.wav');

	useEffect(() => {
		if (user && messaging) {
			getNotificationToken(messaging);

			// Firebase Message observer event
			onMessage(messaging, (payload) => {
				notificationSound.play();

				toast.info(
					<NotificationToaster
						body={payload.data ? payload.data.message : ''}
					/>
				);
				fetchNotificationsQuery.refetch();
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		const onSWBackgroundNotificationEvent = (e: MessageEvent) => {
			if (e.data.type === 'background-notification-event') {
				fetchNotificationsQuery.refetch(); // to update the amount of unseen notifications (that shows on top)
				notificationSound.play();
			}
		};

		navigator.serviceWorker.addEventListener(
			'message',
			onSWBackgroundNotificationEvent
		);
		return () => {
			navigator.serviceWorker.removeEventListener(
				'message',
				onSWBackgroundNotificationEvent
			);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchNotificationsQuery = useQuery(
		['fetchNotifications', user],
		() => {
			return getNotifications(1);
		},
		{
			onSuccess: (data) => {
				// UPDATE page title with the amount of unreadNotifications
				if (data.totalNotificationsUnread > 0)
					document.title = `(${data.totalNotificationsUnread}) Bounty | admin`;
				else document.title = `Bounty | admin`;
			},
			onError: () => {
				toast.error('Ocorreu um problema ao buscar as notificações.');
			},
			enabled: !!user,
			refetchOnWindowFocus: true,
		}
	);

	const toggleNotificationsStatusQuery = useMutation(
		({ notificationId, status }: ToggleNotificationStatusParams) => {
			return toggleNotificationStatus(notificationId, status);
		}
	);

	async function toggleNotificationsStatus(ids: string[], status: boolean) {
		await Promise.all(
			ids.map((notificationId) =>
				toggleNotificationsStatusQuery.mutateAsync({ notificationId, status })
			)
		).then(() => {
			queryClient.invalidateQueries('fetchNotifications');
			fetchNotificationsQuery.refetch();
		});
	}

	function NotificationToaster({ body }: { body: string }) {
		return (
			<div>
				<h4>Você recebeu uma nova notificação</h4>
				<p>{body}</p>
			</div>
		);
	}

	return (
		<NotificationContext.Provider
			value={{
				unreadNotifications:
					fetchNotificationsQuery.data?.totalNotificationsUnread ?? 0,
				toggleNotificationsStatus,
				fetchUnreadNotifications: fetchNotificationsQuery,
			}}
		>
			{children}
		</NotificationContext.Provider>
	);
}
