import { useEffect, useRef, useState } from 'react';
import { useDialogModal } from '../../../../../../hooks/useDialogModal';
import { useQuery } from 'react-query';
import { showErrorMessage } from '../../../../../../utils/ErrorHandler';
import { RiAddCircleLine, RiCloseCircleLine } from 'react-icons/ri';
import * as S from '../styles';
import Modal from '../../../../../../components/Modal';
import PageTitle from '../../../../../../components/PageTitle';
import { Filter } from '../../../../../../components/Filter';
import { Pagination } from '../../../../../../components/Pagination';
import * as TableStyle from '../../../../../../components/Table/TableStyles';
import Loader from '../../../../../../components/Loader';
import { EmptyContent } from '../../../../../../components/EmptyContent';
import { getFilteredGroupCompanies } from '../../../../../../services/queries/GroupCompany';
import { GroupCompany } from '../../../../../../@types';
import { useGroupCompaniesListStore } from '../../../../../../stores/useGroupCompaniesListStore';

export interface GroupToSelect {
	id: string;
	type: 'group';
	name: string;
}

export interface GroupsSelectorProps {
	alreadyAddedGroups: GroupToSelect[];
	onAddAndRemoveGroups(
		groupsToAdd: GroupToSelect[],
		groupsToRemove: GroupToSelect[]
	): void;
	allowRemoveGroup?: boolean;
	buttonText?: string;
}

export function GroupCompaniesSelector({
	alreadyAddedGroups,
	onAddAndRemoveGroups,
	allowRemoveGroup,
	buttonText,
}: GroupsSelectorProps) {
	const [selectedGroups, setSelectedGroups] = useState<GroupToSelect[]>([]);
	const [selectedGroupsToRemove, setSelectedGroupsToRemove] = useState<
		GroupToSelect[]
	>([]);

	const [open, setOpen] = useState(false);
	const [hasMadeChanges, setHasMadeChanges] = useState(false);
	const [groupsCurrentPage, setGroupsCurrentPage] = useState(1);
	const [filtersParams, setFiltersParams] = useGroupCompaniesListStore(
		(state) => [state.filtersParams, state.setFiltersParams, state.resetFilters]
	);

	const listContainer = useRef<HTMLUListElement | null>(null);

	const { openOptionsDialog } = useDialogModal();

	useEffect(() => {
		listContainer.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [groupsCurrentPage, listContainer]);

	useEffect(() => {
		if (open) {
			setSelectedGroups([]);
			setSelectedGroupsToRemove([]);
			setGroupsCurrentPage(1);
			setHasMadeChanges(false);
		}
	}, [open]);

	const allGroupsQuery = useQuery(
		['allGroupCompaniesQuery', groupsCurrentPage, filtersParams],
		() => {
			return getFilteredGroupCompanies(filtersParams, groupsCurrentPage);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os grupos corporativos. '
				);
			},
			enabled: open,
			keepPreviousData: true,
		}
	);

	function handleSubmit() {
		onAddAndRemoveGroups(selectedGroups, selectedGroupsToRemove);

		setOpen(false);
	}

	function handleClose() {
		if (!hasMadeChanges) {
			setOpen(false);
			return;
		}

		openOptionsDialog(
			'Alterações não salvas. Tem certeza que deseja sair?',
			'Confirmar',
			() => setOpen(false),
			'Cancelar',
			() => {}
		);
	}

	function isGroupAlreadyAdded(id: string) {
		return !!alreadyAddedGroups.find((groupAdded) => groupAdded.id === id);
	}

	function isGroupSelected(id: string) {
		return !!selectedGroups.find((c) => c.id === id);
	}

	function isGroupSelectedToRemove(id: string) {
		return !!selectedGroupsToRemove.find((c) => c.id === id);
	}

	function handleSelectGroup(group: GroupToSelect) {
		setHasMadeChanges(true);
		setSelectedGroups([...selectedGroups, group]);
	}

	function handleDeselectGroup(group: GroupToSelect) {
		setHasMadeChanges(true);
		setSelectedGroups(selectedGroups.filter((c) => c.id !== group.id));
	}

	function handleSelectGroupToRemove(group: GroupToSelect) {
		setHasMadeChanges(true);
		setSelectedGroupsToRemove([...selectedGroupsToRemove, group]);
	}

	function handleDeselectGroupToRemove(groupToDeselect: GroupToSelect) {
		setHasMadeChanges(true);
		setSelectedGroupsToRemove(
			selectedGroupsToRemove.filter(
				(company) => company.id !== groupToDeselect.id
			)
		);
	}

	function handleSelector(corpGroup: GroupCompany) {
		const group = {
			id: corpGroup.id,
			type: 'group',
			name: corpGroup.name,
		} as GroupToSelect;

		//Non-selected to add
		if (!isGroupAlreadyAdded(group.id) && !isGroupSelected(group.id)) {
			return (
				<S.Selector
					selected={isGroupSelected(group.id)}
					onClick={() => handleSelectGroup(group)}
					data-testid={`${group.id}-select`}
				>
					<RiAddCircleLine />
				</S.Selector>
			);
		}

		//Already added non-selected to remove
		if (isGroupAlreadyAdded(group.id) && !isGroupSelectedToRemove(group.id!)) {
			return (
				<S.Selector
					selected={false}
					remove={true}
					disabled={allowRemoveGroup ? false : true}
					title={allowRemoveGroup ? '' : 'Grupo corporativo já adicionado'}
					onClick={() => handleSelectGroupToRemove(group)}
					data-testid={`${group.id}-remove`}
				>
					<RiCloseCircleLine />
				</S.Selector>
			);
		}

		//Already added and selected to remove
		if (isGroupSelectedToRemove(group.id!)) {
			return (
				<S.Selector
					selected={true}
					onClick={() => handleDeselectGroupToRemove(group)}
				>
					<RiAddCircleLine />
				</S.Selector>
			);
		}

		//Selected to add
		return (
			<S.Selector
				selected={isGroupSelected(group.id!)}
				onClick={() => handleDeselectGroup(group)}
				data-testid={`${group.id}-unselect`}
			>
				<RiCloseCircleLine />
			</S.Selector>
		);
	}

	if (allGroupsQuery.isLoading) {
		return (
			<>
				<S.MainButton onClick={() => setOpen(true)}>
					{buttonText ?? 'Adicionar grupos corporativos'}
				</S.MainButton>
				<Modal isOpen={open} enableClose onRequestClose={handleClose}>
					<S.Container data-testid='groupsSelector-modal-container'>
						<TableStyle.TableHeaderContainer>
							<PageTitle title='Selecione grupos corporativos' />
							<Filter
								filterParams={filtersParams}
								onFiltersChanged={(updatedFilters) => {
									setGroupsCurrentPage(1);
									setFiltersParams(updatedFilters);
								}}
							/>
							<Pagination
								onPageChange={(page) => setGroupsCurrentPage(page)}
								currentPage={groupsCurrentPage}
								totalCount={1}
							/>
						</TableStyle.TableHeaderContainer>
						<Loader />
					</S.Container>
				</Modal>
			</>
		);
	}

	return (
		<div>
			<S.MainButton onClick={() => setOpen(true)}>
				{buttonText ?? 'Adicionar grupos corporativos'}
			</S.MainButton>

			<Modal isOpen={open} enableClose onRequestClose={handleClose}>
				<S.Container>
					<TableStyle.TableHeaderContainer>
						<PageTitle
							title='Selecione grupos corporativos'
							totalRecords={allGroupsQuery.data?.totalCorpGroups ?? 0}
						/>
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								setGroupsCurrentPage(1);
								setFiltersParams(updatedFilters);
							}}
						/>
						<Pagination
							onPageChange={(page) => setGroupsCurrentPage(page)}
							currentPage={groupsCurrentPage}
							totalCount={allGroupsQuery.data?.totalCorpGroups ?? 0}
						/>
					</TableStyle.TableHeaderContainer>

					<S.ItemsList ref={listContainer}>
						{allGroupsQuery.data?.totalCorpGroups! > 0 ? (
							allGroupsQuery.data?.corpGroups.map((group) => {
								return (
									<S.ItemContainer key={group.id}>
										<S.ItemCard>
											<S.ItemInfoContainer>
												<S.ItemName>{group.name}</S.ItemName>
											</S.ItemInfoContainer>
										</S.ItemCard>

										{handleSelector(group)}
									</S.ItemContainer>
								);
							})
						) : (
							<EmptyContent text='Nenhum grupo corporativo disponível' />
						)}
					</S.ItemsList>

					<S.SaveButton style={{ width: '16rem' }} onClick={handleSubmit}>
						Salvar
					</S.SaveButton>
				</S.Container>
			</Modal>
		</div>
	);
}
