import { createContext, ReactNode, useState } from 'react';
import { useRef } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import Modal from '../../components/Modal';
import * as S from './styles';

interface Props {
	children: ReactNode;
}

export interface DialogModalContextData {
	// MESSAGE TO DISPLAY AND ONSUBMIT CALLBACK
	openConfirmDialog(
		message: string,
		mainOptMessage: string,
		onSubmitMainOption: () => void,
		enableClose?: boolean
	): void;
	openOptionsDialog(
		message: string,
		mainOptMessage: string,
		onSubmitMainOption: () => void,
		secondaryOptMessage: string,
		onSubmitSecondaryOption: () => void,
		enableClose?: boolean
	): void;
}

export const DialogModalContext = createContext({} as DialogModalContextData);

export const OTHER_MODALS_CONTAINER_ID = 'lower-modal-container';
export const DIALOG_MODAL_CONTAINER_ID = 'upper-modal-container';

export const DialogModalProvider = ({ children }: Props) => {
	const submitMainButton = useRef<HTMLButtonElement>(null);
	const submitSecondButton = useRef<HTMLButtonElement>(null);
	const [isOpen, setIsOpen] = useState(false);
	const [message, setMessage] = useState('');
	const [mainOptMessage, setMainOptMessage] = useState('');
	const [secondOptMessage, setSecondOptMessage] = useState('');
	const [enableClose, setEnableClose] = useState(true);

	// Open Confirmation Dialog with one option to click
	function openConfirmDialog(
		message: string,
		mainOptMessage: string, // message to main submit button
		onSubmitMainOption = () => {}, // default value is an empty function
		enableClose = true
	) {
		setIsOpen(true);
		setMessage(message);
		setMainOptMessage(mainOptMessage);
		setEnableClose(enableClose);

		setTimeout(() => {
			// Without this timeout the submitbutton.current won't be mounted already
			submitMainButton.current?.addEventListener('click', () =>
				handleSubmit(onSubmitMainOption)
			);
			// submitSecondButton.current?.addEventListener('click', () => handleSubmit(onSubmitSecondOption))
		}, 20);

		return () => {
			submitMainButton.current?.removeEventListener('click', () =>
				handleSubmit(onSubmitMainOption)
			);
			// submitSecondButton.current?.removeEventListener('click', () => handleSubmit(onSubmitSecondOption))
		};
	}

	// Two options dialog
	function openOptionsDialog(
		message: string,
		mainOptMessage: string, // message to main submit button
		onSubmitMainOption = () => {}, // default value is an empty function
		secondaryOptMessage: string, // message to main submit button
		onSubmitSecondaryOption = () => {}, // default value is an empty function
		enableClose = false
	) {
		setIsOpen(true);
		setMessage(message);
		setMainOptMessage(mainOptMessage);
		setSecondOptMessage(secondaryOptMessage);
		setEnableClose(enableClose);

		setTimeout(() => {
			// Without this timeout the submitbutton.current won't be mounted already
			submitMainButton.current?.addEventListener('click', () =>
				handleSubmit(onSubmitMainOption)
			);
			submitSecondButton.current?.addEventListener('click', () =>
				handleSubmit(onSubmitSecondaryOption)
			);
		}, 20);

		return () => {
			submitMainButton.current?.removeEventListener('click', () =>
				handleSubmit(onSubmitMainOption)
			);
			submitSecondButton.current?.removeEventListener('click', () =>
				handleSubmit(onSubmitSecondaryOption)
			);
		};
	}

	function handleSubmit(onSubmit: () => void) {
		onSubmit(); // Call the Submit function passed to the DialogModalContext when opened

		closeModal();
	}

	function closeModal() {
		setIsOpen(false);
		setMessage('');
		setMainOptMessage('');
		setSecondOptMessage('');
		setEnableClose(true);
	}

	return (
		<DialogModalContext.Provider
			value={{ openConfirmDialog, openOptionsDialog }}
		>
			{children}

			<Modal
				isOpen={isOpen}
				onRequestClose={closeModal}
				enableClose={enableClose}
				parentId={DIALOG_MODAL_CONTAINER_ID}
			>
				<S.Container>
					<FaInfoCircle />
					<S.Message>{message}</S.Message>
					<S.OptionsContainer>
						<S.Option second={false} ref={submitMainButton}>
							{mainOptMessage}
						</S.Option>
						{secondOptMessage && (
							<S.Option second={true} ref={submitSecondButton}>
								{secondOptMessage}
							</S.Option>
						)}
					</S.OptionsContainer>
				</S.Container>
			</Modal>
			<div id={OTHER_MODALS_CONTAINER_ID} style={{ zIndex: 10 }}></div>
			{/* DIALOG_MODAL CONTAINER has a bigger zIndex, so it shows on top of any other MODAL */}
			<div id={DIALOG_MODAL_CONTAINER_ID} style={{ zIndex: 1000 }}></div>
		</DialogModalContext.Provider>
	);
};
