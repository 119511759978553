import { create } from 'zustand';

export type ManageCardStockState = {
	editedFlag: boolean;
	quantityRaw: number;
	quantityEmbossed: number;
	quantityAvailable: number;
	quantityLost: number;
};

type Actions = {
	updateQuantityRaw: (payload: number) => void;
	updateQuantityEmbossed: (payload: number) => void;
	updateQuantityLost: (payload: number) => void;
	setState: (payload: ManageCardStockState) => void;
};

type Store = {
	state: ManageCardStockState;
	actions: Actions;
};

export const useManageCardStockStore = create<Store>((set) => ({
	state: {
		editedFlag: false,
		quantityRaw: 0,
		quantityEmbossed: 0,
		quantityAvailable: 0,
		quantityLost: 0,
	},

	actions: {
		updateQuantityRaw: (payload) =>
			set((state) => ({
				...state,
				state: {
					...state.state,
					quantityRaw: state.state.quantityRaw + Number(payload),
					quantityAvailable: state.state.quantityAvailable + Number(payload),
					editedFlag: true,
				},
			})),

		updateQuantityEmbossed: (payload) =>
			set((state) => ({
				...state,
				state: {
					...state.state,
					quantityEmbossed: state.state.quantityEmbossed + Number(payload),
					quantityAvailable: state.state.quantityAvailable - Number(payload),
					editedFlag: true,
				},
			})),

		updateQuantityLost: (payload) =>
			set((state) => ({
				...state,
				state: {
					...state.state,
					quantityLost: state.state.quantityLost + Number(payload),
					quantityAvailable: state.state.quantityAvailable - Number(payload),
					editedFlag: true,
				},
			})),

		setState: (payload) => set({ state: payload }),
	},
}));
