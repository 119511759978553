import { Header } from '../../components/Header';
import avatarSVG from '../../assets/avatar.svg';
import MainRoutes from '../../routes/MainRoutes';
import { useAuth } from '../../hooks/useAuth';
import * as S from './styles';
import { SidebarLink } from '../../components/SidebarLink';
import { useLocation } from 'react-router-dom';

import { BiSpreadsheet, BiStoreAlt, BiTransfer } from 'react-icons/bi';
import { RiExchangeDollarLine, RiNumbersFill } from 'react-icons/ri';
import {
	FaCcMastercard,
	FaCreditCard,
	FaDollarSign,
	FaHandsHelping,
	FaInfoCircle,
	FaUserFriends,
} from 'react-icons/fa';
import { useQuery } from 'react-query';
import { getPartners } from '../../services/queries/Partners';
import { SidebarDropdown } from '../../components/SidebarDropdown';
import { BsBox } from 'react-icons/bs';

export function Main() {
	const { user } = useAuth();
	const { pathname } = useLocation();

	const getPartnersQuery = useQuery(['fetch-partners'], getPartners);

	return (
		<>
			<Header name={'MASTER'} email={user.email} avatar={avatarSVG} />
			<S.Container>
				<S.MainContent style={{ display: 'flex', minHeight: '100vh' }}>
					<S.Sidebar>
						<SidebarLink
							icon={BiSpreadsheet}
							title='Grupos Corporativos'
							path={`/group-companies`}
							currentPage={pathname === '/group-companies'}
						/>
						<SidebarLink
							icon={BiStoreAlt}
							title='Pré-Cadastro de Empresas'
							path={`/pre-register`}
							currentPage={pathname === '/pre-register'}
						/>
						<SidebarLink
							icon={BiTransfer}
							title='Histórico de Transferências'
							path={`/transfers`}
							currentPage={pathname === '/transfers'}
						/>
						<SidebarLink
							icon={FaDollarSign}
							title='Boletos'
							path={`/billing`}
							currentPage={pathname.includes('billing')}
						/>
						<SidebarLink
							icon={RiExchangeDollarLine}
							title='Estornos'
							path={`/chargebacks`}
							currentPage={pathname.includes('chargebacks')}
						/>
						<SidebarLink
							icon={FaUserFriends}
							title='Colaboradores menores'
							path={`/underage-documents`}
							currentPage={pathname.includes('underage-documents')}
						/>

						<SidebarDropdown
							title='Cartões'
							icon={FaCreditCard}
							subMenuItems={[
								{
									path: '/corpway-cards',
									title: 'Virtuais',
									icon: FaCcMastercard,
									product: 'control',
									strictPathCompare: pathname.includes('/corpway-cards'),
								},
								{
									path: '/cards',
									title: 'Remessas',
									icon: FaCreditCard,
									product: 'multiflex',
									strictPathCompare:
										pathname.includes('/cards/stats') ||
										pathname.includes('/cards/stock'), // don't consider stats route as part of /cards list route
								},
								{
									path: '/cards/stats',
									title: 'Segunda via',
									product: 'multiflex',
									icon: RiNumbersFill,
								},
								{
									path: '/cards/stock',
									title: 'Estoque',
									product: 'multiflex',
									icon: BsBox,
								},
							]}
						/>

						{/* conditionally hide */}
						{!!getPartnersQuery.data && getPartnersQuery.data.length > 0 && (
							<SidebarLink
								icon={FaHandsHelping}
								title='Gerenciamento de Parceiros'
								path={`/partners`}
								currentPage={pathname.includes('partners')}
							/>
						)}

						<SidebarLink
							icon={FaInfoCircle}
							title='Notificar Usuários'
							path={'/notify-users'}
							currentPage={pathname.includes('notify-users')}
						/>
					</S.Sidebar>

					<S.PagesContainer>
						<MainRoutes />
					</S.PagesContainer>
				</S.MainContent>
			</S.Container>
		</>
	);
}
