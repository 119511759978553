import PageTitle from '../../../components/PageTitle';
import * as S from './styles';
import * as FormStyle from '../../../components/Form/FormStyles';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	getPrivacyPolicy,
	getTerms,
	updatePrivacyPolicy,
	updateTermOfUse,
} from '../../../services/queries/Terms';
import Loader from '../../../components/Loader';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { showErrorMessage } from '../../../utils/ErrorHandler';

interface FormProps {
	terms?: FileList;
	privacy?: FileList;
}

export default function Terms() {
	const { register, handleSubmit, reset } = useForm();
	const updateTermOfUseMutation = useMutation((termDoc: FormData) =>
		updateTermOfUse(termDoc)
	);
	const updatePrivacyPolicyMutation = useMutation((privacyDoc: FormData) =>
		updatePrivacyPolicy(privacyDoc)
	);
	const { updateKey } = useParams();
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	useEffect(() => {
		if (updateKey) {
			if (updateKey !== process.env.REACT_APP_UPDATE_TERMS_KEY) {
				navigate('/404');
			}
		}
	}, [updateKey, navigate]);

	const termsQuery = useQuery<string, Error>(
		['operatorTerm'],
		() => {
			return getTerms();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os termos de uso. '
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	const privacyQuery = useQuery(
		['privacyPolicy'],
		() => {
			return getPrivacyPolicy();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar as políticas de privacidade. '
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	async function submitForm(dataForm: FormProps) {
		if (!dataForm.privacy?.length && !dataForm.terms?.length) {
			toast.error(
				'Prencha pelo menos um dos campos para prosseguir com a atualização!'
			);
			return;
		}

		if (dataForm.terms?.length) {
			try {
				const termsDocumentFormData = new FormData();
				termsDocumentFormData.append('terms_document', dataForm.terms![0]);
				await updateTermOfUseMutation.mutateAsync(termsDocumentFormData);
				queryClient.invalidateQueries('operatorTerm');
				toast.info('Texto de termos de uso atualizado com sucesso.');
				reset({
					terms: null,
				});
			} catch (err) {
				console.log(err);
			}
		}

		if (dataForm.privacy?.length) {
			try {
				const privacyDocumentFormData = new FormData();
				privacyDocumentFormData.append(
					'privacy_document',
					dataForm.privacy![0]
				);
				await updatePrivacyPolicyMutation.mutateAsync(privacyDocumentFormData);
				queryClient.invalidateQueries('privacyPolicy');
				toast.info('Texto de política de privacidade atualizado com sucesso.');
				reset({
					privacy: null,
				});
			} catch (err) {
				console.log(err);
			}
		}
	}

	if (
		updatePrivacyPolicyMutation.isLoading ||
		updateTermOfUseMutation.isLoading ||
		privacyQuery.isLoading ||
		termsQuery.isLoading
	) {
		return (
			<S.Container>
				<PageTitle title='Atualizar termos de uso e políticas de privacidade' />
				<div style={{ marginTop: '3rem' }} />
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			<PageTitle title='Atualizar termos de uso e políticas de privacidade' />

			<FormStyle.FormContainer>
				<FormStyle.Form onSubmit={handleSubmit(submitForm)}>
					<FormStyle.FieldSet>
						<FormStyle.Field>
							<FormStyle.Label htmlFor='terms'>Termos de uso</FormStyle.Label>
							<S.InputFile
								type={'file'}
								style={{
									padding: '0.4rem',
									width: '50%',
									height: 'fit-content',
								}}
								multiple={false}
								accept='.doc, .docx'
								id='terms'
								{...register('terms')}
							/>
						</FormStyle.Field>
						<S.DocumentsContainer
							dangerouslySetInnerHTML={{
								__html: termsQuery.data ? termsQuery.data : '',
							}}
						/>
						<FormStyle.Field style={{ marginTop: '3rem' }}>
							<FormStyle.Label htmlFor='privacy'>
								Políticas de privacidade
							</FormStyle.Label>
							<S.InputFile
								type={'file'}
								style={{
									padding: '0.4rem',
									width: '50%',
									height: 'fit-content',
								}}
								multiple={false}
								accept='.doc, .docx'
								id='privacy'
								{...register('privacy')}
							/>
						</FormStyle.Field>
						<S.DocumentsContainer
							dangerouslySetInnerHTML={{
								__html: privacyQuery.data ? privacyQuery.data : '',
							}}
						/>
					</FormStyle.FieldSet>

					<FormStyle.ButtonsContainer>
						<FormStyle.FormButton type='submit'>Atualizar</FormStyle.FormButton>
					</FormStyle.ButtonsContainer>
				</FormStyle.Form>
			</FormStyle.FormContainer>
		</S.Container>
	);
}
