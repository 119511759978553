import { CardBatch } from '../../@types';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../hooks/useSortColumnHook';
import api from '../api';

export type CardsBatchesListReturn = {
	concludedCardsBatches?: CardBatch[];
	totalConcludedCardsBatches?: number;

	pendingCardsBatches?: CardBatch[];
	totalPendingCardsBatches?: number;
};

export type FilteredCardbatchesReturn = {
	totalCardBatches: number;
	cardBatches: CardBatch[];
};

export async function getConcludedCardsBatches(
	concludedCardsBatchesCurrentPage: number,
	sortColumn: SortColumn | null
): Promise<CardsBatchesListReturn> {
	const { data } = await api.get(`api/v1/master/cardbatch/q`, {
		params: {
			page_other: concludedCardsBatchesCurrentPage,
			...(sortColumn && { sort_by_other: sortColumn.name }),
			...(sortColumn && { sort_order_other: sortColumn.order }),
		},
	});

	const concludedCardsBatches = data.othersCardBatchesFormatted;
	const totalConcludedCardsBatches = data.totalOthersCardBatches;

	return {
		concludedCardsBatches,
		totalConcludedCardsBatches,
	};
}

export async function getPendingCardsBatches(
	pendingCardsBatchesCurrentPage: number,
	sortColumn: SortColumn | null
): Promise<CardsBatchesListReturn> {
	const { data } = await api.get(`api/v1/master/cardbatch/q`, {
		params: {
			page_pending: pendingCardsBatchesCurrentPage,
			...(sortColumn && { sort_by_pending: sortColumn.name }),
			...(sortColumn && { sort_order_pending: sortColumn.order }),
		},
	});

	const pendingCardsBatches = data.pendingCardBatchesFormatted;
	const totalPendingCardsBatches = data.totalPendingCardBatches;

	return {
		pendingCardsBatches,
		totalPendingCardsBatches,
	};
}

export async function getFilteredCardBaches(
	filterParams: FilterParams[],
	pending?: boolean,
	page?: number,
	sortColumn: SortColumn | null = null
): Promise<FilteredCardbatchesReturn> {
	const { data } = await api.get(`/api/v1/master/cardbatch/filter/q`, {
		params: {
			page,
			...parseFilterParamsToParams(filterParams),
			status: pending ? 'processing,pending' : 'reproved,done',
			...parseSortColumnToParams(sortColumn),
		},
	});

	const totalCardBatches = data.totalCardBatches;
	const cardBatches = data.cardBatches;

	return {
		totalCardBatches,
		cardBatches,
	};
}

export interface CardsStatsReturn {
	bounty_card_stats: {
		quantity_raw: number;
		quantity_embossed: number;
		quantity_lost: number;
		quantity_requested: number;
		quantity_available: number;
		quantity_processing: number;
		quantity_delivered: number;
	};
	stats_by_company: {
		company_name: string;
		count_requested: number;
		count_processing: number;
		count_delivered: number;
	}[];
	card_stock_master_history: {
		id: string;
		user_id: string;
		user_email: string;
		card_stock_master_id: number;
		new_value_quantity_raw: number;
		new_value_quantity_embossed: number;
		new_value_quantity_cards_available: number;
		original_value_quantity_raw: number;
		original_value_quantity_embossed: number;
		original_value_quantity_cards_available: number;
		created_at: string;
	}[];
	leaflet_stock_stats: {
		quantity_total_envelopes: number;
		quantity_envelopes_lost: number;
		quantity_envelopes_personalized: number;
		quantity_envelopes_available: number;
		quantity_total_accompanying_letters: number;
		quantity_accompanying_letters_lost: number;
		quantity_accompanying_letters_personalized: number;
		quantity_accompanying_letters_available: number;
	};
}
export async function getCardsStats() {
	const { data } = await api.get<CardsStatsReturn>(
		`/api/v1/master/cardbatch_stats`
	);

	return data;
}

export interface CardStockMasterHistory {
	cardStockMasterHistory: {
		id: string;
		user_id: string;
		user_email: string;
		card_stock_master_id: number;
		new_value_quantity_raw: number;
		new_value_quantity_embossed: number;
		new_value_quantity_cards_available: number;
		new_value_quantity_cards_lost: number;
		original_value_quantity_raw: number;
		original_value_quantity_embossed: number;
		original_value_quantity_cards_available: number;
		original_value_quantity_cards_lost: number;
		created_at: string;
	}[];
	totalCardStockMasterHistory: number;
}
export async function getCardsStockMasterHistory(cardStockPage: number) {
	const { data } = await api.get<CardStockMasterHistory>(
		`/api/v1/master/cardbatch_history/`,
		{
			params: {
				page: cardStockPage,
			},
		}
	);

	const totalCardStockMasterHistory = data.totalCardStockMasterHistory;
	const cardStockMasterHistory = data.cardStockMasterHistory;

	return { cardStockMasterHistory, totalCardStockMasterHistory };
}

export async function getAllCardsStockMasterHistory() {
	const { data } = await api.get<CardStockMasterHistory>(
		`/api/v1/master/cardbatch_all_history/`
	);

	const totalCardStockMasterHistory = data.totalCardStockMasterHistory;
	const cardStockMasterHistory = data.cardStockMasterHistory;

	return { cardStockMasterHistory, totalCardStockMasterHistory };
}

export async function fetchCardBatch(cardBatchId: string): Promise<CardBatch> {
	const { data } = await api.get<CardBatch>(
		`/api/v1/master/cardbatch/${cardBatchId}`
	);

	return data;
}

export async function reviewCardBatch(
	cardBatchId: string,
	review: 'canceled' | 'processing' | 'reproved'
): Promise<CardBatch> {
	return await api.put(
		`/api/v1/master/cardbatch/${cardBatchId}/status/${review}`
	);
}

export async function trackItem(cardBatchId: string, cardBatchItemId: string) {
	const { data } = await api.get(
		`/api/v1/master/cardbatch/${cardBatchId}/item/${cardBatchItemId}/track`
	);

	return data.data;
}

export async function trackBatch(companyId: string, cardBatchId: string) {
	const { data } = await api.get(
		`/api/v1/master/cardbatch/${companyId}/cardbatch/${cardBatchId}/track`
	);

	return data.data;
}

interface DuplicateCardsStats {
	total: number;
	reference_month: string;
	reference_year: string;
	items: {
		id: string;
		quantity: string;
		reference_month: string;
		reference_year: string;
		company_name: string;
		company_cnpj: string;
	}[];
}
export async function getFilteredDuplicateCardsStats(
	filterParams: FilterParams[]
) {
	const { data } = await api.get<DuplicateCardsStats[]>(
		`/api/v1/master/duplicate-cards-period/q`,
		{
			params: {
				...parseFilterParamsToParams(filterParams),
			},
		}
	);

	return data;
}

export interface DuplicateCardsPeriodResponse {
	data: {
		reference_month: string;
		reference_year: string;
		company_name: string;
	};
	items: {
		collaborator_name: string;
		collaborator_cpf: string;
		card_pan: number;
		activate_date: string;
	}[];
}
export async function getDuplicateCardsPeriods(periodsIds: string[]) {
	const { data } = await api.post<DuplicateCardsPeriodResponse[]>(
		'/api/v1/master/duplicate-cards-period/details/general',
		{
			periods_ids: periodsIds,
		}
	);
	return data;
}

export async function updateCardStock(
	quantity_available: number,
	quantity_embossed: number,
	quantity_raw: number,
	quantity_lost: number
) {
	const { data } = await api.put(`/api/v1/master/cardbatch-stock-edit`, {
		quantity_available,
		quantity_embossed,
		quantity_raw,
		quantity_lost,
	});
	return data;
}
