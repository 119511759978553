// letters, space, '.', ',', ''', '/', '-'
export const addressesFieldsCollaboratorSWAPRawRegex = String.raw`A-Za-z0-9Á-ÿ\s\u00f1\u00d1.,\/'\-`;
export const addressesFieldsCollaboratorSWAPPattern = String.raw`[${addressesFieldsCollaboratorSWAPRawRegex}]*`;

export const onlyLettersAndSpacesRawRegex = String.raw`A-Za-zÁ-ÿ\s\u00f1\u00d1`;
export const onlyLettersAndSpacesPattern = String.raw`[${onlyLettersAndSpacesRawRegex}]+`;

export const cpfPattern = String.raw`[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}`;

export const addressNumberSWAPPattern = String.raw`[a-zA-Z0-9Á-ÿ\u00f1\u00d1 ']+`;

export const addressNumberPattern = String.raw`[0-9]+`;

export const cepPattern = String.raw`[0-9]{5}-[0-9]{3}`;

export const phonePattern = String.raw`\+[0-9]{2} [0-9]{2} [0-9]{4,5}-[0-9]{4}`;

export const emailPattern = String.raw`^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$`;

export const cnpjPattern = String.raw`[0-9]{2}.[0-9]{3}.[0-9]{3}/[0-9]{4}-[0-9]{2}`;

export const websitePattern = String.raw`\S+\.\S+`;
