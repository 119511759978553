import styled from 'styled-components';
import { OptionsButton } from '../../../../../components/Button';

export const MainButton = styled.button`
	background: none;
	border: none;
	color: var(--primary-blue);
	padding: 0;
	font-size: 1.8rem;
`;

export const SaveButton = styled.button`
	${OptionsButton}
	margin: 0 auto;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 60rem;
	width: 70vw;
	padding-right: 1rem;
	min-height: 20rem;
`;

export const ItemsList = styled.ul`
	display: flex;
	flex-direction: column;
	max-height: 50vh;
	overflow-y: auto;
	margin: 2rem 0;
`;

export const ItemContainer = styled.li`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	border-bottom: 0.1rem solid var(--primary-blue);
`;

export const ItemCard = styled.div`
	display: flex;
	align-items: center;
`;

export const ItemAvatar = styled.img`
	border: 0.2rem solid var(--primary-blue);
	border-radius: 50%;
	height: 5rem;
	width: 5rem;
	margin-right: 1rem;
	object-fit: cover;
`;

export const ItemInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ItemName = styled.span`
	color: var(--primary-blue);
	font-size: 1.6rem;
`;

export const ItemInfo = styled.span`
	font-size: 1.4rem;
	color: var(--mid-gray);
`;

interface ItemSelectionProps {
	selected: boolean;
	remove?: boolean;
}

export const Selector = styled.button<ItemSelectionProps>`
	width: 3.5rem;
	height: 3.5rem;
	background: none;
	& svg {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		color: ${({ selected, remove }) =>
			selected
				? 'var(--light-gray)'
				: remove
				? 'var(--primary-red)'
				: 'var(--primary-blue)'};
	}
	&:disabled {
		opacity: 50%;
		cursor: not-allowed;
	}
`;

export const ListButton = styled.span`
	background: none;
	color: var(--primary-blue);
	font-size: 1.4rem;
`;

export const ListContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 2rem;
	min-width: 30rem;
	padding: 2rem 0;
`;

export const ItemsGenericList = styled.ul`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
`;

export const SelectedItem = styled.li`
	display: flex;
`;
