import ReactModal from 'react-modal'
import styled from 'styled-components'

export const Modal = styled(ReactModal)`
  background: var(--white);
  position: relative;
  margin: 2rem auto;
  border-radius: 1rem;
  padding: 2rem 2rem 1rem 2rem;
  min-width: 20rem;
  width: min-content;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0px 3px 10px -5px rgba(54,54,54,0.86);
`

export const CloseModalBtn = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: 0;  
  border-radius: 0.2rem;
  /* padding: 0.5rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-red);
  transition: filter .2s;

  &:hover {
    filter: brightness(0.9);
  }
`