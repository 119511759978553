import React, { useEffect } from 'react';
import ReactHintFactory from 'react-hint';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './contexts/AuthContext';
import { DialogModalProvider } from './contexts/DialogModalContext';
import { NotificationProvider } from './contexts/NotificationsContext';
import Routes from './routes';
import { GlobalTheme } from './styles/GlobalTheme';
import 'react-hint/css/index.css';
import { GlobalDebug } from './utils/removeConsoles';
import Footer from './components/Footer';
import { useHistoryNonMatchCallbackStore } from './stores/useHistoryNonMatchCallbackStore';
import { useLocation } from 'react-router-dom';

const ReactHint = ReactHintFactory(React);

function App() {
	const queryClient = new QueryClient();

	useEffect(() => {
		process.env.REACT_APP_NODE_ENV === 'production' && GlobalDebug(false);
	}, []);

	const location = useLocation();
	const onLocationChange = useHistoryNonMatchCallbackStore(
		(state) => state.onLocationChange
	);

	// whenever locations are changed call onLocationChange that holds logic whether useHistoryNonMatchCallbackStore
	// should reset page filters or not
	useEffect(() => {
		onLocationChange(location.pathname);
	}, [location.pathname, onLocationChange]);

	return (
		<QueryClientProvider client={queryClient}>
			<ReactHint autoPosition={true} events />
			<DialogModalProvider>
				<AuthProvider>
					<NotificationProvider>
						<Routes />
						<Footer />
						<ToastContainer />
						<GlobalTheme />
					</NotificationProvider>
				</AuthProvider>
			</DialogModalProvider>
		</QueryClientProvider>
	);
}

export default App;
