import { useState } from 'react';
import Modal from '../../../../../components/Modal';
import * as S from './styles';
import PageTitle from '../../../../../components/PageTitle';
import * as TableStyle from '../../../../../components/Table/TableStyles';
import { useQuery } from 'react-query';
import { getDuplicateCardsPeriods } from '../../../../../services/queries/CardsBatchs';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import Loader from '../../../../../components/Loader';
import { cpfMask } from '../../../../../utils/masks';
import { parseReferenceDate } from '../../utils/parseReferenceDate';
import { MdNotes } from 'react-icons/md';
import { GeneratePDFReport } from './GeneratePDFReport';

interface Props {
	periodsIds: string[];
	smallBtn?: boolean;
}
export function PeriodsDetailsModal({ periodsIds, smallBtn = false }: Props) {
	const [isOpen, setOpen] = useState(false);

	const toggleOpen = () => setOpen((state) => !state);

	const duplicateCardsPeriodsQuery = useQuery(
		['duplicateCardsPeriods', periodsIds],
		() => getDuplicateCardsPeriods(periodsIds),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar o detalhamento das segundas vias de cartão. '
				);
			},
			enabled: periodsIds.length > 0,
		}
	);

	const MainButton = () =>
		smallBtn ? (
			<S.MainBtnSmall
				onClick={toggleOpen}
				data-rh='Detalhamento'
				data-testid='open-PeriodsDetailsModal-small-btn'
			>
				<MdNotes />
			</S.MainBtnSmall>
		) : (
			<S.MainBtn onClick={toggleOpen}>Detalhar</S.MainBtn>
		);

	if (!duplicateCardsPeriodsQuery.data) {
		return (
			<>
				<MainButton />

				<Modal isOpen={isOpen} onRequestClose={toggleOpen} enableClose>
					<S.Container>
						<PageTitle title='Detalhamento de segundas vias de cartão' />
						<Loader />
					</S.Container>
				</Modal>
			</>
		);
	}
	return (
		<>
			<MainButton />

			<Modal isOpen={isOpen} onRequestClose={toggleOpen} enableClose>
				<S.Container>
					<PageTitle title='Detalhamento de segundas vias de cartão' />

					<S.Content>
						{duplicateCardsPeriodsQuery.data
							.slice()
							.sort((a, b) => {
								const yearA = parseInt(a.data.reference_year, 10);
								const monthA = parseInt(a.data.reference_month, 10);
								const yearB = parseInt(b.data.reference_year, 10);
								const monthB = parseInt(b.data.reference_month, 10);

								if (yearA !== yearB) {
									return yearA - yearB;
								} else if (monthA !== monthB) {
									return monthA - monthB;
								} else {
									return a.data.company_name.localeCompare(b.data.company_name);
								}
							})
							.map((r) => (
								<div
									key={
										r.data.company_name +
										r.data.reference_month +
										r.data.reference_year
									}
								>
									<S.ReferenceDate>
										{`${r.data.company_name} - ${parseReferenceDate(
											Number(r.data.reference_month),
											r.data.reference_year
										)}`}
									</S.ReferenceDate>
									<TableStyle.Table mBottom='0'>
										<TableStyle.TableHeader>
											<TableStyle.TableRow>
												<TableStyle.TableHeaderCell>
													COLABORADOR
												</TableStyle.TableHeaderCell>
												<TableStyle.TableHeaderCell>
													CPF
												</TableStyle.TableHeaderCell>
												<TableStyle.TableHeaderCell>
													PAN do novo cartão
												</TableStyle.TableHeaderCell>
												<TableStyle.TableHeaderCell>
													Data da ativação
												</TableStyle.TableHeaderCell>
											</TableStyle.TableRow>
										</TableStyle.TableHeader>

										<TableStyle.TableBody>
											{r.items.map((item) => (
												<TableStyle.TableRow
													key={item.card_pan + item.collaborator_cpf}
												>
													<TableStyle.TableData>
														<span>{item.collaborator_name}</span>
													</TableStyle.TableData>
													<TableStyle.TableData>
														<span>{cpfMask(item.collaborator_cpf)}</span>
													</TableStyle.TableData>
													<TableStyle.TableData>
														<span>{item.card_pan}</span>
													</TableStyle.TableData>
													<TableStyle.TableData>
														<span>
															{new Date(
																item.activate_date
															).toLocaleDateString()}
														</span>
													</TableStyle.TableData>
												</TableStyle.TableRow>
											))}
										</TableStyle.TableBody>
									</TableStyle.Table>
									<S.Total>Total de cartões: {r.items.length}</S.Total>
								</div>
							))}
					</S.Content>

					<S.FooterOptionsContainer>
						<S.Option onClick={toggleOpen}>Fechar</S.Option>
						<GeneratePDFReport
							duplicatedPeriodsData={duplicateCardsPeriodsQuery.data}
						/>
					</S.FooterOptionsContainer>
				</S.Container>
			</Modal>
		</>
	);
}
