import { useDialogModal } from '../../../hooks/useDialogModal';
import Modal from '../../Modal';
import { OptionButton } from '../../Table/TableStyles';
import * as FormStyle from '../../Form/FormStyles';
import * as S from './styles';
import { useEffect, useState } from 'react';
import ToggleSwitch from '../../ToggleSwitch';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { updateCompanyProducts } from '../../../services/queries/Companies';
import Loader from '../../Loader';
import { toast } from 'react-toastify';
import { MdProductionQuantityLimits } from 'react-icons/md';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { Operator } from '../../../@types';

interface Props {
	smallButton?: boolean;
	companyId: string;
	product_flexible_benefits_enabled: boolean;
	product_corporate_expenses_enabled: boolean;
	corporate_expenses_operators?: Operator[];
}

export interface ProductsFormData {
	product_flexible_benefits_enabled: boolean;
	product_corporate_expenses_enabled: boolean;
}

export function UpdateProductsModal({
	smallButton = false,
	product_flexible_benefits_enabled,
	product_corporate_expenses_enabled,
	companyId,
}: Props) {
	const queryClient = useQueryClient();
	const [isOpen, setIsOpen] = useState(false);

	const { openOptionsDialog } = useDialogModal();
	const [hasMadeChanges, setHasMadeChanges] = useState(false);
	const { register, handleSubmit, reset } = useForm<ProductsFormData>({
		defaultValues: {
			product_corporate_expenses_enabled: Boolean(
				product_corporate_expenses_enabled
			),
			product_flexible_benefits_enabled: Boolean(
				product_flexible_benefits_enabled
			),
		},
	});

	const updateCompanyProductsMutation = useMutation(
		(d: ProductsFormData) => updateCompanyProducts(companyId, d),
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries('fetchGroupCompany');
				await queryClient.invalidateQueries('listOperators');
				setIsOpen(false);
				toast.info('Produtos atualizados com sucesso!');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar atualizar os produtos da empresa. '
				);
			},
		}
	);

	useEffect(() => {
		if (isOpen) {
			setHasMadeChanges(false);
			reset({
				product_corporate_expenses_enabled: product_corporate_expenses_enabled,
				product_flexible_benefits_enabled: product_flexible_benefits_enabled,
			});
		}
	}, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

	const OpenButton = () =>
		smallButton ? (
			<OptionButton
				type='button'
				data-rh='Gerenciar produtos'
				onClick={() => {
					setIsOpen(true);
				}}
			>
				<MdProductionQuantityLimits />
			</OptionButton>
		) : (
			<S.MainButton
				type='button'
				onClick={() => {
					setIsOpen(true);
				}}
			>
				Gerenciar produtos
			</S.MainButton>
		);

	function handleClose() {
		if (!hasMadeChanges) {
			setIsOpen(false);
			return;
		}

		openOptionsDialog(
			'Alterações não salvas. Tem certeza que deseja sair?',
			'Confirmar',
			() => {
				setIsOpen(false);
			},
			'Cancelar',
			() => {}
		);
	}

	function handleSubmitChanges(products: ProductsFormData) {
		if (!hasMadeChanges) {
			setIsOpen(false);
			return;
		}

		updateCompanyProductsMutation.mutate({
			...products,
		});
	}

	return (
		<>
			<OpenButton />

			<Modal isOpen={isOpen} enableClose={true} onRequestClose={handleClose}>
				<S.Container
					onSubmit={(e) => {
						e.stopPropagation();
						handleSubmit(handleSubmitChanges)(e);
					}}
				>
					<S.Title>Selecione os produtos da empresa</S.Title>
					<FormStyle.FieldSet>
						<FormStyle.Field>
							<FormStyle.Label>Produtos</FormStyle.Label>
							<S.ProductsContainer>
								<S.ProductContainer>
									<FormStyle.Label>Benefícios flexíveis</FormStyle.Label>
									<ToggleSwitch
										onChange={() => setHasMadeChanges(true)}
										name='product_flexible_benefits_enabled'
										register={register}
									/>
								</S.ProductContainer>
								<S.ProductContainer>
									<FormStyle.Label>Bounty Control</FormStyle.Label>
									<ToggleSwitch
										onChange={() => setHasMadeChanges(true)}
										name='product_corporate_expenses_enabled'
										register={register}
									/>
								</S.ProductContainer>
							</S.ProductsContainer>
						</FormStyle.Field>
					</FormStyle.FieldSet>

					<S.MainButton
						disabled={updateCompanyProductsMutation.isLoading}
						type='submit'
						style={{ width: '16rem' }}
					>
						{updateCompanyProductsMutation.isLoading ? (
							<Loader color='#FFF' size={14} />
						) : (
							'Salvar'
						)}
					</S.MainButton>
				</S.Container>
			</Modal>
		</>
	);
}
