import * as S from './styles';
import avatarImg from '../../assets/avatar.svg';

interface UserCardProps {
	name: string;
	office: string;
	dataRhName?: string;
	dataRhOffice?: string;
	avatar_url?: string;
}

export default function UserCard({
	name,
	office,
	dataRhName,
	dataRhOffice,
	avatar_url,
}: UserCardProps) {
	return (
		<S.Container>
			<S.Avatar src={avatar_url ? avatar_url : avatarImg} alt='Avatar' />

			<S.InfoContainer>
				{dataRhName ? (
					<S.Name data-rh={dataRhName}>{name}</S.Name>
				) : (
					<S.Name>{name}</S.Name>
				)}
				{dataRhOffice ? (
					<S.Office data-rh={dataRhOffice}>{office}</S.Office>
				) : (
					<S.Office>{office}</S.Office>
				)}
			</S.InfoContainer>
		</S.Container>
	);
}
