import { Company, CompanyPartner, Partner } from '../../@types';
import api from '../api';

export interface Quantitative {
	id: string;
	company_id: string;
	partner_id: string;
	registration_date: string;
	total_collaborator: number;
}

export async function getPartners() {
	const { data } = await api.get<Partner[]>('/api/v1/master/partners/lookup');

	return data;
}

interface CompanyPartnersResponse {
	company_data: {
		name: string;
		cnpj: string;
	};
	partners_params: CompanyPartner[];
}
export async function getCompanyPartners(companyId: string) {
	const { data } = await api.get<CompanyPartnersResponse>(
		`/api/v1/master/companies/${companyId}/partners`
	);

	return data;
}

export async function updatePartnerStatus(
	partnerId: string,
	newStatus: boolean
) {
	return api.put(`/api/v1/master/partners/${partnerId}/status/${newStatus}`);
}

export interface UpdateCompanyPartnerStatusRequest {
	companyId: string;
	partners_params: {
		id: string;
		status: boolean;
	}[];
}
export async function updateCompanyPartnerStatus({
	companyId,
	partners_params,
}: UpdateCompanyPartnerStatusRequest) {
	return api.put(`/api/v1/master/companies/${companyId}/partners/update`, {
		partners_params,
	});
}

interface PartnersStatsResponse {
	stats1: {
		companies_with_partners: number;
		companies_without_partners: number;
	};
	stats2: {
		company: Company;
		number_of_partners: number;
	}[];
	stats3: {
		company: Company;
		users_by_partner: {
			partner_data: {
				id: string;
				name: string;
			};
			quantity: number;
		}[];
	}[];
}

export interface PartnersStatsByMonthResponse {
	yearRegisterQuantity: {
		month: string;
		data: {
			company: {
				cnpj: string;
				id: string;
				name: string;
			};
			partners: {
				id: string;
				name: string;
				total_collaborator: number;
			}[];
		}[];
	}[];
}

export async function getPartnersStats(groupCompanyId: string) {
	const { data } = await api.get<PartnersStatsResponse>(
		`/api/v1/master/partners/stats/${!!groupCompanyId ? groupCompanyId : ''}`
	);

	return data;
}

export async function getPartnersStatsByMonth(
	corporate_group_id: string,
	date: string
) {
	const [year, month] = date.split('-');

	const endpoint = corporate_group_id
		? `/api/v1/master/partners/quantity/${corporate_group_id}/q`
		: '/api/v1/master/partners/quantity/q';

	const { data } = await api.get<PartnersStatsByMonthResponse>(endpoint, {
		params: { year, month },
	});

	return data;
}

export async function getPartnersStatsByYear(
	corporate_group_id: string,
	date: string
) {
	const [year] = date.split('-');

	const endpoint = corporate_group_id
		? `/api/v1/master/partners/quantity/${corporate_group_id}/q`
		: '/api/v1/master/partners/quantity/q';

	const { data } = await api.get<PartnersStatsByMonthResponse>(endpoint, {
		params: { year },
	});

	return data;
}

export async function getPartnerQuantitative(
	partnerId: string,
	companyId: string
) {
	const { data } = await api.get<Quantitative[]>(
		`/api/v1/master/companies/${companyId}/partners/${partnerId}`
	);

	return data;
}
