import ReactRouterPrompt from 'react-router-prompt';
import Modal from '../Modal';
import * as S from './styles';

interface PreventTransitionPromptProps {
	when: boolean;
	title?: string;
	message?: string;
}

function PreventTransitionPrompt({
	when,
	title,
	message,
}: PreventTransitionPromptProps) {
	return (
		<ReactRouterPrompt when={when}>
			{({ isActive, onConfirm, onCancel }) => (
				<Modal isOpen={isActive} enableClose={true} onRequestClose={onCancel}>
					<S.Container>
						<S.Title>{title ?? 'Confirmar navegação'}</S.Title>
						<S.Message>
							{message ??
								'As modificações serão perdidas. Tem certeza que deseja sair?'}
						</S.Message>

						<S.ActionsContainer>
							<S.MainAction onClick={onConfirm} autoFocus>
								Sim
							</S.MainAction>
							<S.SecondaryAction onClick={onCancel}>Cancelar</S.SecondaryAction>
						</S.ActionsContainer>
					</S.Container>
				</Modal>
			)}
		</ReactRouterPrompt>
	);
}

export default PreventTransitionPrompt;
