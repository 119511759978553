import { useState } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { MdOutlineMarkEmailRead } from 'react-icons/md';
import { useQuery } from 'react-query';
import { CardItemTrack, CardItemTrackEvent } from '../../@types';
import { trackBatch, trackItem } from '../../services/queries/CardsBatchs';
import { cpfMask } from '../../utils/masks';
import Loader from '../Loader';
import Modal from '../Modal';
import * as S from './styles';

interface TrackingModalProps {
	orderId: string;
	cardBatchId: string;
	cardBatchItemId?: string;
	companyId?: string;
	trackLink: string;
}
export function TrackingModal({
	orderId,
	cardBatchId,
	cardBatchItemId,
	companyId,
	trackLink,
}: TrackingModalProps) {
	const [isOpen, setIsOpen] = useState(false);

	const [trackItemsParsed, setTrackItemsParsed] = useState<
		CardItemTrackEvent[]
	>([]);

	const trackQuery = useQuery<CardItemTrack>(
		['trackItem', companyId, cardBatchId, cardBatchItemId],
		() => {
			if (cardBatchItemId) return trackItem(cardBatchId, cardBatchItemId);

			return trackBatch(companyId!, cardBatchId);
		},
		{
			onSuccess: (data) => {
				let singleTrackItems: CardItemTrackEvent[] = [];

				data.events.forEach((event) => {
					if (
						!singleTrackItems.length ||
						singleTrackItems.slice(-1)[0].tracking_status_name !==
							event.tracking_status_name
					) {
						singleTrackItems.push(event);
					}
				});

				setTrackItemsParsed(singleTrackItems);
			},
			enabled: isOpen,
			retry: false,
		}
	);

	function renderTrackContent() {
		if (trackQuery.isLoading || !trackQuery.data) {
			return (
				<div style={{ marginTop: '2rem' }}>
					<Loader />
				</div>
			);
		}

		return (
			<>
				<S.TrackingContent>
					{trackItemsParsed.map((event, index) => (
						<S.TrackContainer key={event.occurred_at}>
							<S.IconContainer lastOne={index === trackItemsParsed.length - 1}>
								<MdOutlineMarkEmailRead />
							</S.IconContainer>
							<div>
								<S.StatusMessage>{event.tracking_status_name}</S.StatusMessage>
								<S.StatusDate>
									{new Date(event.occurred_at).toLocaleDateString()}
								</S.StatusDate>
							</div>
						</S.TrackContainer>
					))}
				</S.TrackingContent>

				{trackItemsParsed.slice(-1)[0] && (
					<S.TrackInfoContainer>
						<span>Status:</span>
						<S.TrackInfo>
							{trackItemsParsed.slice(-1)[0].tracking_status_name}
						</S.TrackInfo>
					</S.TrackInfoContainer>
				)}
				{trackQuery.data.delivered_at && (
					<S.TrackInfoContainer>
						<span>Recebedor:</span>
						<S.TrackInfo>
							{trackQuery.data.receiver ?? 'Não informado'}
						</S.TrackInfo>
					</S.TrackInfoContainer>
				)}
				{trackQuery.data.delivered_at && (
					<S.TrackInfoContainer>
						<span>Documento do recebedor:</span>
						<S.TrackInfo>
							{trackQuery.data.receiver_document?.length === 11
								? cpfMask(trackQuery.data.receiver_document)
								: trackQuery.data.receiver_document ?? 'Não informado'}
						</S.TrackInfo>
					</S.TrackInfoContainer>
				)}
				{trackQuery.data.delivered_at && (
					<S.TrackInfoContainer>
						<span>Data do recebimento:</span>
						<S.TrackInfo>
							{new Date(trackQuery.data.delivered_at).toLocaleDateString()}
						</S.TrackInfo>
					</S.TrackInfoContainer>
				)}
			</>
		);
	}

	function renderErrorMessage() {
		return (
			<S.TrackingContent>
				<h3>
					{cardBatchItemId
						? 'Ainda não há dados de rastreamento sobre esse item. Consulte novamente depois.'
						: 'Ainda não há dados de rastreamento sobre essa remessa. Consulte novamente depois.'}
				</h3>
			</S.TrackingContent>
		);
	}

	return (
		<>
			<S.OpenBtn
				onClick={() => {
					setIsOpen(true);
				}}
			>
				<FaMapMarkerAlt />
			</S.OpenBtn>

			<Modal
				isOpen={isOpen}
				onRequestClose={() => setIsOpen(false)}
				enableClose
			>
				<S.Container>
					<S.Title>Rastreamento do item {orderId}</S.Title>

					{!trackQuery.isError ? renderTrackContent() : renderErrorMessage()}

					<S.ExternalLink
						href={trackLink}
						rel='noreferrer noopener'
						target='_blank'
					>
						Ver no Mobi
					</S.ExternalLink>
				</S.Container>
			</Modal>
		</>
	);
}
