import {
	FaCheck,
	FaExclamationCircle,
	FaQuestionCircle,
	FaRegClock,
	FaTimes,
} from 'react-icons/fa';

export function parseBillingStatusString(status: string) {
	if (status === 'paid') return 'PAGO';

	if (status === 'created') return 'EMITIDO';

	if (status === 'processing_payment') return 'PROCESSANDO PAGAMENTO';

	if (status === 'error') return 'ERRO NA CRIAÇÃO DO BOLETO';

	if (status == 'processing') return 'PROCESSANDO';

	if (status === 'initiated' || status === 'enqueued') return 'GERANDO BOLETO';

	if (status === 'overdue') return 'EXPIRADO';

	return 'GERANDO BOLETO';
}

export function getBillingStatusIcon(status: string) {
	if (status === 'paid') return <FaCheck color='#1CCA0D' />;

	if (status === 'created') return <FaTimes color='#FB1313' />;

	if (status === 'processing_payment') return <FaRegClock color='#C6C6C6' />;

	if (status === 'error') return <FaExclamationCircle color='#FB1313' />;

	// if (
	// 	status === 'initiated' ||
	// 	status === 'enqueued' ||
	// 	status === 'processing'
	// )
	return <FaRegClock color='#C6C6C6' />;
}
