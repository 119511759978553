import {
	Document,
	Page,
	Text,
	View,
	Font,
	Image,
	pdf,
} from '@react-pdf/renderer';
import * as S from '../styles';
import Loader from '../../../../components/Loader';
import { useQuery } from 'react-query';
import { FilterParams } from '../../../../components/Filter';
import {
	getTransfersV2ToReport,
	TransfersListReturn,
} from '../../../../services/queries/Tranfers';
import { toast } from 'react-toastify';
import {
	parseTransferCategoryToReport,
	parseTransferTypeToText,
} from '../utils/parseTransferData';
import { convertCentsToReais } from '../../../../utils/CurrencyConvert';
import { transfersReportStyle } from './styles';
import BountyLogo from '../../../../assets/reports/FlexibleBenefitsLogo.png';
import ReportIcon from '../../../../assets/reports/ReportIcon.png';

Font.register({
	family: 'Poppins',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/hlvAxH6aIdOjWlLzgm0jqg.ttf',
		},
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/4WGKlFyjcmCFVl8pRsgZ9vesZW2xOQ-xsNqO47m55DA.ttf',
			fontWeight: 500,
		},
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/-zOABrCWORC3lyDh-ajNnPesZW2xOQ-xsNqO47m55DA.ttf',
			fontWeight: 600,
		},
	],
});

interface ReportProps {
	filterParams: FilterParams[];
}

export function ReportGenerator({ filterParams }: ReportProps) {
	const fetchTransfersQuery = useQuery<TransfersListReturn>(
		['transfersListToReport', filterParams],
		() => {
			return getTransfersV2ToReport(filterParams);
		},
		{
			enabled: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);

	async function handleGeneratePDF() {
		try {
			const PDFGenerated = await generatePDFContent();
			const blob = await pdf(PDFGenerated).toBlob();

			// Create a temporary URL for the blob
			const url = URL.createObjectURL(blob);

			// Create a link element
			const link = document.createElement('a');
			link.href = url;
			link.download = 'relatorio_de_transferencias.pdf';

			// Simulate a click event to trigger the download
			link.dispatchEvent(new MouseEvent('click'));

			// Clean up the temporary URL
			URL.revokeObjectURL(url);
		} catch (error) {
			toast.error(
				'Ocorreu um erro ao tentar gerar o arquivo de relátorio. Tente novamente'
			);
		}
	}

	function getTotalValues(entrances: number, exits: number) {
		if (filterParams.find((f) => f.value)) {
			const filterType = filterParams.find((f) => f.filter === 'type');

			if (filterType?.selected) {
				if (filterType.value === 'entrance') {
					return (
						<View style={{ ...transfersReportStyle.subheaderInfo }}>
							<Text style={transfersReportStyle.subheaderLabel}>
								Valor total de entradas:
							</Text>
							<Text style={transfersReportStyle.subheaderData}>
								{convertCentsToReais(Number(entrances))}
							</Text>
						</View>
					);
				} else {
					return (
						<View style={{ ...transfersReportStyle.subheaderInfo }}>
							<Text style={transfersReportStyle.subheaderLabel}>
								Valor total de saídas:
							</Text>
							<Text style={transfersReportStyle.subheaderData}>
								{convertCentsToReais(Number(exits))}
							</Text>
						</View>
					);
				}
			} else {
				return (
					<>
						<View style={{ ...transfersReportStyle.subheaderInfo }}>
							<Text style={transfersReportStyle.subheaderLabel}>
								Valor total de entradas:
							</Text>
							<Text style={transfersReportStyle.subheaderData}>
								{convertCentsToReais(Number(entrances))}
							</Text>
						</View>

						<View style={{ ...transfersReportStyle.subheaderInfo }}>
							<Text style={transfersReportStyle.subheaderLabel}>
								Valor total de saídas:
							</Text>
							<Text style={transfersReportStyle.subheaderData}>
								{convertCentsToReais(Number(exits))}
							</Text>
						</View>
					</>
				);
			}
		}
	}

	async function generatePDFContent() {
		const reportFetching = await fetchTransfersQuery.refetch();
		const {
			transfers,
			totalTransfers,
			entrances_totalValue,
			exits_totalValue,
			totalReports,
		} = reportFetching.data!;

		let dateMessage = 'Todo o período';

		const dateFilter = filterParams.find(
			(filter) => filter.name === 'Data' && filter.selected
		);

		if (dateFilter) {
			const formattedStartDate = Intl.DateTimeFormat('pt-BR', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			}).format(new Date(dateFilter.value.replace(/-/g, '/')));

			const formattedEndDate = Intl.DateTimeFormat('pt-BR', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			}).format(new Date(dateFilter.second_value!.replace(/-/g, '/')));

			dateMessage = `${formattedStartDate} - ${formattedEndDate}`;
		}

		return (
			<Document>
				<Page
					size='A4'
					style={transfersReportStyle.page}
					orientation='landscape'
				>
					<View style={transfersReportStyle.header} fixed>
						<Image src={BountyLogo} style={transfersReportStyle.logo} />
						<View style={transfersReportStyle.headerInfo}>
							<Text style={transfersReportStyle.headerInfoLabel}>
								N° Página:
							</Text>
							<Text
								style={transfersReportStyle.headerInfoData}
								render={({ pageNumber, totalPages }) =>
									`${String(pageNumber).padStart(2, '0')}/${String(
										totalPages
									).padStart(2, '0')}`
								}
							/>
						</View>
						<View style={transfersReportStyle.headerInfo}>
							<Text style={transfersReportStyle.headerInfoLabel}>
								Data do pedido:
							</Text>
							<Text style={transfersReportStyle.headerInfoData}>
								{Intl.DateTimeFormat('pt-BR', {
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
									hour12: false, // 24-hour format
								}).format(new Date())}
							</Text>
						</View>
						<View style={{ ...transfersReportStyle.headerInfo, width: '120%' }}>
							<Text style={transfersReportStyle.headerInfoLabel}>
								Intervalo de busca:
							</Text>
							<Text style={transfersReportStyle.headerInfoData}>
								{dateMessage}
							</Text>
						</View>
						<View style={{ ...transfersReportStyle.headerInfo, width: '60%' }}>
							<Text style={transfersReportStyle.headerInfoLabel}>
								N° do pedido:
							</Text>
							<Text style={transfersReportStyle.headerInfoData}>
								{String(totalReports).padStart(7, '0')}
							</Text>
						</View>
					</View>

					<View style={transfersReportStyle.subHeader}>
						<View style={transfersReportStyle.titleContainer}>
							<Image src={ReportIcon} style={transfersReportStyle.reportLogo} />
							<View style={transfersReportStyle.reportTitleContainer}>
								<Text
									style={{
										...transfersReportStyle.reportTitle,
										color: '#FF0037',
									}}
								>
									Relatório de
								</Text>

								<Text> </Text>

								<Text style={transfersReportStyle.reportTitle}>
									Transferências
								</Text>
							</View>
						</View>

						<View style={transfersReportStyle.subheaderDataContainer}>
							{getTotalValues(entrances_totalValue, exits_totalValue)}

							<View style={{ ...transfersReportStyle.subheaderInfo }}>
								<Text style={transfersReportStyle.subheaderLabel}>
									Total de registros:
								</Text>
								<Text style={transfersReportStyle.subheaderData}>
									{totalTransfers}
								</Text>
							</View>
						</View>
					</View>

					<View style={transfersReportStyle.tableContainer}>
						<View style={transfersReportStyle.tableHeader}>
							<View
								style={{
									...transfersReportStyle.columnTitleContainer,
									width: '50%',
								}}
							>
								<Text style={transfersReportStyle.columnTitle}>Tipo</Text>
							</View>

							<View
								style={{
									...transfersReportStyle.columnTitleContainer,
									width: '60%',
								}}
							>
								<Text style={transfersReportStyle.columnTitle}>Data</Text>
							</View>

							<View style={transfersReportStyle.columnTitleContainer}>
								<Text style={transfersReportStyle.columnTitle}>Categoria</Text>
							</View>

							<View style={transfersReportStyle.columnTitleContainer}>
								<Text style={transfersReportStyle.columnTitle}>Produto</Text>
							</View>

							<View
								style={{
									...transfersReportStyle.columnTitleContainer,
									width: '180%',
								}}
							>
								<Text style={transfersReportStyle.columnTitle}>Empresa</Text>
							</View>

							<View
								style={{
									...transfersReportStyle.columnTitleContainer,
									width: '70%',
								}}
							>
								<Text style={transfersReportStyle.columnTitle}>Valor</Text>
							</View>
						</View>

						{transfers.map((transfer) => (
							<View
								style={transfersReportStyle.tableRow}
								key={transfer.id}
								wrap={false}
							>
								<Text
									style={{
										...transfersReportStyle.columnData,
										width: '50%',
									}}
								>
									{parseTransferTypeToText(transfer.type)}
								</Text>

								<Text
									style={{
										...transfersReportStyle.columnData,
										width: '60%',
									}}
								>
									{new Date(transfer.created_at).toLocaleDateString()}
								</Text>

								<Text style={transfersReportStyle.columnData}>
									{parseTransferCategoryToReport(transfer.category)}
								</Text>

								<Text
									style={{
										...transfersReportStyle.columnData,
										color:
											transfer.origin === 'corpway' ? '#27ADFF' : '#FF0037',
									}}
								>
									{transfer.origin === 'corpway'
										? 'Bounty Control'
										: 'Benefícios Flexíveis'}
								</Text>

								<Text
									style={{ ...transfersReportStyle.columnData, width: '180%' }}
								>
									{transfer.company?.name ?? 'N/A'}
								</Text>

								<Text
									style={{
										...transfersReportStyle.columnData,
										fontWeight: 600,
										width: '70%',
									}}
								>
									{convertCentsToReais(Number(transfer.amount))}
								</Text>
							</View>
						))}
					</View>
				</Page>
			</Document>
		);
	}

	return (
		<>
			<S.ReportButton onClick={handleGeneratePDF}>
				{fetchTransfersQuery.isLoading ? (
					<Loader color='#fff' size={15} />
				) : (
					'Gerar relatório'
				)}
			</S.ReportButton>
		</>
	);
}
