import { Transfer } from '../../../../@types';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import * as S from '../styles';

export function parseTransferType(type: Transfer['type']) {
	if (type === 'exit') {
		return (
			<S.StatusContainer>
				<BsArrowLeft color='#FB1313' />
				Saída
			</S.StatusContainer>
		);
	}

	if (type === 'entrance') {
		return (
			<S.StatusContainer>
				<BsArrowRight color='#1CCA0D' />
				Entrada
			</S.StatusContainer>
		);
	}
}

export function parseTransferTypeToText(type: Transfer['type']) {
	if (type === 'exit') {
		return 'Saída';
	}

	if (type === 'entrance') {
		return 'Entrada';
	}

	return '????';
}

export function parseTransferCategory(category: Transfer['category']) {
	switch (category) {
		case 'company_chargeback':
			return 'Estorno de empresa';
		case 'balance_recharge_payment':
			return 'Envio de recarga';
		case 'balance_recharge_receipt':
			return 'Recebimento de recarga';
	}
}

export function parseTransferCategoryToReport(category: Transfer['category']) {
	switch (category) {
		case 'company_chargeback':
			return 'Est. de empresa';
		case 'balance_recharge_payment':
			return 'Envio de recarga';
		case 'balance_recharge_receipt':
			return 'Receb. de recarga';
	}
}

export function getTransferDetailsLink(
	category: Transfer['category'],
	resource_id?: string
) {
	switch (category) {
		case 'company_chargeback':
			return `/chargebacks/${resource_id}`;
		case 'balance_recharge_payment':
			return null;
		case 'balance_recharge_receipt':
			return null;
	}
}
