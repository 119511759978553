import { CorpwayCard } from '../../@types';
import api from '../api';

interface CorpwayCardsQuantitativeResponse {
	companyCardsQuantitatives: {
		company_id: string;
		company_name: string;
		cardCount: string;
	}[];
}
export async function getCorpwayCardsQuantitative() {
	const { data } = await api.get<CorpwayCardsQuantitativeResponse>(
		`api/v1/master/corpway-cards`
	);

	return {
		...data,
	};
}

interface CompanyCorpwayCardsResponse {
	cards: CorpwayCard[];
}
export async function getCompanyCorpwayCards(companyId: string) {
	const { data } = await api.get<CompanyCorpwayCardsResponse>(
		`api/v1/master/company/${companyId}/corpway-cards`
	);
	return data;
}
