import { useState } from 'react';
import { EmptyContent } from '../../../components/EmptyContent';
import Loader from '../../../components/Loader';
import PageTitle from '../../../components/PageTitle';
import * as TableStyle from '../../../components/Table/TableStyles';
import * as S from './styles';
import { GroupCompanyCreationModal } from './GroupCompanyCreationModal';
import { MdNotes } from 'react-icons/md';
import { Pagination } from '../../../components/Pagination';
import { Filter } from '../../../components/Filter';
import { useQuery, useQueryClient } from 'react-query';
import {
	getFilteredGroupCompanies,
	getGroupsCompany,
	GroupCompanyFilterReturn,
	GroupCompanyListReturn,
} from '../../../services/queries/GroupCompany';
import { useAuth } from '../../../hooks/useAuth';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { useSortColumnHook } from '../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../components/SortColumnButton';
import { useGroupCompaniesListStore } from '../../../stores/useGroupCompaniesListStore';
import { useSetHistoryNonMatchCallbackLocation } from '../../../hooks/useSetHistoryNonMatchCallbackLocation';
import { ManageOperatorsAccessModal } from './ManageOperatorsAccessModal';

export function GroupCompanies() {
	const queryClient = useQueryClient();
	const { updateMasterBalance } = useAuth();
	const [groupCompaniesCurrentPage, setGroupCompaniesCurrentPage] = useState(1);
	const { currentSortColumn, toggleSort } = useSortColumnHook();

	const [filtersParams, setFiltersParams, resetFilters] =
		useGroupCompaniesListStore((state) => [
			state.filtersParams,
			state.setFiltersParams,
			state.resetFilters,
		]);

	useSetHistoryNonMatchCallbackLocation('group-companies', resetFilters);

	const getGroupsCompanyQuery = useQuery<GroupCompanyListReturn, Error>(
		['groupCompanyList', groupCompaniesCurrentPage, currentSortColumn],
		() => {
			return getGroupsCompany(groupCompaniesCurrentPage, currentSortColumn);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um erro ao buscar os grupos corporativos. '
				);
			},
			keepPreviousData: true,
		}
	);

	const filterGroupCompany = useQuery<GroupCompanyFilterReturn, Error>(
		[
			'filteredGroupsCompany',
			filtersParams,
			groupCompaniesCurrentPage,
			currentSortColumn,
		],
		() => {
			return getFilteredGroupCompanies(
				filtersParams,
				groupCompaniesCurrentPage,
				currentSortColumn
			);
		},
		{
			onError: (error) => {
				showErrorMessage(error as Error, 'Não foi possível buscar os grupos. ');
			},
			enabled:
				!!filtersParams.find((f) => !!f.value) && !!groupCompaniesCurrentPage,
		}
	);

	function getTableTotalRecords() {
		if (filtersParams.find((f) => !!f.value)) {
			return filterGroupCompany.data?.totalCorpGroups;
		}
		return getGroupsCompanyQuery.data?.totalCorporateGroup;
	}

	function handleGroupCompanyCreated() {
		queryClient.resetQueries('groupCompanyList');
		queryClient.resetQueries('filteredGroupsCompany');
	}

	function refreshPage() {
		setGroupCompaniesCurrentPage(1);
		queryClient.resetQueries('groupCompanyList');
		queryClient.resetQueries('filteredGroupsCompany');
		updateMasterBalance.refetch();
	}

	function getGroupsCompaniesList() {
		return !!filtersParams.find((f) => !!f.value)
			? filterGroupCompany.data?.corpGroups
			: getGroupsCompanyQuery.data?.corporationGroup;
	}

	if (
		getGroupsCompanyQuery.isLoading ||
		filterGroupCompany.isLoading ||
		getGroupsCompanyQuery.isPreviousData
	) {
		return (
			<S.Container>
				<PageTitle title='Grupos corporativos' />
				<Loader />
			</S.Container>
		);
	}
	if (!getGroupsCompanyQuery.data?.corporationGroup.length) {
		return (
			<S.Container>
				<TableStyle.TableHeaderContainer>
					<PageTitle title='Grupos corporativos' />
					<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
				</TableStyle.TableHeaderContainer>

				<EmptyContent text='Nenhum grupo corporativo cadastrado' />
				<GroupCompanyCreationModal
					onGroupCompanyCreate={handleGroupCompanyCreated}
				/>
			</S.Container>
		);
	}
	return (
		<S.Container>
			<TableStyle.TableHeaderContainer>
				<TableStyle.TitleWrapper>
					<PageTitle
						title='Grupos corporativos'
						totalRecords={getTableTotalRecords() ?? 0}
					/>
					<Filter
						filterParams={filtersParams}
						onFiltersChanged={(updatedFilters) => {
							setGroupCompaniesCurrentPage(1);
							setFiltersParams(updatedFilters);
						}}
					/>
				</TableStyle.TitleWrapper>
				{!filtersParams.find((f) => !!f.value) ? (
					<Pagination
						onPageChange={(page) => setGroupCompaniesCurrentPage(page)}
						currentPage={groupCompaniesCurrentPage}
						totalCount={
							getGroupsCompanyQuery.data
								? getGroupsCompanyQuery.data.totalCorporateGroup
								: 1
						}
					/>
				) : (
					<Pagination
						onPageChange={(page) => setGroupCompaniesCurrentPage(page)}
						currentPage={groupCompaniesCurrentPage}
						totalCount={
							filterGroupCompany.data
								? filterGroupCompany.data.totalCorpGroups
								: 1
						}
					/>
				)}
				<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
			</TableStyle.TableHeaderContainer>

			<TableStyle.Table>
				<TableStyle.TableHeader>
					<TableStyle.TableRow>
						<TableStyle.TableHeaderCell>
							<SortColumnButton
								order={
									currentSortColumn?.name === 'name'
										? currentSortColumn.order
										: null
								}
								onToggleSort={() => toggleSort('name')}
							/>
							NOME
						</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>
							<SortColumnButton
								order={
									currentSortColumn?.name === 'companies_quantity'
										? currentSortColumn.order
										: null
								}
								onToggleSort={() => toggleSort('companies_quantity')}
							/>
							Nº DE EMPRESAS
						</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>OPÇÕES</TableStyle.TableHeaderCell>
					</TableStyle.TableRow>
				</TableStyle.TableHeader>

				<TableStyle.TableBody>
					{getGroupsCompaniesList()?.map((groupCompany) => (
						<TableStyle.TableRow key={groupCompany.id}>
							<TableStyle.TableData style={{ maxWidth: '24rem' }}>
								{groupCompany.name}
							</TableStyle.TableData>
							<TableStyle.TableData style={{ maxWidth: '24rem' }}>
								{groupCompany.companiesQuantity}
							</TableStyle.TableData>
							<TableStyle.TableData>
								<TableStyle.OptionsContainer>
									<TableStyle.OptionLink
										data-rh='Detalhar empresas'
										to={`/group-companies/${groupCompany.id}`}
									>
										<MdNotes />
									</TableStyle.OptionLink>

									<ManageOperatorsAccessModal groupCompany={groupCompany} />
								</TableStyle.OptionsContainer>
							</TableStyle.TableData>
						</TableStyle.TableRow>
					))}
				</TableStyle.TableBody>
			</TableStyle.Table>

			<GroupCompanyCreationModal
				onGroupCompanyCreate={handleGroupCompanyCreated}
			/>
		</S.Container>
	);
}
