import { useQuery, useQueryClient } from 'react-query';
import { useSortColumnHook } from '../../../hooks/useSortColumnHook';
import { useState } from 'react';
import { Filter } from '../../../components/Filter';
import {
	BillingListReturn,
	getBilling,
	getFilteredBilling,
} from '../../../services/queries/Billing';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { Billing } from '../../../@types';
import * as TableStyle from '../../../components/Table/TableStyles';
import { parseDate } from '../../../utils/parseDate';
import { convertCentsToReais } from '../../../utils/CurrencyConvert';
import PageTitle from '../../../components/PageTitle';
import { EmptyContent } from '../../../components/EmptyContent';
import * as S from './styles';
import Loader from '../../../components/Loader';
import { Pagination } from '../../../components/Pagination';
import { SortColumnButton } from '../../../components/SortColumnButton';
import { useSetHistoryNonMatchCallbackLocation } from '../../../hooks/useSetHistoryNonMatchCallbackLocation';
import { useBillingListStore } from '../../../stores/useBillingListStore';
import { ReportGeneratorBillingPDF } from './ReportGeneratorPDF';
import { ReportGeneratorBillingExcel } from './ReportGeneratorExcel';
import { FaCheck, FaTimes } from 'react-icons/fa';
import CompanyCard from '../../../components/CompanyCard';
import { ProductOriginLabel } from '../../../components/ProductOriginLabel';
import {
	getBillingStatusIcon,
	parseBillingStatusString,
} from '../../../utils/parseBillingStatus';

export function Billings() {
	const queryClient = useQueryClient();
	const { currentSortColumn, toggleSort } = useSortColumnHook();
	const [billingsCurrentPage, setBillingsCurrentPage] = useState(1);
	const [showingTable, setShowingTable] = useState<string>();

	const [filtersParams, setFiltersParams, resetFilters] = useBillingListStore(
		(state) => [state.filtersParams, state.setFiltersParams, state.resetFilters]
	);

	useSetHistoryNonMatchCallbackLocation('billings', resetFilters);

	const fetchBillingQuery = useQuery<BillingListReturn, Error>(
		['billingList', billingsCurrentPage, currentSortColumn],
		() => {
			return getBilling(billingsCurrentPage, currentSortColumn);
		},
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar os boletos. ');
			},
			keepPreviousData: true,
		}
	);

	const filteredBillingsQuery = useQuery<BillingListReturn, Error>(
		['filteredBilling', filtersParams, billingsCurrentPage, currentSortColumn],
		() => {
			return getFilteredBilling(
				filtersParams,
				billingsCurrentPage,
				currentSortColumn
			);
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar os boletos. '
				);
			},
			enabled: !!filtersParams.find((f) => !!f.value) && !!billingsCurrentPage,
		}
	);

	function getTableTotalRecords() {
		if (filtersParams.find((f) => !!f.value)) {
			return filteredBillingsQuery.data?.totalBillings;
		}
		return fetchBillingQuery.data?.totalBillings;
	}

	function refreshPage() {
		setBillingsCurrentPage(1);
		queryClient.resetQueries('billingList');
		queryClient.resetQueries('filteredBilling');
	}

	function generateRow(billing: Billing) {
		return (
			<TableStyle.TableRow key={billing.id}>
				<TableStyle.TableData style={{ minWidth: '12rem' }}>
					{parseBillingStatusString(billing.status)}
					<span style={{ marginRight: '5px' }} />
					{getBillingStatusIcon(billing.status)}
				</TableStyle.TableData>
				<TableStyle.TableData style={{ minWidth: '12rem' }}>
					{billing.company ? (
						<CompanyCard
							company_name={billing.company.name}
							cnpj={billing.company.cnpj}
							avatar_url={billing.company.avatar_url}
						/>
					) : (
						'N/A'
					)}
				</TableStyle.TableData>
				<TableStyle.TableData
					style={{ minWidth: '12rem', textAlign: 'center' }}
				>
					<ProductOriginLabel origin={billing.origin} />
				</TableStyle.TableData>
				<TableStyle.TableData
					style={{ minWidth: '12rem', textAlign: 'center' }}
				>
					<S.FormattedDate>{parseDate(billing.created_at, 1)}</S.FormattedDate>
				</TableStyle.TableData>
				<TableStyle.TableData
					style={{ minWidth: '12rem', textAlign: 'center' }}
				>
					<S.FormattedDate>
						{billing.payment_date
							? parseDate(billing.payment_date, 1)
							: 'Data não disponível'}
					</S.FormattedDate>
				</TableStyle.TableData>
				<TableStyle.TableData
					style={{ minWidth: '12rem', textAlign: 'center' }}
				>
					{convertCentsToReais(Number(billing.amount))}
				</TableStyle.TableData>
				<TableStyle.TableData
					style={{ minWidth: '12rem', textAlign: 'center' }}
				>
					{convertCentsToReais(Number(billing.amount_paid))}
				</TableStyle.TableData>
				<TableStyle.TableData
					style={{ minWidth: '12rem', textAlign: 'center' }}
				>
					{billing.company?.id_baas}
				</TableStyle.TableData>
			</TableStyle.TableRow>
		);
	}

	function getTable(tableName: string, billings?: Billing[]) {
		if (!billings?.length) {
			return (
				<>
					<TableStyle.TableHeaderContainer>
						<TableStyle.TitleWrapper>
							<PageTitle
								title={tableName}
								totalRecords={getTableTotalRecords() ?? 0}
							/>
							<Filter
								filterParams={filtersParams}
								onFiltersChanged={(updatedFilters) => {
									setBillingsCurrentPage(1);
									setFiltersParams(updatedFilters);
								}}
							/>
						</TableStyle.TitleWrapper>
						<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
					</TableStyle.TableHeaderContainer>

					<EmptyContent text='Não há boletos.' />
				</>
			);
		}

		return (
			<>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle
							title='Boletos'
							totalRecords={getTableTotalRecords() ?? 0}
						/>
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								setBillingsCurrentPage(1);
								setFiltersParams(updatedFilters);
							}}
						/>
						{showingTable === tableName ? (
							<TableStyle.SeeLess
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver menos
							</TableStyle.SeeLess>
						) : (
							<TableStyle.SeeMore
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver mais
							</TableStyle.SeeMore>
						)}
					</TableStyle.TitleWrapper>
					{showingTable === tableName ? (
						tableName === 'Boletos' ? (
							filtersParams.find((f) => !!f.value) ? (
								<Pagination
									onPageChange={(page) => setBillingsCurrentPage(page)}
									currentPage={billingsCurrentPage}
									totalCount={
										filteredBillingsQuery.data
											? filteredBillingsQuery.data.totalBillings
											: 1
									}
								/>
							) : (
								<Pagination
									onPageChange={(page) => setBillingsCurrentPage(page)}
									currentPage={billingsCurrentPage}
									totalCount={
										fetchBillingQuery.data
											? fetchBillingQuery.data.totalBillings
											: 1
									}
								/>
							)
						) : (
							<></>
						)
					) : (
						<></>
					)}
					<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
				</TableStyle.TableHeaderContainer>

				<TableStyle.Table>
					<TableStyle.TableHeader>
						<TableStyle.TableRow>
							<TableStyle.TableHeaderCell
								style={{ minWidth: '13rem', textAlign: 'center' }}
							>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'status'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('status')}
								/>
								STATUS
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'company'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('company')}
								/>
								EMPRESA
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'origin'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('origin')}
								/>
								PRODUTO
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'created_at'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('created_at')}
								/>
								DATA DE CRIAÇÃO
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'payment_date'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('payment_date')}
								/>
								DATA DO PAGAMENTO
							</TableStyle.TableHeaderCell>

							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'amount'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('amount')}
								/>
								VALOR
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'amount_paid'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('amount_paid')}
								/>
								VALOR PAGO
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'id_baas'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('id_baas')}
								/>
								ID
							</TableStyle.TableHeaderCell>
						</TableStyle.TableRow>
					</TableStyle.TableHeader>

					<TableStyle.TableBody>
						{showingTable === tableName
							? billings.map((billing) => generateRow(billing))
							: generateRow(billings[0])}
					</TableStyle.TableBody>
				</TableStyle.Table>
				{billings.length < 1 && (
					<EmptyContent text='Não há ítens a serem exibidos.' />
				)}
			</>
		);
	}

	if (
		fetchBillingQuery.isLoading ||
		filteredBillingsQuery.isLoading ||
		fetchBillingQuery.isPreviousData
	) {
		return (
			<S.Container>
				<PageTitle title='Boletos' />
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			{getTable(
				'Boletos',
				filtersParams.find((f) => !!f.value)
					? filteredBillingsQuery.data?.billings
					: fetchBillingQuery.data?.billings
			)}

			<S.ButtonsContainer>
				{filtersParams.find((f) => f.value) &&
					!!fetchBillingQuery.data?.billings.length && (
						<ReportGeneratorBillingPDF filterParams={filtersParams} />
					)}
				{filtersParams.find((f) => f.value) &&
					!!fetchBillingQuery.data?.billings.length && (
						<ReportGeneratorBillingExcel filterParams={filtersParams} />
					)}
			</S.ButtonsContainer>

			<S.StatsCaptionContainer>
				<span>
					<b>1-Pago</b>: A compensação foi feita e o valor foi creditado na
					conta do Bounty. Confirme se o valor foi retornado para a empresa.
				</span>
				<span>
					<b>2-Emitido</b>: O boleto foi gerado e aguarda confirmação do
					pagamento feito pela empresa.
				</span>
				<span>
					<b>3-Processando pagamento</b>: O pagamento do boleto está sendo
					processado pelo órgão financeiro responsável.
				</span>
				<span>
					<b>4-Gerando boleto</b>: O boleto está sendo gerado pelo SWAP, em
					breve estará disponível para a empresa.
				</span>
				<span>
					<b>5-Erro na criação do boleto</b>: No processo de geração do boleto
					houve um erro, contate o time técnico.
				</span>
				<span>
					<b>6-Expirado</b>: O prazo para pagamento do boleto expirou.
				</span>
				<span>
					<b>7-Processando</b>: O boleto está em processo de geração pelo
					sistema.
				</span>
			</S.StatsCaptionContainer>
		</S.Container>
	);
}
