import { useEffect, useRef, useState } from 'react';
import Loader from '../../../../../../components/Loader';
import Modal from '../../../../../../components/Modal';
import * as S from '../styles';
import PageTitle from '../../../../../../components/PageTitle';
import { Pagination } from '../../../../../../components/Pagination';
import * as TableStyle from '../../../../../../components/Table/TableStyles';
import { Filter } from '../../../../../../components/Filter';
import { EmptyContent } from '../../../../../../components/EmptyContent';
import { useDialogModal } from '../../../../../../hooks/useDialogModal';
import { useQuery } from 'react-query';
import { getAllUsers } from '../../../../../../services/queries/Users';
import { showErrorMessage } from '../../../../../../utils/ErrorHandler';
import { useAllUsersListStore } from '../../../../../../stores/useAllUsersListStore';
import avatarImg from '../../../../../../assets/avatar.svg';
import { RiAddCircleLine, RiCloseCircleLine } from 'react-icons/ri';

export interface UserToSelect {
	id: string;
	type: 'collaborator' | 'operator';
	email: string;
	name: string;
	cpf: string;
	avatar: string;
}

export interface UsersSelectorProps {
	alreadyAddedUsers: UserToSelect[];
	onAddAndRemoveUsers(
		usersToAdd: UserToSelect[],
		usersToRemove: UserToSelect[]
	): void;
	allowRemoveUser?: boolean;
	buttonText?: string;
}

export function UsersSelector({
	alreadyAddedUsers,
	onAddAndRemoveUsers,
	allowRemoveUser,
	buttonText,
}: UsersSelectorProps) {
	const [selectedUsers, setSelectedUsers] = useState<UserToSelect[]>([]);
	const [selectedUsersToRemove, setSelectedUsersToRemove] = useState<
		UserToSelect[]
	>([]);

	const [open, setOpen] = useState(false);
	const [hasMadeChanges, setHasMadeChanges] = useState(false);
	const [usersCurrentPage, setUsersCurrentPage] = useState(1);
	const [filtersParams, setFiltersParams] = useAllUsersListStore((state) => [
		state.filtersParams,
		state.setFiltersParams,
		state.resetFilters,
	]);

	const listContainer = useRef<HTMLUListElement | null>(null);

	const { openOptionsDialog } = useDialogModal();

	useEffect(() => {
		listContainer.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [usersCurrentPage, listContainer]);

	useEffect(() => {
		if (open) {
			setSelectedUsers([]);
			setSelectedUsersToRemove([]);
			setUsersCurrentPage(1);
			setHasMadeChanges(false);
		}
	}, [open]);

	const allUsersQuery = useQuery(
		['allUsersQuery', usersCurrentPage, filtersParams],
		() => {
			return getAllUsers(filtersParams, usersCurrentPage);
		},
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar os usuários. ');
			},
			enabled: open,
			keepPreviousData: true,
		}
	);

	function handleSubmit() {
		onAddAndRemoveUsers(selectedUsers, selectedUsersToRemove);

		setOpen(false);
	}

	function handleClose() {
		if (!hasMadeChanges) {
			setOpen(false);
			return;
		}

		openOptionsDialog(
			'Alterações não salvas. Tem certeza que deseja sair?',
			'Confirmar',
			() => setOpen(false),
			'Cancelar',
			() => {}
		);
	}

	function isUserAlreadyAdded(id: string) {
		return !!alreadyAddedUsers.find((userAdded) => userAdded.id === id);
	}

	function isUserSelected(id: string) {
		return !!selectedUsers.find((c) => c.id === id);
	}

	function isUserSelectedToRemove(id: string) {
		return !!selectedUsersToRemove.find((c) => c.id === id);
	}

	function handleSelectUser(user: UserToSelect) {
		setHasMadeChanges(true);
		setSelectedUsers([...selectedUsers, user]);
	}

	function handleDeselectUser(user: UserToSelect) {
		setHasMadeChanges(true);
		setSelectedUsers(selectedUsers.filter((c) => c.id !== user.id));
	}

	function handleSelectUserToRemove(user: UserToSelect) {
		setHasMadeChanges(true);
		setSelectedUsersToRemove([...selectedUsersToRemove, user]);
	}

	function handleDeselectUserToRemove(userToDeselect: UserToSelect) {
		setHasMadeChanges(true);
		setSelectedUsersToRemove(
			selectedUsersToRemove.filter((user) => user.id !== userToDeselect.id)
		);
	}

	function handleSelector(user: UserToSelect) {
		//Non-selected to add
		if (!isUserAlreadyAdded(user.id) && !isUserSelected(user.id)) {
			return (
				<S.Selector
					selected={isUserSelected(user.id)}
					onClick={() => handleSelectUser(user)}
					data-testid={`${user.id}-select`}
				>
					<RiAddCircleLine />
				</S.Selector>
			);
		}

		//Already added non-selected to remove
		if (isUserAlreadyAdded(user.id) && !isUserSelectedToRemove(user.id!)) {
			return (
				<S.Selector
					selected={false}
					remove={true}
					disabled={allowRemoveUser ? false : true}
					title={allowRemoveUser ? '' : 'Usuário já adicionado'}
					onClick={() => handleSelectUserToRemove(user)}
					data-testid={`${user.id}-remove`}
				>
					<RiCloseCircleLine />
				</S.Selector>
			);
		}

		//Already added and selected to remove
		if (isUserSelectedToRemove(user.id!)) {
			return (
				<S.Selector
					selected={true}
					onClick={() => handleDeselectUserToRemove(user)}
				>
					<RiAddCircleLine />
				</S.Selector>
			);
		}

		//Selected to add
		return (
			<S.Selector
				selected={isUserSelected(user.id!)}
				onClick={() => handleDeselectUser(user)}
				data-testid={`${user.id}-unselect`}
			>
				<RiCloseCircleLine />
			</S.Selector>
		);
	}

	if (allUsersQuery.isLoading) {
		return (
			<>
				<S.MainButton onClick={() => setOpen(true)}>
					{buttonText ?? 'Adicionar usuários'}
				</S.MainButton>
				<Modal isOpen={open} enableClose onRequestClose={handleClose}>
					<S.Container data-testid='usersSelector-modal-container'>
						<TableStyle.TableHeaderContainer>
							<PageTitle title='Selecione usuários' />
							<Filter
								filterParams={filtersParams}
								onFiltersChanged={(updatedFilters) => {
									setUsersCurrentPage(1);
									setFiltersParams(updatedFilters);
								}}
							/>
							<Pagination
								onPageChange={(page) => setUsersCurrentPage(page)}
								currentPage={usersCurrentPage}
								totalCount={1}
							/>
						</TableStyle.TableHeaderContainer>
						<Loader />
					</S.Container>
				</Modal>
			</>
		);
	}

	return (
		<div>
			<S.MainButton onClick={() => setOpen(true)}>
				{buttonText ?? 'Adicionar usuários'}
			</S.MainButton>

			<Modal isOpen={open} enableClose onRequestClose={handleClose}>
				<S.Container data-testid='usersSelector-modal-container'>
					<TableStyle.TableHeaderContainer>
						<PageTitle
							title='Selecione usuários'
							totalRecords={allUsersQuery.data?.total_users ?? 0}
						/>
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								setUsersCurrentPage(1);
								setFiltersParams(updatedFilters);
							}}
						/>
						<Pagination
							onPageChange={(page) => setUsersCurrentPage(page)}
							currentPage={usersCurrentPage}
							totalCount={allUsersQuery.data?.total_users ?? 0}
						/>
					</TableStyle.TableHeaderContainer>

					<S.ItemsList ref={listContainer}>
						{allUsersQuery.data?.total_users! > 0 ? (
							allUsersQuery.data?.users.map((user) => {
								return (
									<S.ItemContainer key={user.id}>
										<S.ItemCard>
											<S.ItemAvatar
												src={user.avatar ? user.avatar : avatarImg}
											/>

											<S.ItemInfoContainer>
												<S.ItemName>{user.name}</S.ItemName>
												<S.ItemInfo>{user.email}</S.ItemInfo>
												<S.ItemInfo>
													{user.type === 'operator'
														? 'Operador'
														: 'Colaborador'}
												</S.ItemInfo>
											</S.ItemInfoContainer>
										</S.ItemCard>

										{handleSelector(user)}
									</S.ItemContainer>
								);
							})
						) : (
							<EmptyContent text='Nenhum usuário disponível' />
						)}
					</S.ItemsList>

					<S.SaveButton style={{ width: '16rem' }} onClick={handleSubmit}>
						Salvar
					</S.SaveButton>
				</S.Container>
			</Modal>
		</div>
	);
}
