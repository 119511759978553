import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Transfer } from '../../../@types';
import { Filter, FilterParams } from '../../../components/Filter';
import {
	TransferCategoryOptionsFilters,
	TransferProductOptionsFilters,
	TransferTypeOptionsFilters,
} from '../../../components/Filter/utils/SelectFilters';
import {
	getTransfersV2,
	TransfersListReturn,
} from '../../../services/queries/Tranfers';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import * as TableStyle from '../../../components/Table/TableStyles';
import {
	getTransferDetailsLink,
	parseTransferCategory,
	parseTransferType,
} from './utils/parseTransferData';
import { formatHours } from '../../../utils/parseDate';
import { formatDateDDMMYYY } from '../../../utils/parseDate';
import { convertCentsToReais } from '../../../utils/CurrencyConvert';
import { MdNotes } from 'react-icons/md';
import PageTitle from '../../../components/PageTitle';
import { EmptyContent } from '../../../components/EmptyContent';
import { Pagination } from '../../../components/Pagination';
import Loader from '../../../components/Loader';
import * as S from './styles';
import CompanyCard from '../../../components/CompanyCard';
import { ReportGenerator } from './ReportGenerator';
import { useSortColumnHook } from '../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../components/SortColumnButton';
import { ProductOriginLabel } from '../../../components/ProductOriginLabel';

export function TransfersV2() {
	const queryClient = useQueryClient();
	const { currentSortColumn, toggleSort } = useSortColumnHook();
	const [transfersCurrentPage, setTransfersCurrentPage] = useState(1);
	const [showingTable, setShowingTable] = useState(false);

	const [filtersParams, setFiltersParams] = useState<FilterParams[]>([
		{
			name: 'Tipo',
			filter: 'type',
			value: '',
			type: 'select',
			selectOptions: TransferTypeOptionsFilters,
			selected: false,
		},
		{
			name: 'Categoria',
			filter: 'category',
			value: '',
			type: 'select',
			selectOptions: TransferCategoryOptionsFilters,
			selected: false,
		},
		{
			name: 'Data',
			filter: 'date',
			value: '',
			type: 'date_interval',
			selected: false,
		},
		{
			name: 'Empresa',
			filter: 'company_name',
			value: '',
			type: 'company',
			selected: false,
		},
		{
			name: 'Produto',
			filter: 'origin',
			value: '',
			type: 'select',
			selectOptions: TransferProductOptionsFilters,
			selected: false,
		},
	]);

	function refreshPage() {
		setTransfersCurrentPage(1);
		queryClient.resetQueries('transfersList');
	}

	const fetchTransfersQuery = useQuery<TransfersListReturn, Error>(
		['transfersList', filtersParams, transfersCurrentPage, currentSortColumn],
		() => {
			return getTransfersV2(
				filtersParams,
				transfersCurrentPage,
				currentSortColumn
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar o histórico de transferências. '
				);
			},
		}
	);

	function getTotalValues() {
		if (filtersParams.find((f) => f.value)) {
			const filterType = filtersParams.find((f) => f.filter === 'type');

			if (filterType?.selected) {
				if (filterType.value === 'entrance') {
					return (
						<S.TotalValuesContainer>
							<S.TotalValue>
								<S.TotalValueLabel>Valor total de entradas:</S.TotalValueLabel>
								<S.TotalValueNumber>
									{convertCentsToReais(
										Number(fetchTransfersQuery.data!.entrances_totalValue)
									)}
								</S.TotalValueNumber>
							</S.TotalValue>
						</S.TotalValuesContainer>
					);
				} else {
					return (
						<S.TotalValuesContainer>
							<S.TotalValue>
								<S.TotalValueLabel>Valor total de saídas:</S.TotalValueLabel>
								<S.TotalValueNumber>
									{convertCentsToReais(
										Number(fetchTransfersQuery.data!.exits_totalValue)
									)}
								</S.TotalValueNumber>
							</S.TotalValue>
						</S.TotalValuesContainer>
					);
				}
			} else {
				return (
					<S.TotalValuesContainer>
						<S.TotalValue>
							<S.TotalValueLabel>Valor total de entradas:</S.TotalValueLabel>
							<S.TotalValueNumber>
								{convertCentsToReais(
									Number(fetchTransfersQuery.data!.entrances_totalValue)
								)}
							</S.TotalValueNumber>
						</S.TotalValue>
						<S.TotalValue>
							<S.TotalValueLabel>Valor total de saídas:</S.TotalValueLabel>
							<S.TotalValueNumber>
								{convertCentsToReais(
									Number(fetchTransfersQuery.data!.exits_totalValue)
								)}
							</S.TotalValueNumber>
						</S.TotalValue>
					</S.TotalValuesContainer>
				);
			}
		}
	}

	function generateRow(transfer: Transfer) {
		return (
			<TableStyle.TableRow key={transfer.id}>
				<TableStyle.TableData style={{ minWidth: '12rem' }}>
					{parseTransferType(transfer.type)}
				</TableStyle.TableData>
				<TableStyle.TableData style={{ minWidth: '12rem' }}>
					<S.BoldText>{formatDateDDMMYYY(transfer.created_at)}</S.BoldText>
					<br />
					<S.BoldText>{formatHours(transfer.created_at)}</S.BoldText>
				</TableStyle.TableData>
				<TableStyle.TableData style={{ minWidth: '20rem' }}>
					{parseTransferCategory(transfer.category)}
				</TableStyle.TableData>
				<TableStyle.TableData>
					{transfer.company ? (
						<CompanyCard
							company_name={transfer.company.name}
							cnpj={transfer.company.cnpj}
							avatar_url={transfer.company.avatar_url}
						/>
					) : (
						'N/A'
					)}
				</TableStyle.TableData>
				<TableStyle.TableData>
					<ProductOriginLabel origin={transfer.origin} />
				</TableStyle.TableData>
				<TableStyle.TableData style={{ minWidth: '12rem' }}>
					{convertCentsToReais(Number(transfer.amount))}
				</TableStyle.TableData>
				<TableStyle.TableData>
					{transfer.resource_id ? (
						<TableStyle.OptionLink
							data-rh='Ir para transferência'
							to={
								getTransferDetailsLink(
									transfer.category,
									transfer.resource_id
								) ?? '#'
							}
						>
							<MdNotes />
						</TableStyle.OptionLink>
					) : (
						<TableStyle.OptionButton disabled>
							<MdNotes />
						</TableStyle.OptionButton>
					)}
				</TableStyle.TableData>
			</TableStyle.TableRow>
		);
	}

	function getTable(transfers: Transfer[]) {
		if (!transfers.length) {
			return (
				<>
					<TableStyle.TableHeaderContainer>
						<TableStyle.TitleWrapper>
							<PageTitle
								title='Histórico de transferências'
								totalRecords={fetchTransfersQuery.data?.totalTransfers}
							/>
							<Filter
								filterParams={filtersParams}
								onFiltersChanged={(updatedFilters) => {
									setTransfersCurrentPage(1);
									setFiltersParams(updatedFilters);
								}}
							/>
						</TableStyle.TitleWrapper>
						<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
					</TableStyle.TableHeaderContainer>

					<EmptyContent text='Histórico de transferências vazio.' />
				</>
			);
		}

		return (
			<>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle
							title='Histórico de transferências'
							totalRecords={fetchTransfersQuery.data!.totalTransfers}
						/>
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								setTransfersCurrentPage(1);
								setFiltersParams(updatedFilters);
							}}
						/>
						{showingTable ? (
							<TableStyle.SeeLess onClick={() => setShowingTable(false)}>
								Ver menos
							</TableStyle.SeeLess>
						) : (
							<TableStyle.SeeMore onClick={() => setShowingTable(true)}>
								Ver mais
							</TableStyle.SeeMore>
						)}
					</TableStyle.TitleWrapper>
					{showingTable && (
						<Pagination
							onPageChange={(page) => setTransfersCurrentPage(page)}
							currentPage={transfersCurrentPage}
							totalCount={
								fetchTransfersQuery.data
									? fetchTransfersQuery.data.totalTransfers
									: 1
							}
						/>
					)}
					<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
				</TableStyle.TableHeaderContainer>

				<TableStyle.Table>
					<TableStyle.TableHeader>
						<TableStyle.TableRow>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'type'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('type')}
								/>
								TIPO
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'created_at'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('created_at')}
								/>
								DATA
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'category'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('category')}
								/>
								CATEGORIA
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'company'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('company')}
								/>
								EMPRESA
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'origin'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('origin')}
								/>
								PRODUTO
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'amount'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('amount')}
								/>
								VALOR
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>OPÇÕES</TableStyle.TableHeaderCell>
						</TableStyle.TableRow>
					</TableStyle.TableHeader>

					<TableStyle.TableBody>
						{showingTable
							? transfers.map((transfer) => generateRow(transfer))
							: generateRow(transfers[0])}
					</TableStyle.TableBody>
				</TableStyle.Table>
			</>
		);
	}

	if (
		!fetchTransfersQuery.data ||
		fetchTransfersQuery.isLoading ||
		fetchTransfersQuery.isPreviousData
	) {
		return (
			<S.Container>
				<PageTitle title='Histórico de transferências' />
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			{getTable(fetchTransfersQuery.data.transfers)}

			{getTotalValues()}

			<S.ButtonsContainer>
				<S.CreateTranfer to='/transfers/create'>
					Criar transferência
				</S.CreateTranfer>
				{filtersParams.find((f) => f.value) &&
					!!fetchTransfersQuery.data.transfers.length && (
						<ReportGenerator filterParams={filtersParams} />
					)}
			</S.ButtonsContainer>
		</S.Container>
	);
}
