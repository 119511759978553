import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBell, FaCog, FaEye, FaEyeSlash, FaSignOutAlt } from 'react-icons/fa';
import logoImg from '../../assets/Bounty.svg';
import * as S from './styles';
import { useAuth } from '../../hooks/useAuth';
import { parseCurrencyToBRLStandard } from '../../utils/parseCurrency';
import { Popover } from 'react-tiny-popover';
import { useNotifications } from '../../hooks/useNotifications';
import { NotificationsList } from '../NotificationsList';
import { MdEditNotifications } from 'react-icons/md';
import { SidebarLink } from '../SidebarLink';

type Props = {
	name: string;
	email: string;
	avatar: string;
};

export function Header({ name, email, avatar }: Props) {
	const { pathname } = useLocation();
	const { signOut, masterBalance } = useAuth();
	const { unreadNotifications } = useNotifications();
	const [isOptionsDropdownShowing, setIsOptionsDropdownShowing] =
		useState(false);
	const [showBalance, setShowBalance] = useState(false);
	const [isUserDropdownShowing, setIsUserDropdownShowing] = useState(false);
	const [isNotificationDropdownShowing, setIsNotificationDropdownShowing] =
		useState(false);

	useEffect(() => {
		const openNotifications = (e: MessageEvent) => {
			if (e.data.type === 'background-notification-click-event')
				setIsNotificationDropdownShowing(true);
		};

		navigator.serviceWorker.addEventListener('message', openNotifications);

		return () => {
			navigator.serviceWorker.removeEventListener('message', openNotifications);
		};
	}, []);

	function toggleBalanceVisibility() {
		setShowBalance((oldState) => !oldState);
	}

	return (
		<S.Container>
			{/* LEFT */}
			<div>
				<Link to='/'>
					<S.LogoImage src={logoImg} alt='Logo do Bounty' />
				</Link>
			</div>

			{/* RIGHT */}
			<div>
				<S.BalanceContainer showBalance={showBalance}>
					<S.ToggleBalanceVisibilityBtn onClick={toggleBalanceVisibility}>
						{showBalance ? <FaEye /> : <FaEyeSlash />}
					</S.ToggleBalanceVisibilityBtn>
					Saldo:{' '}
					<span>
						{parseCurrencyToBRLStandard(Number(masterBalance.toFixed(2)))}
					</span>
				</S.BalanceContainer>

				<Popover // NOTIFICATIONS DROPDOWN
					isOpen={isNotificationDropdownShowing}
					positions={['bottom', 'left']}
					onClickOutside={() => setIsNotificationDropdownShowing(false)}
					content={
						<S.DropdownNotificationsContainer>
							<NotificationsList open={isNotificationDropdownShowing} />
						</S.DropdownNotificationsContainer>
					}
				>
					<S.IconBtn
						onClick={() =>
							setIsNotificationDropdownShowing(!isNotificationDropdownShowing)
						}
					>
						<FaBell />{' '}
						{!!unreadNotifications && (
							<span
								data-rh={
									unreadNotifications > 99
										? `${unreadNotifications}`
										: undefined
								}
							>
								{unreadNotifications > 99 ? '99+' : unreadNotifications}
							</span>
						)}
					</S.IconBtn>
				</Popover>

				<Popover // OPTIONS-MENU DROPDOWN
					isOpen={isOptionsDropdownShowing}
					onClickOutside={() => setIsOptionsDropdownShowing(false)}
					content={
						<S.DropdownContainer>
							<S.OptionsContainer>
								<SidebarLink
									icon={MdEditNotifications}
									path='/notifications-preferences'
									title='Configurar notificações'
									currentPage={pathname === '/notifications-preferences'}
								/>
							</S.OptionsContainer>
						</S.DropdownContainer>
					}
				>
					<S.IconBtn
						onClick={() =>
							setIsOptionsDropdownShowing(!isOptionsDropdownShowing)
						}
						style={{
							color: 'var(--dark-gray)',
							background: 'var(--light-gray)',
						}}
					>
						<FaCog />
					</S.IconBtn>
				</Popover>

				<Popover // PROFILE DROPDOWN
					isOpen={isUserDropdownShowing}
					positions={['bottom', 'left']}
					onClickOutside={() => setIsUserDropdownShowing(false)}
					content={
						<S.DropdownContainer style={{ marginRight: '1rem' }}>
							<S.UserImage src={avatar} alt='Imagem do Usuário' />

							<S.UserDetails>
								<S.ProfileTextNameDrop>{name}</S.ProfileTextNameDrop>
								<S.ProfileTextEmailDrop>{email}</S.ProfileTextEmailDrop>
							</S.UserDetails>

							<S.Separator />

							<S.ProfileOptionButton onClick={signOut}>
								<FaSignOutAlt />
								Sair
							</S.ProfileOptionButton>
						</S.DropdownContainer>
					}
				>
					<S.ProfileBtn
						onClick={() => setIsUserDropdownShowing(!isUserDropdownShowing)}
					>
						<S.UserDetails>
							<S.TextName>{name}</S.TextName>
						</S.UserDetails>
						<S.UserImageSmall src={avatar} alt='Imagem do Usuário' />
					</S.ProfileBtn>
				</Popover>
			</div>
		</S.Container>
	);
}
