import { useForm } from 'react-hook-form';
import { useCompanyForm } from '../../../hooks/useCompanyForm';
import { cepMask, cnpjMask, cpfMask, phoneMask } from '../../../utils/masks';
import { parseDateToInput } from '../../../utils/parseDate';
import Loader from '../../Loader';
import {
	ObrigatoryFieldsIndication,
	ObrigatoryIndicator,
} from '../../ObrigatoryFieldsIndicator';
import * as FormStyles from '../../Form/FormStyles';
import * as S from './styles';
import {
	KYCFieldsIndication,
	KYCFieldsIndicator,
} from '../../KYCFieldsIndicator';
import { UF_List } from '../../../utils/CheckUF';
import {
	addressesFieldsCollaboratorSWAPPattern,
	addressNumberSWAPPattern,
	cepPattern, cnpjPattern,
	cpfPattern,
	emailPattern,
	onlyLettersAndSpacesPattern,
	phonePattern, websitePattern,
} from '../../../utils/patterns';
import { toast } from 'react-toastify';
import { trimObjectData } from '../../../utils/trimObjectData';
import { useGetAddressByCEP } from '../../../hooks/useGetAddressByCEP';
import { SearchCEPButton } from '../../SearchCEPButton';
import { ShareholderFormData } from '../../../contexts/CompanyFormContext';
import * as FormStyle from '../../Form/FormStyles';
import { useState } from 'react';
import suitcase from "../../../assets/suitcase.png"
import professional from "../../../assets/professional.png"

interface ShareholdersFormProps {
	shareholder: ShareholderFormData;
	goBack: () => void;
}
export function ShareholdersForm({
	shareholder,
	goBack,
}: ShareholdersFormProps) {
	const [userType, setUserType] = useState<"PF" | "PJ" | undefined>(shareholder.type_of_social_contract)

	const {
		company,
		createShareholderToCompanyCreation,
		updateShareholderToCompanyCreation,
		addShareholder,
		updateShareholder,
		loading,
	} = useCompanyForm();

	const { register, handleSubmit, reset, getValues, setValue } = useForm({
		defaultValues: {
			...shareholder,
			cpf: cpfMask(shareholder.cpf || ''),
			birth_date: parseDateToInput(shareholder.birth_date || ''),
			founding_date: parseDateToInput(shareholder.founding_date || ''),
			cnpj: cnpjMask(shareholder.cnpj || ''),
			phone_number: phoneMask(shareholder.phone_number || ''),
			cep: cepMask(shareholder.cep || ''),
			uf: shareholder.uf || '',
			type: shareholder.type || '',
			type_of_social_contract: userType
		},
	});

	const { searchAddressByCEP, isSearchingCEP } = useGetAddressByCEP({
		onAddressFoundCallback: (address) => {
			reset({ ...getValues(), ...address }); // reset the form filling with the fetched address
		},
	});

	function clearValues(type : "PF" | "PJ") {
		if (type === "PJ") {
			setValue("cpf", "")
			setValue("birth_date", "")
			setValue("mother_name", "")
			setValue("full_name", "")
		} else {
			setValue("cnpj", "")
			setValue("trading_name", "")
			setValue("legal_name", "")
			setValue("founding_date", "")
			setValue("main_activity", "")
			setValue("website", "")
		}
	}

	async function handleSubmitForm(data: ShareholderFormData) {
		let success = false;

		if (shareholder.id) {
			// update an existing shareholder
			success = await updateShareholder(trimObjectData(data));
		} else if (!shareholder.id && !!company?.id) {
			// adding a shareholder to an existing company
			success = await addShareholder(trimObjectData(data));
		} else {
			// creating/updating a shareholder to a new company creation
			if (!shareholder.cpf && !shareholder.cnpj){
				// create
				success = createShareholderToCompanyCreation(trimObjectData(data));
			} else {
				success = updateShareholderToCompanyCreation(
					shareholder.cpf !== "" ? shareholder.cpf! : shareholder.cnpj!,
					trimObjectData(data)
				);
			}
		}

		if (success) goBack();
	}

	function subtractYears(date: Date, years: number) {
		date.setFullYear(date.getFullYear() - years);
		return date;
	}

	function onInvalidInput(message: string) {
		toast.error(message, { style: { fontSize: '1.3rem' } });
	}

	if (loading) {
		return <Loader />;
	}

	const UserSelection = () => {
		return (
			<div style={{ display: 'flex', gap: '3rem', height: "100%", alignItems: "center", justifyContent: "center" }}>
				<S.CardContainer>
					<S.CardHeaderContainer>
						<img src={professional} alt={"Pessoa física"}/>
					</S.CardHeaderContainer>
					<S.CardContentContainer>
						<h1>Pessoa física</h1>
						<p>Para empresas cujo quadro societário é comporto apenas por Pessoas <br/> Físicas</p>
						<S.Divider/>
						<S.ButtonContainer
							onClick={() => {
								setUserType("PF")
								setValue("type_of_social_contract", "PF")
							}}
							color={"#27ADFF"}>
							Cadastrar sócio
						</S.ButtonContainer>
					</S.CardContentContainer>
				</S.CardContainer>
				<S.CardContainer>
					<S.CardHeaderContainer>
						<img src={suitcase} alt={"Empresa"}/>
					</S.CardHeaderContainer>
					<S.CardContentContainer>
						<h1>Pessoa jurídica</h1>
						<p>Para empresas cujo quadro societário tem um ou mais sócios que são Pessoas Jurídicas.</p>
						<S.Divider/>
						<S.ButtonContainer
							onClick={() => {
								setUserType("PJ")
								setValue("type_of_social_contract", "PJ")
							}}
						  color={"#FF0037"}>
							Cadastrar empresa
						</S.ButtonContainer>
					</S.CardContentContainer>
				</S.CardContainer>
			</div>
		)
	}

	if (userType === undefined)
		return  (<UserSelection/>)
	else
		return (
			<FormStyles.Form
				style={{ marginTop: 0 }}
				onSubmit={(e) => {
					e.preventDefault();
					clearValues(userType)
					handleSubmit(handleSubmitForm)();
					e.stopPropagation(); // do not submit the parent form
				}}
				autoComplete="off"
				autoCorrect="off"
				data-testid='shareholder_form_test_id'
			>
				{
					userType === "PJ" ?
						<>
							<FormStyles.FieldGroup>
								<FormStyles.Field>
									<FormStyles.Label>
										Razão Social <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</FormStyles.Label>
									<FormStyles.Input
										maxLength={255}
										type='text'
										pattern={onlyLettersAndSpacesPattern}
										{...register('legal_name')}
										data-testid='legalNameInput_test_id'
										required
									/>
								</FormStyles.Field>
								<FormStyles.Field>
									<FormStyles.Label>
										Nome Fantasia <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</FormStyles.Label>
									<FormStyles.Input
										maxLength={255}
										type='text'
										{...register('trading_name')}
										pattern={onlyLettersAndSpacesPattern}
										data-testid='tradingNameInput_test_id'
										required
									/>
								</FormStyles.Field>

							</FormStyles.FieldGroup>

							<FormStyles.FieldGroup>
								<FormStyles.Field >
									<FormStyles.Label>
										CNPJ <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</FormStyles.Label>
									<FormStyles.Input
										type='text'
										{...register('cnpj')}
										pattern={cnpjPattern}
										required
										onChange={(event) => {
											const { value } = event.target;
											event.target.value = cnpjMask(value);
										}}
										data-testid='cnpjInput_test_id'
									/>
								</FormStyles.Field>
								<FormStyles.Field >
									<FormStyle.Label htmlFor='founding_date'>
										Data de Fundação <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</FormStyle.Label>
									<FormStyle.Input
										type='date'
										{...register('founding_date')}
										name='founding_date'
										max={new Date().toISOString().split('T')[0]}
										id='founding_date'
										data-testid='foundingInput_test_id'
										required
									/>
								</FormStyles.Field>
							</FormStyles.FieldGroup>
							<FormStyles.FieldGroup>
								<FormStyle.Field>
									<FormStyle.Label htmlFor='main_activity'>
										Número do CNAE <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</FormStyle.Label>
									<FormStyle.Input
										maxLength={50}
										type='text'
										{...register('main_activity')}
										name='main_activity'
										id='main_activity'
										data-testid='main_activityInput_test_id'
										required
									/>
								</FormStyle.Field>
								<FormStyle.Field>
									<FormStyle.Label htmlFor="website">
										Website <KYCFieldsIndicator />
									</FormStyle.Label>
									<FormStyle.Input
										maxLength={255}
										type="text"
										{...register('website')}
										name="website"
										id="website"
										pattern={websitePattern}
										placeholder="Ex: www.exemplo.com"
										data-testid="websiteInput_test_id"
									/>
								</FormStyle.Field>
							</FormStyles.FieldGroup>
							</>
					:
						<>
							<FormStyles.FieldGroup>
								<FormStyles.Field>
									<FormStyles.Label>
										Nome Completo <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</FormStyles.Label>
									<FormStyles.Input
										maxLength={255}
										type='text'
										pattern={onlyLettersAndSpacesPattern}
										{...register('full_name')}
										data-testid='fullNameInput_test_id'
										required
									/>
								</FormStyles.Field>
							</FormStyles.FieldGroup>

							<FormStyles.FieldGroup>
								<FormStyles.Field>
									<FormStyles.Label>
										Nome da mãe <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</FormStyles.Label>
									<FormStyles.Input
										maxLength={255}
										type='text'
										{...register('mother_name')}
										pattern={onlyLettersAndSpacesPattern}
										data-testid='motherNameInput_test_id'
										required
									/>
								</FormStyles.Field>
								<FormStyles.Field style={{ width: '46%' }}>
									<FormStyles.Label>
										CPF <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</FormStyles.Label>
									<FormStyles.Input
										type='text'
										{...register('cpf')}
										pattern={cpfPattern}
										placeholder='Ex: 999.999.999-99'
										required
										onChange={(event) => {
											const { value } = event.target;
											event.target.value = cpfMask(value);
										}}
										data-testid='cpfInput_test_id'
									/>
								</FormStyles.Field>
								<FormStyles.Field style={{ width: '50%' }}>
									<FormStyles.Label>
										Data de nascimento <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</FormStyles.Label>
									<FormStyles.Input
										type='date'
										max={subtractYears(new Date(), 9).toISOString().split('T')[0]}
										{...register('birth_date')}
										data-testid='birthInput_test_id'
										required
									/>
								</FormStyles.Field>
							</FormStyles.FieldGroup>

						</>
				}

				<FormStyles.FieldGroup>
					<FormStyles.Field style={{ width: '50%' }}>
						<FormStyles.Label>
							Tipo <ObrigatoryIndicator />
							<KYCFieldsIndicator />
						</FormStyles.Label>
						<FormStyles.SelectInput
							{...register('type')}
							required
							data-testid='typeInput_test_id'
						>
							<option disabled value=''>
								Selecione uma opção
							</option>
							<option key={'partner'} value={'partner'}>
								Sócio
							</option>
							<option key={'proxyholder'} value={'proxyholder'}>
								Procurador
							</option>
							<option key={'legal_representative'} value={'legal_representative'}>
								Representante legal
							</option>
							<option key={'other'} value={'other'}>
								Outro
							</option>
						</FormStyles.SelectInput>
					</FormStyles.Field>
				</FormStyles.FieldGroup>

				<FormStyles.FieldGroup>
					<FormStyles.Field>
						<FormStyles.Label>
							Email <ObrigatoryIndicator />
							<KYCFieldsIndicator />
						</FormStyles.Label>
						<FormStyles.Input
							type='email'
							placeholder='Ex: exemplo@email.com'
							pattern={emailPattern}
							{...register('email')}
							data-testid='emailInput_test_id'
							required
						/>
					</FormStyles.Field>
					<FormStyles.Field>
						<FormStyles.Label>
							Telefone <ObrigatoryIndicator />
							<KYCFieldsIndicator />
						</FormStyles.Label>
						<FormStyles.Input
							type='tel'
							{...register('phone_number')}
							placeholder='Ex: +55 81 99999-9999'
							pattern={phonePattern}
							required
							onChange={(event) => {
								const { value } = event.target;
								event.target.value = phoneMask(value);
							}}
							data-testid='phoneInput_test_id'
						/>
					</FormStyles.Field>
				</FormStyles.FieldGroup>

				<FormStyles.FieldGroup>
					<FormStyles.Field>
						<FormStyles.Label>
							Endereço <ObrigatoryIndicator />
							<KYCFieldsIndicator />
						</FormStyles.Label>
						<FormStyles.Input
							maxLength={100}
							type='text'
							{...register('address')}
							pattern={addressesFieldsCollaboratorSWAPPattern}
							onInvalid={(e) => {
								if (e.currentTarget.value === '') return;
								onInvalidInput(
									"Utilize apenas letras, espaços e (.)(,)(')(/)(-) no campo de endereço"
								);
							}}
							data-testid='addressInput_test_id'
							required
						/>
					</FormStyles.Field>
					<FormStyles.Field>
						<FormStyles.Label>
							Número <ObrigatoryIndicator />
							<KYCFieldsIndicator />
						</FormStyles.Label>
						<FormStyles.Input
							maxLength={10}
							type='number'
							{...register('number')}
							pattern={addressNumberSWAPPattern}
							onInvalid={(e) => {
								if (e.currentTarget.value === '') return;
								onInvalidInput(
									"Utilize apenas letras, espaços e (') no campo de número"
								);
							}}
							data-testid='numberInput_test_id'
							required
						/>
					</FormStyles.Field>
				</FormStyles.FieldGroup>

				<FormStyles.FieldGroup>
					<FormStyles.Field>
						<FormStyles.Label>
							Bairro <ObrigatoryIndicator />
							<KYCFieldsIndicator />
						</FormStyles.Label>
						<FormStyles.Input
							maxLength={100}
							type='text'
							{...register('district')}
							pattern={addressesFieldsCollaboratorSWAPPattern}
							onInvalid={(e) => {
								if (e.currentTarget.value === '') return;
								onInvalidInput(
									"Utilize apenas letras, espaços e (.)(,)(')(/)(-) no campo de bairro"
								);
							}}
							data-testid='districtInput_test_id'
							required
						/>
					</FormStyles.Field>
					<FormStyles.Field style={{ maxWidth: '20%' }}>
						<FormStyles.Label>
							CEP <ObrigatoryIndicator />
							<KYCFieldsIndicator />
						</FormStyles.Label>
						<FormStyles.Input
							type='text'
							{...register('cep')}
							pattern={cepPattern}
							placeholder='Ex: 99999-999'
							required
							onChange={(event) => {
								const { value } = event.target;
								event.target.value = cepMask(value);
							}}
							data-testid='cepInput_test_id'
						/>
					</FormStyles.Field>
					<SearchCEPButton
						isLoading={isSearchingCEP}
						onClick={() => searchAddressByCEP(getValues().cep)}
					/>
				</FormStyles.FieldGroup>

				<FormStyles.FieldGroup>
					<FormStyles.Field>
						<FormStyles.Label>Complemento</FormStyles.Label>
						<FormStyles.Input
							maxLength={50}
							type='text'
							{...register('complement')}
							pattern={addressesFieldsCollaboratorSWAPPattern}
							onInvalid={(e) => {
								if (e.currentTarget.value === '') return;
								onInvalidInput(
									"Utilize apenas letras, espaços e (.)(,)(')(/)(-) no campo de complemento"
								);
							}}
							data-testid='complementInput_test_id'
						/>
					</FormStyles.Field>
					<FormStyles.Field style={{ width: '60%' }}>
						<FormStyles.Label>
							Cidade <ObrigatoryIndicator />
							<KYCFieldsIndicator />
						</FormStyles.Label>
						<FormStyles.Input
							maxLength={50}
							type='text'
							{...register('city')}
							pattern={onlyLettersAndSpacesPattern}
							onInvalid={(e) => {
								if (e.currentTarget.value === '') return;
								onInvalidInput(
									'Utilize apenas letras e espaços no campo de cidade'
								);
							}}
							data-testid='cityInput_test_id'
							required
						/>
					</FormStyles.Field>
					<FormStyles.Field style={{ width: '30%' }}>
						<FormStyles.Label>
							UF <ObrigatoryIndicator />
							<KYCFieldsIndicator />
						</FormStyles.Label>
						<FormStyles.SelectInput
							{...register('uf')}
							required
							data-testid='ufInput_test_id'
						>
							<option disabled value=''>
								Selecione uma opção
							</option>
							{UF_List.map((uf) => (
								<option key={uf} value={uf}>
									{uf}
								</option>
							))}
						</FormStyles.SelectInput>
					</FormStyles.Field>
				</FormStyles.FieldGroup>

				<ObrigatoryFieldsIndication />
				<KYCFieldsIndication />
				<S.BottomOptionsContainer>
					<S.BackButton type='button' onClick={goBack}>
						Voltar
					</S.BackButton>
					<S.SaveButton type='submit'>Salvar</S.SaveButton>
				</S.BottomOptionsContainer>
			</FormStyles.Form>
		);
}
