import { Document, Font, Page, Text, View, pdf } from '@react-pdf/renderer';
import {
	PDFstyles,
	getColumnDataStyle,
	getColumnTitleStyle,
} from '../../../../../components/PDFReportStyles';
import { parseReferenceDate } from '../../utils/parseReferenceDate';
import { cpfMask } from '../../../../../utils/masks';
import { useAuth } from '../../../../../hooks/useAuth';
import * as S from './styles';
import { toast } from 'react-toastify';
import { DuplicateCardsPeriodResponse } from '../../../../../services/queries/CardsBatchs';

Font.register({
	family: 'Poppins',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/hlvAxH6aIdOjWlLzgm0jqg.ttf',
		},
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/4WGKlFyjcmCFVl8pRsgZ9vesZW2xOQ-xsNqO47m55DA.ttf',
			fontWeight: 500,
		},
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/-zOABrCWORC3lyDh-ajNnPesZW2xOQ-xsNqO47m55DA.ttf',
			fontWeight: 600,
		},
	],
});

interface Props {
	duplicatedPeriodsData: DuplicateCardsPeriodResponse[];
}

export function GeneratePDFReport({ duplicatedPeriodsData }: Props) {
	const { user } = useAuth();

	async function handleGeneratePDF() {
		try {
			const PDFGenerated = await generatePDFContent();
			const blob = await pdf(PDFGenerated).toBlob();

			// Create a temporary URL for the blob
			const url = URL.createObjectURL(blob);

			// Create a link element
			const link = document.createElement('a');
			link.href = url;
			link.download = 'segundas_vias_de_cartão.pdf';

			// Simulate a click event to trigger the download
			link.dispatchEvent(new MouseEvent('click'));

			// Clean up the temporary URL
			URL.revokeObjectURL(url);
		} catch (error) {
			console.log(error);
			toast.error(
				'Ocorreu um erro ao tentar gerar o arquivo de relátorio. Tente novamente'
			);
		}
	}

	async function generatePDFContent() {
		const currentTime = new Date();

		return (
			<Document>
				<Page size='A4' style={PDFstyles.page}>
					<View style={PDFstyles.section}>
						<View>
							<Text style={PDFstyles.heading}>
								Detalhamento de segundas vias de cartão
							</Text>
						</View>

						{duplicatedPeriodsData.map((r) => (
							<View
								style={{ ...PDFstyles.content, ...{ marginVertical: '10px' } }}
								key={
									r.data.company_name +
									r.data.reference_month +
									r.data.reference_year
								}
							>
								<Text>
									{`${r.data.company_name} - ${parseReferenceDate(
										Number(r.data.reference_month),
										r.data.reference_year
									)}`}
								</Text>

								<View style={PDFstyles.resultsRow}>
									<Text style={{ ...getColumnTitleStyle(160) }}>
										COLABORADOR
									</Text>
									<Text style={{ ...getColumnTitleStyle(90) }}>CPF</Text>
									<Text style={{ ...getColumnTitleStyle(110) }}>
										PAN do novo cartão
									</Text>
									<Text style={{ ...getColumnTitleStyle(60) }}>
										Data da ativação
									</Text>
								</View>

								{r.items.map((item) => (
									<View
										style={PDFstyles.resultsRow}
										key={item.card_pan + item.collaborator_cpf}
									>
										<Text style={{ ...getColumnDataStyle(160) }}>
											{item.collaborator_name}
										</Text>
										<Text
											style={{
												...getColumnDataStyle(90),
											}}
										>
											{cpfMask(item.collaborator_cpf)}
										</Text>
										<Text
											style={{
												...getColumnDataStyle(110),
											}}
										>
											{item.card_pan}
										</Text>
										<Text
											style={{
												...getColumnDataStyle(60),
											}}
										>
											{new Date(item.activate_date).toLocaleDateString()}
										</Text>
									</View>
								))}
								<Text style={{ fontSize: '8px', marginTop: '10px' }}>
									{`Total de cartões: ${r.items.length}`}
								</Text>
							</View>
						))}

						<View style={PDFstyles.footer}>
							<Text>
								Gerado em {currentTime.toLocaleDateString()} às{' '}
								{currentTime.toLocaleTimeString([], {
									hour: '2-digit',
									minute: '2-digit',
								})}{' '}
								no Bounty Master. Usuário: {user.email}.
							</Text>
						</View>
					</View>
				</Page>
			</Document>
		);
	}
	return <S.Option onClick={handleGeneratePDF}>Gerar PDF</S.Option>;
}
