import styled from 'styled-components';
import { OptionsButton } from '../../../../../components/Button';

export const Container = styled.form`
	flex: 1;
	display: flex;
	flex-direction: column;
	width: max-content;
	max-width: 70rem;
`;

export const MainButton = styled.button`
	${OptionsButton}
	background-color: var(--white);
	color: var(--primary-blue);
`;
