import { useState } from 'react';
import Modal from '../../../../components/Modal';
import styled from 'styled-components';
import * as TableStyles from '../../../../components/Table/TableStyles';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { useQuery } from 'react-query';
import { getCompanyCorpwayCards } from '../../../../services/queries/CorpwayCards';
import { MdNotes } from 'react-icons/md';
import { CorpwayCardStatus } from '../../../../@types';
import Loader from '../../../../components/Loader';
import { useSortColumnHook } from '../../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../../components/SortColumnButton';

interface Props {
	company: {
		id: string;
		name: string;
	};
	disabled: boolean;
}

export function CardsListModal(props: Props) {
	const [isOpen, setIsOpen] = useState(false);
	const { currentSortColumn, toggleSort, sortList } = useSortColumnHook();

	const fetchCompanyCards = useQuery(
		['corpway-cards', props.company.id],
		() => getCompanyCorpwayCards(props.company.id),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar a listagem de cartões. '
				);
			},
			enabled: isOpen,
		}
	);

	function parseCardStatus(status: CorpwayCardStatus) {
		const statusMap: Record<CorpwayCardStatus, string> = {
			active: 'Ativo',
			blocked: 'Bloqueado',
			canceled: 'Cancelado',
		};
		return statusMap[status];
	}

	return (
		<>
			<OpenButton
				onClick={() => setIsOpen(true)}
				disabled={props.disabled}
				data-rh={props.disabled ? 'Nenhum cartão' : 'Ver cartões'}
			>
				<MdNotes />
			</OpenButton>

			<Modal
				isOpen={isOpen}
				onRequestClose={() => setIsOpen(false)}
				enableClose
			>
				<Title>Cartões da empresa: {props.company.name}</Title>
				{fetchCompanyCards.isLoading && <Loader />}
				{fetchCompanyCards.data && (
					<TableStyles.Table>
						<TableStyles.TableHeader>
							<TableStyles.TableRow>
								<TableStyles.TableHeaderCell>
									Cartão
								</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>
									<SortColumnButton
										order={
											currentSortColumn?.name === 'status'
												? currentSortColumn.order
												: null
										}
										onToggleSort={() => toggleSort('status')}
									/>
									Status
								</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>
									<SortColumnButton
										order={
											currentSortColumn?.name === 'created_at'
												? currentSortColumn.order
												: null
										}
										onToggleSort={() => toggleSort('created_at')}
									/>
									Data de criação
								</TableStyles.TableHeaderCell>
							</TableStyles.TableRow>
						</TableStyles.TableHeader>

						<TableStyles.TableBody>
							{sortList(fetchCompanyCards.data.cards).map((card) => (
								<TableStyles.TableRow key={card.id}>
									<TableStyles.TableData>{card.pan}</TableStyles.TableData>
									<TableStyles.TableData>
										{parseCardStatus(card.status)}
									</TableStyles.TableData>
									<TableStyles.TableData>
										{new Date(card.created_at).toLocaleDateString()}
									</TableStyles.TableData>
								</TableStyles.TableRow>
							))}
						</TableStyles.TableBody>
					</TableStyles.Table>
				)}
			</Modal>
		</>
	);
}

const OpenButton = styled.button`
	background-color: var(--primary-blue);
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: min-content;
	& svg {
		width: 2rem;
		height: 2rem;
		color: var(--white);
	}
`;
const Title = styled.h1`
	text-align: center;
`;
