import styled from 'styled-components';
import { OptionsButton } from '../../../../../components/Button';
import { Link } from 'react-router-dom';

export const Container = styled.div`
	margin-bottom: 4rem;
`;

export const HeaderContainer = styled.div`
	display: flex;
	align-items: center;
	column-gap: 6rem;
`;

interface SummaryContainerProps {
	showCaptions: boolean;
}
export const SummaryContainer = styled.div<SummaryContainerProps>`
	display: flex;
	justify-content: ${(props) => (props.showCaptions ? 'flex-start' : 'center')};
	column-gap: 2rem;
	width: 100%;
	row-gap: 1rem;
	& sup {
		display: ${(props) => (props.showCaptions ? 'inline-block' : 'none')};
	}
	flex-wrap: wrap;
`;

export const Stat = styled.div`
	display: flex;
	align-items: center;
	font-size: 1.4rem;
	column-gap: 0.5rem;
`;

export const OpenBtn = styled.button`
	${OptionsButton}
	width: 9rem;
	height: 2rem;
	padding: 0;
	align-self: center;
`;

export const OptionLink = styled(Link)`
	${OptionsButton}
	font-size: 1.4rem;
	width: 9rem;
	height: 2rem;
	padding: 0;
	text-align: center;
`;

export const ModalContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 2rem;
	row-gap: 2rem;
`;

export const CompaniesTableContainer = styled.div`
	width: 100rem;
	max-height: 40rem;
	overflow-y: auto;
`;
