import { create } from 'zustand';
import { FilterParams } from '../components/Filter';

type Store = {
	filtersParams: FilterParams[];
	setFiltersParams: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const filtersParams: FilterParams[] = [
	{ name: 'Nome', filter: 'name', value: '', type: 'text', selected: false },
	{
		name: 'CPF',
		filter: 'cpf',
		value: '',
		type: 'cpf',
		selected: false,
	},
	{
		name: 'Email',
		filter: 'email',
		value: '',
		type: 'email',
		selected: false,
	},
	{
		name: 'Empresa',
		filter: 'company_id',
		value: '',
		type: 'company_id',
		selected: false,
	},
	{
		name: 'Tipo de usuário',
		filter: 'user_type',
		value: '',
		type: 'select',
		selected: false,
		selectOptions: [
			{
				text: 'Colaborador',
				value: 'collaborator',
			},
			{
				text: 'Operador',
				value: 'operator',
			},
		],
	},
];

const useAllUsersListStore = create<Store>()((set) => ({
	filtersParams,
	setFiltersParams: (f) => set({ filtersParams: f }),
	resetFilters: () => set({ filtersParams }),
}));

export { useAllUsersListStore };
