import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';

export const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 4rem 7rem 4rem 7rem;
`;

export const InfoContentContainer = styled.div`
	display: flex;
	column-gap: 10rem;
	row-gap: 7rem;
	margin: 2rem 0;
	flex-wrap: wrap;

	& + & {
		margin-top: 7rem;
	}
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1.6rem;
`;

export const InfoTitle = styled.h2`
	font-weight: 500;
	font-size: 1.8rem;
	color: var(--dark-gray);
	margin-bottom: 1rem;
	text-transform: uppercase;
`;

export const DocumentLink = styled.a`
	color: var(--primary-blue);
	margin-right: 1rem;
	text-decoration: underline;
	font-style: italic;
`;

export const OptionsContainer = styled.div`
	display: flex;
	margin-left: auto;
	margin-top: auto;
	column-gap: 2rem;
`;

export const ReproveOption = styled.button`
	${OptionsButton}
	background-color: var(--white);
	color: var(--primary-blue);
`;
export const ApproveOption = styled.button`
	${OptionsButton}
`;

export const FieldsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-column-gap: 2rem;
	grid-row-gap: 2rem;
	max-width: 100rem;
`;

export const Field = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 200px;
`;

export const FieldTitle = styled.h3`
	color: rgba(198, 198, 198, 1);
	font-size: 1.6rem;
	margin-bottom: 1rem;
	text-transform: uppercase;
`;
