import axios from 'axios';
import { ReactNode, createContext, useState, useEffect } from 'react';
import {
	useMutation,
	useQuery,
	useQueryClient,
	UseQueryResult,
} from 'react-query';
import { useNavigate } from 'react-router-dom';
// import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify';
import { MasterBenefit } from '../@types';

import api from '../services/api';
import { getMasterBenefits } from '../services/queries/Benefits';
import {
	deleteToken,
	getMasterBalance,
	refreshToken,
	session,
} from '../services/queries/Session';
import { socket } from '../services/socket';
import { getMessageError } from '../utils/DefaultErrors';
import { showErrorMessage } from '../utils/ErrorHandler';

type Props = {
	children: ReactNode;
};

type User = {
	id: string;
	email: string;
};

type AuthState = {
	token: string;
	user: User;
};

type LoginResult = {
	concludedLogin: boolean;
	message?: string;
};

type SignInCredentials = {
	email: string;
	password: string;
};

export type AuthContextData = {
	user: User;
	signIn(credentials: SignInCredentials): Promise<LoginResult>;
	signOut(): void;
	masterBenefits: MasterBenefit[];
	masterBalance: number;
	updateMasterBalance: UseQueryResult<any, Error>;
};

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: Props) {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const [masterBalance, setMasterBalance] = useState(0);

	const [data, setData] = useState(() => {
		const token = localStorage.getItem('@Bounty:token');
		const refreshToken = localStorage.getItem('@Bounty:refreshToken');
		const user = localStorage.getItem('@Bounty:user');

		if (token && user && refreshToken) {
			api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
			setInterceptorResponseOnApi();

			return {
				token,
				user: JSON.parse(user),
			};
		}

		return {} as AuthState;
	});

	const refreshTokenQuery = useMutation((refreshTokenParam: string) =>
		refreshToken(refreshTokenParam)
	);
	const clearToken = useMutation(deleteToken);
	const signInQuery = useMutation(({ email, password }: SignInCredentials) => {
		return session(email, password);
	});

	useEffect(() => {
		socket.connect();
		socket.on('connect', () => {
			socket.emit('master_connection', { master_id: data.user.id });
		});

		socket.on('balance_update', async () => {
			await updateMasterBalance.refetch();
		});

		return () => {
			socket.disconnect();
			socket.off('balance_update');
		};
	}, [data.user]); //eslint-disable-line

	const fetchMasterBenefitsQuery = useQuery(
		['fetchBenefits'],
		() => {
			return getMasterBenefits();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar os benefícios do Master'
				);
			},
			enabled: !!data.token,
			refetchOnWindowFocus: false,
		}
	);

	const updateMasterBalance = useQuery<any, Error>(
		['updateBalance'],
		() => {
			return getMasterBalance();
		},
		{
			onSuccess: (data) => {
				setMasterBalance(data.balance / 100);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar o saldo da empresa'
				);
			},
			enabled: !!data.user,
			refetchOnWindowFocus: false,
		}
	);

	async function signIn({ email, password }: SignInCredentials) {
		try {
			const response = await signInQuery.mutateAsync({ email, password });
			const { token, user, refreshToken } = response;

			const userData: User = {
				id: user.id,
				email: user.email,
			};

			localStorage.setItem('@Bounty:token', token);
			localStorage.setItem('@Bounty:refreshToken', refreshToken);
			localStorage.setItem('@Bounty:user', JSON.stringify(userData));

			api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
			setInterceptorResponseOnApi();

			setData({ token, user });

			return {
				concludedLogin: true,
			};
		} catch (err) {
			if (axios.isAxiosError(err)) {
				let resultMessage = getMessageError(err.response?.data);

				return {
					concludedLogin: false,
					message: 'Ocorreu um erro ao fazer login. ' + resultMessage,
				};
			} else {
				return {
					concludedLogin: false,
					message: 'Ocorreu um erro ao fazer login.',
				};
			}
		}
	}

	async function clearAuthState() {
		setData({} as AuthState);

		localStorage.removeItem('@Bounty:token');
		localStorage.removeItem('@Bounty:user');
		localStorage.removeItem('@Bounty:refreshToken');

		try {
			await clearToken.mutateAsync();
		} catch (err) {
			console.log(err);
		}
	}

	async function signOut() {
		queryClient.cancelQueries();
		clearAuthState();
		navigate('/session');
	}

	// This function sets a response interceptor at the api, so on each request if the token expires, refresh it)
	function setInterceptorResponseOnApi() {
		api.interceptors.response.use(
			(response) => {
				return response;
			},

			async (err: any) => {
				const originalConfig = err.config;
				let refreshToken = localStorage.getItem('@Bounty:refreshToken');

				// Access Token was expired
				if (
					refreshToken &&
					err.response.status === 401 &&
					!originalConfig._retry
				) {
					originalConfig._retry = true;
					try {
						const data = await refreshTokenQuery.mutateAsync(refreshToken);
						const newToken = data.token;
						const newRefreshToken = data.refreshToken;

						api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
						localStorage.setItem('@Bounty:token', newToken);

						if (newRefreshToken)
							// refreshToken was expired as well, so update it with the new one
							localStorage.setItem('@Bounty:refreshToken', newRefreshToken);
						return api({
							...originalConfig,
							headers: { Authorization: `Bearer ${newToken}` },
						});
					} catch (_error) {
						console.log('SAINDO, deu ERRO ATUALIZANDO O TOKEN, catch', err);
						signOut();
						return Promise.reject(_error);
					}
				}
				return Promise.reject(err);
			}
		);
	}

	return (
		<AuthContext.Provider
			value={{
				user: data.user,
				signIn,
				signOut,
				masterBenefits: fetchMasterBenefitsQuery.data ?? [],
				masterBalance,
				updateMasterBalance,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}
