import styled from 'styled-components'

export const Container = styled.div`
  width: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  & svg {
    color: var(--primary-blue);
    width: 4rem;
    height: 4rem;
  }
`

export const Message = styled.p`
  text-align: center;
  font-size: 1.4rem;
  margin: 2rem 0;
`

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 1.5rem;
`

type OptionType = {
  second: boolean
}

export const Option = styled.button<OptionType>`
  border: 0.2rem solid var(--primary-blue);
  color: ${({second}) => second ? 'var(--primary-blue)' : 'var(--white)'};
  background-color: ${({second}) => second ? 'var(--white)' : 'var(--primary-blue)'};
  padding: 1rem 2rem;
  width: 100%;
  border-radius: 0.2rem;
  transition: filter 0.2s;
  margin: 0.5rem 0;
  
  &:hover {
    filter: brightness(0.9);
  }
`