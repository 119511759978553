import { useState } from 'react';
import { useQuery } from 'react-query';
import Loader from '../../../../../components/Loader';
import Modal from '../../../../../components/Modal';
import PageTitle from '../../../../../components/PageTitle';
import {
	CardsStatsReturn,
	getCardsStats,
} from '../../../../../services/queries/CardsBatchs';
import * as TableStyle from '../../../../../components/Table/TableStyles';
import * as S from './styles';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { useSortColumnHook } from '../../../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../../../components/SortColumnButton';

export function CardBatchStats() {
	const [isOpen, setOpen] = useState(false);
	const { currentSortColumn, toggleSort, sortList } = useSortColumnHook();
	const fetchCardStatsQuery = useQuery('card-stats', getCardsStats, {
		onError: (err) => {
			showErrorMessage(
				err as Error,
				'Não foi possível buscar o quantitativo de cartões. '
			);
		},
	});

	const SummaryContainer = ({ data }: { data: CardsStatsReturn }) => {
		return (
			<S.SummaryContainer showCaptions={!isOpen}>
				<S.Stat>
					<strong>
						Plásticos<sup>1</sup>:
					</strong>
					{data.bounty_card_stats.quantity_raw}
				</S.Stat>
				<S.Stat>
					<strong>
						Cartões disponíveis para personalização<sup>2</sup>:
					</strong>
					{data.bounty_card_stats.quantity_available}
				</S.Stat>
				<S.Stat>
					<strong>
						Cartões prontos não utilizados<sup>3</sup>:
					</strong>
					{data.bounty_card_stats.quantity_embossed}
				</S.Stat>
				<S.Stat>
					<strong>
						Cartões enviados<sup>4</sup>:
					</strong>
					{data.bounty_card_stats.quantity_processing}
				</S.Stat>
				<S.Stat>
					<strong>
						Cartões solicitados por empresas<sup>5</sup>:
					</strong>
					{data.bounty_card_stats.quantity_requested}
				</S.Stat>
				<S.Stat>
					<strong>
						Cartões entregues<sup>6</sup>:
					</strong>
					{data.bounty_card_stats.quantity_delivered}
				</S.Stat>

				<S.Stat>
					<strong>
						Cartões perdidos<sup>7</sup>:
					</strong>
					{data.bounty_card_stats.quantity_lost}
				</S.Stat>

				<S.Stat>
					<strong>
						Total de envelopes<sup>8</sup>:
					</strong>
					{data.leaflet_stock_stats.quantity_total_envelopes}
				</S.Stat>
				<S.Stat>
					<strong>
						Envelopes disponíveis<sup>9</sup>:
					</strong>
					{data.leaflet_stock_stats.quantity_envelopes_available}
				</S.Stat>
				<S.Stat>
					<strong>
						Envelopes personalizados<sup>10</sup>:
					</strong>
					{data.leaflet_stock_stats.quantity_envelopes_personalized}
				</S.Stat>
				<S.Stat>
					<strong>
						Envelopes perdidos<sup>11</sup>:
					</strong>
					{data.leaflet_stock_stats.quantity_envelopes_lost}
				</S.Stat>
				<S.Stat>
					<strong>
						Total de cartas berço<sup>12</sup>:
					</strong>
					{data.leaflet_stock_stats.quantity_total_accompanying_letters}
				</S.Stat>
				<S.Stat>
					<strong>
						Cartas berço disponíveis<sup>13</sup>:
					</strong>
					{data.leaflet_stock_stats.quantity_accompanying_letters_available}
				</S.Stat>
				<S.Stat>
					<strong>
						Cartas berço personalizadas<sup>14</sup>:
					</strong>
					{data.leaflet_stock_stats.quantity_accompanying_letters_personalized}
				</S.Stat>
				<S.Stat>
					<strong>
						Cartas berço perdidas<sup>15</sup>:
					</strong>
					{data.leaflet_stock_stats.quantity_accompanying_letters_lost}
				</S.Stat>
			</S.SummaryContainer>
		);
	};

	if (!fetchCardStatsQuery.data) {
		return (
			<S.Container>
				<PageTitle title='Resumo do Estoque' />
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			<S.HeaderContainer>
				<PageTitle title='Resumo do Estoque' />
				<S.OpenBtn onClick={() => setOpen(true)}>Ver mais</S.OpenBtn>
				<S.OptionLink to={'/cards/stock'}>Editar</S.OptionLink>
			</S.HeaderContainer>
			<SummaryContainer data={fetchCardStatsQuery.data} />

			<Modal isOpen={isOpen} onRequestClose={() => setOpen(false)} enableClose>
				<S.ModalContainer>
					<PageTitle title='Quantitativo de cartões' />
					<SummaryContainer data={fetchCardStatsQuery.data} />

					<S.CompaniesTableContainer>
						<PageTitle title='Quantitativo clientes' />

						<TableStyle.Table>
							<TableStyle.TableHeader>
								<TableStyle.TableRow>
									<TableStyle.TableHeaderCell>
										<SortColumnButton
											order={
												currentSortColumn?.name === 'company_name'
													? currentSortColumn.order
													: null
											}
											onToggleSort={() => toggleSort('company_name')}
										/>
										EMPRESA
									</TableStyle.TableHeaderCell>
									<TableStyle.TableHeaderCell>
										<SortColumnButton
											order={
												currentSortColumn?.name === 'count_requested'
													? currentSortColumn.order
													: null
											}
											onToggleSort={() => toggleSort('count_requested')}
										/>
										CARTÕES SOLICITADOS
									</TableStyle.TableHeaderCell>
									<TableStyle.TableHeaderCell>
										<SortColumnButton
											order={
												currentSortColumn?.name === 'count_processing'
													? currentSortColumn.order
													: null
											}
											onToggleSort={() => toggleSort('count_processing')}
										/>
										CARTÕES ENVIADOS
									</TableStyle.TableHeaderCell>
									<TableStyle.TableHeaderCell>
										<SortColumnButton
											order={
												currentSortColumn?.name === 'count_delivered'
													? currentSortColumn.order
													: null
											}
											onToggleSort={() => toggleSort('count_delivered')}
										/>
										CARTÕES ENTREGUES
									</TableStyle.TableHeaderCell>
								</TableStyle.TableRow>
							</TableStyle.TableHeader>

							<TableStyle.TableBody>
								{sortList(fetchCardStatsQuery.data.stats_by_company).map(
									(companyStat) => (
										<TableStyle.TableRow key={companyStat.company_name}>
											<TableStyle.TableData>
												<span>{companyStat.company_name}</span>
											</TableStyle.TableData>
											<TableStyle.TableData>
												<span>{companyStat.count_requested}</span>
											</TableStyle.TableData>
											<TableStyle.TableData>
												<span>{companyStat.count_processing}</span>
											</TableStyle.TableData>
											<TableStyle.TableData>
												<span>{companyStat.count_delivered}</span>
											</TableStyle.TableData>
										</TableStyle.TableRow>
									)
								)}
							</TableStyle.TableBody>
						</TableStyle.Table>
					</S.CompaniesTableContainer>
				</S.ModalContainer>
			</Modal>
		</S.Container>
	);
}
