import { useQuery } from 'react-query';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { getAllLeafletStock } from '../../../../../services/queries/Leaflet';
import {
	Document,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
	pdf,
} from '@react-pdf/renderer';
import ArrowUp from '../../../../../assets/arrowUp.png';
import ArrowDown from '../../../../../assets/arrowDown.png';
import { formatDateWithHours } from '../../../../../utils/parseDate';
import { toast } from 'react-toastify';
import { PDFstyles } from '../../../../../components/PDFReportStyles';
import * as TableStyle from '../../../../../components/Table/TableStyles';
import * as Excel from 'exceljs';
import { FaFileExcel, FaFilePdf } from 'react-icons/fa';

export function GenerateHistoryFile() {
	const CustomPDFstyles = StyleSheet.create({
		table: {
			width: '100%',
			borderCollapse: 'collapse',
		},
		tableHeaderCell: {
			backgroundColor: '#393939',
			color: 'white',
			fontSize: 12,
			textAlign: 'center',
			padding: 4,
		},
		tableDataCell: {
			fontSize: 12,
			textAlign: 'center',
			padding: 8,
			margin: 1,
			borderBottomColor: '#000',
			borderBottomWidth: 1,
			color: '#575757',
		},
		logo: {
			width: 120,
			left: 10,
		},
		heading: {
			fontSize: 24,
			marginBottom: 10,
			marginLeft: 200,
			fontFamily: 'Ubuntu',
			color: '#575757',
		},
	});

	const getColumnTitleStyle = (width: Number) => ({
		...CustomPDFstyles.tableHeaderCell,
		width: width + '%',
	});

	const getColumnDataStyle = (width: Number) => ({
		...CustomPDFstyles.tableDataCell,
		width: width + '%',
	});

	const fetchAllLeafletStockMasterHistory = useQuery(
		['leaflet-stock-history'],
		getAllLeafletStock,
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar o histórico de mudanças da folheteria. '
				);
			},
			refetchOnWindowFocus: false,
		}
	);
	const leafletHistory =
		fetchAllLeafletStockMasterHistory.data?.leafletStockMasterHistory;

	async function generateSheetContent() {
		const workbook = new Excel.Workbook();

		const worksheet = workbook.addWorksheet(
			'Histórico de modificações do estoque de folheteria'
		);

		let columns = [
			{ header: 'DATA-HORA', key: 'date', width: 20 },
			{ header: 'ENVELOPES', key: 'envelopes', width: 20 },
			{
				header: 'ENVELOPES DISPONÍVEIS',
				key: 'envelopes_available',
				width: 30,
			},
			{
				header: 'ENVELOPES PERSONALIZADOS',
				key: 'envelopes_personalized',
				width: 30,
			},
			{ header: 'ENVELOPES PERDIDOS', key: 'envelopes_lost', width: 40 },

			{ header: 'CARTAS BERÇO', key: 'accompanying_letters', width: 20 },
			{
				header: 'CARTAS BERÇO DISPONÍVEIS',
				key: 'accompanying_letters_available',
				width: 30,
			},
			{
				header: 'CARTAS BERÇO PERSONALIZADOS',
				key: 'accompanying_letters_personalized',
				width: 30,
			},
			{
				header: 'CARTAS BERÇO PERDIDOS',
				key: 'accompanying_letters_lost',
				width: 30,
			},
		];

		// headers
		worksheet.columns = columns;

		leafletHistory?.forEach((historyItem) => {
			const row = {
				date: formatDateWithHours(historyItem.created_at),
				envelopes: historyItem.new_value_quantity_total_envelopes,
				envelopes_available: historyItem.new_value_quantity_envelopes_available,
				envelopes_personalized:
					historyItem.new_value_quantity_envelopes_personalized,
				envelopes_lost: historyItem.new_value_quantity_envelopes_lost,

				accompanying_letters:
					historyItem.new_value_quantity_total_accompanying_letters,
				accompanying_letters_available:
					historyItem.new_value_quantity_accompanying_letters_available,
				accompanying_letters_personalized:
					historyItem.new_value_quantity_accompanying_letters_personalized,
				accompanying_letters_lost:
					historyItem.new_value_quantity_accompanying_letters_lost,
			};
			worksheet.addRow(row);
		});

		const buffer = await workbook.xlsx.writeBuffer();
		const fileType =
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

		const blob = new Blob([buffer], { type: fileType });

		return blob;
	}

	async function generatePDFContent() {
		const currentTime = new Date();

		const getCellContent = (originalValue: number, newValue: number) => (
			<Text style={{ ...getColumnDataStyle(70) }}>
				{originalValue} /{' '}
				<Text style={{ fontSize: 13, color: 'black' }}>{newValue}</Text>
				{newValue !== originalValue && (
					<Image
						source={
							Number(newValue) > Number(originalValue) ? ArrowUp : ArrowDown
						}
						style={{ width: 12, height: 12, marginLeft: 5 }}
					/>
				)}
			</Text>
		);

		return (
			<Document>
				<Page size='A3' style={PDFstyles.page} orientation='landscape'>
					<View style={PDFstyles.section}>
						<View>
							<Image
								src={`${window.location.origin}/LogoBounty.png`}
								style={CustomPDFstyles.logo}
							/>
							<Text style={CustomPDFstyles.heading}>
								Histórico de modificações do estoque de folheteria
							</Text>
						</View>

						<View style={CustomPDFstyles.table}>
							<View style={PDFstyles.resultsRow}>
								<Text style={{ ...getColumnTitleStyle(80) }}>
									DATA | HORA {'\n'}
									<Text>
										dd/mm/aaaa | <Text> hh/mm/ss</Text>
									</Text>
								</Text>

								<Text style={{ ...getColumnTitleStyle(70) }}>
									ENVELOPES{'\n'}
									<Text>
										valor antigo |{' '}
										<Text style={{ fontWeight: 'bold' }}>valor novo</Text>
									</Text>
								</Text>
								<Text style={{ ...getColumnTitleStyle(70) }}>
									ENVELOPES DISPONÍVEIS {'\n'}
									<Text>
										valor antigo |{' '}
										<Text style={{ fontWeight: 'bold' }}>valor novo</Text>
									</Text>
								</Text>
								<Text style={{ ...getColumnTitleStyle(70) }}>
									ENVELOPES PERSONALIZADOS {'\n'}
									<Text>
										valor antigo |{' '}
										<Text style={{ fontWeight: 'bold' }}>valor novo</Text>
									</Text>
								</Text>
								<Text style={{ ...getColumnTitleStyle(70) }}>
									ENVELOPES PERDIDOS {'\n'}
									<Text>
										valor antigo |{' '}
										<Text style={{ fontWeight: 'bold' }}>valor novo</Text>
									</Text>
								</Text>

								<Text style={{ ...getColumnTitleStyle(70) }}>
									CARTAS BERÇO{'\n'}
									<Text>
										valor antigo |{' '}
										<Text style={{ fontWeight: 'bold' }}>valor novo</Text>
									</Text>
								</Text>
								<Text style={{ ...getColumnTitleStyle(70) }}>
									CARTAS BERÇO DISPONÍVEIS {'\n'}
									<Text>
										valor antigo |{' '}
										<Text style={{ fontWeight: 'bold' }}>valor novo</Text>
									</Text>
								</Text>
								<Text style={{ ...getColumnTitleStyle(70) }}>
									CARTAS BERÇO PERSONALIZADOS {'\n'}
									<Text>
										valor antigo |{' '}
										<Text style={{ fontWeight: 'bold' }}>valor novo</Text>
									</Text>
								</Text>
								<Text style={{ ...getColumnTitleStyle(70) }}>
									CARTAS BERÇO PERDIDOS {'\n'}
									<Text>
										valor antigo |{' '}
										<Text style={{ fontWeight: 'bold' }}>valor novo</Text>
									</Text>
								</Text>
							</View>

							{leafletHistory?.map((historyItem) => (
								<View style={PDFstyles.resultsRow} key={historyItem.id}>
									<Text style={{ ...getColumnDataStyle(80) }}>
										{`${formatDateWithHours(historyItem.created_at)}`}
									</Text>
									{getCellContent(
										historyItem.original_value_quantity_total_envelopes,
										historyItem.new_value_quantity_total_envelopes
									)}
									{getCellContent(
										historyItem.original_value_quantity_envelopes_available,
										historyItem.new_value_quantity_envelopes_available
									)}
									{getCellContent(
										historyItem.original_value_quantity_envelopes_personalized,
										historyItem.new_value_quantity_envelopes_personalized
									)}
									{getCellContent(
										historyItem.original_value_quantity_envelopes_lost,
										historyItem.new_value_quantity_envelopes_lost
									)}

									{getCellContent(
										historyItem.original_value_quantity_total_accompanying_letters,
										historyItem.new_value_quantity_total_accompanying_letters
									)}
									{getCellContent(
										historyItem.original_value_quantity_accompanying_letters_available,
										historyItem.new_value_quantity_accompanying_letters_available
									)}
									{getCellContent(
										historyItem.original_value_quantity_accompanying_letters_personalized,
										historyItem.new_value_quantity_accompanying_letters_personalized
									)}
									{getCellContent(
										historyItem.original_value_quantity_accompanying_letters_lost,
										historyItem.new_value_quantity_accompanying_letters_lost
									)}
								</View>
							))}

							<View style={PDFstyles.footer}>
								<Text>
									Gerado em {currentTime.toLocaleDateString()} às{' '}
									{currentTime.toLocaleTimeString([], {
										hour: '2-digit',
										minute: '2-digit',
									})}{' '}
									no Bounty Master.
								</Text>
							</View>
						</View>
					</View>
				</Page>
			</Document>
		);
	}

	async function handleGenerateStockHistory(pdfReport: boolean) {
		try {
			let blob: Blob;

			if (pdfReport) {
				const PDFGenerated = await generatePDFContent();
				blob = await pdf(PDFGenerated).toBlob();
			} else {
				blob = await generateSheetContent();
			}

			// Create a temporary URL for the blob
			const url = URL.createObjectURL(blob);

			// Create a link element
			const link = document.createElement('a');
			link.href = url;
			link.download = `historico_modificacoes_estoque_folheteria.${
				pdfReport ? 'pdf' : 'xlsx'
			}`;

			// Simulate a click event to trigger the download
			link.dispatchEvent(new MouseEvent('click'));

			// Clean up the temporary URL
			URL.revokeObjectURL(url);
		} catch (error) {
			toast.error(
				'Ocorreu um erro ao tentar gerar o arquivo do histórico de modificações do estoque de folheteria. Tente novamente.'
			);
		}
	}

	return (
		<div style={{ display: 'flex', gap: '1rem', width: 'min-content' }}>
			<TableStyle.OptionButton
				data-rh='Baixar histórico PDF'
				onClick={async () => await handleGenerateStockHistory(true)}
			>
				<FaFilePdf />
			</TableStyle.OptionButton>

			<TableStyle.OptionButton
				data-rh='Baixar histórico XLSX'
				onClick={async () => await handleGenerateStockHistory(false)}
			>
				<FaFileExcel />
			</TableStyle.OptionButton>
		</div>
	);
}
