import { create } from 'zustand';
import { FilterParams } from '../components/Filter';
import {
	BillingStatusOptionsFilters,
	TransferProductOptionsFilters,
} from '../components/Filter/utils/SelectFilters';

type Store = {
	filtersParams: FilterParams[];
	setFiltersParams: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const filtersParams: FilterParams[] = [
	{
		name: 'Data',
		filter: 'date',
		value: '',
		type: 'date_interval',
		selected: false,
	},
	{
		name: 'Empresa',
		filter: 'company_name',
		value: '',
		type: 'company',
		selected: false,
	},
	{
		name: 'Status',
		filter: 'status',
		value: '',
		type: 'select',
		selected: false,
		selectOptions: BillingStatusOptionsFilters,
	},
	{
		name: 'Produto',
		filter: 'origin',
		value: '',
		type: 'select',
		selected: false,
		selectOptions: TransferProductOptionsFilters,
	},
];

const useBillingListStore = create<Store>()((set) => ({
	filtersParams,
	setFiltersParams: (f) => set({ filtersParams: f }),
	resetFilters: () => set({ filtersParams }),
}));

export { useBillingListStore };
