import { toast } from 'react-toastify';
import axios from 'axios';
import { getMessageError } from './DefaultErrors';

export function showErrorMessage(error: Error, defaultMessage = '') {
	if (axios.isAxiosError(error)) {
		let errorMessage = getMessageError(error.response?.data);

		toast.error(`${defaultMessage} ${errorMessage}`);
	} else {
		toast.error(`${defaultMessage}`);
	}
}
