import { SelectOption } from '..';

export const DeliveryAddressOptionsFilters: SelectOption[] = [
	{
		text: 'Colaboradores',
		value: 'collaborators',
	},
	{
		text: 'Empresa',
		value: 'company',
	},
	{
		text: 'Customizado',
		value: 'custom',
	},
];

export const StatusOptionsFilters: SelectOption[] = [
	{
		text: 'Ativos',
		value: 'true',
	},
	{
		text: 'Desativados',
		value: 'false',
	},
];

export const TransfersTypesOptionsFilters: SelectOption[] = [
	{
		text: 'Entrada',
		value: 'income',
	},
	{
		text: 'Saída',
		value: 'outcome',
	},
];

export const SendingTypesFilters: SelectOption[] = [
	{
		text: 'PLUS',
		value: 'plus',
	},
	{
		text: 'VIP',
		value: 'vip',
	},
];

export const TransferTypeOptionsFilters: SelectOption[] = [
	{ text: 'Entrada', value: 'entrance' },
	{ text: 'Saída', value: 'exit' },
];

export const TransferCategoryOptionsFilters: SelectOption[] = [
	{ text: 'Envio de recarga', value: 'balance_recharge_payment' },
	{ text: 'Recebimento de recarga', value: 'balance_recharge_receipt' },
	{ text: 'Estorno da empresa', value: 'company_chargeback' },
];

export const TransferProductOptionsFilters: SelectOption[] = [
	{ text: 'Benefícios Flexíveis', value: 'multiflex' },
	{ text: 'Bounty Control', value: 'corpway' },
];

export const BillingStatusOptionsFilters: SelectOption[] = [
	{ text: 'Pago', value: 'paid' },
	{ text: 'Emitido', value: 'created' },
	{ text: 'Processando', value: 'processing' },
	{ text: 'Processando pagamento', value: 'processing_payment' },
	{ text: 'Gerando boleto', value: 'enqueued' },
	{ text: 'Gerando boleto', value: 'initiated' },
	{ text: 'Expirado', value: 'overdue' },
	{ text: 'Erro na criação do boleto', value: 'error' },
];
