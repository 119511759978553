import { CompanyForm } from '../../../../../../components/CompanyForm';
import PageTitle from '../../../../../../components/PageTitle';
import * as S from './styles';

export function CreateCompany() {
	return (
		<S.Container>
			<PageTitle title='Criar empresa' />
			<CompanyForm update={false} />
		</S.Container>
	);
}
