import { useEffect, useRef, useState } from 'react';
import { useDialogModal } from '../../../../../../hooks/useDialogModal';
import { useQuery } from 'react-query';
import { showErrorMessage } from '../../../../../../utils/ErrorHandler';
import { RiAddCircleLine, RiCloseCircleLine } from 'react-icons/ri';
import * as S from '../styles';
import Modal from '../../../../../../components/Modal';
import PageTitle from '../../../../../../components/PageTitle';
import { Filter } from '../../../../../../components/Filter';
import { Pagination } from '../../../../../../components/Pagination';
import * as TableStyle from '../../../../../../components/Table/TableStyles';
import Loader from '../../../../../../components/Loader';
import avatarImg from '../../../../../../assets/avatar.svg';
import { EmptyContent } from '../../../../../../components/EmptyContent';
import { getAllCompanies } from '../../../../../../services/queries/Companies';
import { useAllCompaniesListStore } from '../../../../../../stores/useCompaniesListStore';

export interface CompanyToSelect {
	id: string;
	type: 'company';
	email: string;
	name: string;
	cnpj: string;
	avatar: string;
}

export interface CompaniesSelectorProps {
	alreadyAddedCompanies: CompanyToSelect[];
	onAddAndRemoveCompanies(
		companiesToAdd: CompanyToSelect[],
		companiesToRemove: CompanyToSelect[]
	): void;
	allowRemoveCompany?: boolean;
	buttonText?: string;
}

export function CompaniesSelector({
	alreadyAddedCompanies,
	onAddAndRemoveCompanies,
	allowRemoveCompany,
	buttonText,
}: CompaniesSelectorProps) {
	const [selectedCompanies, setSelectedCompanies] = useState<CompanyToSelect[]>(
		[]
	);
	const [selectedCompaniesToRemove, setSelectedCompaniesToRemove] = useState<
		CompanyToSelect[]
	>([]);

	const [open, setOpen] = useState(false);
	const [hasMadeChanges, setHasMadeChanges] = useState(false);
	const [companiesCurrentPage, setCompaniesCurrentPage] = useState(1);
	const [filtersParams, setFiltersParams] = useAllCompaniesListStore(
		(state) => [state.filtersParams, state.setFiltersParams, state.resetFilters]
	);

	const listContainer = useRef<HTMLUListElement | null>(null);

	const { openOptionsDialog } = useDialogModal();

	useEffect(() => {
		listContainer.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [companiesCurrentPage, listContainer]);

	useEffect(() => {
		if (open) {
			setSelectedCompanies([]);
			setSelectedCompaniesToRemove([]);
			setCompaniesCurrentPage(1);
			setHasMadeChanges(false);
		}
	}, [open]);

	const allCompaniesQuery = useQuery(
		['allCompaniesQuery', companiesCurrentPage, filtersParams],
		() => {
			return getAllCompanies(filtersParams, companiesCurrentPage);
		},
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar as empresas. ');
			},
			enabled: open,
			keepPreviousData: true,
		}
	);

	function handleSubmit() {
		onAddAndRemoveCompanies(selectedCompanies, selectedCompaniesToRemove);

		setOpen(false);
	}

	function handleClose() {
		if (!hasMadeChanges) {
			setOpen(false);
			return;
		}

		openOptionsDialog(
			'Alterações não salvas. Tem certeza que deseja sair?',
			'Confirmar',
			() => setOpen(false),
			'Cancelar',
			() => {}
		);
	}

	function isCompanyAlreadyAdded(id: string) {
		return !!alreadyAddedCompanies.find(
			(companyAdded) => companyAdded.id === id
		);
	}

	function isCompanySelected(id: string) {
		return !!selectedCompanies.find((c) => c.id === id);
	}

	function isCompanySelectedToRemove(id: string) {
		return !!selectedCompaniesToRemove.find((c) => c.id === id);
	}

	function handleSelectCompany(company: CompanyToSelect) {
		setHasMadeChanges(true);
		setSelectedCompanies([...selectedCompanies, company]);
	}

	function handleDeselectCompany(company: CompanyToSelect) {
		setHasMadeChanges(true);
		setSelectedCompanies(selectedCompanies.filter((c) => c.id !== company.id));
	}

	function handleSelectCompanyToRemove(company: CompanyToSelect) {
		setHasMadeChanges(true);
		setSelectedCompaniesToRemove([...selectedCompaniesToRemove, company]);
	}

	function handleDeselectCompanyToRemove(companyToDeselect: CompanyToSelect) {
		setHasMadeChanges(true);
		setSelectedCompaniesToRemove(
			selectedCompaniesToRemove.filter(
				(company) => company.id !== companyToDeselect.id
			)
		);
	}

	function handleSelector(company: CompanyToSelect) {
		//Non-selected to add
		if (!isCompanyAlreadyAdded(company.id) && !isCompanySelected(company.id)) {
			return (
				<S.Selector
					selected={isCompanySelected(company.id)}
					onClick={() => handleSelectCompany(company)}
					data-testid={`${company.id}-select`}
				>
					<RiAddCircleLine />
				</S.Selector>
			);
		}

		//Already added non-selected to remove
		if (
			isCompanyAlreadyAdded(company.id) &&
			!isCompanySelectedToRemove(company.id!)
		) {
			return (
				<S.Selector
					selected={false}
					remove={true}
					disabled={allowRemoveCompany ? false : true}
					title={allowRemoveCompany ? '' : 'Empresa já adicionada'}
					onClick={() => handleSelectCompanyToRemove(company)}
					data-testid={`${company.id}-remove`}
				>
					<RiCloseCircleLine />
				</S.Selector>
			);
		}

		//Already added and selected to remove
		if (isCompanySelectedToRemove(company.id!)) {
			return (
				<S.Selector
					selected={true}
					onClick={() => handleDeselectCompanyToRemove(company)}
				>
					<RiAddCircleLine />
				</S.Selector>
			);
		}

		//Selected to add
		return (
			<S.Selector
				selected={isCompanySelected(company.id!)}
				onClick={() => handleDeselectCompany(company)}
				data-testid={`${company.id}-unselect`}
			>
				<RiCloseCircleLine />
			</S.Selector>
		);
	}

	if (allCompaniesQuery.isLoading) {
		return (
			<>
				<S.MainButton onClick={() => setOpen(true)}>
					{buttonText ?? 'Adicionar empresas'}
				</S.MainButton>
				<Modal isOpen={open} enableClose onRequestClose={handleClose}>
					<S.Container data-testid='companiesSelector-modal-container'>
						<TableStyle.TableHeaderContainer>
							<PageTitle title='Selecione empresas' />
							<Filter
								filterParams={filtersParams}
								onFiltersChanged={(updatedFilters) => {
									setCompaniesCurrentPage(1);
									setFiltersParams(updatedFilters);
								}}
							/>
							<Pagination
								onPageChange={(page) => setCompaniesCurrentPage(page)}
								currentPage={companiesCurrentPage}
								totalCount={1}
							/>
						</TableStyle.TableHeaderContainer>
						<Loader />
					</S.Container>
				</Modal>
			</>
		);
	}

	return (
		<div>
			<S.MainButton onClick={() => setOpen(true)}>
				{buttonText ?? 'Adicionar empresas'}
			</S.MainButton>

			<Modal isOpen={open} enableClose onRequestClose={handleClose}>
				<S.Container>
					<TableStyle.TableHeaderContainer>
						<PageTitle
							title='Selecione empresas'
							totalRecords={allCompaniesQuery.data?.total_companies ?? 0}
						/>
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								setCompaniesCurrentPage(1);
								setFiltersParams(updatedFilters);
							}}
						/>
						<Pagination
							onPageChange={(page) => setCompaniesCurrentPage(page)}
							currentPage={companiesCurrentPage}
							totalCount={allCompaniesQuery.data?.total_companies ?? 0}
						/>
					</TableStyle.TableHeaderContainer>

					<S.ItemsList ref={listContainer}>
						{allCompaniesQuery.data?.total_companies! > 0 ? (
							allCompaniesQuery.data?.companies.map((company) => {
								return (
									<S.ItemContainer key={company.id}>
										<S.ItemCard>
											<S.ItemAvatar
												src={company.avatar ? company.avatar : avatarImg}
											/>

											<S.ItemInfoContainer>
												<S.ItemName>{company.name}</S.ItemName>
												<S.ItemInfo>{company.email}</S.ItemInfo>
											</S.ItemInfoContainer>
										</S.ItemCard>

										{handleSelector(company)}
									</S.ItemContainer>
								);
							})
						) : (
							<EmptyContent text='Nenhuma empresa disponível' />
						)}
					</S.ItemsList>

					<S.SaveButton style={{ width: '16rem' }} onClick={handleSubmit}>
						Salvar
					</S.SaveButton>
				</S.Container>
			</Modal>
		</div>
	);
}
