import {
	Navigate,
	Route,
	Routes as RouterRoutes,
	useLocation,
} from 'react-router-dom';
import { CompanyFormProvider } from '../contexts/CompanyFormContext';
import { CreateCompany } from '../pages/Main/GroupCompanies/GroupCompanyDetails/Companies/Create';
import { CompanyDetails } from '../pages/Main/GroupCompanies/GroupCompanyDetails/Companies/Details';
import { GroupCompanies } from '../pages/Main/GroupCompanies';
import { GroupCompanyDetails } from '../pages/Main/GroupCompanies/GroupCompanyDetails';
import { CreateTransfer } from '../pages/Main/TransfersV2/Create';
import { RequireAuth } from './RequireAuth';
import { Chargebacks } from '../pages/Main/Chargebacks';
import { CreateChargeback } from '../pages/Main/Chargebacks/CreateChargeback';
import ChargebackDetails from '../pages/Main/Chargebacks/ChargebackDetails';
import { Support } from '../pages/Main/Support';
import Terms from '../pages/Main/Terms';
import { CardsBatches } from '../pages/Main/Cards';
import { CardBatchDetails } from '../pages/Main/Cards/CardBatchDetails';
import NotificationsPreferences from '../pages/Main/NotificationsPreferences';
import { UnderageDocuments } from '../pages/Main/UnderageDocuments';
import { UnderageDocumentReview } from '../pages/Main/UnderageDocuments/UnderageDocumentReview';
import { Partners } from '../pages/Main/Partners';
import { ManageCompanyPartners } from '../pages/Main/Partners/ManageCompanyPartners';
import { PartnersStats } from '../pages/Main/Partners/PartnersStats';
import { TransfersV2 } from '../pages/Main/TransfersV2';
import { PartnerQuantitative } from '../pages/Main/Partners/PartnersStats/PartnerQuantitative';
import { DuplicateCardsStats } from '../pages/Main/Cards/DuplicateCardsStats';
import { CardStock } from '../pages/Main/Cards/CardStock';
import { Billings } from '../pages/Main/Billing';
import { ReportUsers } from '../pages/Main/ReportUsers';
import { CorpwayCards } from '../pages/Main/Cards/CorpwayCards';
import { PreRegisterCompanies } from '../pages/Main/PreRegisterCompanies';
import { PreRegisterCompanyDetails } from '../pages/Main/PreRegisterCompanies/Details';

export default function MainRoutes() {
	let location = useLocation();

	return (
		<RequireAuth>
			<RouterRoutes>
				<Route
					path={`/`}
					element={
						<Navigate
							to='/group-companies'
							state={{ from: location }}
							replace
						/>
					}
				/>

				<Route path={`/group-companies`} element={<GroupCompanies />} />

				<Route path={`/pre-register`} element={<PreRegisterCompanies />} />
				<Route
					path={`/pre-register/:id`}
					element={<PreRegisterCompanyDetails />}
				/>

				<Route
					path={`/group-companies/:id`}
					element={<GroupCompanyDetails />}
				/>

				<Route
					path={`/group-companies/:groupCompanyId/companies/create`}
					element={
						<CompanyFormProvider>
							<CreateCompany />
						</CompanyFormProvider>
					}
				/>
				<Route
					path={`/group-companies/:groupCompanyId/companies/:id`}
					element={
						<CompanyFormProvider>
							<CompanyDetails />
						</CompanyFormProvider>
					}
				/>

				<Route path={`/transfers`} element={<TransfersV2 />} />

				<Route path={`/transfers/create`} element={<CreateTransfer />} />

				<Route path='/chargebacks' element={<Chargebacks />} />
				<Route path='/chargebacks/:id' element={<ChargebackDetails />} />
				<Route path='/chargebacks/create' element={<CreateChargeback />} />

				<Route path='/underage-documents' element={<UnderageDocuments />} />
				<Route
					path='/underage-documents/:id'
					element={<UnderageDocumentReview />}
				/>

				<Route path='/cards' element={<CardsBatches />} />
				<Route path='/cards/stats' element={<DuplicateCardsStats />} />
				<Route path='/cards/:id' element={<CardBatchDetails />} />
				<Route path='/cards/stock' element={<CardStock />} />
				<Route path='/corpway-cards' element={<CorpwayCards />} />

				<Route
					path='/notifications-preferences'
					element={<NotificationsPreferences />}
				/>

				<Route path='/partners' element={<Partners />} />
				<Route path='/partners/stats' element={<PartnersStats />} />
				<Route
					path='/partners/company/:company_id/quantitative/:partner_id'
					element={<PartnerQuantitative />}
				/>
				<Route
					path='/partners/company/:companyId/manage'
					element={<ManageCompanyPartners />}
				/>

				<Route path='/billing' element={<Billings />} />

				<Route path='/notify-users' element={<ReportUsers />} />

				<Route path='/support' element={<Support />} />

				<Route path='/update-terms/:updateKey' element={<Terms />} />

				<Route
					path={`*`}
					element={<Navigate to='/404' state={{ from: location }} replace />}
				/>
			</RouterRoutes>
		</RequireAuth>
	);
}
