import { Operator } from '../../@types';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import { AccessInfo } from '../../pages/Main/GroupCompanies/ManageOperatorsAccessModal';
import { OperatorFormData } from '../../pages/Main/GroupCompanies/ManageOperatorsAccessModal/CreateOperatorModal';
import { UserToSelect } from '../../pages/Main/ReportUsers/SegmentationModal/components/UsersSelector';
import api from '../api';

interface AllUsersResponse {
	users: UserToSelect[];
	total_users: number;
}

export interface AllOperatorsResponse {
	operators: Operator[];
	total_operators: number;
}

export async function getAllUsers(
	filterParams: FilterParams[],
	page = 1
): Promise<AllUsersResponse> {
	const { data } = await api.get(`/api/v1/master/users/all/q`, {
		params: {
			page,
			...parseFilterParamsToParams(filterParams),
		},
	});

	return data;
}

export async function getAllOperators(
	corporate_id: string,
	filterParams: FilterParams[],
	page = 1
): Promise<AllOperatorsResponse> {
	const { data } = await api.get(
		`/api/v1/master/groups-companies/${corporate_id}/operators/all/q`,
		{
			params: {
				page,
				...parseFilterParamsToParams(filterParams),
			},
		}
	);

	return data;
}

export async function createOperator(
	operator: OperatorFormData
): Promise<Operator> {
	const { data } = await api.post(`/api/v1/master/operators`, operator);
	return data;
}

export async function setOperatorsAccess(operatorsAccess: AccessInfo[]) {
	const { data } = await api.patch(`/api/v1/master/operators/access`, {
		operators_access: operatorsAccess,
	});
	return data;
}
