import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	margin-top: 2rem;
	width: 100%;
	max-width: 60rem;
`;

export const FormContainer = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr;
`;

export const Submit = styled.button`
	${OptionsButton}
	padding: 0;
	width: 16rem;
	height: 4rem;
`;
