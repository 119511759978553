import { InputHTMLAttributes } from 'react';
import { parseCurrencyStrToNumber } from '../../utils/parseCurrency';

interface CurrencyInputProps extends InputHTMLAttributes<HTMLInputElement> {
	value: number | string;
	onValueChange?:
		| ((values: { value: string; floatValue: number }) => void)
		| undefined;
}
const moneyMask = (value: string) => {
	value = value.replaceAll('.', '').replace(',', '').replace(/\D/g, '');

	const options = { minimumFractionDigits: 2 };
	const result = new Intl.NumberFormat('pt-BR', options).format(
		isNaN(parseFloat(value)) ? 0 : parseFloat(value) / 100
	);

	return result;
};

export function CurrencyInput({
	onValueChange,
	value,
	...rest
}: CurrencyInputProps) {
	if (typeof value === 'string') {
		value = parseCurrencyStrToNumber(value);
	}

	function handleChangeValue(input: string) {
		const value = moneyMask(input);
		const floatValue = parseCurrencyStrToNumber(value);
		// console.log(value, floatValue);
		onValueChange && onValueChange({ value, floatValue });
	}
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				fontSize: '1.4rem',
				columnGap: '0.2rem',
			}}
		>
			R$
			<input
				{...rest}
				onChange={(e) => handleChangeValue(e.target.value)}
				value={moneyMask(value.toFixed(2))}
			/>
		</div>
	);
}
