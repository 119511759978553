import { create } from 'zustand';
import { FilterParams } from '../components/Filter';
import { TransferProductOptionsFilters } from '../components/Filter/utils/SelectFilters';

type Store = {
	filtersParams: FilterParams[];
	setFiltersParams: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const filtersParams: FilterParams[] = [
	{ name: 'Nome', filter: 'name', value: '', type: 'text', selected: false },
	{
		name: 'Email',
		filter: 'email',
		value: '',
		type: 'email',
		selected: false,
	},
	{
		name: 'Produto',
		filter: 'origin',
		value: '',
		type: 'select',
		selectOptions: TransferProductOptionsFilters,
		selected: false,
	},
];

const useOperatorsListStore = create<Store>()((set) => ({
	filtersParams,
	setFiltersParams: (f) => set({ filtersParams: f }),
	resetFilters: () => set({ filtersParams }),
}));

export { useOperatorsListStore };
